import styled from '@mui/styles/styled';
import makeStyles from '@mui/styles/makeStyles';

export const CatalogTableWrapper = styled('div')<
  Theme,
  { filterOpen: boolean }
>(({ theme, filterOpen }) => ({
  display: 'grid',
  gridTemplateAreas: filterOpen ? "'filters' 'table'" : "'table'",
  gridTemplateColumns: filterOpen ? '250px 1fr' : '1fr',
  columnGap: theme.spacing(2),
}));

export const useStyles = makeStyles({
  exportLink: { display: 'none' },
});
