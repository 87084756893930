import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Cyber Week Phase';
const id = 'cyberweek-filter';
export const CyberWeekPhaseFilter = React.memo(() => {
  const { cyberWeekPhaseFilter, setCyberWeekPhaseFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  return (
    <FormControl size="small" margin="none">
      <Autocomplete
        id={id}
        multiple
        options={['pre-event', 'event', 'post-event']}
        value={cyberWeekPhaseFilter}
        onChange={(_, value) => setCyberWeekPhaseFilter(value)}
        classes={{ inputRoot }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            size="small"
            margin="dense"
          />
        )}
      />
    </FormControl>
  );
});
