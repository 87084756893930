import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { loadTestingApiRef } from '../../api/LoadTestOrchestratorApi';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useStyles } from '../../StylingComponent/styles';

interface StopRampButtonProps {
  rampId: string;
  stepVus: number;
  intervalSeconds: number;
  targetVus: number;
  disabled: boolean;
  showSnackbar: (message: string, severity: 'success' | 'error') => void;
}

export const StopRampButton: React.FC<StopRampButtonProps> = ({
  rampId,
  stepVus,
  intervalSeconds,
  targetVus,
  disabled,
  showSnackbar,
}) => {
  const loadTestingApi = useApi(loadTestingApiRef);
  const classes = useStyles();

  const handleStopRamp = async () => {
    try {
      await loadTestingApi.stopRamp(rampId);
      showSnackbar(
        `Stopping ramp with step VUs: ${stepVus}, interval seconds: ${intervalSeconds}, target VUs: ${targetVus}`,
        'success',
      );
    } catch (error) {
      showSnackbar(`Error stopping ramp with ID: ${rampId}`, 'error');
    }
  };

  return (
    <Tooltip title="Stops the ramping process and keeps the current VUs">
      <span>
        <Button
          color="primary"
          onClick={handleStopRamp}
          variant="outlined"
          className={classes.callToActionButton}
          disabled={disabled}
        >
          Stop
        </Button>
      </span>
    </Tooltip>
  );
};
