import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import { IPreferencesService } from '../../../services';
import { Tooltip } from '../../../common';

interface Props {
  service: IPreferencesService;
}

export const UserPreferencesAction = observer(({ service }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [preferences, setPreferences] = useLocalStorage<{
    absoluteDate: boolean;
  }>(`preferences`, { absoluteDate: false });

  useEffect(() => {
    if (preferences) {
      service.setAbsoluteDate(preferences.absoluteDate);
    }
  }, [service, preferences]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'preferences-popover' : undefined;

  return (
    <>
      <Tooltip title="User preferences">
        <IconButton aria-label="user preferences" onClick={handleClick}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardContent>
            <Typography color="secondary" gutterBottom>
              User Preferences
            </Typography>
            <Typography variant="body2">
              Preferences are saved in your browser's local storage.
            </Typography>
            <hr />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={service.absoluteDate}
                    onChange={(_, value) =>
                      setPreferences({ absoluteDate: value })
                    }
                  />
                }
                label="Use absolute datetime."
              />
            </FormGroup>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
});
