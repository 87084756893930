import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import LensIcon from '@mui/icons-material/Lens';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useCostGrowthLegendStyles } from '../../utils/styles';

export type LegendItemProps = {
  title: string;
  tooltipText?: string;
  markerColor?: string;
};

export const LegendItem = ({
  title,
  tooltipText,
  markerColor,
  children,
}: PropsWithChildren<LegendItemProps>) => {
  const classes = useCostGrowthLegendStyles();
  return (
    <Box display="flex" flexDirection="column">
      <Box
        minHeight={25}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        {markerColor && (
          <div className={classes.marker}>
            <LensIcon style={{ fontSize: '1em', fill: markerColor }} />
          </div>
        )}
        <Typography className={classes.title} variant="overline">
          {title}
        </Typography>
        {tooltipText && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={
              <Typography className={classes.tooltipText}>
                {tooltipText}
              </Typography>
            }
            placement="top-start"
          >
            <span role="img" aria-label="help" className={classes.helpIcon}>
              <HelpOutlineOutlinedIcon fontSize="small" />
            </span>
          </Tooltip>
        )}
      </Box>
      <Box marginLeft={markerColor ? '1.5em' : 0}>
        <Typography className={classes.h5} variant="h5">
          {children}
        </Typography>
      </Box>
    </Box>
  );
};
