import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TrackedLink } from '../../Tracking';

interface EmptyContentProps {
  action: string;
}

export const EmptyContent = ({ action }: EmptyContentProps) => {
  const watchTeamsLink = (): IEventTracker => ({
    plugin: 'catalog',
    eventCategory: 'Api Catalog',
    eventLabel: 'Watch teams link is clicked from empty owned ${action}',
    eventAction: 'click',
    interaction: 'onClick',
  });

  return (
    <Box
      height="350px"
      textAlign="center"
      display="flex"
      alignItems="center"
      padding="0 50px"
    >
      <Typography>
        We couldn't find any {`${action}`} owned by your team(s), but you can
        update your{' '}
        <TrackedLink
          to="/settings/personalization"
          color="primary"
          {...watchTeamsLink()}
        >
          personalization settings
        </TrackedLink>{' '}
        to "watch" content from the delivery teams you collaborate with which
        will enable you to view them here.
      </Typography>
    </Box>
  );
};
