import React from 'react';
import TimerIcon from '@mui/icons-material/Timer';

import { Text } from '../../../../common';
import { Timer } from '../../../common';

type Props = { status: string; startedAt: string };

function areEqual(prevProps: Props, nextProps: Props) {
  return prevProps.status === nextProps.status;
}

export const Runtime = React.memo(function RuntimeComponent(props: Props) {
  return (
    <Text>
      <TimerIcon fontSize="inherit" />
      <Text marginLeft={0.5}>
        Running for:&nbsp;
        <Timer startedAt={props.startedAt} status={props.status} />
      </Text>
    </Text>
  );
}, areEqual);
