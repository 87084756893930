import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FilterGroupsContext } from 'plugin-ui-components';
import { CostCentersFilter, TeamsFilter, TechTeamsFilter } from './filters';

export const TeamsResultsFilters = () => {
  const { setCostCenterFilter, setIdsAndAliasesFilter, setTeamsFilter } =
    React.useContext(FilterGroupsContext);

  const onClearFilters = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    setCostCenterFilter([]);
    setIdsAndAliasesFilter([]);
    setTeamsFilter([]);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop="1.5rem"
      rowGap="0.5rem"
      padding="0.5rem"
    >
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6" style={{ margin: 0, fontSize: '0.75rem' }}>
          Refine Results
        </Typography>
        <Link href="" color="inherit" onClick={onClearFilters}>
          Clear
        </Link>
      </Box>
      <TeamsFilter label="Parent Team" />
      <CostCentersFilter />
      <TechTeamsFilter />
    </Box>
  );
};
