import React from 'react';
import { TableColumn } from '@backstage/core-components';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch';
import { TestRun, Ramp, Test } from '../../api/LoadTestOrchestratorApi';
import { CopyField, InfoTooltip } from 'plugin-ui-components';
import { RuntimeVusComponent } from './RuntimeVusComponent';
import { StateComponent } from './StateComponent';
import { StateControlButtons } from './StateControlButtons';
import { StopRampButton } from './StopRampButton';
import { useStyles } from '../../StylingComponent/styles';

export const getTestRunColumns = (
  test: Test,
  showSnackbar: (message: string, severity: 'success' | 'error') => void,
  fetchTestDetails: () => Promise<void>,
  setSelectedTestRunId: React.Dispatch<React.SetStateAction<string | null>>,
  setCreateRampOpen: React.Dispatch<React.SetStateAction<boolean>>,
  classes: ReturnType<typeof useStyles>,
): TableColumn<TestRun>[] => {
  return [
    {
      title: 'Test Run ID',
      field: 'id',
      render: (rowData: TestRun) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <CopyField>{rowData.id}</CopyField>
          </Grid>
        </Grid>
      ),
    },
    {
      title: (
        <>
          Script Slug
          <InfoTooltip>
            This is made up of a hyphenated prefix of the folder in which the
            test scenario name is in, example: fashion-store. Separated by an
            underscore, then comes the suffix which is the name of the test
            scenario, example: add-to-cart.
          </InfoTooltip>
        </>
      ),
      field: 'script_slug',
      highlight: true,
    },
    {
      title: (
        <>
          Parallelism
          <InfoTooltip>
            <code>Parallelism</code> denotes the number of pods generating load
            test traffic in parallel. VUs are distributed across pods evenly,
            e.g. 90 VUs and parallelism of 3 would mean 30 VUs per pod.
          </InfoTooltip>
        </>
      ),
      field: 'run_options.parallelism',
    },
    {
      title: (
        <>
          State
          <InfoTooltip>
            There are 3 states, namely: <br />
            <br />
            <ul>
              <li>
                <code>scheduled</code> for when the test{' '}
                <code>scheduled_for</code> date is in the future or pods are
                initialising
              </li>
              <br />
              <br />
              <li>
                <code>running</code> for when the specific scenario is running
              </li>
              <br />
              <br />
              <li>
                <code>stopped</code> for when the scenario is stopped and all
                relevant resources have been deleted. At the latter state, the
                scenario cannot run again.
              </li>
            </ul>
          </InfoTooltip>
        </>
      ),
      field: 'state',
      render: rowData => (
        <StateComponent testRun={rowData} refetch={fetchTestDetails} />
      ),
    },
    {
      title: (
        <>
          Runtime VUs
          <InfoTooltip>
            VUs (Virtual Users) are simulated users that run separate and
            concurrent iterations of your test script.
          </InfoTooltip>
        </>
      ),
      field: 'runtime_vus',
      render: rowData => (
        <RuntimeVusComponent testRun={rowData} refetch={fetchTestDetails} />
      ),
    },
    {
      title: 'VUs Ramping Actions',
      field: 'ramps',
      render: (rowData: TestRun) => {
        const activeRamps = rowData.ramps!.filter((ramp: Ramp) => ramp.active);

        const state = rowData.state_parameters!.state;
        const hasRunningTestRun = state === 'running';
        const isButtonDisabled = activeRamps.length > 0 || !hasRunningTestRun;

        let tooltipMessage = '';

        if (activeRamps.length > 0) {
          tooltipMessage =
            'A ramp is currently running. Stop the ramp before creating a new one.';
        } else if (hasRunningTestRun) {
          tooltipMessage =
            'Creates the gradual ramping up or down of VUs to the test run. This will respectively increase or decrease the number of VUs over time.';
        } else {
          tooltipMessage =
            'It is not possible to create a ramp for a test run that is not running.';
        }
        return (
          <Grid container spacing={1} alignItems="center" wrap="wrap">
            <Grid item>
              <Tooltip title={tooltipMessage}>
                <span>
                  <Button
                    color="primary"
                    variant="outlined"
                    className={classes.callToActionButton}
                    onClick={() => {
                      setSelectedTestRunId(rowData.id);
                      setCreateRampOpen(true);
                    }}
                    disabled={isButtonDisabled}
                  >
                    Create
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            {activeRamps.map((ramp: Ramp) => (
              <Grid item key={ramp.id}>
                <StopRampButton
                  rampId={ramp.id}
                  showSnackbar={showSnackbar}
                  stepVus={ramp.step_vus}
                  intervalSeconds={ramp.interval_seconds}
                  targetVus={ramp.target_vus}
                  disabled={activeRamps.length === 0}
                />
              </Grid>
            ))}
          </Grid>
        );
      },
    },
    {
      title: 'Test Run Actions',
      field: 'test_run_actions',
      render: rowData => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <StateControlButtons
              testRun={rowData}
              scheduledFor={test.scheduled_for}
              showSnackbar={showSnackbar}
              refetch={fetchTestDetails}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      title: 'Metrics',
      field: 'metrics',
      render: rowData => (
        <Grid container alignItems="center">
          <Tooltip title="View metrics for this test run">
            <span>
              <IconButton
                href={`https://load-test-orchestrator.load-testing.zalan.do/testruns/${rowData.id}/status`}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                <LaunchIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      ),
    },
  ];
};
