import React from 'react';
import Box from '@mui/material/Box';
import { TrackedLinkButton } from 'plugin-ui-components';
import PetsIcon from '@mui/icons-material/Pets';
import DescriptionIcon from '@mui/icons-material/Description';

export const SupportButtons = () => {
  const SUPPORT_STYLE = { width: '15px', height: '15px' };

  const ML_EXPERIMENTATION_SUPPORT_URLS = [
    {
      name: 'Docs',
      link: 'https://ml-platform.docs.zalando.net/',
      icon: <DescriptionIcon style={SUPPORT_STYLE} />,
    },
    {
      name: 'Users Chat',
      link: 'https://chat.google.com/room/AAAAZIM2aIA',
      icon: <PetsIcon style={SUPPORT_STYLE} />,
    },
  ];

  return (
    <Box color="white" display="flex" flexDirection="column">
      {ML_EXPERIMENTATION_SUPPORT_URLS.map(supportUrl => {
        return (
          <Box
            display="grid"
            marginTop="8px"
            key={`${supportUrl.name}-support-url`}
          >
            <TrackedLinkButton
              size="small"
              target="_blank"
              variant="outlined"
              color="inherit"
              to={supportUrl.link}
              plugin="ml-experimentation"
              eventCategory={`Navigation to ${supportUrl.name}`}
              eventLabel={`URL: ${supportUrl.link} - Location: ${supportUrl.name}`}
            >
              <span>{supportUrl.name}&nbsp;</span>
              {supportUrl.icon}
            </TrackedLinkButton>
          </Box>
        );
      })}
    </Box>
  );
};
