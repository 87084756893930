import makeStyles from '@mui/styles/makeStyles';

export const useDialogStyles = makeStyles(theme => ({
  dialog: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxWidth: '800px',
    maxHeight: '600px',
  },

  dialog__icon: {
    '& > span': {
      textTransform: 'lowercase',
      verticalAlign: 'middle',
      color: theme.palette.primary.main,
    },
  },
}));

export const useRollbackLoaderStyles = makeStyles({
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  loader__message: {
    position: 'relative',
    textAlign: 'center',
    fontSize: '20px',
  },
});

export const useRollbackErrorStyles = makeStyles({
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  error__title: {
    position: 'relative',
    textAlign: 'center',
    fontSize: '20px',
    marginBottom: 0,
  },
  error__details: {
    position: 'relative',
    textAlign: 'center',
    fontSize: '15px',
  },
});

export const useRollbackSuccessStyles = makeStyles({
  success: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  success__message: {
    position: 'relative',
    textAlign: 'center',
    fontSize: '20px',
  },
});

export const useRollbackPlanStyles = makeStyles(theme => ({
  plan: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  plan__title: {
    position: 'relative',
    marginTop: 0,
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  plan__subtitle: {
    position: 'relative',
    color: theme.palette.text.hint,
    fontWeight: 600,
    fontSize: '11px',
    marginBottom: 0,
    textTransform: 'uppercase',
  },
  plan__description: {
    fontSize: '14px',
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
  },
  plan__summary: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: '150px auto',
    fontSize: '14px',
    textAlign: 'left',
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    justifyContent: 'left',
    gridGap: 1,
    '&+&': {
      borderTop: `1px solid ${theme.palette.background.paper}`,
    },
  },
  plan__from: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    flexGrow: 1,
  },
  plan__list: {
    width: '100%',
    padding: 0,
    listStyle: 'none',
  },
  plan__option: {
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.background.default}`,
    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
  },
  plan__disabled: {
    opacity: 0.5,
  },
  plan__heading: {
    display: 'flex',
    alignItems: 'center',
  },
  plan__action: {
    paddingTop: theme.spacing(3),
    marginTop: 'auto',
    textAlign: 'center',
  },
}));

export const useOptionStyles = makeStyles({
  title: {
    display: 'flex',
    alignItem: 'center',
  },
  label: {
    fontSize: '14px',
  },
});

export const useRollbackSummaryStyles = makeStyles(theme => ({
  title: {
    position: 'relative',
    color: theme.palette.text.hint,
    fontWeight: 600,
    fontSize: '11px',
    marginBottom: 0,
    textTransform: 'uppercase',
  },
  description: {
    fontSize: '14px',
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
  },
  summary: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: '150px auto',
    fontSize: '14px',
    textAlign: 'left',
    padding: theme.spacing(0, 1),
    alignItems: 'center',
    justifyContent: 'left',
    gridGap: 1,
    '&+&': {
      borderTop: `1px solid ${theme.palette.background.paper}`,
    },
  },
  action: {
    paddingTop: theme.spacing(3),
    marginTop: 'auto',
    textAlign: 'center',
  },
}));
