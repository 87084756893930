import { getTeamNameFromFullName } from 'plugin-core';
import {
  Entity,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { TeamScorecardsTreeData } from './components/Overview/types';
import { UserTeamsScorecardsInfo } from './hooks';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import cloneDeep from 'lodash/cloneDeep';
import type {
  ApplicationsScorecardsContextRelations,
  IApplicationsScorecardsContext,
} from './ApplicationScorecardsContext';

export const calculateScorecardCompletion = (
  scorecards: ScorecardEntityWithAssessment[],
) => {
  if (!scorecards) return 0;
  const totalScorecards = scorecards.filter(
    sc => !!sc.spec.checks.length,
  ).length;
  const completedScorecards = scorecards
    .filter(sc => !!sc.spec.checks.length)
    .filter(scorecard => scorecard.spec.completionPercentage === 100).length;
  return Math.round((completedScorecards / totalScorecards) * 100) || 0;
};

/* Build a tree structure from a user's teams
 * containing each team's scorecard metrics
 */
export function transformUserTeamsToTree(
  userTeams: IEntityGroup[],
  userTeamsInfo: UserTeamsScorecardsInfo,
): TeamScorecardsTreeData[] {
  const { teamMetrics } = userTeamsInfo;

  // Create the initial team data structure
  const tree = userTeams.map((group, index) => {
    const { name } = group.metadata;
    const metrics = teamMetrics.get(name);

    return {
      parentId: 0,
      id: index + 1,
      name,
      fullName: getTeamNameFromFullName(group.spec.fullName),
      failing: metrics?.failing || 0,
      passing: metrics?.passing || 0,
      notApplicable: metrics?.notApplicable || 0,
      score: metrics?.score || 0,
      childTeams: userTeams
        .filter(t =>
          t.relations?.find(
            rel =>
              rel.type === 'childOf' &&
              parseEntityRef(rel.targetRef).name === name,
          ),
        )
        .map(t => getTeamNameFromFullName(t.spec.fullName)),
      relations: group.relations?.filter(({ type }) =>
        ['parentOf', 'childOf'].includes(type),
      ),
    } as TeamScorecardsTreeData;
  });

  // Assign parentId based on "childOf" relations
  tree.forEach(team => {
    const childRelation = team.relations?.find(
      ({ type }) => type === 'childOf',
    );
    if (childRelation) {
      const parentTeam = tree.find(
        t => t.name === parseEntityRef(childRelation.targetRef).name,
      );
      if (parentTeam) {
        team.parentId = parentTeam.id;
      }
    }
  });

  // Sort teams by parentId in descending order (process deeper children first)
  const sortedTeams = [...tree].sort((a, b) => b.parentId - a.parentId);

  // Aggregate scores for parents from their children
  sortedTeams.forEach(team => {
    if (team.parentId !== 0) {
      const parentTeam = tree.find(t => t.id === team.parentId);
      if (parentTeam) {
        parentTeam.failing += team.failing;
        parentTeam.passing += team.passing;
        parentTeam.notApplicable += team.notApplicable;

        const { failing, passing } = parentTeam;
        parentTeam.score =
          passing + failing > 0
            ? Math.round((passing / (passing + failing)) * 100)
            : 0;
      }
    }
  });

  return tree;
}

export const getColor = (
  completionPercentage: number,
): 'success' | 'warning' | 'error' => {
  if (completionPercentage >= 90) {
    return 'success';
  }
  if (completionPercentage >= 50) {
    return 'warning';
  }
  return 'error';
};

/* Fetches an application's scorecards and their assessments
 * either via passed relations or by fetching them through the catalog API
 */
export const getScorecardsWithAssessments = async (
  application: Entity,
  catalogApi: CatalogApi,
  relations: ApplicationsScorecardsContextRelations | undefined,
  scorecardsToShow?: IEntityScorecard[],
): Promise<{
  assessments: ScorecardEntityWithAssessment[];
  scorecards: IEntityScorecard[];
}> => {
  try {
    // Extract relations from the application, if any
    const appRelations = application.relations || [];
    let scorecards: IEntityScorecard[] = relations?.scorecards || [];
    let assessments: IEntityScorecardAssessment[] =
      relations?.assessments || [];

    // If scorecards and assessments are not pre-provided (from context), fetch them from the catalog
    if (!relations) {
      const scorecardRefs = appRelations
        .filter(rel => rel.type === 'consumesScorecard')
        .map(rel => rel.targetRef)
        // If scorecardsToShow is provided, filter to only include those scorecards
        .filter(
          targetRef =>
            !scorecardsToShow ||
            scorecardsToShow.some(
              scorecard =>
                scorecard.metadata.name === parseEntityRef(targetRef).name,
            ),
        );

      const assessmentRefs = appRelations
        .filter(rel => rel.type === 'produces')
        .map(rel => rel.targetRef);

      if (scorecardRefs.length || assessmentRefs.length) {
        // Fetch scorecards and assessments by the combined references
        const response = await catalogApi.getEntitiesByRefs({
          entityRefs: [...scorecardRefs, ...assessmentRefs],
        });

        // Filter response to get scorecards
        scorecards = response.items.filter(
          item => item?.kind === 'Scorecard',
        ) as IEntityScorecard[];

        // Filter response to get assessments
        assessments = response.items.filter(
          item => item?.kind === 'ScorecardAssessment',
        ) as IEntityScorecardAssessment[];
      }
    }
    // Merge scorecards and assessments into one result array
    const appScorecardsWithAssessments = scorecards
      .reduce<ScorecardEntityWithAssessment[]>((result, scorecard) => {
        // Find the corresponding assessment for the current scorecard
        const assessment = assessments.find(
          item =>
            item?.spec.assessment.scorecard === stringifyEntityRef(scorecard) &&
            item.spec.assessment.entityRef === stringifyEntityRef(application),
        );

        if (assessment) {
          // If an assessment is found, combine its spec with the scorecard's spec
          result.push(
            cloneDeep({
              ...scorecard,
              spec: {
                ...scorecard.spec,
                ...assessment.spec.assessment,
              },
            }),
          );
        }

        return result;
      }, [])
      // Push the scorecards with low completion percentage to the top
      .sort(
        (a, b) => a.spec.completionPercentage - b.spec.completionPercentage,
      );
    return { assessments: appScorecardsWithAssessments, scorecards };
  } catch (e) {
    throw new Error(
      `Failed to fetch scorecards for this application: ${
        (e as Error).message
      }`,
    );
  }
};

// Gets an application's scorecards with their assessments
export const getApplicationScorecards = (
  application: Entity,
  contextData: IApplicationsScorecardsContext,
  catalogApi: CatalogApi,
  scorecardsToShow?: IEntityScorecard[],
) => {
  const { data, isLoading } = contextData;
  if (isLoading) return { assessments: [], scorecards: [] };

  // Separate scorecards and assessments from the provided relations (context fetches everything)
  const getRelationsByType = (relations: (Entity | undefined)[]) => {
    const args = [relations, application] as const;
    return {
      scorecards: filterRelationsByKind<IEntityScorecard>(
        ...args,
        'Scorecard',
        scorecardsToShow,
      ),
      assessments: filterRelationsByKind<IEntityScorecardAssessment>(
        ...args,
        'ScorecardAssessment',
        scorecardsToShow,
      ),
    };
  };

  return getScorecardsWithAssessments(
    application,
    catalogApi,
    // If the context has the data, use it to avoid sending extra requests
    data.relations.length ? getRelationsByType(data.relations) : undefined,
    scorecardsToShow,
  );
};

// Separates an array of relations (scorecard and assessment) by their kind and the provided application
function filterRelationsByKind<
  T extends IEntityScorecardAssessment | IEntityScorecard,
>(
  relations: (Entity | undefined)[],
  application: Entity,
  kind: 'Scorecard' | 'ScorecardAssessment',
  scorecardsToShow?: IEntityScorecard[],
) {
  const filterScorecardKind = (scorecard: IEntityScorecard) => {
    return (
      // If scorecardsToShow is provided, ensure we only include those scorecards
      (!scorecardsToShow ||
        scorecardsToShow.some(
          item => item.metadata.name === scorecard.metadata.name,
        )) &&
      // Ensure the scorecards are related to the current application
      (scorecard.relations || []).some(
        rel => rel.targetRef === stringifyEntityRef(application),
      )
    );
  };
  const filterAssessmentKind = (assessment: IEntityScorecardAssessment) => {
    return (
      // If scorecardsToShow is provided, ensure the assessment matches those scorecards
      (!scorecardsToShow ||
        scorecardsToShow.some(
          scorecard =>
            scorecard.metadata.name ===
            parseEntityRef(assessment.spec.assessment.scorecard).name,
        )) &&
      // Ensure the assessments are related to the current application
      parseEntityRef(assessment.spec.assessment.entityRef).name ===
        application.metadata.name
    );
  };

  return relations.filter(item => {
    const relation = item as T;
    if (!relation) return false;
    return (
      relation.kind === kind &&
      (kind === 'Scorecard'
        ? filterScorecardKind(relation as IEntityScorecard)
        : filterAssessmentKind(relation as IEntityScorecardAssessment))
    );
  }) as T[];
}
