import React, {
  useState,
  forwardRef,
  ChangeEvent,
  FormEventHandler,
} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Alert, AlertFormProps } from '../types';

export type AlertAcceptFormProps = AlertFormProps<Alert, null>;

export const AlertAcceptForm = forwardRef<
  HTMLFormElement,
  AlertAcceptFormProps
>(({ onSubmit, disableSubmit }, ref) => {
  const [checked, setChecked] = useState(false);

  const onFormSubmit: FormEventHandler = e => {
    e.preventDefault();
    onSubmit(null);
  };

  const onChecked = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    setChecked(isChecked);
    disableSubmit(!isChecked);
  };

  return (
    <form ref={ref} onSubmit={onFormSubmit}>
      <FormControl component="fieldset" fullWidth>
        <FormControlLabel
          label="My team can commit to making this change soon, or has already."
          value={checked}
          control={
            <Checkbox color="primary" checked={checked} onChange={onChecked} />
          }
        />
      </FormControl>
    </form>
  );
});
