import React from 'react';
import { RollbackOption } from '../../../api/types/responses';
import { ROLLBACK_STRATEGIES } from '../../../constants';

export function getLabelByType(option: RollbackOption): JSX.Element {
  switch (option.type) {
    case ROLLBACK_STRATEGIES.RETRY_DEPLOYMENT:
      return (
        <span>
          {`Retry step `}
          <strong>{option.metadata.step_name}</strong>
          {` of pipeline `}
          <strong>{option.metadata.pipeline_build_version}.</strong>
        </span>
      );
    case ROLLBACK_STRATEGIES.RETRY_TRAFFIC_SWITCH:
      return (
        <span>
          {`Retry traffic switch step `}
          <strong>{option.metadata.step_name}</strong>
          {` of pipeline `}
          <strong>{option.metadata.pipeline_build_version}</strong>
        </span>
      );
    case ROLLBACK_STRATEGIES.RETRIGGER:
      return (
        <span>
          {`Retrigger pipeline `}
          <strong>{option.metadata.pipeline_build_version}.</strong>
        </span>
      );
    default:
      return (
        <span>
          {`Retrigger pipeline `}
          <strong>{option.metadata.pipeline_build_version}.</strong>
        </span>
      );
  }
}

export function getURLByType(
  option: RollbackOption,
  domainAlias: string,
  org: string,
  name: string,
): string {
  switch (option.type) {
    case ROLLBACK_STRATEGIES.RETRY_DEPLOYMENT:
    case ROLLBACK_STRATEGIES.RETRY_TRAFFIC_SWITCH:
      return `/cdp/${domainAlias}/${org}/${name}/${option.metadata.pipeline_id}/steps/${option.metadata.step_ordinal}`;
    case ROLLBACK_STRATEGIES.RETRIGGER:
      return `/cdp/pipeline/${option.metadata.pipeline_id}`;
    default:
      return `cdp/pipeline/${option.metadata.pipeline_id}`;
  }
}

export function filterPlans(plans: RollbackOption[]): {
  disabled: RollbackOption[];
  available: RollbackOption[];
} {
  const available: RollbackOption[] = [];
  const disabled: RollbackOption[] = [];

  plans.forEach(p => {
    if (Boolean(p.disabled)) {
      disabled.push(p);
      return;
    }
    available.push(p);
  });

  return { disabled, available };
}
