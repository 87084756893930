import React from 'react';
import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';
import { colors } from 'plugin-ui-components';

const P3Chip = withStyles({
  root: {
    borderColor: colors.semantic.infoBase,
  },
})(Chip);
const P2Chip = withStyles({
  root: {
    borderColor: colors.semantic.successBase,
  },
})(Chip);
const P1Chip = withStyles({
  root: {
    borderColor: colors.semantic.errorBase,
  },
})(Chip);

export const StatusChip = ({ status }: { status: string }) => {
  let chip = <></>;
  switch (status) {
    case 'closed':
      chip = <P3Chip label={status} size="small" variant="outlined" />;
      break;
    case 'resolved':
      chip = <P2Chip label={status} size="small" variant="outlined" />;
      break;
    case 'open':
      chip = <P1Chip label={status} size="small" variant="outlined" />;
      break;
    default:
      chip = <span>N/A</span>;
  }

  return <div>{chip}</div>;
};
