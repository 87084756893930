import { UserTeam, ZflowProject, ZflowUserInfo } from '../interfaces/project';
import { HttpClient } from '../httpClient';
import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

export interface ZflowProjectsServiceApi {
  getProjects(
    limit: number,
    teamIds: string[],
    offset?: number,
  ): Promise<ZflowProject[]>;
  getUserInfo(): Promise<ZflowUserInfo>;
  getTeamsWithProject(): Promise<Array<UserTeam>>;
}

export class ZflowProjectsService implements ZflowProjectsServiceApi {
  readonly httpClient: HttpClient;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.httpClient = new HttpClient({
      pluginProxyEndpoint: 'zflow',
      ...options,
    });
  }

  async getProjects(
    limit: number,
    teamId: string[],
    offset?: number,
  ): Promise<ZflowProject[]> {
    let allProjects: ZflowProject[] = [];

    for (const id of teamId) {
      const query = {
        limit,
        team_id: id,
        offset,
      };
      const response = await this.httpClient.get('/api/projects', query);
      allProjects = allProjects.concat(response.projects);
    }
    return allProjects;
  }

  async getUserInfo(): Promise<ZflowUserInfo> {
    return await this.httpClient.get(`/api/users`);
  }

  async getTeamsWithProject(): Promise<Array<UserTeam>> {
    const params = {
      with_project: true,
    };
    return await this.httpClient.get(`/api/teams`, params);
  }
}

export const zflowProjectsApiRef = createApiRef<ZflowProjectsServiceApi>({
  id: 'ml-platform-zflow-api',
});
