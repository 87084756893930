import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import {
  CatalogApi,
  catalogApiRef,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Table,
  TableColumn,
  TableProps,
  WarningPanel,
} from '@backstage/core-components';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useAsync } from 'react-use';
import { ReviewChip, TrackedLink } from 'plugin-ui-components';
import EditIcon from '@mui/icons-material/Edit';
import {
  discoverApplicationViaDomain,
  discoverOwnerViaDomain,
} from '../../../tracking';

const getDomainApplications = async (
  catalogApi: CatalogApi,
  domain: IEntityDomain,
) => {
  const specAppEntities = domain.spec.spec.entities.filter(
    specEntity => specEntity.type === 'APPLICATION',
  );
  return await catalogApi.getEntities({
    filter: specAppEntities.map(item => {
      return { kind: 'Component', 'metadata.name': `${item.ref}` };
    }),
  });
};

export function DomainApplications() {
  const catalogApi = useApi(catalogApiRef);
  const { entity: domain } = useEntity<IEntityDomain>();

  const {
    value: apps = [],
    loading,
    error,
  } = useAsync(
    () => getDomainApplications(catalogApi, domain).then(data => data.items),
    [catalogApi],
  );

  const columns: TableColumn<IEntityApp>[] = [
    {
      title: 'Name',
      field: 'metadata.name',
      render: ({ metadata: { name } }) => {
        return (
          <TrackedLink
            to={`/catalog/default/Component/${name}`}
            {...discoverApplicationViaDomain(name, domain.metadata.name)}
          >
            {name}
          </TrackedLink>
        );
      },
      width: 'fit-content',
    },
    {
      title: 'Owner',
      field: 'spec.spec.team_id',
      render: ({
        spec: {
          owner,
          spec: { team_id },
        },
      }) => {
        return (
          <TrackedLink
            to={`/catalog/default/Group/${owner}`}
            {...discoverOwnerViaDomain(team_id, domain.metadata.name)}
          >
            {team_id}
          </TrackedLink>
        );
      },
      width: 'fit-content',
    },
    {
      title: 'Criticality',
      field: 'spec.lifecycle',
      render: ({ spec, metadata }) => (
        <div className="flex-wrapped">
          {spec?.lifecycle && (
            <Chip
              className="info-chip"
              label={spec.lifecycle}
              size="small"
              variant="outlined"
            />
          )}
          {spec?.spec?.incident_contact && (
            <Tooltip title="Has 24/7 support">
              <Chip
                className="info-chip"
                label="24/7"
                size="small"
                variant="outlined"
              />
            </Tooltip>
          )}
          {metadata.cyberweekInScope && (
            <Tooltip title="Relevant for cyberweek">
              <Chip
                className="info-chip"
                label="cyberweek"
                size="small"
                variant="outlined"
              />
            </Tooltip>
          )}
        </div>
      ),
      width: 'fit-content',
    },
    {
      title: 'Review Status',
      field: 'metadata.lastReview.needsReview',
      render: app => <ReviewChip entity={app} />,
      width: 'fit-content',
    },
    {
      title: 'PRR',
      field: 'metadata.productionReadinessReviewDate',
      render: ({ metadata }) => {
        return (
          <div className="flex-wrapped">
            {metadata.productionReadinessReviewDate && (
              <Tooltip title="Production Readiness Review">
                <Chip
                  className="info-chip"
                  label={metadata.productionReadinessReviewDate}
                  size="small"
                  variant="outlined"
                />
              </Tooltip>
            )}
          </div>
        );
      },
      width: 'fit-content',
    },
  ];

  const actions: TableProps<IEntityApp>['actions'] = [
    {
      position: 'toolbar',
      icon: () => (
        <IconButton disabled aria-label="delete" size="small">
          <EditIcon />
        </IconButton>
      ),
      tooltip: 'Coming soon',
      onClick: () => {},
    },
  ];

  if (error) {
    return (
      <WarningPanel severity="error" title="Could not load applications.">
        <Typography>Failed loading applications for this domain.</Typography>
      </WarningPanel>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Paper>
          <Table
            title={`Applications (${apps.length})`}
            columns={columns}
            data={apps}
            options={{
              loadingType: 'linear',
              padding: 'dense',
              pageSize: 15,
            }}
            actions={actions}
            isLoading={loading}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
