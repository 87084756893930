import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: '0.5rem 1rem',
  },
  root: {
    '& .content': {
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem',

      '& .highlight': {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',

        '& p:first-child': {
          fontWeight: 'bold',
          fontSize: theme.typography.body2.fontSize,
        },
        '& p:not(:first-child)': {
          fontSize: theme.typography.caption.fontSize,
        },
      },
      '& dl': {
        display: 'grid',
        gridTemplateAreas: 'dt dd',
        gridTemplateColumns: 'auto 1fr',
        gap: '0rem 0.5rem',
        marginBottom: 0,

        '& dt,dd': {
          padding: 0,
          margin: 0,
        },
      },
    },
    '& .social': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBlock: '0.2rem',
      gap: '1rem',

      '& > a': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem',
      },
    },
  },
  largePicture: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center',

    '& .error-icon': {
      fontSize: '1.2em',
    },
  },
  userName: {
    '&.active': {
      color: 'inherit',
    },
    '&.inactive': {
      color: 'gray',
    },
  },
}));
