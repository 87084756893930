import React from 'react';
import { InfoCard } from 'plugin-ui-components';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';

export const NoAnalysisCardContent = () => {
  return (
    <CardContent>
      <Link href="https://cloud.docs.zalando.net/howtos/codacy/">
        Add Codacy{' '}
      </Link>
      to your repository in order to get code quality analysis.
    </CardContent>
  );
};

export const NoAnalysisCard = () => {
  return (
    <InfoCard
      id="code-quality-widget"
      title="Code Quality"
      style={{ marginTop: 32 }}
    >
      <NoAnalysisCardContent />
    </InfoCard>
  );
};
