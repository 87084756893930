import React, { useState } from 'react';
import cn from 'classnames';
import TextField, { type TextFieldProps } from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

export function SearchBar({
  hideClearButton,
  ...props
}: TextFieldProps & { hideClearButton?: boolean }) {
  const [focused, setFocused] = useState(false);

  const shouldLabelShrink = focused || Boolean(props.value);
  return (
    <div>
      <TextField
        fullWidth
        margin="dense"
        variant="outlined"
        label="Search terms"
        type="search"
        role="searchbox"
        inputMode="search"
        autoComplete="off"
        size="small"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        sx={{
          '& fieldset': {
            borderColor: 'var(--disabled-color)',
          },
        }}
        InputProps={{
          sx: { pl: 0.5 },
          className: cn({
            'hide-clear-button': hideClearButton,
          }),
          startAdornment: (
            <InputAdornment position="end" color="red" sx={{ mr: 1 }}>
              <SearchIcon
                fontSize="small"
                color={props.disabled ? 'disabled' : 'inherit'}
              />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: shouldLabelShrink,
          sx: {
            pl: shouldLabelShrink ? 0 : 4,
            transition: 'all 200ms ease-out',
          },
        }}
        {...props}
      />
    </div>
  );
}
