import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  infoIcon: {
    '& svg': {
      marginLeft: theme.spacing(1),
      fontSize: '1rem',
    },
  },
  buttonIcon: {
    '& button, & a': {
      minWidth: 'initial',
      padding: 6,
    },
    '& [class*=MuiButtonGroup-groupedTextHorizontal]': {
      border: 'none !important',
    },

    '& [class*=MuiButton-startIcon]': {
      margin: 0,
    },
    '& [class*=Mui-disabled]': {
      color: 'var(--divider-clr)',
    },
  },
  titleColumn: {
    lineHeight: '2',
    fontWeight: 400,
  },
  tableWrapper: {
    display: 'grid',
    gridTemplateAreas: "'filters' 'table'",
    gridTemplateColumns: '250px 1fr',
    '& .portfolio-filters': {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5rem 1rem',
      minWidth: 300,
      maxWidth: 300,

      '& .filter-header': {
        display: 'flex',
        justifyContent: 'space-between',

        '& h6': {
          fontSize: '0.75rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          margin: 0,
        },
      },
    },
  },
  inputRoot: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
