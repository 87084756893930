import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    padding: theme.spacing(1.5),
    height: '100%',
  },
  backdrop: {
    position: 'absolute',
    zIndex: 10,
    backgroundColor: alpha(theme.palette.grey.A100, 0.3),
  },
  executionTimeStat: {
    padding: theme.spacing(0.5),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '&:not(:first-child)': {
      borderLeft: '1px solid currentColor',
    },

    '& > p': {
      fontSize: '2em',
    },

    '& > h4': {
      fontSize: '1em',
    },
  },
  toolbar: {
    paddingInline: 0,
    display: 'flex',
    gap: theme.spacing(1),
  },
  select: {
    '& legend': {
      /**
       * Ensures that the label won't overlap with the fieldset border
       * This is the same value applied by MUI when the element is in focus
       */
      maxWidth: 1000,
    },
  },
  statTypography: {
    fontSize: theme.typography.h2.fontSize,
  },
  failedRankingTable: {
    height: '100%',
    '& > div, & > div > div': {
      height: '100%',
    },
    '& tfoot tr': {
      backgroundColor: 'transparent !important',
    },
  },
  dateInput: {
    '& input[type=date]::-webkit-calendar-picker-indicator': {
      filter: `invert(${theme.palette.text.primary === '#fff' ? '1' : '0'})`,
    },
  },
}));
