export const common: Pick<IEventTracker, 'plugin' | 'eventCategory'> = {
  plugin: 'nakadi',
  eventCategory: 'Nakadi SQL query landing page',
};

export const onTeamClick = (): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Go to the team landing page',
});

export const onUserClick = (): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Go to the user landing page',
});

export const onApplicationClick = (): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Go to the application landing page',
});

export const onOwningAppClick = (): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Go to the owning application landing page',
});

export const onSubscriptionClick = (): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Go to the subscriptions landing page',
});

export const onEventTypeClick = (): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Go to the event type landing page',
});
