import {
  DiscoveryApi,
  OAuthApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import {
  Change,
  ChangeInput,
  Changeset,
  CommentType,
  GetChangesetsInput,
  GetChangesetsResult,
  GetRouteVersionsResult,
  Route,
  SnapshotRoutesInput,
  SnapshotRoutesResult,
  Validation,
  Comment,
} from './types';

export interface RkeepApi {
  snapshotRoutes(options: SnapshotRoutesInput): Promise<SnapshotRoutesResult>;
  getChangesets(options: GetChangesetsInput): Promise<GetChangesetsResult>;
  getRouteById(routeId: string): Promise<Route>;
  getRouteVersions(routeId: string): Promise<GetRouteVersionsResult>;
  getChangesetById(changesetId: string): Promise<Changeset>;
  createChangeset(title: string, description?: string): Promise<Changeset>;
  patchChangeset(
    changesetId: string,
    changes: Partial<Changeset>,
  ): Promise<Changeset>;
  deleteChangeset(changesetId: string): Promise<void>;
  addRouteToChangeset(changesetId: string, route: Route): Promise<void>;
  createChangesetChange(
    changesetId: string,
    change: ChangeInput,
  ): Promise<void>;
  createChangesetComment(
    changesetId: string,
    commentText: string,
    commentType: CommentType,
  ): Promise<Comment>;
  getChangesetChangeById(
    changesetId: string,
    changeId: string,
  ): Promise<Change>;
  getChangesetChanges(changesetId: string): Promise<Change[]>;
  updateChangesetChanges(
    changesetId: string,
    change: ChangeInput,
  ): Promise<void>;
  deleteChangesetChanges(changesetId: string, routeId: string): Promise<void>;
  getChangesetValidations(changesetId: string): Promise<Validation[]>;
  getChangesetComments(changesetId: string): Promise<Comment[]>;
  submitBulkEditChanges(changesetId: string, changes: Change[]): Promise<void>;
}

export class RkeepApiClient implements RkeepApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly oauth2Api: OAuthApi,
  ) {}

  async errorHandler(message: string, response: Response): Promise<void> {
    if (response.status === 401) {
      throw new Error(
        `${message} : Unauthorized Access ${response.statusText}`,
      );
    }
    if (response.status === 404) {
      throw new Error(`${message} : ${response.statusText}`);
    }
    const data = await response.json();
    if (typeof data === 'object')
      throw new Error(
        `${message} : ${data.title}(${data.status}), ${data.detail}`,
      );
    else throw new Error(`${message} : ${data}`);
  }

  convertRouteToChangeInput(route: Route): ChangeInput {
    return {
      deleted: route.deleted,
      route_id: route.route_id === '' ? undefined : route.route_id,
      name: route.name === '' || route.deleted ? undefined : route.name,
      description:
        route.description === '' || route.deleted
          ? undefined
          : route.description,
      route_data: !route.deleted ? route.route_data : undefined,
      base_changeset_id:
        route.changeset_id === '' ? undefined : route.changeset_id,
    };
  }

  async getHeaders(): Promise<HeadersInit> {
    const token = await this.oauth2Api.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'X-Rkeep-Sunrise-Ui': 'true' };
  }

  async snapshotRoutes({
    limit,
    search,
  }: SnapshotRoutesInput): Promise<SnapshotRoutesResult> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(`${proxyUrl}/rkeep/snapshot-routes`);
    if (limit) {
      apiUrl.searchParams.append('limit', limit.toString());
    }
    if (search) {
      apiUrl.searchParams.append('search', search);
    }
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler('Failed to fetch routes', response);
    const data = await response.json();
    return data as SnapshotRoutesResult;
  }

  async getChangesets({
    status,
    limit,
  }: GetChangesetsInput): Promise<GetChangesetsResult> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(`${proxyUrl}/rkeep/changesets`);
    if (status) {
      apiUrl.searchParams.append('status', status);
    }
    if (limit) {
      apiUrl.searchParams.append('limit', limit.toString());
    }
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler('Failed to fetch changesets', response);
    const data = await response.json();
    return data as GetChangesetsResult;
  }

  async getRouteById(routeId: string): Promise<Route> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(`${proxyUrl}/rkeep/routes/${routeId}`);
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler('Failed to fetch route', response);
    const data = await response.json();
    return data as Route;
  }

  async getRouteVersions(routeId: string): Promise<GetRouteVersionsResult> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(`${proxyUrl}/rkeep/routes/${routeId}/versions`);
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler('Failed to fetch route versions', response);
    const data = await response.json();
    return data as GetRouteVersionsResult;
  }

  async getChangesetById(changesetId: string): Promise<Changeset> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(`${proxyUrl}/rkeep/changesets/${changesetId}`);
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler('Failed to fetch changeset', response);
    const data = await response.json();
    return data as Changeset;
  }

  async createChangeset(
    title: string,
    description: string,
  ): Promise<Changeset> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(`${proxyUrl}/rkeep/changesets`);
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ title, description }),
    });
    if (response.status !== 201)
      await this.errorHandler('Failed to create changeset', response);
    const data = await response.json();
    return data as Changeset;
  }

  async patchChangeset(
    changesetId: string,
    changes: Partial<Changeset>,
  ): Promise<Changeset> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(`${proxyUrl}/rkeep/changesets/${changesetId}`);
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(changes),
    });
    if (!response.ok)
      await this.errorHandler('Failed to update changeset', response);
    const data = await response.json();
    return data as Changeset;
  }

  async deleteChangeset(changesetId: string): Promise<void> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(`${proxyUrl}/rkeep/changesets/${changesetId}`);
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      method: 'DELETE',
      headers: headers,
    });
    if (response.status !== 204)
      await this.errorHandler('Failed to delete changeset', response);
    return;
  }

  async addRouteToChangeset(changesetId: string, route: Route): Promise<void> {
    const change = this.convertRouteToChangeInput(route);
    return await this.createChangesetChange(changesetId, change);
  }

  async createChangesetChange(
    changesetId: string,
    change: ChangeInput,
  ): Promise<void> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(
      `${proxyUrl}/rkeep/changesets/${changesetId}/changes`,
    );
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(change),
    });
    if (response.status !== 201)
      await this.errorHandler('Failed to create changeset change', response);
    return;
  }

  async createChangesetComment(
    changesetId: string,
    commentText: string,
    commentType: CommentType,
  ): Promise<Comment> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(
      `${proxyUrl}/rkeep/changesets/${changesetId}/comments`,
    );
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ text: commentText, type: commentType }),
    });
    if (response.status !== 201)
      await this.errorHandler('Failed to create comment', response);
    const data = await response.json();
    return data as Comment;
  }

  async getChangesetChangeById(
    changesetId: string,
    changeId: string,
  ): Promise<Change> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(
      `${proxyUrl}/rkeep/changesets/${changesetId}/changes/${changeId}`,
    );
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler('Failed to fetch changeset change', response);
    const data = await response.json();
    return data as Change;
  }

  async getChangesetChanges(changesetId: string): Promise<Change[]> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(
      `${proxyUrl}/rkeep/changesets/${changesetId}/changes`,
    );
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler('Failed to fetch changeset changes', response);
    const data = await response.json();
    return data as Change[];
  }

  async updateChangesetChanges(
    changesetId: string,
    change: ChangeInput,
  ): Promise<void> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(
      `${proxyUrl}/rkeep/changesets/${changesetId}/changes/${change.route_id}`,
    );
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(change),
    });
    if (!response.ok)
      await this.errorHandler('Failed to update changeset changes', response);
    return;
  }

  async deleteChangesetChanges(
    changesetId: string,
    routeId: string,
  ): Promise<void> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(
      `${proxyUrl}/rkeep/changesets/${changesetId}/changes/${routeId}`,
    );
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      method: 'DELETE',
      headers: headers,
    });
    if (response.status !== 204)
      await this.errorHandler('Failed to delete changeset changes', response);
    return;
  }

  async getChangesetValidations(changesetId: string): Promise<Validation[]> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(
      `${proxyUrl}/rkeep/changesets/${changesetId}/validations`,
    );
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler(
        'Failed to fetch changeset validations',
        response,
      );
    const data = await response.json();
    return data as Validation[];
  }

  async getChangesetComments(changesetId: string): Promise<Comment[]> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const apiUrl = new URL(
      `${proxyUrl}/rkeep/changesets/${changesetId}/comments`,
    );
    const headers = await this.getHeaders();
    const response = await fetch(apiUrl, {
      headers: headers,
    });
    if (!response.ok)
      await this.errorHandler('Failed to fetch changeset comments', response);
    const data = await response.json();
    return data as Comment[];
  }

  async submitBulkEditChanges(
    changesetId: string,
    changes: Change[],
  ): Promise<void> {
    const promises = await Promise.allSettled(
      changes.map(async change => {
        const changeInput: ChangeInput = {
          base_changeset_id: change.base_changeset_id,
          deleted: change.deleted,
          description: change.description,
          name: change.name,
          route_data: change.route_data,
        };
        if (change.route_id === '') {
          // change is new to be created
          await this.createChangesetChange(changesetId, changeInput);
        } else {
          // change is existing to be updated
          changeInput.route_id = change.route_id;
          await this.updateChangesetChanges(changesetId, changeInput);
        }
      }),
    );
    const rejected = promises.filter(
      (p): p is PromiseRejectedResult => p.status === 'rejected',
    );
    if (rejected.length > 0) {
      throw new Error(
        `Submit bulk edit changes had the following errors (${
          rejected.length
        } out of ${promises.length} failed): ${rejected
          .map(pr => pr.reason)
          .join(', ')}. Please reload and retry again.`,
      );
    }
  }
}

export const rkeepApiRef = createApiRef<RkeepApi>({
  id: 'plugin.rkeep',
});
