import React from 'react';
import prettyMilliseconds from 'pretty-ms';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import useTheme from '@mui/styles/useTheme';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { EventType, isNakadiSQLAdminOf } from '../../domain/EventTypes';
import Alert from '@mui/lab/Alert';
import { ApiResponse } from '../../hooks/useApiCall';
import { EventTypesManager } from '../../api/EventTypesApi/EventTypesApi';
import { UnPromisify } from '../../utils/typeUtils';
import { StyledTableCell } from '../Generic';
import {
  StatusError,
  StatusOK,
  StatusWarning,
} from '@backstage/core-components';
import { TrackedLink } from 'plugin-ui-components';
import BarChart from '@mui/icons-material/BarChart';
import Edit from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { toHumanString } from 'human-readable-numbers';
import { toHumanReadableDataSize } from '../../utils/unitFormatters';

type EventTypeStatsProps = {
  publishingStats: ApiResponse<
    UnPromisify<ReturnType<EventTypesManager['getPublishingStats']>>
  >;
};

export const EventTypeStats = ({
  eventType,
  application,
  nakadiUiURL,
  publishingStats: { status, isLoading, payLoad: eventTypeStats },
}: EventTypeStatsProps & {
  eventType: EventType;
  application: string;
  nakadiUiURL: string;
}) => {
  if (status.isError) {
    return (
      <StyledTableCell colSpan={12}>
        <Alert severity="error">{status.error}</Alert>
      </StyledTableCell>
    );
  }

  if (isLoading) {
    return (
      <StyledTableCell colSpan={12}>
        <LinearProgress data-testid="loading_indicator" />
      </StyledTableCell>
    );
  }

  return (
    <>
      <StyledTableCell align="left">
        {toHumanString(eventTypeStats?.total_events_count || 0)} Events/Month
      </StyledTableCell>
      <StyledTableCell align="left">
        {eventTypeStats?.costs.toFixed(2)} $/Month
      </StyledTableCell>
      <StyledTableCell align="left" size="small" padding="none">
        {(eventType.owning_application === application ||
          eventType.owning_application === `stups_${application}`) && (
          <Tooltip title={`${eventType.name} is owned by your application`}>
            <CheckIcon style={{ fontSize: '2em' }} />
          </Tooltip>
        )}
      </StyledTableCell>
      <StyledTableCell align="left" size="small" padding="none">
        {eventType.authorization?.writers?.find(
          writer =>
            writer.value === `stups_${application}` &&
            writer.data_type === 'service',
        ) && (
          <Tooltip
            title={`Your application is publishing to ${eventType.name}`}
          >
            <CheckIcon style={{ fontSize: '2em' }} />
          </Tooltip>
        )}
      </StyledTableCell>
      <StyledTableCell align="left">
        <TrackedLink
          plugin="nakadi"
          eventCategory="Event Type List"
          eventLabel={`Event type redirect to Grafana - ${eventType.name}`}
          to={`https://grafana.zalando.net/d/dr63Z1IZk/nakadi-et?orgId=1&var-et=${eventType.name}&var-cluster=nakadi`}
          target="_blank"
        >
          <BarChart style={{ fontSize: '2em' }} />
        </TrackedLink>
        <TrackedLink
          plugin="nakadi"
          eventCategory="Event Type List"
          eventLabel={`Event type redirect to Nakadi UI - ${eventType.name}`}
          to={`${nakadiUiURL}/#types/${eventType.name}`}
          target="_blank"
        >
          <Edit style={{ fontSize: '2em' }} />
        </TrackedLink>
        {isNakadiSQLAdminOf(eventType) && (
          <TrackedLink
            plugin="nakadi"
            eventCategory="Event Type List"
            eventLabel={`Edit NSQL query - ${eventType.name}`}
            to={`/nakadi/queries/${eventType.name}/edit`}
          >
            <LockOpenIcon style={{ fontSize: '2em' }} />
          </TrackedLink>
        )}
      </StyledTableCell>
    </>
  );
};

const PublishingStatsRow = withStyles(() => {
  const theme = useTheme();
  return createStyles({
    root: {
      backgroundColor: `${theme.palette.background.paper} !important`,
      borderBottom: `1px ${theme.palette.grey[400]} solid`,
    },
  });
})(TableRow);

const PublishingStats = ({
  publishingStats: { status, isLoading, payLoad: eventTypeStats },
}: EventTypeStatsProps) => {
  const theme = useTheme();
  if (status.isError || status.isError) {
    return <Alert severity="error">{status.isError || status.isError}</Alert>;
  }

  if (isLoading || isLoading) {
    return <LinearProgress data-testid="loading_indicator" />;
  }

  const maxTrafficRate = eventTypeStats?.bytes_per_second.max || 0;
  const avgTrafficRate = eventTypeStats?.bytes_per_second.avg || 0;
  let MaxTrafficStatus = maxTrafficRate > 100 ? <StatusOK /> : <></>;
  let TrafficStatus = avgTrafficRate > 100 ? <StatusOK /> : <></>;

  const partitionCount = eventTypeStats?.partition_count || 0;
  if (maxTrafficRate / partitionCount >= 2e6) {
    MaxTrafficStatus = <StatusError />;
  } else if (maxTrafficRate / partitionCount >= 1e6) {
    MaxTrafficStatus = <StatusWarning />;
  }

  if (avgTrafficRate / partitionCount >= 2e6) {
    TrafficStatus = <StatusError />;
  } else if (avgTrafficRate / partitionCount >= 1e6) {
    TrafficStatus = <StatusWarning />;
  }

  return (
    <Table
      size="small"
      padding="normal"
      style={{ border: `1px ${theme.palette.grey[400]} solid` }}
    >
      <TableHead>
        <PublishingStatsRow style={{ backgroundColor: 'none' }}>
          <StyledTableCell variant="head">
            <strong>Publishing Stats</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>Min</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>Max</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>Avg</strong>
          </StyledTableCell>
        </PublishingStatsRow>
      </TableHead>
      <TableBody>
        <PublishingStatsRow>
          <StyledTableCell>Traffic</StyledTableCell>
          <StyledTableCell>
            {`${toHumanReadableDataSize(
              eventTypeStats?.bytes_per_second.min || 0,
            )}/s`}
          </StyledTableCell>
          <StyledTableCell>
            {MaxTrafficStatus}
            {`${toHumanReadableDataSize(
              eventTypeStats?.bytes_per_second.max || 0,
            )}/s`}
          </StyledTableCell>
          <StyledTableCell>
            {TrafficStatus}
            {`${toHumanReadableDataSize(
              eventTypeStats?.bytes_per_second.avg || 0,
            )}/s`}
          </StyledTableCell>
        </PublishingStatsRow>
        <PublishingStatsRow>
          <StyledTableCell>Event/s Rate</StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.events_per_second.min.toFixed(2)} Event/s
          </StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.events_per_second.max.toFixed(2)} Event/s
          </StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.events_per_second.avg.toFixed(2)} Event/s
          </StyledTableCell>
        </PublishingStatsRow>
        <PublishingStatsRow>
          <StyledTableCell>Event Size</StyledTableCell>
          <StyledTableCell>
            {toHumanReadableDataSize(eventTypeStats?.event_size.min || 0)}
          </StyledTableCell>
          <StyledTableCell>
            {toHumanReadableDataSize(eventTypeStats?.event_size.max || 0)}
          </StyledTableCell>
          <StyledTableCell>
            {toHumanReadableDataSize(eventTypeStats?.event_size.avg || 0)}
          </StyledTableCell>
        </PublishingStatsRow>
        <PublishingStatsRow>
          <StyledTableCell>Request Count</StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.requests_per_second.min.toFixed(2)} Req/s
          </StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.requests_per_second.max.toFixed(2)} Req/s
          </StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.requests_per_second.avg.toFixed(2)} Req/s
          </StyledTableCell>
        </PublishingStatsRow>
        <PublishingStatsRow style={{ backgroundColor: 'none' }}>
          <StyledTableCell variant="head" />
          <StyledTableCell>
            <strong>P50</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>P95</strong>
          </StyledTableCell>
          <StyledTableCell>
            <strong>P99</strong>
          </StyledTableCell>
        </PublishingStatsRow>
        <PublishingStatsRow>
          <StyledTableCell>Batching</StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.events_per_batch.p_50.toFixed()} Events/Batch
          </StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.events_per_batch.p_95.toFixed()} Events/Batch
          </StyledTableCell>
          <StyledTableCell>
            {eventTypeStats?.events_per_batch.p_99.toFixed()} Events/Batch
          </StyledTableCell>
        </PublishingStatsRow>
        <PublishingStatsRow>
          <StyledTableCell>Latency</StyledTableCell>
          <StyledTableCell>
            {prettyMilliseconds(eventTypeStats?.latency.p_50 || 0)}
          </StyledTableCell>
          <StyledTableCell>
            {prettyMilliseconds(eventTypeStats?.latency.p_95 || 0)}
          </StyledTableCell>
          <StyledTableCell>
            {prettyMilliseconds(eventTypeStats?.latency.p_99 || 0)}
          </StyledTableCell>
        </PublishingStatsRow>
      </TableBody>
    </Table>
  );
};

export const DetailedPublishingStats = ({
  isTableOpen,
  publishingStats,
}: {
  isTableOpen: boolean;
} & EventTypeStatsProps) => {
  return (
    <TableRow>
      <TableCell colSpan={6} style={{ padding: '1rem 3.5rem' }}>
        <Collapse in={isTableOpen} timeout="auto" mountOnEnter>
          <PublishingStats publishingStats={publishingStats} />
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
