import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Maybe, Metric } from '../../types';
import { useSelectStyles as useStyles } from '../../utils/styles';

export type MetricSelectProps = {
  metric: Maybe<string>;
  metrics: Array<Metric>;
  onSelect: (metric: Maybe<string>) => void;
};

export const MetricSelect = ({
  metric,
  metrics,
  onSelect,
}: MetricSelectProps) => {
  const classes = useStyles();

  function onChange(e: SelectChangeEvent) {
    if (e.target.value === 'none') {
      onSelect(null);
    } else {
      onSelect(e.target.value as string);
    }
  }

  return (
    <FormControl variant="outlined">
      <InputLabel shrink id="metric-select-label">
        Compare to:
      </InputLabel>
      <Select
        id="metric-select"
        labelId="metric-select-label"
        label="Compare to:"
        className={classes.select}
        value={metric ?? 'none'}
        onChange={onChange}
      >
        <MenuItem className={classes.menuItem} key="none" value="none">
          <em>None</em>
        </MenuItem>
        {metrics.map((m: Metric) => (
          <MenuItem className={classes.menuItem} key={m.kind} value={m.kind}>
            <b>{m.name}</b>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
