import React from 'react';
import IconButton from '@mui/material/IconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';

const exportGraph = (domNodeId: string) => {
  const node = document.getElementById(domNodeId);
  if (!node) {
    return;
  }

  domtoimage.toBlob(node, { bgcolor: 'white' }).then((blob: Blob) => {
    fileDownload(blob, `${domNodeId}.png`);
  });
};

export const SaveAction = ({ targetRef }: { targetRef: string }) => {
  return (
    <IconButton
      aria-label="save"
      title="Save as image"
      onClick={() => exportGraph(targetRef)}
    >
      <SaveAltIcon />
    </IconButton>
  );
};
