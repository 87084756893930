import styled from '@mui/styles/styled';
import { fontSize } from '../../../../common';

export const Text = styled('p')(props => ({
  maxWidth: '100%',
  margin: '0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '.85rem',
  marginBottom: props.theme.spacing(1),

  ['& a:hover']: {
    color: props.theme.palette.link,
  },
}));

export const TextWrapped = styled(Text)({
  whiteSpace: 'break-spaces',
  wordBreak: 'break-all',
});

export const TextLight = styled(Text)(props => ({
  color: props.theme.palette.text.secondary,
  fontSize: fontSize.small,
  marginBottom: 0,
}));

export const ErrorMessage = styled('div')(props => ({
  padding: '4px 6px',
  color: props.theme.palette.error.main,
  fontWeight: 600,
  fontSize: '.75rem',
  lineHeight: '1',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  background: 'rgb(253 59 92 / 0.1)',
  borderRadius: '4px',
}));

export const Tags = styled('div')(props => ({
  '& > div:not(:last-child)': {
    marginRight: props.theme.spacing(0.5),
  },

  [props.theme.breakpoints.up('sm')]: {
    '& > div:not(:last-child)': {
      marginRight: 0,
      marginBottom: props.theme.spacing(0.5),
    },
  },
}));
