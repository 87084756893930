import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { EventTracker } from 'plugin-ui-components';
import { useStyles } from './styles';

type Props = {
  children: React.ReactChild;
  hasEditPermission: boolean | undefined;
  trackingProps: Omit<IEventTracker, 'interaction'>;
  tooltipText?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const ActionButton = ({
  children,
  hasEditPermission,
  trackingProps,
  tooltipText,
  onClick,
}: Props) => {
  const styles = useStyles();
  let tooltipTitle = '';

  if (!hasEditPermission) {
    tooltipTitle = 'Only the owner can edit the application';
  } else if (tooltipText) {
    tooltipTitle = tooltipText;
  }

  return (
    <EventTracker interaction="onClick" {...trackingProps}>
      <Tooltip title={tooltipTitle} placement="top">
        <span>
          <IconButton
            disabled={!hasEditPermission}
            className={styles.cardIcon}
            onClick={onClick}
          >
            {children}
          </IconButton>
        </span>
      </Tooltip>
    </EventTracker>
  );
};
