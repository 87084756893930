import React from 'react';
import Box, { type BoxProps } from '@mui/material/Box';

export enum fontSize {
  tiny = '.75rem',
  small = '.8rem',
  default = '.85rem',
  medium = '1rem',
  large = '1.2rem',
  super = '1.5rem',
}

interface Props extends Omit<BoxProps, 'fontSize'> {
  bold?: boolean;
  italic?: boolean;
  capitalize?: boolean;
  uppercase?: boolean;
  breakAll?: boolean;
  ellipsis?: boolean;
  error?: boolean;
  success?: boolean;
  info?: boolean;
  shrink?: number;
  fullWidth?: boolean;
  fontSize?: fontSize;
}

export function Text({
  bold,
  italic,
  capitalize,
  uppercase,
  breakAll,
  ellipsis,
  error,
  success,
  info,
  shrink,
  fullWidth,
  ...props
}: Props) {
  return (
    <Box
      component="span"
      {...props}
      sx={{
        ...(bold && { fontWeight: 'bold' }),
        ...(italic && { fontStyle: 'italic' }),
        ...(capitalize && { textTransform: 'capitalize' }),
        ...(uppercase && { textTransform: 'uppercase' }),
        ...(breakAll && { wordBreak: 'break-all' }),
        ...(error && { color: 'error.main' }),
        ...(success && { color: 'success.main' }),
        ...(info && { color: 'info.main' }),
        ...(shrink && { flexShrink: shrink }),
        ...(fullWidth && { flex: 1 }),
        ...(ellipsis && {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }),
      }}
    />
  );
}

// export const Text = styled('span')<DefaultTheme, Props>(
//   ({ theme, ...props }) => {
//     return {
//       ...(props.bold && { fontWeight: 'bold' }),
//       ...(props.italic && { fontStyle: 'italic' }),
//       ...(props.capitalize && { textTransform: 'capitalize' }),
//       ...(props.uppercase && { textTransform: 'uppercase' }),
//       ...(props.breakAll && { wordBreak: 'break-all' }),
//       ...(props.error && { color: theme?.palette.error.main }),
//       ...(props.success && { color: theme?.palette.success.main }),
//       ...(props.info && { color: theme?.palette.info.main }),
//       ...(props.shrink && { flexShrink: props.shrink }),
//       ...(props.fullWidth && { flex: 1 }),
//       ...(props.p && { padding: theme.spacing(props.p) }),
//       ...(props.display && { display: props.display }),
//       fontSize: props.fontSize || fontSize.default,
//       ...(props.ellipsis && {
//         whiteSpace: 'nowrap',
//         overflow: 'hidden',
//         textOverflow: 'ellipsis',
//       }),
//     };
//   },
// );
