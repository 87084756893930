import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useStyles } from './styles';
import { RowProps, TreeNodeWithChildren } from './types';
import { TreeExpandIcon } from './TreeExpandIcon';

export const Row = React.memo(
  ({
    isTree,
    columns,
    row,
    indent = 1,
    openDepth,
    onTreeExpandClicked,
    showTreeGuides,
  }: RowProps) => {
    const styles = useStyles();
    const [open, setOpen] = React.useState(
      openDepth !== undefined ? openDepth > indent : false,
    );
    const [localDepth, setLocalDepth] = React.useState(openDepth);

    React.useEffect(() => {
      if (openDepth !== undefined) {
        setLocalDepth(openDepth);
        setOpen(openDepth > indent);
      }
    }, [openDepth, indent]);

    return (
      <React.Fragment>
        <TableRow>
          {columns.map((column, i) => {
            if (column.hidden) return null;
            return (
              <TableCell
                key={`cell-${column.field || column.children?.toString()}`}
                className={styles.tableCell}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {i === 0 && (
                    <TreeExpandIcon
                      open={open}
                      isTree={isTree}
                      columns={columns}
                      row={row}
                      onTreeExpandClicked={onTreeExpandClicked}
                      showTreeGuides={showTreeGuides}
                      openDepth={openDepth}
                      indent={indent}
                      setLocalDepth={setLocalDepth}
                      setOpen={setOpen}
                    />
                  )}
                  {column.render
                    ? column.render(row)
                    : String(row[column.field as any])}
                </div>
              </TableCell>
            );
          })}
        </TableRow>
        {!!row.children?.length &&
          open &&
          row.children.map((child: TreeNodeWithChildren, index: number) => (
            <Row
              key={index}
              isTree={isTree}
              columns={columns}
              row={child}
              indent={indent + 1}
              openDepth={localDepth}
              onTreeExpandClicked={onTreeExpandClicked}
              showTreeGuides={showTreeGuides}
            />
          ))}
      </React.Fragment>
    );
  },
);
