import React from 'react';
import { StyledGridColumn, StyledGridRow } from '../../common';
import { getColorByStatus } from '../../../utils/pipeline';
import { TagSmall } from '../../../common';
import { Deployment } from '../../../api/types/responses';
import { usePipelineRun } from '../../../hooks';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import { getFormattedShortDateTime } from '../../../utils/time';

function DeploymentRow({ deployment }: { deployment: Deployment }) {
  const { loading, pipeline } = usePipelineRun(deployment.pipeline_id);

  if (loading) {
    return <CircularProgress />;
  }

  const step = pipeline?.steps.find(s => s.run.id === deployment.id);

  return (
    <StyledGridRow key={deployment.id}>
      <StyledGridColumn item>
        {!!pipeline ? (
          <TagSmall color={getColorByStatus(pipeline.status)}>
            {pipeline.status}
          </TagSmall>
        ) : (
          'unknown'
        )}
      </StyledGridColumn>

      <StyledGridColumn item>
        {!!pipeline ? (
          <Link href={`./${pipeline.id}`}>{pipeline.build_version}</Link>
        ) : (
          deployment.pipeline_id
        )}
      </StyledGridColumn>

      <StyledGridColumn item>
        <TagSmall color={getColorByStatus(deployment.status)}>
          {deployment.status}
        </TagSmall>
      </StyledGridColumn>

      <StyledGridColumn item>
        {!!step ? (
          <Link href={`./${pipeline?.id}/steps/${step.ordinal}`}>
            {step.id}
          </Link>
        ) : (
          deployment.id
        )}
      </StyledGridColumn>

      <StyledGridColumn item>{deployment.environment.name}</StyledGridColumn>

      <StyledGridColumn item>
        {getFormattedShortDateTime(deployment.finished_at)}
      </StyledGridColumn>
    </StyledGridRow>
  );
}

export function RecentDeployments({ items }: { items: Deployment[] }) {
  return (
    <>
      {items.map(item => (
        <DeploymentRow key={item.id} deployment={item} />
      ))}
    </>
  );
}
