import React, { PropsWithChildren } from 'react';
import { createTheme } from '@mui/material/styles';
import ThemeProvider from '@mui/styles/ThemeProvider';
import {
  costInsightsDarkTheme,
  costInsightsLightTheme,
} from '../../utils/styles';
import { CostInsightsTheme } from '../../types';

export const CostInsightsThemeProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  return (
    <ThemeProvider
      theme={theme =>
        createTheme({
          ...theme,
          palette: {
            ...theme.palette,
            ...(theme.palette.mode === 'dark'
              ? costInsightsDarkTheme.palette
              : costInsightsLightTheme.palette),
          },
        }) as CostInsightsTheme
      }
    >
      {children}
    </ThemeProvider>
  );
};
