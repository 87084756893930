import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { LinkButton } from '@backstage/core-components';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import { ParentDomainsTable } from './ParentDomainsTable';
import { SubDomainsTable } from './SubDomainsTable';

export function ConnectedDomains() {
  return (
    <div>
      <LinkButton
        to="#"
        variant="outlined"
        color="primary"
        disabled
        startIcon={<BubbleChartIcon />}
        style={{ marginBottom: '1rem' }}
      >
        View in Graph
      </LinkButton>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper>
            <SubDomainsTable />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <ParentDomainsTable />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
