import styled from '@mui/styles/styled';
import { TrackedLink } from 'plugin-ui-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const StyledTrackedLink = styled(TrackedLink)({
  textDecoration: 'underline',
  color: 'inherit',
  fontWeight: 600,
});

export const StyledOpenInNewIcon = styled(OpenInNewIcon)({
  fontSize: '0.8em',
  verticalAlign: 'middle',
});
