import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Alert from '@mui/lab/Alert';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Link } from '@backstage/core-components';
import { TrackedButton } from 'plugin-ui-components';
import { essentialsApiRef } from '../../../../../api';
import * as S from './styles';
import {
  cancelResourceType,
  editingResourceType,
  savingResourceType,
} from './tracking';
import { useNavigationBack } from '../../../useNavigationBack';

interface ResourceTypeFormProps {
  edit?: boolean;
}

const ID_SEPARATOR = '.';

export const ResourceTypeForm = ({ edit = false }: ResourceTypeFormProps) => {
  const { entity } = useEntity();
  const [searchParams, _] = useSearchParams();
  const resourceId = searchParams.get('resourceId') ?? '';
  const essentialApi = useApi(essentialsApiRef);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [useAppName, setUseAppName] = useState(false);
  const [isIdUnique, setIsIdUnique] = useState(true);

  const handleBack = useNavigationBack({
    page: edit ? 'resourceTypeEditPage' : 'resourceTypeCreatePage',
    searchParamsKeys: edit ? ['resourceId'] : ['createResourceType'],
  });
  const saveTrackingProps = edit
    ? editingResourceType(resourceId)
    : savingResourceType;

  useEffect(() => {
    if (edit) {
      essentialApi.getResourceTypeDetails(resourceId).then(result => {
        setId(result.id ?? '');
        setName(result.name ?? '');
        setDescription(result.description ?? '');
      });
    }
  }, [edit, essentialApi, resourceId]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    let formattedResourceId = id;

    // Prepend app ID to provided resource ID to match API expectation if user is not using app name
    if (!edit) {
      formattedResourceId = useAppName
        ? entity.metadata.name
        : `${entity.metadata.name}${ID_SEPARATOR}${id}`;
    }

    essentialApi
      .saveResourceType(formattedResourceId, name, description)
      .then(response => {
        if (response.ok) {
          handleBack();
        } else {
          setErrorMessage(response.message);
          setHasError(true);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  // checks if resource type id exists in Essentials API
  async function checkResourceTypeId(resourceTypeId: string) {
    const resourceDetails = await essentialApi.getResourceTypeDetails(
      resourceTypeId,
    );
    return Object.keys(resourceDetails).length === 0;
  }

  return (
    <Container>
      <Card>
        <CardContent>
          <Box marginBottom={2}>
            <Button
              onClick={handleBack}
              startIcon={<ArrowBackIosIcon />}
              size="small"
            >
              Back
            </Button>
          </Box>

          <Box paddingBottom={2}>
            <Typography variant="h4">
              {edit ? 'Edit' : 'Create'} Resource Type
            </Typography>
            {hasError && (
              <Alert severity="error" variant="outlined">
                An Error occurred while saving the form.
                <br />
                {errorMessage}
              </Alert>
            )}
            {id && !isIdUnique && (
              <Alert severity="error" variant="outlined">
                A resource type with the same identifier already exists.
              </Alert>
            )}
          </Box>

          <form onSubmit={handleSubmit}>
            <S.StyledFormControl required fullWidth>
              {!edit && (
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!useAppName}
                        size="small"
                        onChange={async (__, checked) => {
                          if (checked) {
                            const idExists = await checkResourceTypeId(
                              entity.metadata.name,
                            );
                            setId(entity.metadata.name);
                            setIsIdUnique(idExists);
                          } else {
                            setId('');
                          }
                          setUseAppName(checked);
                        }}
                      />
                    }
                    label={
                      <p style={{ fontSize: '.75rem', margin: 0 }}>
                        Create Standard Resource Type (using only Application
                        name as an identifier)
                      </p>
                    }
                  />
                </Box>
              )}
              <TextField
                required
                label="ID"
                inputProps={{
                  'data-testid': 'resource-id',
                }}
                variant="outlined"
                margin="dense"
                size="small"
                id="resource-id"
                // Check if ID exists when input loses focus
                onBlur={async () => {
                  if (id) {
                    const idExists = await checkResourceTypeId(
                      `${entity.metadata.name}${ID_SEPARATOR}${id}`,
                    );
                    setIsIdUnique(idExists);
                  }
                }}
                value={id}
                disabled={isLoading || useAppName || edit}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setId(event.target.value);
                  setIsIdUnique(true);
                }}
                InputProps={{
                  startAdornment: !edit && !useAppName && (
                    <InputAdornment position="start">
                      {entity.metadata.name}
                      {ID_SEPARATOR}
                    </InputAdornment>
                  ),
                }}
                helperText={
                  <Box>
                    Your Resource Type identifier must follow{' '}
                    <Link
                      to="https://opensource.zalando.com/restful-api-guidelines/#225"
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      the naming conventions
                      <OpenInNewIcon
                        style={{ fontSize: '.75rem', marginLeft: 2 }}
                        color="primary"
                      />
                    </Link>{' '}
                    for permissions.
                  </Box>
                }
              />
            </S.StyledFormControl>
            <S.StyledFormControl required fullWidth size="small">
              <TextField
                required
                label="Name"
                inputProps={{
                  'data-testid': 'resource-name',
                }}
                variant="outlined"
                margin="dense"
                size="small"
                id="resource-name"
                value={name}
                disabled={isLoading}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setName(event.target.value)
                }
                helperText="Please enter the name of the resource type"
              />
            </S.StyledFormControl>
            <S.StyledFormControl required fullWidth size="small">
              <TextField
                multiline
                minRows={3}
                label="Description"
                inputProps={{
                  'data-testid': 'resource-description',
                }}
                variant="outlined"
                margin="dense"
                size="small"
                id="resource-description"
                value={description}
                disabled={isLoading}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setDescription(event.target.value)
                }
                helperText="Please add an elaborate description with examples of what kind of data this resource type includes"
              />
            </S.StyledFormControl>
            <Box paddingTop={1} display="flex" justifyContent="space-between">
              <TrackedButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={handleBack}
                {...cancelResourceType}
              >
                Cancel
              </TrackedButton>
              <TrackedButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading || !isIdUnique}
                {...saveTrackingProps}
              >
                Save
              </TrackedButton>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};
