import { TableColumn } from '@backstage/core-components';
import React from 'react';
import { CatalogFilter } from '../CatalogFilter';
import {
  apiColumns,
  applicationColumns,
  CatalogTable,
  docsColumns,
  teamsColumns,
} from '../CatalogTable';
import { APIResultsFilter, ApplicationResultsFilter } from '../ResultsFilter';
import { EmptyContent } from './EmptyContent';
import * as S from './styles';
import { TableButtons } from './TableButtons';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { getQueryValue } from 'plugin-core';
import { useFilteredEntities } from '../../../hooks';
import { DocsResultsFilter } from '../ResultsFilter/DocsResultsFilter';
import { TeamsResultsFilters } from '../ResultsFilter/TeamsResultsFilter';
import Typography from '@mui/material/Typography';
import { TrackedLink } from '../../Tracking';

type CatalogPageProps = {
  type: 'api' | 'application' | 'docs' | 'teams';
  filterGroups: ICatalog.ButtonGroup[];
  initiallySelectedFilter?: string;
};

const teamsTooltipLinkTracking: IEventTracker = {
  plugin: 'teams-catalog',
  eventCategory: 'Teams Table',
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Teams table info tooltip link',
};

const TeamsTableInfoText = () => {
  return (
    <Typography component="span" style={{ fontSize: '.8rem' }}>
      If you would like to create a new team, request a tech alias / ID, or if
      you believe there is something incorrect in your team or department data,
      you'll need to{' '}
      <TrackedLink
        to="https://team-change.docs.zalando.net"
        {...teamsTooltipLinkTracking}
      >
        request changes in SAP.
      </TrackedLink>
    </Typography>
  );
};

export function CatalogPage({
  type,
  filterGroups,
  ...props
}: CatalogPageProps) {
  const { loading, error, matchingEntities } = useFilteredEntities();
  const [order, setOrder] = React.useState<'asc' | 'desc' | ''>('asc');
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const showScorecardsColumn = featureFlagsApi.isActive('tech-insights');
  const [selectedSidebarItem, setSelectedSidebarItem] = React.useState<{
    id: string;
    label: string;
  }>();
  const initiallySelectedFilter =
    selectedSidebarItem?.id ??
    props.initiallySelectedFilter ??
    (getQueryValue('group') || 'mine');

  // Mapping of the different types of catalog pages to their respective filter components
  const FILTERS = React.useMemo(
    () => ({
      application: <ApplicationResultsFilter />,
      api: <APIResultsFilter />,
      docs: <DocsResultsFilter />,
      teams: <TeamsResultsFilters />,
    }),
    [],
  );

  const columns = React.useMemo((): TableColumn<ICatalog.EntityRow>[] => {
    switch (type) {
      case 'application':
        return applicationColumns(
          order,
          showScorecardsColumn ? [] : ['scorecards'],
        );
      case 'api':
        return apiColumns;
      case 'docs':
        return docsColumns;
      case 'teams':
        return teamsColumns;
      default:
        return [];
    }
  }, [order, showScorecardsColumn, type]);

  const tableProps = React.useMemo(
    () => ({
      titlePreamble: selectedSidebarItem?.label ?? '',
      entities: matchingEntities,
      loading: loading,
      error: error,
      emptyContent: selectedSidebarItem?.id === 'mine' && (
        <EmptyContent action={type} />
      ),
      canExport: type === 'application',
      ...(type === 'application' && { setOrder }),
      ...(type === 'teams' && { infoText: <TeamsTableInfoText /> }),
    }),
    [selectedSidebarItem, loading, error, matchingEntities, type],
  );

  const FilterComponent = React.useMemo(
    () => FILTERS[type] || null,
    [type, FILTERS],
  );

  return (
    <>
      {type === 'application' && <TableButtons showRegister />}
      <S.ContentWrapper>
        <div>
          <CatalogFilter
            buttonGroups={filterGroups}
            onChange={setSelectedSidebarItem}
            initiallySelected={initiallySelectedFilter}
          />
          {FilterComponent}
        </div>

        <CatalogTable {...tableProps} columns={columns} />
      </S.ContentWrapper>
    </>
  );
}
