import React from 'react';
import isEqual from 'lodash/isEqual';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from '@backstage/core-components';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Skeleton from '@mui/lab/Skeleton';
import { useStyles } from './styles';
import { removePendingUpdates, setPendingUpdates } from '../../utils';
import { EventTracker, TrackedLink, useUser } from 'plugin-ui-components';
import { useApi } from '@backstage/core-plugin-api';
import { teamsApiRef } from '../../../../api';
import { addBucket, addSuggestedBucket, removeBucket } from './tracking';

const DEFAULT_MINT_BUCKET = 'zalando-stups-mint-${id}-eu-west-1';

export function CredentialItem({
  s3_buckets: appBuckets,
  has_problems: hasProblems,
  editable,
  updatedData,
  setUpdatedData,
}: IAccessControl.ApplicationType & {
  editable: boolean;
  updatedData: Array<any>;
  setUpdatedData: Function;
}) {
  const teamsApi = useApi(teamsApiRef);
  const [bucketInput, setBucketInput] = React.useState('');
  const [suggestedBucket, setSuggestedBucket] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const styles = useStyles();

  const { value: user } = useUser();

  const getSuggestedBucket = async () => {
    if (user) {
      try {
        const AWSAccounts = await teamsApi.getUserAWSAccounts(
          user?.metadata.name,
        );
        setSuggestedBucket(
          DEFAULT_MINT_BUCKET.replace('${id}', AWSAccounts[0]),
        );
      } catch {
        setHasError(true);
      }
    }
  };

  const handleAdd = (bucket?: string) => {
    setUpdatedData((prev: any) => [...prev, bucket ?? bucketInput]);
    if (!bucket) setBucketInput('');
  };

  const handleRemove = (bucket: string) => {
    const edit = [...updatedData];
    const index = edit.indexOf(bucket);
    if (index !== -1) edit.splice(index, 1);
    setUpdatedData(edit);
  };

  React.useEffect(() => {
    setUpdatedData(appBuckets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appBuckets]);

  React.useEffect(() => {
    getSuggestedBucket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (isEqual(updatedData, appBuckets)) {
      removePendingUpdates('credentialDistribution');
    } else {
      setPendingUpdates('credentialDistribution');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData]);

  return (
    <>
      {hasProblems && !!appBuckets?.length && !editable && (
        <Box className={styles.alert}>
          Mint has problems syncing credentials. Please try the following:
          <br />
          <br />
          1. Check that all configured S3 buckets exist and give write access to
          mint-worker.
          <br />
          2. Try to manually trigger the sync of credentials via the "Sync"
          button above.
        </Box>
      )}
      <Typography variant="body2">
        <strong>Registered buckets</strong>
      </Typography>
      {!editable ? (
        <>
          <List className={styles.strippedList}>
            {!!appBuckets?.length ? (
              appBuckets.map(bucket => <ListItem>{bucket}</ListItem>)
            ) : (
              <ListItem>There are no S3 buckets configured.</ListItem>
            )}
          </List>
        </>
      ) : (
        <>
          <Typography variant="body2">
            Activate credential distribution into these S3 buckets (please check
            the{' '}
            <Link to="http://docs.aws.amazon.com/AmazonS3/latest/dev/BucketRestrictions.html">
              Naming Conventions
            </Link>
            ).
          </Typography>
          <List className={styles.strippedList}>
            {!appBuckets.length && !updatedData.length && (
              <ListItem>There are no S3 buckets configured.</ListItem>
            )}
            {appBuckets.map(bucket => (
              <ListItem dense>
                {updatedData.includes(bucket) ? (
                  <>
                    <ListItemText>{bucket}</ListItemText>
                    <ListItemSecondaryAction
                      onClick={() => handleRemove(bucket)}
                    >
                      <IconButton edge="end" size="small">
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </>
                ) : (
                  <>
                    <ListItemText className={styles.disabled}>
                      <del>{bucket}</del>
                    </ListItemText>
                    <EventTracker {...addBucket()}>
                      <ListItemSecondaryAction
                        onClick={() => handleAdd(bucket)}
                      >
                        <IconButton edge="end" size="small">
                          <SettingsBackupRestoreIcon fontSize="inherit" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </EventTracker>
                  </>
                )}
              </ListItem>
            ))}
            {/* Show added buckets */}
            {updatedData.map(
              bucket =>
                !appBuckets.includes(bucket) && (
                  <ListItem dense>
                    <ListItemText>{bucket}</ListItemText>
                    <EventTracker {...removeBucket()}>
                      <ListItemSecondaryAction
                        onClick={() => handleRemove(bucket)}
                      >
                        <IconButton edge="end" size="small">
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </EventTracker>
                  </ListItem>
                ),
            )}
          </List>
          <Typography variant="body2" className={styles.subtitle}>
            <strong>Add new buckets</strong>
          </Typography>
          {!appBuckets.length && !hasError && (
            <Typography variant="body2">
              Your Mint Bucket is probably{' '}
              <strong>
                {suggestedBucket ? (
                  suggestedBucket
                ) : (
                  <Skeleton
                    variant="text"
                    width={295}
                    style={{ display: 'inline-block' }}
                  />
                )}
              </strong>{' '}
              (
              <TrackedLink
                to="#"
                onClick={event => {
                  event.preventDefault();
                  handleAdd(suggestedBucket);
                }}
                {...addSuggestedBucket()}
              >
                + Add this
              </TrackedLink>
              ).
            </Typography>
          )}
          <Paper className={styles.paper}>
            <InputBase
              className={styles.input}
              placeholder="Enter bucket's name"
              onChange={event => setBucketInput(event.target.value)}
              value={bucketInput}
            />
            <IconButton
              onClick={() => handleAdd()}
              className={styles.iconButton}
              size="small"
              disabled={!bucketInput.length}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </Paper>
        </>
      )}
    </>
  );
}
