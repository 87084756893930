import React, { useState } from 'react';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useAsync } from 'react-use';
import AlertUI from '@mui/lab/Alert';
import { Entity } from '@backstage/catalog-model';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import RelativeTime from 'dayjs/plugin/relativeTime';
import { opsgenieApiRef } from '../../api';
import { Alert } from '../../types';
import { AlertActionsMenu, AlertStatus } from '../Alert';
import { TrackedLink } from 'plugin-ui-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

dayjs.extend(UTC);
dayjs.extend(RelativeTime);

const useStyles = makeStyles({
  root: {
    alignItems: 'baseline',
  },
  listItemPrimary: {
    fontWeight: 'bold',
  },
  listItemIcon: {
    minWidth: '1em',
  },
});

const AlertListItem = ({ entity, alert }: { alert: Alert; entity: Entity }) => {
  const classes = useStyles();
  const opsgenieApi = useApi(opsgenieApiRef);

  const [alertState, setAlertState] = useState({
    data: alert,
    updatedAt: alert.updatedAt,
  });

  const onAlertChanged = (newAlert: Alert) => {
    setAlertState({
      data: newAlert,
      updatedAt: new Date().toISOString(),
    });
  };

  return (
    <ListItem className={classes.root} dense key={alertState.data.id}>
      <ListItemIcon className={classes.listItemIcon}>
        <AlertStatus alert={alertState.data} />
      </ListItemIcon>
      <ListItemText
        primary={
          <TrackedLink
            plugin="opsgenie"
            eventCategory={`Service Catalog Detail For '${entity.metadata.name}'`}
            eventLabel={`OpsGenie Alert #${alert.id} opened - OpsGenie Alert Widget`}
            target="_blank"
            to={opsgenieApi.getAlertDetailsURL(alert)}
          >
            <Grid container direction="row" spacing={0} alignItems="center">
              <Typography variant="body2" noWrap>
                {' '}
                {alertState.data.message}{' '}
                <OpenInNewIcon style={{ width: '0.5em', height: '0.5em' }} />
              </Typography>
            </Grid>
          </TrackedLink>
        }
        primaryTypographyProps={{
          variant: 'body1',
          className: classes.listItemPrimary,
        }}
        secondary={
          <Typography noWrap variant="body2" color="textSecondary">
            Created {dayjs.utc(alertState.data.createdAt).fromNow()}
          </Typography>
        }
      />
      <ListItemSecondaryAction>
        <AlertActionsMenu
          alert={alertState.data}
          onAlertChanged={onAlertChanged}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

interface AlertsSummaryTableProps {
  entity: Entity;
  alerts: Alert[];
}

interface AlertsSummaryProps {
  entity: Entity;
  query: string;
}

const AlertsSummaryTable = ({ entity, alerts }: AlertsSummaryTableProps) => {
  return (
    <List dense>
      {alerts.map((alert, index) => (
        <AlertListItem entity={entity} key={alert.id + index} alert={alert} />
      ))}
      {alerts.length === 0 && <>No recent alerts</>}
    </List>
  );
};

export const AlertsSummary = ({ entity, query }: AlertsSummaryProps) => {
  const opsgenieApi = useApi(opsgenieApiRef);

  const { value, loading, error } = useAsync(
    async () => await opsgenieApi.getAlerts({ limit: 3, query: query }),
  );

  if (loading) {
    return <Progress />;
  } else if (error) {
    return (
      <AlertUI data-testid="error-message" severity="error">
        {error.message}
      </AlertUI>
    );
  }

  return <AlertsSummaryTable entity={entity} alerts={value || []} />;
};
