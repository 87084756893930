import { NavLink } from 'react-router-dom';
import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import styled from '@mui/styles/styled';

export const Breadcrumbs = styled(MaterialBreadcrumbs)(props => ({
  color: 'white',

  [props.theme.breakpoints.up('sm')]: {
    ['& > ol']: {
      maxWidth: 'calc(100vw - 200px)', // Note: a partial solution for a problem with backstage's header styles
      flexFlow: 'nowrap',
      overflow: 'hidden',
    },

    ['& li']: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

export const BreadcrumbItem = styled(NavLink)(
  (props: { 'aria-selected': string; disabled: boolean }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: props['aria-selected'] === 'true' ? 600 : 400,
    cursor: 'pointer',
    pointerEvents:
      props.disabled || props['aria-selected'] === 'true' ? 'none' : 'initial',

    ['&:hover']: {
      textDecoration: 'underline',
    },
  }),
);
