import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAlertInsightsSectionStyles as useStyles } from '../../utils/styles';
import { ScrollAnchor } from '../../utils/scroll';
import { Alert } from '../../types';

type AlertInsightsSectionHeaderProps = {
  alert: Alert;
  number: number;
};

export const AlertInsightsSectionHeader = ({
  alert,
  number,
}: AlertInsightsSectionHeaderProps) => {
  const classes = useStyles();

  const isViewInstructionsButtonDisplayed = !!alert.url;

  return (
    <Box position="relative" mb={3} textAlign="left">
      <ScrollAnchor id={`alert-${number}`} />
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Avatar className={classes.button}>{number}</Avatar>
            </Box>
            <Box>
              <Typography variant="h5">{alert.title}</Typography>
              <Typography gutterBottom>{alert.subtitle}</Typography>
            </Box>
          </Box>
        </Grid>
        {isViewInstructionsButtonDisplayed && (
          <Grid item>
            <Button variant="text" color="primary" href={alert.url}>
              {alert.buttonText || 'View Instructions'}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
