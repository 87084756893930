import { PluginTracking } from 'plugin-ui-components';
import { QUERY_KEY, TAB_KEY } from '../constants';

type HTMLEvent = keyof Omit<
  import('react').DOMAttributes<HTMLElement>,
  'children' | 'dangerouslySetInnerHTML'
>;

const searchLocation =
  window.location.pathname === '/search' ? 'search_page' : 'search_modal';

const defaultParams: Pick<GATrackingEvent['parameters'], 'search_location'> = {
  search_location: searchLocation,
};

export const common: Pick<IEventTracker, 'plugin' | 'eventCategory'> = {
  plugin: 'search',
  eventCategory: searchLocation,
};

function getSearchTerm(): string | null {
  return new URLSearchParams(window.location.search).get(QUERY_KEY);
}

function getSearchTab(): string | null {
  return new URLSearchParams(window.location.search).get(TAB_KEY);
}

/**
 * Search for a term
 * @param term The term that was searched
 * @param tab The tab that was searched
 */
export function searchQuery(term: string, tab: string) {
  PluginTracking.sendEvent({
    name: 'search',
    parameters: { search_term: term, ...common, search_tab: tab ? tab : 'all' },
  });
}

/**
 * Search filter change
 * @param filters The filters that were changed
 */
export function filterChange(filters: object) {
  PluginTracking.sendEvent({
    name: 'search',
    parameters: { ...common, search_filters: JSON.stringify(filters) },
  });
}

/**
 * Click on a search result link
 * @param url The URL of the result
 */
export function resultClick(
  url: string,
): GATrackingEvent & { interaction: HTMLEvent } {
  return {
    interaction: 'onClick',
    name: 'search',
    parameters: {
      ...defaultParams,
      search_interaction: `Click link: ${url}`,
      search_tab: getSearchTab(),
      search_term: getSearchTerm(),
    },
  };
}

/** User email is clicked */
export function userEmailClick(): IEventTracker {
  return {
    ...common,
    eventCategory: `Search result interaction for ${common.eventCategory}`,
    interaction: 'onClick',
    eventAction: 'click',
    eventLabel: `Tab: User, Email clicked`,
  };
}

/** User email is clicked */
export function teamEmailClick(): IEventTracker {
  return {
    ...common,
    eventCategory: `Search result interaction for ${common.eventCategory}`,
    interaction: 'onClick',
    eventAction: 'click',
    eventLabel: `Tab: Team, Email clicked`,
  };
}

/**
 * Search page changed
 * @param newPage The new page that was selected
 */
export function pageChange(newPage: number) {
  return PluginTracking.sendEvent({
    ...common,
    eventAction: 'click',
    eventLabel: `Page "${newPage}" selected`,
  });
}

/**
 * Change the search tab
 * @param newTab The new tab that was selected
 */
export function tabChange(newTab: string) {
  return PluginTracking.sendEvent({
    ...common,
    eventAction: 'click',
    eventLabel: `Tab "${newTab}" selected`,
  });
}

/**
 * Click on a link in the Recent Searches section
 * @param term The term that was clicked
 */
export function clickRecentSearches(term: string): IEventTracker {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Click on "${term}" on Recent Searches`,
  };
}

/**
 * Click on a link in the Top Searches section
 * @param term The term that was clicked
 */
export function clickTopSearches(term: string): IEventTracker {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Click on "${term}" on Top Searches`,
  };
}

/**
 * Click on a documentation link in the Top Searches section
 * @param term The term that was clicked
 */
export function clickTopSearchesDocs(term: string): IEventTracker {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Click on "${term}" on Top Searches Docs link`,
  };
}

/**
 * Expand or collapse the Hot Pages accordion
 * @param expanded Whether the accordion is expanded or collapsed
 */
export function expandHotPages(expanded: boolean): IEventTracker {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Set Hot Pages Accordion ${
      expanded ? 'expanded' : 'collapsed'
    }}`,
  };
}

/**
 * Click on a link in the Hot Pages section
 * @param link The link that was clicked
 */
export function clickHotPagesLink(link: string): IEventTracker {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Click on "${link}" link on Hot Pages`,
  };
}

/**
 * Expand the search modal
 * @param data The data that is being searched for
 */
export function expandModal(data: string): IEventTracker {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: `Expand Modal with data: ${data}`,
  };
}

/** Close the search modal */
export function closeModal(): IEventTracker {
  return {
    ...common,
    eventAction: 'click',
    interaction: 'onClick',
    eventLabel: 'Close Modal',
  };
}
