import { ItemCardGrid } from '@backstage/core-components';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/lab/Pagination';
import { SearchBar, useUserTeams } from 'plugin-ui-components';
import React, { memo, useEffect } from 'react';
import { useDebounce } from './hooks/useDebouce';
import { Schedule } from '../../types';
import { ResponderTitleFormatter } from './OnCallList';
import { OnCallForScheduleCard } from './OnCallScheduleCard';
import { WeekButton } from './WeekButton';
import { useStyles } from './styles';

export const SchedulesGrid = memo(
  ({
    schedules,
    cardsPerPage,
    responderFormatter,
  }: {
    schedules: Schedule[];
    cardsPerPage: number;
    responderFormatter?: ResponderTitleFormatter;
  }) => {
    const classes = useStyles();
    const [results, setResults] = React.useState(schedules);

    const { value } = useUserTeams();
    const userTeam = React.useMemo(
      () =>
        value?.userTeams?.map((item: { spec: { fullName: string } }) =>
          item.spec.fullName.toLowerCase(),
        ),
      [value],
    );

    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [offset, setOffset] = React.useState(0);
    const [date, setDate] = React.useState('');
    const handleChange = (_: React.ChangeEvent<unknown>, val: number) => {
      setOffset((val - 1) * cardsPerPage);
      setPage(val);
    };
    const handleDate = (newDate: string) => {
      setDate(newDate);
    };
    const debouncedSearch = useDebounce(search, 300);

    // debounced search
    useEffect(() => {
      if (!debouncedSearch) {
        setResults(schedules);
        return;
      }

      const filtered = schedules.filter(schedule => {
        return schedule.name
          .toLowerCase()
          .includes(debouncedSearch.toLowerCase());
      });

      setResults(filtered);
    }, [debouncedSearch, schedules, userTeam]);

    return (
      <div>
        <Grid container spacing={2} className={classes.search}>
          <Grid item xs={12} md={8} xl={9}>
            <SearchBar
              value={search}
              label="Search teams..."
              onChange={e => setSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4} xl={3}>
            <WeekButton handleDate={handleDate} />
          </Grid>
        </Grid>

        <div style={{ marginBottom: '0.5rem' }}>
          <Typography variant="caption">
            Found <b>{schedules?.length}</b> on call responders <br />
          </Typography>
        </div>

        <ItemCardGrid classes={{ root: classes.onCallItemGrid }}>
          {results
            .filter((_, i) => i >= offset && i < offset + cardsPerPage)
            .map(schedule => (
              <OnCallForScheduleCard
                key={schedule.id}
                schedule={schedule}
                responderFormatter={responderFormatter}
                date={date}
              />
            ))}
        </ItemCardGrid>

        <Pagination
          className={classes.pagination}
          count={Math.ceil(results.length / cardsPerPage)}
          page={page}
          onChange={handleChange}
          showFirstButton
          showLastButton
        />
      </div>
    );
  },
);
