import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import ReplayIcon from '@mui/icons-material/Replay';
import { observer } from 'mobx-react-lite';
import {
  PIPELINE_PENDING_STATUSES,
  PIPELINE_STOPPED_STATUSES,
} from '../../../../constants';
import { useServices } from '../../../../services';
import { Select, Dialog } from '../../../../common';
import { RetriggerForm, GenericFieldModel } from './RetriggerForm';

const DEPENDENCY_TYPE = {
  original: 'original',
  latest: 'latest',
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',

    '& > *': {
      marginTop: theme.spacing(1),
    },

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',

      '& > *': {
        marginLeft: theme.spacing(1),
      },
    },
  },
}));

export const Actions = observer(() => {
  const navigate = useNavigate();
  const [dependency, setDependency] = useState(DEPENDENCY_TYPE.latest);
  const [open, setOpen] = useState(false);
  const { runService } = useServices();
  const classes = useStyles();

  function retrigger() {
    const { alias, organization, name } = runService.run.repoInfo;
    runService.retriggerRun(dependency === DEPENDENCY_TYPE.latest, () =>
      navigate(`/cdp/${alias}/${organization}/${name}`),
    );
  }

  function retriggerWithParams(
    parameters?: Array<{ name: string; value: string | boolean }>,
  ) {
    const { alias, organization, name } = runService.run.repoInfo;
    runService.retriggerRun(
      dependency === DEPENDENCY_TYPE.latest,
      () => navigate(`/cdp/${alias}/${organization}/${name}`),
      parameters,
    );
  }

  function abort() {
    runService.abortRun();
  }

  function handleDependencySelection(event: ChangeEvent<HTMLSelectElement>) {
    setDependency(event.target.value);
  }

  return (
    <>
      {PIPELINE_PENDING_STATUSES.includes(runService.run.status) && (
        <Button
          size="small"
          variant="outlined"
          onClick={abort}
          disabled={runService.actionState.isLoading}
        >
          Abort
        </Button>
      )}

      {PIPELINE_STOPPED_STATUSES.includes(runService.run.status) && (
        <div className={classes.wrapper}>
          {runService.run.hasDependencyVars && (
            <Select
              id="event-type-select"
              value={dependency}
              elementSize="small"
              disabled={runService.actionState.isLoading}
              onChange={handleDependencySelection}
            >
              <option value={DEPENDENCY_TYPE.latest}>Dependency: latest</option>
              <option value={DEPENDENCY_TYPE.original}>
                Dependency: original
              </option>
            </Select>
          )}

          {runService.run.hasParameters ? (
            <>
              <Button
                size="small"
                variant="outlined"
                disabled={runService.actionState.isLoading}
                onClick={retrigger}
                startIcon={<ReplayIcon />}
              >
                Retrigger
              </Button>
              <Button
                size="small"
                variant="outlined"
                disabled={runService.actionState.isLoading}
                onClick={() => setOpen(true)}
                startIcon={<ReplayIcon />}
              >
                Retrigger With Params
              </Button>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                title="Parametrize your pipeline"
                children={
                  <RetriggerForm
                    data={runService.run.parameters.map(
                      param => new GenericFieldModel(param),
                    )}
                    action={retriggerWithParams}
                  />
                }
              />
            </>
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={retrigger}
              disabled={runService.actionState.isLoading}
              startIcon={<ReplayIcon />}
            >
              Retrigger
            </Button>
          )}
        </div>
      )}
    </>
  );
});
