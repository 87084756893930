import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/lab/Autocomplete';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const reviewStatues: IAppReview.ReviewStatus[] = [
  'reviewed',
  'needs review',
  'never',
];
const label = 'Review status';
const id = 'review-filter';
export const AppReviewFilter = React.memo(() => {
  const { reviewFilter, setReviewFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  return (
    <FormControl size="small" margin="none">
      <Autocomplete
        id={id}
        multiple
        options={reviewStatues}
        value={reviewFilter}
        classes={{ inputRoot }}
        onChange={(_: any, value) =>
          setReviewFilter(value as IAppReview.ReviewStatus[])
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            margin="dense"
            size="small"
          />
        )}
      />
    </FormControl>
  );
});
