import React from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';

import { useServices } from '../../../../services';
import { getStepDetails } from './utils';
import { STEP_TYPE } from '../../../../constants';
import { Emoji, List, ListItem, InfoCard } from '../../../../common';

import {
  Error,
  Warnings,
  StatusLog,
  Grid,
  GridItem,
  DeploymentProblems,
  UserErrors,
  InternalErrors,
  ValidationProblems,
} from '../../../common';
import { StepRunHistory } from './StepRunHistory';
import { ZesDetails } from '../ZesDetails';
import { TrafficProgress } from './TrafficProgress';

export const Details = observer(() => {
  const {
    runService: { step },
  } = useServices();

  const details = getStepDetails(step);

  return (
    <Box padding={2}>
      <Grid>
        <GridItem>
          <InfoCard
            title={
              <span>
                <Emoji name="info" emoji="ℹ️" /> Step run info
              </span>
            }
          >
            <List>
              {details.map(
                (item, index) =>
                  Boolean(item.value) &&
                  item.value !== 0 && (
                    <ListItem key={index} icon={item.icon}>
                      {item.text}
                    </ListItem>
                  ),
              )}
            </List>
          </InfoCard>
        </GridItem>

        {step.type === STEP_TYPE.TRAFFIC && Boolean(step.is_automated) && (
          <GridItem>
            <TrafficProgress />
          </GridItem>
        )}

        {!!step.run_history.length && (
          <GridItem>
            <StepRunHistory history={step.run_history} />
          </GridItem>
        )}

        {!!step.run.error && (
          <GridItem>
            {step.runner === 'zflow' ? (
              <ZesDetails
                cdpDeploymentId={step.run.id}
                cdpError={step.run.error}
              />
            ) : (
              <Error error={step.run.error} />
            )}
          </GridItem>
        )}

        {!!step.run.warnings.length && (
          <GridItem>
            <Warnings warnings={step.run.warnings} />
          </GridItem>
        )}

        {!!Object.keys(step.run.problems.deploymentValidationProblems)
          .length && (
          <GridItem>
            <DeploymentProblems
              deploymentValidationProblems={
                step.run.problems.deploymentValidationProblems
              }
            />
          </GridItem>
        )}

        {!!step.run.problems.scriptValidationProblems.length && (
          <GridItem>
            <ValidationProblems
              validationProblems={step.run.problems.scriptValidationProblems}
            />
          </GridItem>
        )}

        {!!step.run.status_log.length && (
          <GridItem>
            <StatusLog statusLog={step.run.status_log} />
          </GridItem>
        )}

        {step.type === STEP_TYPE.SCRIPT && !!step.run.user_errors.length && (
          <GridItem>
            <UserErrors userErrors={step.run.user_errors} />
          </GridItem>
        )}

        {step.type === STEP_TYPE.SCRIPT &&
          !!step.run.internal_errors.length && (
            <GridItem>
              <InternalErrors internalErrors={step.run.internal_errors} />
            </GridItem>
          )}
      </Grid>
    </Box>
  );
});
