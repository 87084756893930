import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = '24/7 Support';
const id = '24/7-filter';
export const Support247 = React.memo(() => {
  const { support247Filter, setSupport247Filter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" margin="dense" size="small">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        variant="outlined"
        size="small"
        className="select-filter"
        value={support247Filter || ''}
        onChange={ev => setSupport247Filter(ev.target.value as any)}
        label={label}
        labelId={id}
        classes={{ select }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
    </FormControl>
  );
});
