import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'PRR';
const id = 'prr-filter';
export const ProductionReadinessReviewFilter = React.memo(() => {
  const { prrFilter, setPrrFilter } = React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" size="small" margin="dense">
      <InputLabel id="prr-filter">{label}</InputLabel>
      <Select
        variant="outlined"
        size="small"
        className="select-filter"
        title="Production readiness review"
        value={prrFilter}
        onChange={ev =>
          setPrrFilter(String(ev.target.value) as ICatalog.FilterYesOrNever)
        }
        classes={{ select }}
        label={label}
        labelId={id}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        <MenuItem value="yes">yes</MenuItem>
        <MenuItem value="never">never</MenuItem>
      </Select>
    </FormControl>
  );
});
