import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import useTheme from '@mui/styles/useTheme';
import { LegendItem } from '../LegendItem';
import { currencyFormatter } from '../../utils/formatters';
import { CostInsightsTheme } from '../../types';
import { useBarChartLayoutStyles as useStyles } from '../../utils/styles';

export type BarChartLegendOptions = {
  previousName: string;
  previousFill: string;
  currentName: string;
  currentFill: string;
  hideMarker?: boolean;
};

export type BarChartLegendProps = {
  costStart: number;
  costEnd: number;
  options?: Partial<BarChartLegendOptions>;
};

export const BarChartLegend = ({
  costStart,
  costEnd,
  options = {},
  children,
}: PropsWithChildren<BarChartLegendProps>) => {
  const theme = useTheme<CostInsightsTheme>();
  const classes = useStyles();

  const data = Object.assign(
    {
      previousName: 'Previous',
      previousFill: theme.palette.lightBlue,
      currentName: 'Current',
      currentFill: theme.palette.darkBlue,
    },
    options,
  );

  return (
    <Box className={classes.legend} display="flex" flexDirection="row">
      <Box marginRight={2}>
        <LegendItem
          title={data.previousName}
          markerColor={options.hideMarker ? undefined : data.previousFill}
        >
          {currencyFormatter.format(costStart)}
        </LegendItem>
      </Box>
      <Box marginRight={2}>
        <LegendItem
          title={data.currentName}
          markerColor={options.hideMarker ? undefined : data.currentFill}
        >
          {currencyFormatter.format(costEnd)}
        </LegendItem>
      </Box>
      {children}
    </Box>
  );
};
