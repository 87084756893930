import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import { Table } from '@backstage/core-components';
import { Test } from '../../api/LoadTestOrchestratorApi';
import { getTestRunColumns } from './TestRunTable';
import { useStyles } from '../../StylingComponent/styles';

interface TestDetailsContentProps {
  test: Test;
  showSnackbar: (message: string, severity: 'success' | 'error') => void;
  fetchTestDetails: () => Promise<void>;
  setSelectedTestRunId: React.Dispatch<React.SetStateAction<string | null>>;
  setCreateRampOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TestDetailsContent = ({
  test,
  showSnackbar,
  fetchTestDetails,
  setSelectedTestRunId,
  setCreateRampOpen,
}: TestDetailsContentProps) => {
  const classes = useStyles();

  return (
    <>
      <Card style={{ marginBottom: 20 }}>
        <CardContent>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Test ID:</Typography>
              <Typography>{test.id}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Description:</Typography>
              <Typography>{test.metadata.description}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Scheduled For:</Typography>
              <Typography>
                {new Date(test.scheduled_for).toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <TableContainer>
            <Table
              title="Test Runs"
              data={test.test_runs}
              columns={getTestRunColumns(
                test,
                showSnackbar,
                fetchTestDetails,
                setSelectedTestRunId,
                setCreateRampOpen,
                classes,
              )}
              options={{
                padding: 'dense',
                pageSize: 10,
                headerStyle: { textTransform: 'capitalize' },
                tableLayout: 'auto',
              }}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
};
