import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import DoneAll from '@mui/icons-material/DoneAll';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ReportProblem from '@mui/icons-material/ReportProblem';
import {
  useRollbackErrorStyles,
  useRollbackLoaderStyles,
  useRollbackSuccessStyles,
} from './styles';

export function Loader({ msg }: { msg: string }) {
  const classes = useRollbackLoaderStyles();

  return (
    <div className={classes.loader}>
      <CircularProgress />
      <p className={classes.loader__message}> {msg} </p>
    </div>
  );
}

export function RollbackError({
  title,
  details,
  closeDialog,
}: {
  title: string;
  details: string;
  closeDialog: () => void;
}) {
  const classes = useRollbackErrorStyles();

  return (
    <div className={classes.error}>
      <ReportProblem color="primary" />
      <h2 className={classes.error__title}>{title}</h2>
      <p className={classes.error__details}>{details} </p>
      <Button color="primary" variant="contained" onClick={closeDialog}>
        Close
      </Button>
    </div>
  );
}

export function RollbackSuccess({ closeDialog }: { closeDialog: () => void }) {
  const classes = useRollbackSuccessStyles();

  return (
    <div className={classes.success}>
      <DoneAll color="primary" />
      <p className={classes.success__message}>Rollback initiated!</p>
      <Button color="primary" variant="contained" onClick={closeDialog}>
        Back to pipelines
      </Button>
    </div>
  );
}

export function Empty() {
  return (
    <Box textAlign="center">
      <figure>
        <LiveHelpIcon />
      </figure>
      <Typography> No rollback plan available for this pipeline.</Typography>
      <Typography>
        Please, proceed with a manual rollback or contanct&nbsp;
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="/support/build-test-deploy/continuous-delivery-cdp-+"
        >
          support.
        </Link>
      </Typography>
    </Box>
  );
}
