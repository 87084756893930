import React from 'react';
import { Tooltip } from '../Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import * as S from './styles';

interface UserTagProps {
  user: string;
}

export const UserTag = ({ user }: UserTagProps) => {
  return (
    <S.Text>
      <PersonIcon />

      <Tooltip title={`Created by: ${user}`} placement="bottom-start">
        <a
          href={`/catalog/default/user/${user}`}
          target="_blank"
          onClick={e => e?.stopPropagation()}
        >
          {user}
        </a>
      </Tooltip>
    </S.Text>
  );
};
