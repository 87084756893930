import { useEffect, useState, useMemo } from 'react';
import { Library, FetchLibrariesOptions } from '../types';
import { useUserTeams } from 'plugin-ui-components';
import { useFetchLibraries } from './useFetchLibraries';
import { parseEntityRef } from '@backstage/catalog-model';

export const useUserLibs = (
  options: FetchLibrariesOptions,
  pageNumber: number,
) => {
  const {
    value: { userTeams },
  } = useUserTeams();
  const [userLibs, setUserLibs] = useState<Library[]>([]);
  const [next, setNext] = useState<string | null>(null);
  const [prev, setPrev] = useState<string | null>(null);

  const userApps: string[] | undefined = useMemo(
    () =>
      userTeams
        ?.flatMap(item =>
          item.relations
            ?.filter(r => r.type === 'ownerOf')
            .map(a => parseEntityRef(a.targetRef).name),
        )
        .filter((name): name is string => name !== undefined), // Filter out undefined values
    [userTeams],
  );

  const mergedOptions: FetchLibrariesOptions = useMemo(
    () => ({
      ...options,
      application: userApps,
    }),
    [options, userApps],
  );

  const fetchLibraries = useFetchLibraries(mergedOptions);

  useEffect(() => {
    if (userApps?.length) {
      fetchLibraries().then(newLibraries => {
        if (newLibraries.items) {
          const libraries: Library[] = newLibraries.items.map(item => ({
            ...item,
            owners: [], // add the missing owners property
            applications: item.applications.filter(
              (app): app is IEntityApp => app !== undefined,
            ),
          }));
          setUserLibs(libraries);
          setNext(newLibraries.next);
          setPrev(newLibraries.prev);
        }
      });
    }
  }, [userApps, fetchLibraries, mergedOptions, pageNumber]);

  return { userLibs, next, prev };
};
