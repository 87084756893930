import React from 'react';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import LinkIcon from '@mui/icons-material/Link';
import { Link, LinkProps } from '@backstage/core-components';

export interface IconLinkVerticalProps extends Omit<LinkProps, 'to'> {
  icon?: React.ReactNode;
  href?: string;
  disabled?: boolean;
  title?: string;
  label: React.ReactNode;
}

const useIconStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    gap: 4,
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  disabled: {
    color: theme.palette.text.disabled,
    cursor: 'default',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  label: {
    fontSize: '0.7rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    letterSpacing: 1.2,
  },
}));

export function IconLinkVertical({
  icon = <LinkIcon />,
  href = '',
  disabled = false,
  ...props
}: IconLinkVerticalProps) {
  const classes = useIconStyles();

  return (
    <Link
      {...props}
      className={cn(
        classes.link,
        props.className,
        disabled ? classes.disabled : null,
      )}
      to={disabled ? '' : href}
    >
      {icon}
      <span className={classes.label}>{props.label}</span>
    </Link>
  );
}
