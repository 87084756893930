import React, { useRef } from 'react';
import {
  Entity,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useUserTeamsApps } from 'plugin-ui-components';
import { useApplicationScorecardsContext } from '../ApplicationScorecardsContext';
import { useQuery } from 'react-query';
import { useAsyncRetry } from 'react-use';
import {
  getApplicationScorecards,
  getScorecardsWithAssessments,
} from '../utils';

interface ScorecardMetrics {
  passing: number;
  failing: number;
}

interface TeamScorecardMetrics extends ScorecardMetrics {
  score: number;
  notApplicable: number;
}

export interface UserTeamsScorecardsInfo {
  teamMetrics: Map<string, TeamScorecardMetrics>;
  appsByTeam: Map<string, IEntityApp[]>;
  scorecardAssessmentsByTeams: Map<string, ScorecardEntityWithAssessment[][]>;
}

// Hook to fetch all scorecards and assessments related to an application
export function useApplicationScorecards(
  application: Entity,
  scorecardsToShow?: IEntityScorecard[],
) {
  const contextData = useApplicationScorecardsContext();
  const catalogApi = useApi(catalogApiRef);
  return useAsyncRetry(async () => {
    return getApplicationScorecards(
      application,
      contextData,
      catalogApi,
      scorecardsToShow,
    );
  }, [application, scorecardsToShow, contextData]);
}

// Hook to fetch all scorecards and assessments related to the user's (watched, owned...) applications
export const useUserApplicationsScorecards = () => {
  const catalogApi = useApi(catalogApiRef);
  const { value: apps = [] } = useUserTeamsApps();
  const distinctScorecardRefs = useRef(new Set<string>());

  // Used to avoid fetching relations for each app resulting in multiple HTTP requests
  const assessmentRefsByApp = React.useMemo(() => {
    const refsMap = new Map<IEntityApp, string[]>();
    apps.forEach(app => {
      // Default values
      const relations = app.relations || [];
      const appRelations = refsMap.get(app) || [];
      // Get all scorecard and assessment refs by using the relations
      const assessmentRefs = relations
        .filter(rel => rel.type === 'produces')
        .map(rel => rel.targetRef);
      const scorecardRefs = relations
        .filter(rel => rel.type === 'consumesScorecard')
        .map(rel => rel.targetRef);

      scorecardRefs.forEach(ref => distinctScorecardRefs.current.add(ref));

      appRelations.push(...assessmentRefs);
      if (appRelations.length) refsMap.set(app, appRelations);
    });
    return refsMap;
  }, [apps]);

  return useQuery(
    `user-applications-scorecards`,
    async () => {
      // Fetch all relations in one go
      const entityRefs = [
        ...distinctScorecardRefs.current.values(),
        ...[...assessmentRefsByApp.values()].flat(),
      ];
      const allRelations: Array<Entity | undefined> = entityRefs.length
        ? (await catalogApi.getEntitiesByRefs({ entityRefs })).items
        : [];

      // Group entities from the catalog response by type
      const relationsByType = allRelations.reduce<{
        assessments: IEntityScorecardAssessment[];
        scorecards: IEntityScorecard[];
      }>(
        (acc, item) => {
          if (item?.kind === 'ScorecardAssessment') {
            acc.assessments.push(item as IEntityScorecardAssessment);
          } else if (item?.kind === 'Scorecard') {
            acc.scorecards.push(item as IEntityScorecard);
          }
          return acc;
        },
        { assessments: [], scorecards: [] },
      );

      const scorecardsAssessments = await Promise.all(
        Array.from(assessmentRefsByApp.keys()).map(app =>
          getScorecardsWithAssessments(app, catalogApi, relationsByType).then(
            r => r.assessments,
          ),
        ),
      );

      return {
        scorecardsAssessments,
        scorecards: relationsByType.scorecards,
      };
    },
    {
      enabled: !!apps.length && !!assessmentRefsByApp,
    },
  );
};

export const useUserTeamsScorecards = () => {
  const { value: apps } = useUserTeamsApps();
  const { data: userAppScorecards } = useUserApplicationsScorecards();
  return useQuery<UserTeamsScorecardsInfo>(
    [`user-teams-scorecards`, apps, userAppScorecards],
    () => {
      return new Promise((resolve, reject) => {
        try {
          // Group applications by team
          const appsByTeam = (() => {
            return apps!.reduce((acc, value) => {
              const owner = value.relations?.find(
                rel => rel.type === 'ownedBy',
              );
              if (owner) {
                const ownerName = parseEntityRef(owner.targetRef).name;
                if (acc.has(ownerName)) {
                  acc.get(ownerName)!.push(value);
                } else {
                  acc.set(ownerName, [value]);
                }
              }
              return acc;
            }, new Map<string, IEntityApp[]>());
          })();
          // Group scorecards assessments by team
          const scorecardAssessmentsByTeams = (() => {
            const map = new Map<string, ScorecardEntityWithAssessment[][]>();
            if (userAppScorecards && appsByTeam) {
              return userAppScorecards.scorecardsAssessments.reduce(
                (acc, value) => {
                  if (value[0]) {
                    const assessmentApp = parseEntityRef(
                      value[0].spec.entityRef,
                    ).name;
                    let appTeam;
                    // Find the team that owns the application related to the assessment
                    Array.from(appsByTeam.keys()).every(team => {
                      if (
                        appsByTeam.get(team)?.some(app => {
                          return app.metadata.name === assessmentApp;
                        })
                      ) {
                        appTeam = team;
                        return false;
                      }
                      return true;
                    });
                    if (appTeam) {
                      // Group each scorecard assessment by team name
                      if (acc.has(appTeam)) {
                        acc.get(appTeam)!.push(value);
                      } else {
                        acc.set(appTeam, [value]);
                      }
                    }
                  }
                  return acc;
                },
                map,
              );
            }
            return map;
          })();
          // Calculate metrics for each team (passing, failing, score)
          const teamMetrics = (() => {
            const metricsByTeam = new Map<string, TeamScorecardMetrics>();
            if (scorecardAssessmentsByTeams) {
              scorecardAssessmentsByTeams.forEach((teamScorecards, team) => {
                const { passing, failing } =
                  teamScorecards.reduce<ScorecardMetrics>(
                    (acc, value) => {
                      let passingCounter = 0;
                      let failingCounter = 0;
                      value.forEach(v => {
                        if (v.spec.completionPercentage === 100) {
                          passingCounter += 1;
                        } else {
                          failingCounter += 1;
                        }
                      });
                      return {
                        passing: acc.passing + passingCounter,
                        failing: acc.failing + failingCounter,
                      };
                    },
                    { passing: 0, failing: 0 },
                  );
                // Count not applicable applications based on exclusions
                let notApplicable = 0;
                userAppScorecards?.scorecards.forEach(sc => {
                  const appsRefs = (appsByTeam.get(team) || []).map(app =>
                    stringifyEntityRef(app),
                  );
                  appsRefs.forEach(r => {
                    if (sc.spec.exclusions.map(x => x.entity_ref).includes(r))
                      notApplicable++;
                  });
                });
                metricsByTeam.set(team, {
                  passing,
                  failing,
                  notApplicable,
                  score:
                    passing + failing > 0
                      ? Math.round((passing / (passing + failing)) * 100)
                      : 0,
                });
              });
              return metricsByTeam;
            }
            return metricsByTeam;
          })();
          // Return the metrics and the scorecard assessments grouped by team
          resolve({
            teamMetrics,
            scorecardAssessmentsByTeams,
            appsByTeam,
          });
        } catch (err) {
          reject(`An error occured getting user scorecards data ${err}`);
        }
      });
    },
    {
      enabled: !!apps?.length && !!userAppScorecards,
    },
  );
};
