import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Field } from './Field';
import { GenericFieldModel } from './model';
import * as S from './styles';

const validateForm = (data: Array<GenericFieldModel>) =>
  !data.some(field => {
    return !field.isValid;
  });

interface Props {
  data: Array<GenericFieldModel>;
  action: (
    parameters?: Array<{ name: string; value: string | boolean }>,
  ) => void;
}

export const RetriggerForm = ({ data, action }: Props) => {
  const [isValid, setIsValid] = useState(false);

  const handleFieldChange = (
    field: GenericFieldModel,
    value: string | boolean,
  ) => {
    field.setValue(value);
    field.validate();

    setIsValid(validateForm(data));
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setIsValid(validateForm(data));
  }, [data]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (isValid) {
      action(data.map(({ name, value }) => ({ name, value })));
    }
  }

  return (
    <>
      <S.Form onSubmit={handleSubmit}>
        <DialogContent dividers>
          {data.map(field => (
            <Field
              key={field.name}
              field={field}
              onChange={handleFieldChange}
              onKeyPress={handleKeyPress}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button type="submit" disabled={!isValid} variant="outlined">
            Retrigger
          </Button>
        </DialogActions>
      </S.Form>
    </>
  );
};
