import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Link from '@mui/material/Link';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/lab/Autocomplete';
import type {
  AutocompleteCloseReason,
  AutocompleteRenderGroupParams,
} from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { ListBoxComponent } from './ListBoxComponent';
import { sortByGroup, sortSelectedFirst } from './sort.utils';
import { AutoCompleteProps, IOption } from './types';
import { useStyles } from './styles';

export function AutoComplete({
  onChange = () => {},
  isLoading = false,
  hasError = false,
  options = [],
  defaultSelection = [],
  getOptionSelected = (a: IOption, b: IOption) => a.value === b.value,
  placeholder = 'Selection',
  loadingText = 'Loading...',
  uniqueId = 'default-id',
  noOptionsText = 'No Option Found',
  noOptionsErrorText = 'Error while fetching the options',
  inputPlaceholder = 'Filter options',
  disabled = false,
  shouldGroup = false,
  showSelectedFirst = true,
}: AutoCompleteProps) {
  const classes = useStyles({ hasError });
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [inputRef, setInputRef] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<Array<IOption>>(defaultSelection);
  const [isOpen, setIsOpen] = useState(false);
  const [canGroup, setCanGroup] = useState(shouldGroup);

  useEffect(() => {
    if (defaultSelection.length > 0 && value.length === 0) {
      setValue(defaultSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelection]);

  useEffect(() => {
    setCanGroup(shouldGroup && !options.some(option => !option.group));
  }, [shouldGroup, options]);

  useEffect(() => {
    // Filter out any removed options
    if (value.length > 0) {
      setValue(
        value.filter(
          item =>
            options?.findIndex(option => getOptionSelected(item, option)) !==
            -1,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleClose = (
    _: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason,
  ) => {
    if (reason === 'toggleInput' || reason === 'blur') {
      return;
    }

    if (reason === 'escape') {
      setIsOpen(false);
      return;
    }
  };

  useEffect(() => {
    if (isOpen) {
      inputRef?.focus();
    }
  }, [isOpen, inputRef]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div>
        <Button
          variant="outlined"
          fullWidth
          aria-describedby={isOpen ? uniqueId : undefined}
          onClick={() => setIsOpen(!isOpen)}
          ref={anchorRef}
          className={classes.button}
          data-testid="main-button"
          disabled={disabled}
          disableRipple
        >
          <Typography>
            {placeholder}
            {!!value.length && !isLoading
              ? ` (${value.length}/${options?.length})`
              : ''}
          </Typography>
          {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
        <Popper
          id={uniqueId}
          open={isOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          className={classes.popper}
          disablePortal
          data-testid="popper"
        >
          <Autocomplete<IOption, true>
            open={isOpen}
            onClose={handleClose}
            multiple
            loading={isLoading}
            loadingText={loadingText}
            isOptionEqualToValue={getOptionSelected}
            {...(canGroup ? { groupBy: option => option.group || '' } : {})}
            ListboxComponent={ListBoxComponent}
            renderGroup={(params: AutocompleteRenderGroupParams) => [
              <ListSubheader
                className={classes.subheader}
                data-testid="option-group"
                key={params.key}
                component="li"
              >
                {params.group}
              </ListSubheader>,
              params.children,
            ]}
            classes={{
              paper: classes.paper,
              option: classes.option,
              popperDisablePortal: classes.popperDisablePortal,
              noOptions: classes.noOptions,
            }}
            value={value}
            onChange={(_, newValue) => {
              setValue(newValue as IOption[]);
              onChange(newValue as IOption[]);
            }}
            disableCloseOnSelect
            disablePortal
            renderTags={() => null}
            noOptionsText={hasError ? noOptionsErrorText : noOptionsText}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <DoneIcon
                  className={classes.svg}
                  color="primary"
                  style={{ visibility: selected ? 'visible' : 'hidden' }}
                />
                <Typography variant="body2" noWrap>
                  {(option as IOption).label}
                </Typography>
                <CloseIcon
                  color="disabled"
                  className={classes.svg}
                  style={{ visibility: selected ? 'visible' : 'hidden' }}
                />
              </li>
            )}
            options={sortByGroup(
              showSelectedFirst
                ? sortSelectedFirst(options, value, getOptionSelected)
                : options,
              canGroup,
            )}
            getOptionLabel={option => option.label}
            renderInput={params => {
              return (
                <Box flexDirection="column">
                  <InputBase
                    margin="dense"
                    size="small"
                    className={classes.inputBase}
                    inputRef={setInputRef}
                    ref={params.InputProps.ref}
                    inputProps={{
                      ...params.inputProps,
                      'data-testid': 'input',
                    }}
                    onKeyDown={event => {
                      if (event.key === 'Backspace') {
                        event.stopPropagation();
                      }
                    }}
                    placeholder={inputPlaceholder}
                  />
                  <span className={classes.clearFilter}>
                    {!!value.length ? (
                      <Link
                        component="button"
                        variant="body2"
                        data-testid="clear-filter"
                        onClick={() => {
                          setValue([]);
                          onChange([]);
                        }}
                      >
                        <CloseIcon className={classes.svg} />
                        Clear Filter
                      </Link>
                    ) : (
                      <div>
                        <CloseIcon className={classes.svg} />
                        No Filter Applied
                      </div>
                    )}
                  </span>
                </Box>
              );
            }}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
