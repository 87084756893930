import React from 'react';
import Chip from '@mui/material/Chip';
import makeStyles from '@mui/styles/makeStyles';
import Done from '@mui/icons-material/Done';
import DoneAll from '@mui/icons-material/DoneAll';
import Warning from '@mui/icons-material/Warning';
import { Alert } from '../../types';

const useStyles = makeStyles(theme => ({
  warning: {
    borderColor: theme.palette.status.warning,
    color: theme.palette.status.warning,
    '& *': {
      color: theme.palette.status.warning,
    },
  },
  error: {
    borderColor: theme.palette.status.error,
    color: theme.palette.status.error,
    '& *': {
      color: theme.palette.status.error,
    },
  },
  closed: {
    borderColor: theme.palette.status.ok,
    color: theme.palette.status.ok,
    '& *': {
      color: theme.palette.status.ok,
    },
  },
}));

export const StatusChip = ({ alert }: { alert: Alert }) => {
  const classes = useStyles();

  let chipClass = classes.error;
  let label = 'Unknown';
  let icon = <Warning />;
  if (alert.status === 'open' && !alert.acknowledged && !alert.isSeen) {
    chipClass = classes.error;
    label = 'Open';
    icon = <Warning />;
  } else if (alert.status === 'open' && !alert.acknowledged && alert.isSeen) {
    chipClass = classes.error;
    label = 'Seen';
    icon = <Done />;
  } else if (alert.status === 'open' && alert.acknowledged) {
    chipClass = classes.warning;
    label = 'Acknowledged';
    icon = <DoneAll />;
  } else if (alert.status === 'closed') {
    chipClass = classes.closed;
    label = 'Closed';
  }

  return (
    <Chip
      label={label}
      size="small"
      variant="outlined"
      icon={alert.status === 'closed' ? undefined : icon}
      className={chipClass}
    />
  );
};
