import React, { useEffect } from 'react';
import { useRollbackPlans } from '../../common/Rollback/hooks';
import { ErrorBox, InfoCard } from 'plugin-ui-components';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import { Link } from '@backstage/core-components';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

import { RollbackPlan } from '../../common/Rollback/RollbackPlan';
import { useNavigate } from 'react-router';
import { Text, Tooltip } from '../../../common';

export function RollbackPlans({
  domain,
  org,
  repo,
}: {
  domain: string;
  org: string;
  repo: string;
}) {
  const [{ loading, error, value }, fetch] = useRollbackPlans(
    domain,
    org,
    repo,
  );

  const navigate = useNavigate();

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleError() {
    return;
  }

  function handleSuccess() {
    navigate('./../');
  }

  return (
    <InfoCard
      style={{ height: '100%' }}
      title={
        <Box display="inline-block">
          <Text margin={0}>Available rollback plans</Text>
          <Tooltip title="Refresh rollback plans">
            <IconButton onClick={fetch}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      }
      tooltipText={
        <span>
          Learn more in the How-To on{' '}
          <Link to="https://cloud.docs.zalando.net/howtos/quick-rollbacks/">
            Cloud docs
          </Link>
        </span>
      }
    >
      <CardContent>
        {loading && <LinearProgress />}

        {!loading &&
          !error &&
          (!value || !value.plans || !value?.plans.length) && (
            <ErrorBox
              severity="info"
              description="There is no rollback plan available for this repository."
              error="Not found"
            />
          )}

        {!loading && !!error && (
          <ErrorBox
            severity="error"
            description="Unable to fetch rollback plans"
            error={error}
          />
        )}

        {!loading && !error && !!value && !!value.plans?.length && (
          <RollbackPlan
            plan={value}
            handleError={handleError}
            handleSuccess={handleSuccess}
            hasTitle={false}
            showConfirmationModal
          />
        )}
      </CardContent>
    </InfoCard>
  );
}
