import React from 'react';
import {
  ContentHeader,
  LinkButton,
  SupportButton,
} from '@backstage/core-components';
import AddCircle from '@mui/icons-material/AddCircle';

interface Props {
  showRegister?: boolean;
}

export const TableButtons = React.memo<Props>(({ showRegister = false }) => (
  // NOTE: The title is empty because providing it will have redundant titles in the page while not providing it will display `Unknown page` 🤷🏻‍
  <ContentHeader title="">
    {showRegister && (
      <LinkButton color="primary" to="/create/application">
        <AddCircle style={{ marginRight: 8 }} />
        Register
      </LinkButton>
    )}
    <SupportButton>All your software catalog entities</SupportButton>
  </ContentHeader>
));
