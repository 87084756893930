import React from 'react';
import { CSVLink } from 'react-csv';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useStyles } from './styles';
import { VStack } from '../../Stacks';
import { TrackedButton } from '../../Tracking';

const exportTracking: IEventTracker = {
  plugin: 'catalog',
  eventCategory: 'Catalog Table',
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Exporting data',
};

export const ExportAction = ({ rows }: { rows: ICatalog.EntityRow[] }) => {
  const selectFormatRef = React.useRef<HTMLButtonElement>(null);
  const exportRef = React.useRef<any>(null);
  const exportJSONRef = React.useRef<HTMLAnchorElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const styles = useStyles();

  const handleJSONExport = () => {
    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(rows.map(row => ({ ...row.entity.spec.spec }))),
    )}`;
    exportJSONRef.current?.setAttribute('href', dataStr);
    exportJSONRef.current?.setAttribute('download', 'data.json');
    exportJSONRef.current?.click();
  };

  return (
    <VStack>
      <div>
        <TrackedButton
          startIcon={<GetAppIcon />}
          ref={selectFormatRef}
          aria-haspopup="true"
          onClick={event => setAnchorEl(event.currentTarget)}
          {...exportTracking}
        >
          Export data
        </TrackedButton>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          id="simple-menu"
          anchorEl={selectFormatRef.current}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            onClick={() => {
              exportRef.current?.link.click();
              setAnchorEl(null);
            }}
          >
            TSV
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleJSONExport();
              setAnchorEl(null);
            }}
          >
            JSON
          </MenuItem>
        </Menu>
      </div>
      <CSVLink
        separator={`\t`}
        enclosingCharacter=""
        ref={exportRef}
        data={rows.map(row => ({
          ...row.entity.spec.spec,
          ...row.entity.metadata,
          ...(row.entity.metadata.apecStatus && {
            apecStatus: row.entity.metadata.apecStatus.status,
          }),
          ...(row.entity.metadata.lastReview && {
            lastReview: row.entity.metadata.lastReview.time,
          }),
          annotations: '',
          ...(row.entity.metadata.description && {
            description: row.entity.metadata.description.replaceAll('\n', ''),
          }),
        }))}
        target="_blank"
        filename="data.tsv"
        className={styles.exportLink}
      >
        &nbsp;
      </CSVLink>
      <a ref={exportJSONRef} className={styles.exportLink} href="/#">
        &nbsp;
      </a>
    </VStack>
  );
};
