import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import { oauth2ApiRef } from 'plugin-core';
import { rootRouteRef } from './routes';
import { modelsApiRef, ModelService } from './api/services/models';
import {
  applicationResourceApiRef,
  ApplicationResourceService,
} from './api/services/application';
import { aiSystemApiRef, AISystemService } from './api/services/ai_system';
import { pipelinesApiRef, PipelineService } from './api/services/pipelines';
import {
  zflowProjectsApiRef,
  ZflowProjectsService,
} from './api/services/projects';

const pluginDeps = {
  oauth2Api: oauth2ApiRef,
  discoveryApi: discoveryApiRef,
  storageApi: storageApiRef,
};

export const mlPlugin = createPlugin({
  id: 'ml',
  featureFlags: [{ name: 'ml-platform' }],
  apis: [
    createApiFactory({
      api: modelsApiRef,
      deps: pluginDeps,
      factory: ({ oauth2Api, discoveryApi }) =>
        new ModelService({
          oauth2Api: oauth2Api,
          discoveryApi: discoveryApi,
        }),
    }),
    createApiFactory({
      api: applicationResourceApiRef,
      deps: pluginDeps,
      factory: ({ oauth2Api, discoveryApi }) =>
        new ApplicationResourceService({
          oauth2Api: oauth2Api,
          discoveryApi: discoveryApi,
        }),
    }),
    createApiFactory({
      api: aiSystemApiRef,
      deps: pluginDeps,
      factory: ({ oauth2Api, discoveryApi }) =>
        new AISystemService({
          oauth2Api: oauth2Api,
          discoveryApi: discoveryApi,
        }),
    }),
    createApiFactory({
      api: pipelinesApiRef,
      deps: pluginDeps,
      factory: ({ oauth2Api, discoveryApi }) =>
        new PipelineService({
          oauth2Api: oauth2Api,
          discoveryApi: discoveryApi,
        }),
    }),
    createApiFactory({
      api: zflowProjectsApiRef,
      deps: pluginDeps,
      factory: ({ oauth2Api, discoveryApi }) =>
        new ZflowProjectsService({
          oauth2Api: oauth2Api,
          discoveryApi: discoveryApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const MLRouter = mlPlugin.provide(
  createRoutableExtension({
    name: 'MLRouter',
    component: () => import('./router').then(m => m.MLRouter),
    mountPoint: rootRouteRef,
  }),
);
