import React from 'react';
import { Link } from 'react-router-dom';
import { WarningIcon } from '@backstage/core-components';
import ReportIcon from '@mui/icons-material/Report';

import { RunsModel, IWithRepoInfo } from '../../../models';
import { Emoji, Tooltip } from '../../../common';
import { getIconByStepType } from '../../../utils/pipeline';
import { PendingStateMessage } from '../PendingState';
import { PIPELINE_STATUS } from '../../../constants';

import 'style-loader!css-loader!sass-loader!./styles.scss';

type Props = {
  run: RunsModel;
  repoUrl: (run: IWithRepoInfo) => string;
};

export function StepsList({ run, repoUrl }: Props) {
  if (run.status === PIPELINE_STATUS.PENDING) {
    return (
      <div>
        <div>
          <Emoji name="hourglass" emoji="⌛" />
          <strong>&nbsp;Pending pipeline</strong>
        </div>
        <PendingStateMessage
          domain={run.repoInfo.domain}
          org={run.repoInfo.organization}
          repo={run.repoInfo.name}
          branch={run.tags.branch}
        />
      </div>
    );
  }

  return (
    <ul className="steps-list">
      {run.steps.map((step, i) => (
        <li className="step-item" key={step.id}>
          <Link to={`${repoUrl(run)}/${run.id}/steps/${i}`}>
            <Tooltip
              key={step.id}
              title={`${step.id}: ${step.status.toLowerCase()}${
                step.hasValidationErrors || step.hasValidationWarnings
                  ? `, validation problems found`
                  : ''
              }`}
            >
              <span className={`step-wrapper ${step.status.toLowerCase()}`}>
                {getIconByStepType(step.type)}
                {step.hasValidationErrors && <ReportIcon />}
                {step.hasValidationWarnings && !step.hasValidationErrors && (
                  <WarningIcon />
                )}
                <span>{step.id}</span>
              </span>
            </Tooltip>
          </Link>
        </li>
      ))}
    </ul>
  );
}
