import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { repoApiRef, RepositoryClient } from './api';
import { rootRouteRef, templateRouteRef } from './routes';
import { oauth2ApiRef } from 'plugin-core';

export const createRepositoryPlugin = createPlugin({
  id: 'create',
  featureFlags: [{ name: 'create-revamp' }],
  apis: [
    createApiFactory({
      api: repoApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        oauth2Api: oauth2ApiRef,
        identityApi: identityApiRef,
      },
      factory: ({ discoveryApi, oauth2Api, identityApi }) =>
        new RepositoryClient({
          discoveryApi: discoveryApi,
          oauth2Api: oauth2Api,
          identityApi: identityApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
    template: templateRouteRef,
  },
});

export const CreateRepositoryPage = createRepositoryPlugin.provide(
  createRoutableExtension({
    name: 'plugin-create-repository',
    component: () =>
      import('./components/CreateRepositoryPage').then(
        m => m.CreateRepositoryPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
