import React, { RefObject, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { observer } from 'mobx-react-lite';
import withStyles from '@mui/styles/withStyles';
import Snackbar from '@mui/material/Snackbar';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  LOGS_POLLING_RETRIES,
  PRIMARY_LOGS_POLLING_TIMER,
  STEP_STATUS,
} from '../../../../constants';
import { useServices } from '../../../../services';
import { Text } from '../../../../common';
import { Alert } from '../../../common';
import { LogsHeader } from './components/Header';
import { LogsLines } from './components/Lines';
import {
  ErrorMessage,
  LoadingMessage,
  NoLogsMessage,
} from './components/Messages';
import { cssVariables } from './cssVariables';
import 'style-loader!css-loader!sass-loader!./styles.scss';

interface Props {
  scrollableRef: RefObject<HTMLElement>;
}

export const ScalyrFlatLogsComponent = observer(({ scrollableRef }: Props) => {
  const { runService, logsFlatService } = useServices();
  const [counter, setCounter] = useState(1);
  const [wasRunning, setWasRunning] = useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    if (runService.step.run.hasStarted) {
      setCounter(1);
      logsFlatService.clean();
      logsFlatService.getLines(runService.step.run);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runService.step.run.id, runService.step.run.hasStarted]);

  useEffect(() => {
    if (runService.step.run.isRunning) {
      setWasRunning(true);
    }
  }, [runService.step.run.isRunning]);

  useEffect(() => {
    if (
      runService.step.run.isFinished &&
      !wasRunning &&
      !logsFlatService.logs.warning &&
      (logsFlatService.logsState.isLoaded ||
        logsFlatService.logsState.isRecoverable)
    ) {
      logsFlatService.updateFinalState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runService.step.run.isFinished, logsFlatService.logsState.state]);

  useInterval(
    async () => {
      if (runService.step.run.isFinished) {
        setCounter(counter + 1);
        await logsFlatService.getLines(runService.step.run);
        if (counter === LOGS_POLLING_RETRIES) {
          logsFlatService.updateFinalState();
        }
      }
    },
    runService.step.run.isFinished &&
      wasRunning &&
      !logsFlatService.logsState.isUnrecoverable &&
      counter <= LOGS_POLLING_RETRIES &&
      !logsFlatService.logs.warning
      ? PRIMARY_LOGS_POLLING_TIMER
      : null,
  );

  useInterval(
    () => logsFlatService.getLines(runService.step.run),
    runService.step.run.isRunning ? PRIMARY_LOGS_POLLING_TIMER : null,
  );

  if (!runService.step.run.id || logsFlatService.logsState.isNotAvailable) {
    return <NoLogsMessage />;
  }

  switch (runService.step.run.status) {
    case STEP_STATUS.PENDING_APPROVAL:
      return <Text p={2}>Waiting for manual approval…</Text>;
    case STEP_STATUS.NOT_STARTED:
      return <Text p={2}>Script step was not reached yet.</Text>;
    case STEP_STATUS.INIT:
      return <Text p={2}>Script step is initializing.</Text>;
    default:
      break;
  }

  if (logsFlatService.logsState.isUnrecoverable) {
    return <ErrorMessage message={logsFlatService.logsState.message} />;
  }

  if (logsFlatService.logs.lines.length === 0) {
    return (
      <LoadingMessage
        isRunning={runService.step.run.isRunning}
        wasRunning={wasRunning}
      />
    );
  }

  return (
    <div id="scalyr-logs">
      <LogsHeader handle={handle} scrollableRef={scrollableRef} />
      <FullScreen handle={handle}>
        <LogsLines
          lines={logsFlatService.logs.lines}
          scrollableRef={scrollableRef}
        />
      </FullScreen>

      <Snackbar open={logsFlatService.logs.warning}>
        <Alert severity="warning">
          Supported limit of 20k lines was reached, download the log file to
          view all lines.
        </Alert>
      </Snackbar>
    </div>
  );
});

export const ScalyrFlatLogs = withStyles(cssVariables)(ScalyrFlatLogsComponent);
