import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { useApi, useRouteRefParams } from '@backstage/core-plugin-api';
import {
  Content,
  Header,
  Link,
  Page,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import {
  catalogApiRef,
  entityRouteRef,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import { parseEntityRef } from '@backstage/catalog-model';
import { getTeamNameFromFullName } from 'plugin-core';
import { EntityNotFound } from '../EntityNotFound';
import { Labels } from './Labels';
import { Tabbed } from './Tabbed';
import { Title } from './Title';
import { headerProps } from './utils';
import { useAsync, useAsyncRetry } from 'react-use';
import { KioEntityPage } from '../KioEntityPage';
import { kioApiRef } from '../../api';
import { techInsightsApiRef } from 'plugin-scorecards';
import { ScorecardEntityPage } from '../EntityPage/pages/ScorecardEntityPage';

// Will be displayed as a subtitle, if the entity is a Domain
const DomainEntityBreadcrumb = ({
  parentDomain,
  domainTitle,
}: {
  parentDomain: IEntity;
  domainTitle: string;
}) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ color: 'white' }}>
      <Link to="/domains" color="inherit">
        Domains
      </Link>
      <Link
        to={`/catalog/default/Domain/${parentDomain.metadata.name}`}
        color="inherit"
      >
        {parentDomain.metadata.title}
      </Link>
      <Typography color="inherit">{domainTitle}</Typography>
    </Breadcrumbs>
  );
};

export const EntityPageLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const catalogApi = useApi(catalogApiRef);
  const kioApi = useApi(kioApiRef);
  const techInsightsApi = useApi(techInsightsApiRef);
  const { kind, namespace, name } = useRouteRefParams<{
    kind: string;
    namespace: string;
    name: string;
  }>(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity<IEntity>();
  let entityHeaderName = name;

  if (entity?.kind.toLowerCase() === 'group') {
    const entitySpec = entity?.spec as IEntityGroupSpec;
    entityHeaderName =
      entitySpec?.fullName && getTeamNameFromFullName(entitySpec?.fullName);
  }

  const { headerTitle, headerType } = headerProps(
    kind,
    namespace,
    entityHeaderName,
    entity!,
  );
  const [headerSubTitle, setHeaderSubTitle] =
    React.useState<React.ReactNode>('');

  React.useEffect(() => {
    if (entity?.kind.toLowerCase() === 'domain') {
      // Get parent domain then set breadcrumb as subtitle
      const parentRef = entity?.relations?.find(rel => rel.type === 'childOf');
      if (parentRef) {
        catalogApi
          .getEntities({
            filter: {
              kind: 'Domain',
              'metadata.name': `${parseEntityRef(parentRef.targetRef).name}`,
            },
          })
          .then(domain => {
            setHeaderSubTitle(
              <DomainEntityBreadcrumb
                parentDomain={domain.items[0] as IEntity}
                domainTitle={entity.metadata.title || ''}
              />,
            );
          });
      }
      return;
    }
    setHeaderSubTitle('');
  }, [entity, catalogApi]);

  const { value: kioApp, loading: kioAppLoading } = useAsync(async () => {
    // If application does not exist in sunrise's catalog look for it in Kio
    if (!entity && kind.toLowerCase() === 'component') {
      return kioApi.fetchKioApp(name);
    }
    return undefined;
  }, [name, entity]);

  const {
    value: scorecard,
    loading: scorecardLoading,
    retry: retryScorecard,
  } = useAsyncRetry(async () => {
    if (!entity && kind.toLowerCase() === 'scorecard') {
      return await techInsightsApi.getScorecardByName(name);
    }
    return undefined;
  }, [name, entity]);

  const getThemeId = React.useCallback(() => {
    const INACTIVE_APP_THEME = 'inactiveApp';
    if (entity) {
      switch (entity.kind.toLowerCase()) {
        case 'component':
          if (!(entity.spec?.spec as IEntityAPPSpecSpec).active || kioApp)
            return INACTIVE_APP_THEME;
          break;
        case 'user':
          if ((entity.metadata.zalandoMetadata as IZalandoMetadata).inactive)
            return INACTIVE_APP_THEME;
          break;
        default:
          return entity.kind.toLowerCase() ?? 'home';
      }
    }
    return 'home';
  }, [entity, kioApp]);

  if (loading || kioAppLoading) {
    return (
      <Content>
        <Progress />
      </Content>
    );
  }

  return (
    <Page themeId={getThemeId()}>
      <Header
        title={<Title title={headerTitle} entity={entity!} />}
        pageTitleOverride={headerTitle}
        type={headerType}
        subtitle={headerSubTitle}
      >
        {/* TODO: Make entity labels configurable for entity kind / type */}
        {entity && <Labels />}
      </Header>

      {entity && <Tabbed.Layout>{children}</Tabbed.Layout>}

      {error && (
        <Content>
          <ResponseErrorPanel error={error} />
        </Content>
      )}

      {(kioApp || kioAppLoading) && (
        <KioEntityPage kioApp={kioApp} loading={kioAppLoading} />
      )}
      {scorecard && (
        <ScorecardEntityPage scorecard={scorecard} retry={retryScorecard} />
      )}
      {!loading &&
        !error &&
        !entity &&
        !kioApp &&
        !kioAppLoading &&
        !scorecard &&
        !scorecardLoading && <EntityNotFound />}
    </Page>
  );
};

EntityPageLayout.Content = Tabbed.Content;
