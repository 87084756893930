const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'catalog',
  eventCategory: 'Tooling Overview',
  eventAction: 'click',
  interaction: 'onClick',
};

export function clickLink(eventLabel: string): IEventTracker {
  return { ...common, eventLabel };
}
