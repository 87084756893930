import React, { Fragment, useCallback } from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { InfoCard, Progress } from '@backstage/core-components';
import {
  configApiRef,
  discoveryApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { Config } from '../../config';
import { TrackedLink } from 'plugin-ui-components';
import { versionClicked } from './tracking';
import { oauth2ApiRef } from 'plugin-core';

type Dependencies = Config['release']['dependencies'];

export function SettingsAboutPage() {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [nodeVersion, setNodeVersion] = React.useState<string | null>(null);
  const configApi = useApi(configApiRef);
  const oauth2Api = useApi(oauth2ApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const getNodeVersion = useCallback(async () => {
    if (nodeVersion) return;
    const token = await oauth2Api.getAccessToken();
    const Authorization = `Bearer ${token}`;
    const backendPluginUrl = await discoveryApi.getBaseUrl('about');
    try {
      const response = await fetch(`${backendPluginUrl}/node`, {
        headers: { Authorization },
      });
      const resp = await response.json();
      setNodeVersion(resp.nodeVersion);
    } catch (e) {
      // handle error
      setNodeVersion(null);
    } finally {
      setIsLoading(false);
    }
  }, [nodeVersion, oauth2Api, discoveryApi]);

  React.useEffect(() => {
    getNodeVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Progress />;
  }

  const dependencies = configApi.getOptional(
    'release.dependencies',
  ) as Dependencies;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <InfoCard title="About Sunrise">
          <CardContent>
            <Typography variant="h3">Dependencies</Typography>
            <dl className="datalist">
              {dependencies?.map(item => (
                <Fragment key={item.name}>
                  <dt>
                    <Typography variant="overline">
                      <b>{item.name}</b>
                    </Typography>
                  </dt>
                  <dd>
                    <TrackedLink
                      to={item.url}
                      className="code"
                      color="textPrimary"
                      {...versionClicked(item.name)}
                    >
                      <code className="code">v{item.version}</code>
                    </TrackedLink>
                  </dd>
                </Fragment>
              ))}
              <Fragment key="Node">
                <dt>
                  <Typography variant="overline">
                    <b>Node</b>
                  </Typography>
                </dt>
                <dd>
                  {nodeVersion ? (
                    <TrackedLink
                      to={`https://github.com/nodejs/node/releases/tag/${nodeVersion}`}
                      className="code"
                      color="textPrimary"
                      {...versionClicked('Node')}
                    >
                      <code className="code">{nodeVersion}</code>
                    </TrackedLink>
                  ) : (
                    <code className="code">'-'</code>
                  )}
                </dd>
              </Fragment>
              {!dependencies.length && <div>No information found</div>}
            </dl>
          </CardContent>
        </InfoCard>
      </Grid>
    </Grid>
  );
}
