import Card from '@mui/material/Card';
import styled from '@mui/styles/styled';

export const RunOverview = styled('div')(props => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: props.theme.spacing(2),
  minHeight: 0,
}));

export const Header = styled('div')(props => ({
  marginBottom: props.theme.spacing(3),
}));

export const Content = styled(Card)({
  flex: 1,
  overflow: 'visible',
});
