export const common: Pick<IEventTracker, 'plugin' | 'eventCategory'> = {
  plugin: 'teams-catalog-plugin',
  eventCategory: 'Teams Catalog Plugin',
};

export const addFilter = (label: string, values: string[]): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Filter by ${label}: ${values.join(', ')}`,
});
