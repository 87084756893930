import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import { useBarChartStepperStyles as useStyles } from '../../utils/styles';

export type BarChartStepsProps = {
  steps: number;
  activeStep: number;
  onClick: (index: number) => void;
};

export const BarChartSteps = ({
  steps,
  activeStep,
  onClick,
}: BarChartStepsProps) => {
  const classes = useStyles();
  const handleOnClick =
    (index: number) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      onClick(index);
    };

  return (
    <div className={classes.steps}>
      {[...new Array(steps)].map((_, index) => (
        <ButtonBase key={index} centerRipple onClick={handleOnClick(index)}>
          <div
            data-testid="bar-chart-step"
            className={`${classes.step} ${
              index === activeStep ? classes.stepActive : ''
            }`}
          />
        </ButtonBase>
      ))}
    </div>
  );
};
