import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useApi } from '@backstage/core-plugin-api';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LaunchIcon from '@mui/icons-material/Launch';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DashboardIcon from '@mui/icons-material/Dashboard';
import fileDownload from 'js-file-download';
import { notebookRendererApiRef } from '../../api';
import { useLocation, useNavigate } from 'react-router';
import { createPoller, getDatalabUrl } from '../../utils';
import { Snackbar } from '../Snackbar';
import { Confirmation } from '../Confirmation';
import { Warning } from '../Warning';
import { RunNow } from '../RunNow';

interface NotebookActionBarProps {
  s3Path?: string;
  notebook_path?: string;
  id: string;
  name: string;
  parentId: string;
  status: string;
  createdBy: string;
  isInteractive?: boolean;
  shouldPublish?: boolean;
  archived: boolean;
  showFullScreenButton: boolean;
  onDelete: (id: string) => Promise<any>;
  showRunNow?: boolean;
  onPollCallback?: () => any;
}

const getLinkToDataLab = (s3Path: string, notebookName: string, id: string) => {
  return `${getDatalabUrl()}/hub/user-redirect/zflow/notebook/download?notebook_s3_path=${s3Path}&path_to_save_in_nfs=${id}.${notebookName}.ipynb`;
};

const getLinkToDashboard = (
  isInteractive: boolean,
  createdBy: string,
  id: string,
) => {
  return isInteractive && createdBy
    ? `${getDatalabUrl()}/services/zflow_dashboards/user/${createdBy}/zflow/dashboards/voila/render/${id}.ipynb`
    : '';
};

export const NotebookActionBar = (props: NotebookActionBarProps) => {
  const {
    s3Path,
    notebook_path,
    id,
    name,
    parentId,
    status,
    createdBy,
    isInteractive,
    shouldPublish,
    archived,
    showFullScreenButton,
    onDelete,
    showRunNow,
    onPollCallback,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<Error>();

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const toggleFullScreen = () => {
    const iframeElement = document.getElementById('notebookSnapshot');
    if (iframeElement === null) {
      return;
    }
    if (!document.fullscreenElement) {
      iframeElement.requestFullscreen();
    }
  };

  const notebookName = (notebook_path?.split('/').pop() || '').replace(
    '.ipynb',
    '',
  );
  const notebookRendererClient = useApi(notebookRendererApiRef);

  const downloadNotebook = async () => {
    const res = await notebookRendererClient.getNotebookContent(
      s3Path || '',
      false,
      'ipynb',
    );
    const fileName = (notebook_path || '').split('/').pop() || 'download.ipynb';
    const content = new Blob([JSON.stringify(res.notebook_content)], {
      type: 'application/json;charset=utf-8',
    });
    fileDownload(content, fileName);
  };

  const handleDelete = async () => {
    setSnackBarMsg(`Deleting ${name}`);
    try {
      await onDelete(id);
      const pathArr = location.pathname.split('/');
      pathArr.pop();
      navigate(pathArr.join('/'));
    } catch (err) {
      setError(err as Error);
      closeDialog();
    }
  };

  const shouldPoll = onPollCallback
    ? createPoller(status, onPollCallback)
    : false;

  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Snackbar message={snackBarMsg} onClose={setSnackBarMsg} />
          <Box paddingTop={2} paddingLeft={2}>
            <Typography variant="h4">
              <Box component="span" fontWeight="fontWeightLight">
                {name}
              </Box>
              <Box component="span" fontSize="small">
                {shouldPoll ? <CircularProgress size="1rem" /> : null}
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            paddingTop={2}
            display="flex"
            justifyContent="flex-end"
            whiteSpace="nowrap"
          >
            <ButtonGroup variant="text">
              {showRunNow && (
                <RunNow
                  id={parentId}
                  setError={setError}
                  setSnackBarMsg={setSnackBarMsg}
                />
              )}
              <Button
                color="secondary"
                startIcon={<DeleteIcon />}
                onClick={openDialog}
                disabled={archived}
              >
                Delete
              </Button>
              {shouldPublish && s3Path && notebook_path ? (
                <Button
                  startIcon={<CloudDownloadIcon />}
                  onClick={downloadNotebook}
                >
                  Download
                </Button>
              ) : null}
              {shouldPublish && s3Path && showFullScreenButton ? (
                <Button
                  startIcon={<FullscreenIcon />}
                  onClick={toggleFullScreen}
                >
                  Full Screen
                </Button>
              ) : null}
              {isInteractive ? (
                <Button
                  startIcon={<DashboardIcon />}
                  href={getLinkToDashboard(isInteractive, createdBy, id)}
                  target="_blank"
                >
                  Open Dashboard
                </Button>
              ) : null}
              {s3Path ? (
                <Button
                  startIcon={<LaunchIcon />}
                  href={getLinkToDataLab(s3Path, notebookName, id)}
                  target="_blank"
                >
                  Open in datalab
                </Button>
              ) : null}
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>

      <Confirmation
        open={open}
        handleCancel={closeDialog}
        handleConfirm={handleDelete}
        cancelText="Cancel"
        confirmText="Delete"
        titleText="Deleting Execution"
        bodyText={`Are you sure you want to delete ${name}?`}
      />
      {error?.name ? (
        <Warning
          name={error.name}
          message={error.message}
          setError={setError}
        />
      ) : null}
    </div>
  );
};
