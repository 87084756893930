import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from '../styles';
import { getHistogramStatusesData } from './utils';
import { ChartTitle } from '../components/ChartTitle';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface Props {
  data?: ICDPMonitoring.PipelinePerformanceTimeseries;
  loading: boolean;
}

export function PerformanceStatusOverTime({ data, loading }: Props) {
  const styles = useStyles();

  return (
    <Card elevation={3} className={styles.card}>
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
      <CardHeader
        title={
          <ChartTitle
            text={`Pipeline statuses count by ${data?.granularity}`}
          />
        }
      />
      <CardContent>
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'bottom',
              },
              tooltip: {
                callbacks: {
                  label: item =>
                    `${item.dataset.label} ${
                      item.dataset.data[item.dataIndex]
                    } times`,
                },
              },
            },
          }}
          data={getHistogramStatusesData(data?.items)}
        />
      </CardContent>
    </Card>
  );
}
