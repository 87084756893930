import React from 'react';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { IWithRepoInfo } from '../../../../models';
import { useNestedRouterServices } from '../../../../services';
import { RunGroupsItemHeader, RunGroupsItemBody } from '../../../common';

export const RunGroupsList = observer(() => {
  const { runGroupsService, preferencesService } = useNestedRouterServices();

  const getRepoUrl = (run: IWithRepoInfo) =>
    `/cdp/${run.repoInfo.alias}/${run.repoInfo.organization}/${run.repoInfo.name}`;

  return (
    <Box flex={1} margin={0} padding={0}>
      {runGroupsService.runGroups.map(item => (
        <div key={item.id} data-testid="run-groups-item">
          <RunGroupsItemHeader run={item} repoUrl={getRepoUrl} />
          <RunGroupsItemBody
            run={item}
            repoUrl={getRepoUrl}
            reload={runGroupsService.reload}
            preferencesService={preferencesService}
          />
        </div>
      ))}
    </Box>
  );
});
