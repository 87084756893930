import React from 'react';
import { useParams } from 'react-router';
import { ErrorBox, InfoCard } from 'plugin-ui-components';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import LinearProgress from '@mui/material/LinearProgress';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

import { RepositoryNavigation } from '../common/RepositoryNavigation';
import { getDomainFromAlias } from '../../utils/domains';
import { Layout, Text, Tooltip } from '../../common';
import { useLatestDeployments } from '../../hooks/useLatestDeployments';
import { GridLayout } from '../common';
import { deploymentsCssVariables } from '../../styles/css-variables';
import { RollbackPlans } from './components/RollbackPlans';
import { LatestDeploymentPipeline } from './components/LatestDeployment';
import { RecentDeployments } from './components/RecentDeployments';

function DeploymentsSummary({
  fullRepoName,
  name,
  org,
  domain,
}: {
  fullRepoName: string;
  domain: string;
  org: string;
  name: string;
}) {
  const { deployments, loading, error, retry } =
    useLatestDeployments(fullRepoName);
  const latestDeployment = deployments[0];

  return (
    <Grid container>
      <Grid item md={7} xs={12}>
        <InfoCard
          style={{ height: '100%' }}
          title={
            <Box display="inline-block">
              <Text margin={0}>Latest production deployment pipeline</Text>
              <Tooltip title="Refresh deployments list">
                <IconButton onClick={retry}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
          }
          tooltipText="Latest pipeline containing one or more steps that deployed or modified application in production targets."
        >
          <CardContent>
            {loading && <LinearProgress />}

            {!loading && !latestDeployment && (
              <ErrorBox
                severity={!!error ? 'error' : 'info'}
                description="It looks like this repository doesn't have production deployment pipelines."
                error={error || 'Not found'}
              />
            )}

            {!loading && !!latestDeployment && (
              <LatestDeploymentPipeline
                pipelineID={latestDeployment.pipeline_id}
              />
            )}
          </CardContent>
        </InfoCard>
      </Grid>

      <Grid item md={5} xs={12}>
        <RollbackPlans domain={domain} org={org} repo={name} />
      </Grid>

      <Grid item md={12} xs={12}>
        <InfoCard
          title={
            <Box display="inline-block">
              <Text margin={0}>Recent production deployments</Text>
              <Tooltip title="Refresh deployments list">
                <IconButton onClick={retry}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            </Box>
          }
        >
          <Box marginTop="-1px">
            <GridLayout
              columns={[
                'Pipeline status',
                'Pipeline build version',
                'Deployment status',
                'Step name',
                'Account',
                'Deployed at',
              ]}
            >
              {loading && <LinearProgress />}

              {!loading && !deployments.length && (
                <ErrorBox
                  severity={!!error ? 'error' : 'info'}
                  description="It looks like this repository doesn't have production deployments."
                  error={error || 'Not found'}
                />
              )}

              {!loading && !!deployments.length && (
                <RecentDeployments items={deployments} />
              )}
            </GridLayout>
          </Box>
        </InfoCard>
      </Grid>
    </Grid>
  );
}

export function DeploymentsPageComponent() {
  const { domain_alias = '', org_name = '', repo_name = '' } = useParams();
  const domain = getDomainFromAlias(domain_alias);
  const fullRepoName = `${domain}/${org_name}/${repo_name}`;

  return (
    <>
      <RepositoryNavigation
        fullRepoName={fullRepoName}
        domainAlias={domain_alias}
        org={org_name}
        name={repo_name}
      />

      <DeploymentsSummary
        fullRepoName={fullRepoName}
        domain={domain}
        org={org_name}
        name={repo_name}
      />
    </>
  );
}

export const DeploymentsPage = withStyles(deploymentsCssVariables)(() => (
  <Layout render={<DeploymentsPageComponent />} />
));
