import { useProblemStyles, useReferenceStyles } from './styles';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import LaunchIcon from '@mui/icons-material/Launch';
import React from 'react';
import { Emoji } from '../../../common';

export function Reference({ URL }: { URL: string }) {
  const classes = useReferenceStyles();

  return (
    <Link
      href={URL}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
    >
      <span>more info&nbsp;</span>
      <LaunchIcon className={classes.icon} />
    </Link>
  );
}

export function IconError() {
  return (
    <Tooltip title="Error: action required!">
      <span>
        <Emoji name="error" emoji="❌" />
      </span>
    </Tooltip>
  );
}

export function IconWarning() {
  return (
    <Tooltip title="Warning">
      <span>
        <Emoji name="warning" emoji="⚠️" />
      </span>
    </Tooltip>
  );
}

export function Label({ text, title }: { text: string; title: string }) {
  const classes = useProblemStyles();
  return (
    <Tooltip title={title}>
      <Chip className={classes.chip} size="small" label={text} />
    </Tooltip>
  );
}
