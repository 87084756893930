import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InfoIcon from '@mui/icons-material/Info';
import { StepActionProps } from './types';
import { Text, Tooltip, Dialog } from '../../../../common';

export const SecurityErrorAction: React.FC<StepActionProps> = ({
  action,
  actionProps,
}) => {
  const [open, setOpen] = useState(false);

  function handleAcknowledgeErrorStep() {
    setOpen(false);
    action();
  }

  function openModal() {
    setOpen(true);
  }

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          title="Are you sure you want to continue?"
          children={
            <>
              <DialogContent>
                <Text>
                  <span>
                    {`By choosing this option, you are taking ownership of the security error. The issue is logged and the pipeline reruns, ignoring the error. You have a set time to remediate the issue. `}
                  </span>

                  <Text info>
                    <a
                      href="https://automata.docs.zalando.net/cdp/opa/#infrastructure"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Read more.
                    </a>
                  </Text>
                </Text>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleAcknowledgeErrorStep}
                  type="button"
                  variant="outlined"
                  size="small"
                >
                  Retry
                </Button>
              </DialogActions>
            </>
          }
        />
      )}
      <Button onClick={openModal} variant="outlined" size="small">
        {actionProps.title}
      </Button>

      <Tooltip title="By choosing this option, you are taking ownership of the security error. The issue is logged and the pipeline reruns, ignoring the error. You have a set time to remediate the issue.">
        <InfoIcon fontSize="small" />
      </Tooltip>
    </>
  );
};
