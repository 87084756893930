import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import React, { useState } from 'react';
import { Tooltip } from '../Tooltip';
import Box from '@mui/material/Box';

const COPY_TEXT = 'Copy download and open command';

function useHoverAndCopy() {
  const [copyText, setCopyText] = useState(COPY_TEXT);
  const [hover, setHover] = useState(false);

  const handleHover = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
    setCopyText(COPY_TEXT);
  };

  const copyToClipboard = (path: string) => {
    setCopyText('Copied!');
    navigator.clipboard.writeText(path);
  };

  return { copyText, hover, handleHover, handleMouseOut, copyToClipboard };
}

export function CopyAction({ text }: { text: string }) {
  const { copyText, handleMouseOut, copyToClipboard, handleHover } =
    useHoverAndCopy();

  function copy() {
    copyToClipboard(text);
  }

  return (
    <Box
      onMouseOver={handleHover}
      onFocus={handleHover}
      onMouseLeave={handleMouseOut}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingLeft={2}
      paddingRight={2}
    >
      <Tooltip placement="top" title={copyText}>
        <FileCopyOutlined onClick={copy} color="inherit" fontSize="inherit" />
      </Tooltip>
    </Box>
  );
}

export function AuthenticatedLink({
  action,
  filename,
  children,
}: {
  action: () => Promise<Response>;
  filename: string;
  children: React.ReactNode;
}) {
  const link = React.useRef<HTMLAnchorElement | null>(null);

  const handleAction = async () => {
    if (!link.current) return;
    if (link.current.href) return;

    const result = await action();
    const blob = await result.blob();

    const href = window.URL.createObjectURL(blob);

    link.current.download = filename;
    link.current.href = href;
    link.current.click();
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
    <a
      style={{ lineHeight: 0 }}
      role="button"
      ref={link}
      onClick={handleAction}
    >
      {children}
    </a>
  );
}
