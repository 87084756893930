export const common: Pick<IEventTracker, 'plugin' | 'eventCategory'> = {
  plugin: 'plugin-catalog',
  eventCategory: 'Data classification chip',
};

export const clickDataClassification = (): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Data Classification chip',
});

export const hoverDataClassification = (): IEventTracker => ({
  ...common,
  interaction: 'onMouseEnter',
  eventAction: 'hover',
  eventLabel: `User hovered over the data classification chip in ${location.pathname}`,
});
