import Alert from '@mui/lab/Alert';
import AlertTitle from '@mui/lab/AlertTitle';
import React from 'react';

interface warningProps {
  name: string;
  message: string;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
}

export const Warning = (props: warningProps) => {
  return (
    <Alert
      severity="error"
      onClose={() => {
        props.setError(undefined);
      }}
    >
      <AlertTitle>{props.name}</AlertTitle>
      <strong>{props.message}</strong>
    </Alert>
  );
};
