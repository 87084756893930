import Card from '@mui/material/Card';
import styled from '@mui/styles/styled';
import { SIDEBAR_HEADER_HEIGHT } from '../../../../constants';

export const StepOverview = styled('div')(props => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: props.theme.spacing(2),
}));

export const Section = styled('section')(props => ({
  marginBottom: props.theme.spacing(2),
}));

export const Header = styled('div')(props => ({
  marginBottom: props.theme.spacing(3),
}));

export const Content = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'visible',
});

export const PipelineHint = styled('div')(props => ({
  padding: props.theme.spacing(1, 2),
  height: SIDEBAR_HEADER_HEIGHT,
}));
