import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Tags';
const id = 'tags-filter';
export const TagsFilter = React.memo(() => {
  const { tagsFilter, setTagsFilter, availableTags } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  return (
    <FormControl>
      <Autocomplete
        id={id}
        multiple
        options={availableTags}
        value={tagsFilter}
        classes={{ inputRoot }}
        onChange={(_: any, value) => setTagsFilter(value as string[])}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            margin="dense"
          />
        )}
      />
    </FormControl>
  );
});
