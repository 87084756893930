import styled from '@mui/styles/styled';

export const Well = styled('pre')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
}));
