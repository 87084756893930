import React from 'react';
import { useDebounce } from 'react-use';
import { useLocation } from 'react-router';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Entity } from '@backstage/catalog-model';
import {
  CodeSnippet,
  Table,
  TableColumn,
  TableProps,
  WarningPanel,
} from '@backstage/core-components';
import { SEARCH_DEBOUNCE_TIME } from 'plugin-core';
import { getTableRawsFromEntities } from '../../../utils';
import { useApplicationScorecardsContext } from 'plugin-scorecards';
import { FilterCollapseButton } from '../FilterCollapseButton';
import * as S from './styles';
import 'style-loader!css-loader!sass-loader!./styles.scss';
import { EventTracker, PluginTracking } from '../../Tracking';
import { ExportAction } from './ExportAction';

type CatalogTableProps = {
  columns: TableColumn<ICatalog.EntityRow>[];
  entities: Entity[];
  titlePreamble: string;
  loading: boolean;
  error?: any;
  emptyContent?: React.ReactNode;
  filters?: React.ReactElement;
  filterOptions?: {
    openByDefault: boolean;
    allowCollapse: boolean;
  };
  infoText?: React.ReactElement;
  setOrder?: (arg: 'desc' | 'asc') => void;
  canExport?: Boolean;
  tableOptions?: TableProps['options'];
};

const infoTooltipTracking: IEventTracker = {
  plugin: 'catalog',
  eventCategory: 'Catalog Table',
  interaction: 'onMouseEnter',
  eventAction: 'hover',
  eventLabel: 'Info Tooltip on table header',
};

export const CatalogTable = ({
  entities,
  loading,
  error,
  titlePreamble,
  columns,
  emptyContent,
  filters,
  filterOptions = {
    openByDefault: false,
    allowCollapse: false,
  },
  setOrder,
  infoText,
  canExport,
  tableOptions,
}: CatalogTableProps) => {
  const { updateApplications } = useApplicationScorecardsContext();

  const [search, setSearch] = React.useState<string>('');
  const [filterOpen, setFilterOpen] = React.useState(
    filterOptions.openByDefault,
  );
  const tableRef = React.useRef<{ state: { data: Array<any> } }>();

  useDebounce(
    () => PluginTracking.sendSearchQuery(search),
    SEARCH_DEBOUNCE_TIME,
    [search],
  );

  const location = useLocation();
  const entityType = location.pathname.split('/')[1];
  const rows: ICatalog.EntityRow[] = getTableRawsFromEntities(entities);

  const getDataCount = () => {
    if (search) return tableRef.current?.state?.data.length;
    if (entities) return entities.length;
    return 0;
  };

  /* Update list of applications from scorecards context
   * to fetch scorecards and assessments in one go
   */
  React.useEffect(() => {
    const apps = entities.filter(e => e.kind === 'Component');
    if (apps.length) updateApplications(apps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title={`Failed to load ${entityType}. Please try again by refreshing the page.`}
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  return (
    <S.CatalogTableWrapper filterOpen={filterOpen}>
      {filters && filterOpen && React.cloneElement(filters)}
      <Paper className="catalog-table">
        <Table<ICatalog.EntityRow>
          isLoading={loading}
          columns={columns}
          emptyContent={emptyContent}
          options={{
            paging: true,
            pageSize: 20,
            actionsColumnIndex: -1,
            loadingType: 'linear',
            showEmptyDataSourceMessage: !loading,
            pageSizeOptions: [20, 50, 100],
            padding: 'dense',
            headerStyle: {
              whiteSpace: 'nowrap',
            },
            // Apply custom table options
            ...tableOptions,
          }}
          title={
            <Box display="flex" alignItems="center">
              {filterOptions.allowCollapse && (
                <FilterCollapseButton
                  onClick={() =>
                    setFilterOpen((prev: typeof filterOpen) => !prev)
                  }
                />
              )}
              <Typography variant="h4">
                {titlePreamble} ({getDataCount()})
              </Typography>
              {infoText && (
                <EventTracker {...infoTooltipTracking}>
                  <Tooltip title={infoText} placement="right" arrow>
                    <span>
                      <IconButton aria-label="info">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </EventTracker>
              )}
            </Box>
          }
          components={{
            Actions: () => {
              return <>{canExport && <ExportAction rows={rows} />}</>;
            },
          }}
          tableRef={tableRef}
          data={rows}
          onSearchChange={setSearch}
          onOrderChange={(_, o) => setOrder?.(o)}
        />
      </Paper>
    </S.CatalogTableWrapper>
  );
};
