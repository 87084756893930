import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  linkContainer: {
    display: 'flex',
    marginRight: '10px',
    width: 'auto',
  },
  copyIcon: {
    width: '12px',
    fontSize: '14px',
    opacity: '0.5',
    cursor: 'pointer',
    display: 'block',
    '&:hover': {
      opacity: '0.8',
    },
  },
});
