import React from 'react';
import { InfoCard } from '@backstage/core-components';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/lab/Alert';
import { TrackedLink } from 'plugin-ui-components';
import { EventType } from '../../domain/EventTypes';
import { Status } from '../../hooks/useApiCall';
import { StyledTableCell } from '../Generic';
import { EventTypeView } from './EventTypeView';

export type EventTypeListProps = {
  application: string;
};

export type EventTypeProps = {
  eventTypes: EventType[];
  isLoading: boolean;
  nakadiUiURL: string;
  application: string;
};

export const EventTypeWithStats = ({
  eventTypes,
  nakadiUiURL,
  isLoading,
  status,
  application,
}: EventTypeProps & Status) => {
  if (isLoading) {
    return (
      <TableRow>
        <StyledTableCell colSpan={12}>
          <LinearProgress data-testid="loading_indicator" />
        </StyledTableCell>
      </TableRow>
    );
  }

  if (status.isError) {
    return (
      <TableRow>
        <StyledTableCell colSpan={12}>
          <Alert severity="error">{status.error}</Alert>
        </StyledTableCell>
      </TableRow>
    );
  }

  if (eventTypes.length === 0) {
    return (
      <TableRow>
        <StyledTableCell colSpan={12}>
          <Alert severity="info">
            <span>
              No event types found! Please make sure your application is
              explicitly listed in the
              <TrackedLink
                plugin="nakadi"
                eventCategory="Event Type List"
                eventLabel="No event types found - Authorization documentation visit"
                to="https://nakadi.docs.zalando.net/nakadi-event-broker/authorization/"
                target="blank"
              >
                {' '}
                authorization{' '}
              </TrackedLink>
              section with write permissions.
            </span>
          </Alert>
        </StyledTableCell>
      </TableRow>
    );
  }

  return (
    <>
      {eventTypes.map(et => (
        <EventTypeView
          key={et.name}
          eventType={et}
          nakadiUiURL={nakadiUiURL}
          application={application}
        />
      ))}
    </>
  );
};

export const EventTypeList = (
  props: EventTypeListProps & EventTypeProps & Status,
) => (
  <InfoCard
    title="Event Types"
    subheader={`All Nakadi events produced by ${props.application}`}
  >
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">
              <strong>Event Type</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <strong>Avg. Publishing Rate</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <strong>Est. Cost</strong>
            </StyledTableCell>
            <StyledTableCell align="left" size="small" padding="none">
              <strong>Owned</strong>
            </StyledTableCell>
            <StyledTableCell align="left" size="small" padding="none">
              <strong>Publishing</strong>
            </StyledTableCell>
            <StyledTableCell align="left">
              <strong>More</strong>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <EventTypeWithStats {...props} />
        </TableBody>
      </Table>
    </TableContainer>
  </InfoCard>
);
