import React, { RefObject, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { FullScreenHandle } from 'react-full-screen';
import IconButton from '@mui/material/IconButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useServices } from '../../../../../../services';
import { Tooltip } from '../../../../../../common';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
  handle: FullScreenHandle;
  scrollableRef: RefObject<HTMLElement>;
}

export const LogsHeader = observer(({ handle, scrollableRef }: Props) => {
  const { runService, logsService } = useServices();

  function scrollTop() {
    scrollableRef.current?.scrollTo(0, 0);
  }

  function scrollBottom() {
    if (scrollableRef && scrollableRef.current) {
      scrollableRef.current.scroll({
        top: scrollableRef.current.scrollHeight,
      });
    }
  }

  useEffect(() => {
    if (logsService.downloadLinkState.isLoaded) {
      window.open(logsService.downloadLink);
      logsService.downloadLinkState.setIdle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsService.downloadLink]);

  return (
    <header>
      <Tooltip title="Scroll to top" placement="bottom-start">
        <IconButton onClick={scrollTop}>
          <ExpandLessIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Scroll to bottom" placement="bottom-start">
        <IconButton onClick={scrollBottom}>
          <ExpandMoreIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Prepare and Download logs">
        <IconButton
          aria-label="download log file"
          onClick={() => logsService.getDownloadLink(runService.step.run)}
          disabled={logsService.downloadLinkState.isLoading}
        >
          <CloudDownloadIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Open in Fullscreen">
        <IconButton onClick={handle.enter} aria-label="open in fullscreen">
          <FullscreenIcon />
        </IconButton>
      </Tooltip>
    </header>
  );
});
