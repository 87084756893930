import { lighten } from '@mui/material/styles';
import dayjs from 'dayjs';
import { colors as c } from 'plugin-ui-components';
import { PipelineStatusCountModel } from '../../../models/monitoring/metrics.model';
import { getColorByStatus } from '../../../utils/pipeline'; // @TODO: add tests

// @TODO: add tests

type Dataset = {
  label: string;
  data: number[];
  borderColor: string;
  backgroundColor: string;
};

type ChartData = {
  labels: string[];
  datasets: Dataset[];
};

type StatusChartData = {
  statuses: string[];
  colors: string[];
  counts: number[];
};

export function getChartData(pipelineData?: ICDPMonitoring.ExecutionCount) {
  if (!pipelineData) {
    return { labels: [], datasets: [] };
  }

  const data = Object.keys(pipelineData).reduce(
    (acc: StatusChartData, curr: string) => {
      const statuses = [...acc.statuses, curr];
      const colors = [...acc.colors, getColorByStatus(curr)];
      const counts = [...acc.counts, (pipelineData as any)[curr]];

      return { statuses, colors, counts };
    },
    { statuses: [], counts: [], colors: [] } as StatusChartData,
  );

  return {
    labels: data.statuses,
    datasets: [
      {
        data: data.counts,
        backgroundColor: data.colors,
        hoverOffset: 1,
      },
    ],
  };
}

export function getHistogramData(
  data?: ICDPMonitoring.PipelineTimeseriesItem[],
) {
  if (!data) return { labels: [], datasets: [] };

  const chartData = data.reduce(
    (acc: ChartData, curr: any) => {
      const labels = [
        ...acc.labels,
        getIntervalLableFromTimestamp(curr.timestamp),
      ];

      let datasets;
      if (!acc.datasets.length) {
        datasets = [
          {
            label: 'Average time to execute',
            data: [curr.metrics.execution_time_seconds.avg],
            borderColor: c.semantic.infoBase,
            backgroundColor: lighten(c.semantic.infoBase, 0.5),
          },
        ];
      } else {
        const newSet = {
          ...acc.datasets[0],
          data: [
            ...acc.datasets[0].data,
            curr.metrics.execution_time_seconds.avg,
          ],
        };
        datasets = [newSet];
      }

      return { labels, datasets };
    },
    { labels: [], datasets: [] } as ChartData,
  );

  return chartData;
}

export function getHistogramStatusesData(
  data?: ICDPMonitoring.PipelineTimeseriesItem[],
) {
  if (!data) return { labels: [], datasets: [] };

  const chartData = data.reduce(
    (acc: ChartData, curr: ICDPMonitoring.PipelineTimeseriesItem) => {
      const labels = [
        ...acc.labels,
        getIntervalLableFromTimestamp(curr.timestamp),
      ];

      // @TODO: return model from the API client
      const statuses = new PipelineStatusCountModel(
        curr.metrics.execution_count,
      );

      const datasets = Object.keys(statuses).reduce(
        (acc2: Dataset[], curr2: string) => {
          const dataSet = acc.datasets.find(set => set.label === curr2);
          const statusCount = (statuses as any)[curr2] as number;
          const statusColor = getColorByStatus(curr2);

          let newDataset;

          if (!dataSet) {
            newDataset = {
              label: curr2,
              data: [statusCount],
              borderColor: statusColor,
              backgroundColor: lighten(statusColor, 0.5),
            };
          } else {
            newDataset = { ...dataSet, data: [...dataSet.data, statusCount] };
          }
          return [...acc2, newDataset];
        },
        [] as Dataset[],
      );

      return {
        labels,
        datasets: [...datasets],
      };
    },
    { labels: [], datasets: [] } as ChartData,
  );

  return chartData;
}

function getIntervalLableFromTimestamp(timestamp: string): string {
  const date = Intl.DateTimeFormat('en', { month: 'short', day: '2-digit' })
    .format(new Date(timestamp))
    .replace(/\s/, '/');

  return `CW ${dayjs(timestamp).isoWeek()}: (${date})`;
}
