import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(3),
  },
  icon: {
    verticalAlign: 'middle',
  },
  text: {
    wordBreak: 'break-word',
  },
  textLight: {
    color: theme.palette.text.hint,
  },
}));
