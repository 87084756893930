import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { default as CloseButton } from '@mui/icons-material/Close';
import { useEntityDialogStyles as useStyles } from '../../utils/styles';
import { Entity } from '../../types';
import {
  ProductEntityTable,
  ProductEntityTableOptions,
} from './ProductEntityTable';
import { findAlways } from '../../utils/assert';
import { HeaderTabs } from '@backstage/core-components';

type ProductEntityDialogProps = {
  open: boolean;
  entity: Entity;
  options?: ProductEntityTableOptions;
  onClose: () => void;
};

export const ProductEntityDialog = ({
  open,
  entity,
  options = {},
  onClose,
}: ProductEntityDialogProps) => {
  const classes = useStyles();
  const labels = Object.keys(entity.entities);
  const [selectedLabel, setSelectedLabel] = useState(
    findAlways(labels, _ => true),
  );

  const tabs = labels.map((label, index) => ({
    id: index.toString(),
    label: `Breakdown by ${label}`,
  }));

  return (
    <Dialog open={open} onClose={onClose} scroll="body" fullWidth maxWidth="lg">
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseButton />
      </IconButton>
      <HeaderTabs
        tabs={tabs}
        onChange={index => setSelectedLabel(labels[index])}
      />
      <ProductEntityTable
        entityLabel={selectedLabel}
        entity={entity}
        options={options}
      />
    </Dialog>
  );
};
