import styled from '@mui/styles/styled';
import { Link } from 'react-router-dom';
import { fontSize } from '../../../../common';

// TODO: Fix duplication of text styles between RunGroupList and RunsList
export const Text = styled('p')(props => ({
  maxWidth: '100%',
  margin: '0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: fontSize.small,
  marginBottom: props.theme.spacing(1),

  ['& a:hover']: {
    color: props.theme.palette.link,
  },
}));

export const TextLight = styled(Text)(props => ({
  color: props.theme.palette.text.secondary,
  fontSize: '.8rem',
  marginBottom: 0,
}));

export const ErrorMessage = styled('div')(props => ({
  padding: '4px 6px',
  color: props.theme.palette.error.main,
  fontWeight: 600,
  fontSize: '.75rem',
  lineHeight: '1',
  overflow: 'hidden',
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  background: 'rgb(253 59 92 / 0.1)',
  borderRadius: '4px',
}));

export const Message = styled(Link)(props => ({
  display: 'block',
  width: '100%',
  marginBottom: props.theme.spacing(1),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 600,
  fontSize: fontSize.small,

  ['&, &:visited']: {
    color: props.theme.palette.text.primary,
  },

  ['&:hover, &:active']: {
    color: props.theme.palette.link,
  },
}));
