import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Link } from '@backstage/core-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { BANNERS } from './data';
import { shouldShowBanner } from './utils';
import { CyberWeekChip } from './Chip';
import { useStyles } from './styles';
import { PluginTracking } from 'plugin-ui-components';

export const CyberWeekBanner = () => {
  const classes = useStyles();
  const [weekBannerIndex, setWeekBannerIndex] = useState(
    BANNERS.findIndex(item => shouldShowBanner(item)),
  );
  const url =
    'https://zlife.zalando.net/workspaces/cyber-week-2023/apps/content/deployment-policies-2023';

  if (!BANNERS[weekBannerIndex]) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <Accordion id="deployment-policy">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            <InfoIcon />
            View this week's Deployment Policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.header}
          >
            <IconButton
              aria-label="previous-week"
              onClick={() => setWeekBannerIndex(weekBannerIndex - 1)}
              disabled={weekBannerIndex <= 0}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
            {shouldShowBanner(BANNERS[weekBannerIndex]) && (
              <div className={classes.tag}>Today</div>
            )}
            <Typography variant="body1" className={classes.title}>
              {BANNERS[weekBannerIndex].name}
            </Typography>
            <IconButton
              aria-label="next-week"
              onClick={() => setWeekBannerIndex(weekBannerIndex + 1)}
              disabled={weekBannerIndex >= BANNERS.length - 1}
            >
              <KeyboardBackspaceIcon className={classes.rotate} />
            </IconButton>
          </Box>
          <table className={classes.table}>
            <thead>
              <tr>
                <th />
                <th>New features or architectural changes</th>
                <th>Major feature changes or updates</th>
                <th>Minor changes</th>
                <th>Bug fixes and patches</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Pre-event relevant</th>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].arch.pre} />
                </td>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].major.pre} />
                </td>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].minor.pre} />
                </td>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].patch.pre} />
                </td>
              </tr>
              <tr>
                <th>Event relevant</th>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].arch.current} />
                </td>
                <td>
                  <CyberWeekChip
                    zone={BANNERS[weekBannerIndex].major.current}
                  />
                </td>
                <td>
                  <CyberWeekChip
                    zone={BANNERS[weekBannerIndex].minor.current}
                  />
                </td>
                <td>
                  <CyberWeekChip
                    zone={BANNERS[weekBannerIndex].patch.current}
                  />
                </td>
              </tr>
              <tr>
                <th>Post-event relevant</th>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].arch.post} />
                </td>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].major.post} />
                </td>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].minor.post} />
                </td>
                <td>
                  <CyberWeekChip zone={BANNERS[weekBannerIndex].patch.post} />
                </td>
              </tr>
            </tbody>
          </table>

          <Link
            target="_blank"
            underline="hover"
            to={url}
            onClickCapture={() => {
              PluginTracking.sendEvent({
                plugin: 'cyberweek',
                eventCategory: 'Current deployment policy widget',
                eventLabel: `Clicked on a link: ${url}`,
                eventAction: 'click',
              });
            }}
          >
            View the full Deployment Policy and details on zLife{' '}
            <OpenInNewIcon className={classes.icon} />
          </Link>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
