import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import styled from '@mui/styles/styled';

export const Breadcrumbs = styled(MaterialBreadcrumbs)(props => ({
  color: 'white',

  [props.theme.breakpoints.up('sm')]: {
    '& > ol': {
      maxWidth: 'calc(100vw - 350px)',
      flexFlow: 'nowrap',
      overflow: 'hidden',
    },

    // Never collapse the "Projects" breadcrumb
    '& li:not(:first-child)': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: '1ch', // Don't clip separators
    },

    '& li a': {
      color: 'white',
    },

    '& li:last-child a': {
      pointerEvents: 'none',
      textDecoration: 'none',
      fontWeight: 600,
    },
  },
}));
