import React from 'react';
import cn from 'classnames';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ISort, TableColumn } from './types';

interface Props {
  columns: TableColumn[];
  isTree?: boolean;
  sortOptions?: ISort;
  onSort?: (column: TableColumn) => void;
}

export function Columns({ columns, sortOptions, onSort }: Props) {
  if (!columns) return <></>;

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          if (column.hidden) return null;
          const isSortCapable = !!(
            column.sortBy ||
            column.defaultSort ||
            column.sorting
          );
          const isSorted = sortOptions?.column.field === column.field;

          return (
            <TableCell key={`th-${index}`} variant="head">
              <button
                onClick={() => {
                  if (isSortCapable && onSort) {
                    onSort(column);
                  }
                }}
              >
                <div>{column.children}</div>
                {isSortCapable && (
                  <div
                    className={cn('sort-icon', {
                      [sortOptions?.order || '']: !!sortOptions?.order,
                      sorted: isSorted,
                    })}
                  >
                    <ArrowDownwardIcon />
                  </div>
                )}
              </button>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
