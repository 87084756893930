import React from 'react';
import type { CardProps } from '@mui/material/Card';
import type { CardHeaderProps } from '@mui/material/CardHeader';
import type { CardContentProps } from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { ComingSoonWrapper } from '../ComingSoonWrapper';
import * as S from './styles';

export interface InfoCardProps extends Omit<CardProps, 'title'> {
  title: React.ReactNode;
  subheader?: React.ReactNode;
  cardFooter?: React.ReactNode;
  cardHeaderProps?: CardHeaderProps;
  cardContentProps?: CardContentProps;
  disabled?: boolean;
  disabledText?: string;
  tooltipText?: React.ReactNode;
}

export const InfoCard = React.forwardRef<HTMLDivElement, InfoCardProps>(
  (props, ref) => {
    const {
      title,
      subheader,
      children,
      cardHeaderProps,
      cardContentProps,
      disabled = false,
      disabledText,
      tooltipText,
      cardFooter,
      ...cardProps
    } = props;

    const hasHeaderContent = title || subheader;

    const HeaderTooltip = () =>
      tooltipText ? (
        <Tooltip title={tooltipText} placement="right">
          <InfoIcon />
        </Tooltip>
      ) : (
        <></>
      );

    return (
      <S.StyledCard {...cardProps} ref={ref}>
        {hasHeaderContent ? (
          <>
            <S.StyledCardHeader
              title={
                <>
                  {title} <HeaderTooltip />
                </>
              }
              subheader={subheader}
              titleTypographyProps={
                {
                  component: 'h3',
                  variant: 'h3',
                } as any
              }
              {...cardHeaderProps}
            />

            <Divider />
          </>
        ) : (
          ''
        )}

        {disabled ? (
          <ComingSoonWrapper comingSoonText={disabledText}>
            <S.StyledCardContent {...cardContentProps}>
              {children}
            </S.StyledCardContent>
          </ComingSoonWrapper>
        ) : (
          <S.StyledCardContent disabled={disabled} {...cardContentProps}>
            {children}
          </S.StyledCardContent>
        )}

        {cardFooter && (
          <>
            <Divider />
            {cardFooter}
          </>
        )}
      </S.StyledCard>
    );
  },
);
