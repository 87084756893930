import React, { forwardRef, PropsWithChildren, Ref } from 'react';
import ButtonBase, { type ButtonBaseProps } from '@mui/material/ButtonBase';
import { useBarChartStepperButtonStyles as useStyles } from '../../utils/styles';

interface BarChartStepperButtonProps extends ButtonBaseProps {
  name: string;
}

export const BarChartStepperButton = forwardRef(
  (
    {
      name,
      children,
      ...buttonBaseProps
    }: PropsWithChildren<BarChartStepperButtonProps>,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const classes = useStyles();
    return (
      <ButtonBase
        ref={ref}
        classes={classes}
        disableRipple
        data-testid={`bar-chart-stepper-button-${name}`}
        {...buttonBaseProps}
      >
        {children}
      </ButtonBase>
    );
  },
);
