import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/lab/Alert';
import { costInsightsApiRef } from '../../api';
import { ProductInsightsCardList } from '../ProductInsightsCard/ProductInsightsCardList';
import { Duration, Entity, Maybe, Product } from '../../types';
import { intervalsOf, PRODUCT_DEFAULT_DURATION } from '../../utils/duration';
import {
  DefaultLoadingAction,
  initialStatesOf,
  ProductState,
} from '../../utils/loading';
import { totalAggregationSort } from '../../utils/sort';
import {
  useLoading,
  useLastCompleteBillingDate,
  MapLoadingToProps,
} from '../../hooks';
import { useApi } from '@backstage/core-plugin-api';

type LoadingProps = (isLoading: boolean) => void;

const mapLoadingToProps: MapLoadingToProps<LoadingProps> =
  ({ dispatch }) =>
  (isLoading: boolean) =>
    dispatch({ [DefaultLoadingAction.CostInsightsProducts]: isLoading });

type ProductInsightsProps = {
  group: string;
  project: Maybe<string>;
  product: Product;
};

export const ProductInsights = ({
  group,
  project,
  product,
}: ProductInsightsProps) => {
  const client = useApi(costInsightsApiRef);
  const [initialStates, setStates] = useState<ProductState[]>([]);
  const [error, setError] = useState<Maybe<Error>>(null);
  const lastCompleteBillingDate = useLastCompleteBillingDate();
  const dispatchLoading = useLoading(mapLoadingToProps);

  /* eslint-disable react-hooks/exhaustive-deps */
  // See @CostInsightsPage
  const dispatchLoadingProducts = useCallback(dispatchLoading, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const onSelectAsyncMemo = useCallback(
    async function onSelectAsync(
      prod: Product,
      duration: Duration,
    ): Promise<Entity> {
      return client.getProductInsights({
        group: group,
        project: project,
        product: prod.kind,
        intervals: intervalsOf(duration, lastCompleteBillingDate),
      });
    },
    [client, group, project, lastCompleteBillingDate],
  );

  useEffect(() => {
    async function getProductInsights() {
      try {
        dispatchLoadingProducts(true);
        const responses = await client.getProductInsights({
          group: group,
          project: project,
          product: product.kind,
          intervals: intervalsOf(
            PRODUCT_DEFAULT_DURATION,
            lastCompleteBillingDate,
          ),
        });

        const updatedInitialStates = initialStatesOf(
          [product],
          [responses],
        ).sort(totalAggregationSort);
        setStates(updatedInitialStates);
      } catch (e: any) {
        setError(new Error(e));
      } finally {
        dispatchLoadingProducts(false);
      }
    }

    getProductInsights();
  }, [
    client,
    group,
    project,
    product,
    lastCompleteBillingDate,
    dispatchLoadingProducts,
  ]);

  return (
    <Box px={3} py={0}>
      {error ? (
        <Alert severity="error">{error.message}</Alert>
      ) : (
        <ProductInsightsCardList
          initialStates={initialStates}
          onSelectAsync={onSelectAsyncMemo}
        />
      )}
    </Box>
  );
};
