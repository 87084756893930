import React, { Fragment, MouseEventHandler } from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import SnoozeIcon from '@mui/icons-material/AccessTime';
import AcceptIcon from '@mui/icons-material/Check';
import DismissIcon from '@mui/icons-material/Delete';
import { ActionItemCard } from './ActionItemCard';
import { Alert, AlertStatus } from '../../types';
import { useScroll, ScrollType } from '../../hooks';
import { useActionItemCardStyles as useStyles } from '../../utils/styles';

type AlertStatusButtonProps = {
  title: string;
  amount: number;
  icon: JSX.Element;
  onClick: MouseEventHandler;
} & IconButtonProps;

const AlertStatusButton = ({
  title,
  amount,
  icon,
  onClick,
  ...buttonProps
}: AlertStatusButtonProps) => (
  <Tooltip title={title}>
    <IconButton
      onClick={onClick}
      role="button"
      aria-hidden={false}
      {...buttonProps}
    >
      <Badge badgeContent={amount}>{icon}</Badge>
    </IconButton>
  </Tooltip>
);

type ActionItemsProps = {
  active: Alert[];
  snoozed: Alert[];
  accepted: Alert[];
  dismissed: Alert[];
};

export const ActionItems = ({
  active,
  snoozed,
  accepted,
  dismissed,
}: ActionItemsProps) => {
  const classes = useStyles();
  const [, setScroll] = useScroll();

  const isSnoozedButtonDisplayed = !!snoozed.length;
  const isAcceptedButtonDisplayed = !!accepted.length;
  const isDismissedButtonDisplayed = !!dismissed.length;
  const isStatusButtonGroupDisplayed = !!active.length;

  const onStatusButtonClick: MouseEventHandler = () =>
    setScroll(ScrollType.AlertSummary);

  return (
    <>
      <Paper>
        {active.map((alert, index) => (
          <Fragment key={`alert-${index}`}>
            <ActionItemCard
              alert={alert}
              number={index + 1}
              avatar={<Avatar className={classes.avatar}>{index + 1}</Avatar>}
            />
            {index < active.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Paper>
      {isStatusButtonGroupDisplayed && (
        <Box display="flex" justifyContent="flex-end" mt={2}>
          {isAcceptedButtonDisplayed && (
            <AlertStatusButton
              title="Accepted"
              aria-label={AlertStatus.Accepted}
              icon={<AcceptIcon />}
              amount={accepted.length}
              onClick={onStatusButtonClick}
            />
          )}
          {isSnoozedButtonDisplayed && (
            <AlertStatusButton
              title="Snoozed"
              aria-label={AlertStatus.Snoozed}
              amount={snoozed.length}
              icon={<SnoozeIcon />}
              onClick={onStatusButtonClick}
            />
          )}
          {isDismissedButtonDisplayed && (
            <AlertStatusButton
              title="Dismissed"
              aria-label={AlertStatus.Dismissed}
              icon={<DismissIcon />}
              amount={dismissed.length}
              onClick={onStatusButtonClick}
            />
          )}
        </Box>
      )}
    </>
  );
};
