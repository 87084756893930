import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  clusterItem: {
    marginBottom: theme.spacing(),
    fontWeight: 500,
  },
  checkbox: {
    padding: '6px 9px',
  },
}));
