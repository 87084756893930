import React, { MouseEventHandler } from 'react';
import classnames from 'classnames';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { useScroll } from '../../hooks';
import { Alert } from '../../types';
import { useActionItemCardStyles as useStyles } from '../../utils/styles';

type ActionItemCardProps = {
  alert: Alert;
  number?: number;
  avatar?: JSX.Element;
  disableScroll?: boolean;
};

export const ActionItemCard = ({
  alert,
  avatar,
  number,
  disableScroll = false,
}: ActionItemCardProps) => {
  const classes = useStyles();
  const rootClasses = classnames(classes.root, {
    [classes.activeRoot]: !disableScroll,
  });
  const [, setScroll] = useScroll();

  const onActionItemClick: MouseEventHandler = () => {
    if (!disableScroll && number) {
      setScroll(`alert-${number}`);
    }
  };

  return (
    <Card className={classes.card} raised={false} onClick={onActionItemClick}>
      <CardHeader
        classes={{
          root: rootClasses,
          action: classes.action,
          title: classes.title,
        }}
        title={alert.title}
        subheader={alert.subtitle}
        avatar={avatar}
      />
    </Card>
  );
};
