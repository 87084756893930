import React from 'react';
import Box from '@mui/material/Box';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';

interface Props {
  isActive?: boolean;
}

export function ActiveState({ isActive }: Props) {
  const text = isActive ? 'Active' : 'Inactive';

  return (
    <Box display="inline-flex" alignItems="center">
      {text}
      <FiberManualRecord
        className="dot-icon"
        color={isActive ? 'success' : 'disabled'}
      />
    </Box>
  );
}
