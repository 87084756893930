import React, { useState } from 'react';
import Skeleton from '@mui/lab/Skeleton';
import { Link } from '@backstage/core-components';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';
import type { RequestState, TeamReferenceProps } from './types';
import { useReferenceContext } from '../../context';
import { TeamReferenceError } from './TeamReferenceError';
import { formatTeamName, getTeamNameFromFullName } from 'plugin-core';

export function TeamReference({
  team,
  format = 'nameAndAlias',
  ...linkProps
}: TeamReferenceProps) {
  const { getTeam } = useReferenceContext();
  const [{ error, value, loading }, setRequestState] = useState<
    RequestState<IEntityGroup>
  >({
    value: undefined,
    error: undefined,
    loading: true,
  });
  const isEntity = typeof team !== 'string';

  React.useEffect(() => {
    if (!isEntity) {
      getTeam(team)
        .then(data => {
          if (typeof data !== 'string') {
            setRequestState(prev => ({ ...prev, value: data, loading: false }));
          }
        })
        .catch(err => {
          setRequestState(prev => ({ ...prev, error: err, loading: false }));
        });
    } else {
      setRequestState(prev => ({ ...prev, value: team, loading: false }));
    }
  }, [getTeam, isEntity, team]);

  const entity = value;

  const teamAlias = entity?.spec?.id || '';
  const teamFullName = getTeamNameFromFullName(entity?.spec?.fullName ?? '');
  const hideTooltip = format === 'nameAndAlias';
  const Wrapper = hideTooltip ? React.Fragment : Tooltip;
  const wrapperProps: Omit<TooltipProps, 'children'> = hideTooltip
    ? ({} as any)
    : {
        title: format === 'aliasOnly' ? teamFullName : teamAlias,
        placement: 'top',
        arrow: true,
      };

  if (loading) {
    return <Skeleton variant="text" className="team-reference" />;
  }

  if (error || !entity) {
    return <TeamReferenceError error={error} team={team as string} />;
  }

  return (
    <Link
      to={`/catalog/default/group/${entity?.metadata?.name}`}
      {...linkProps}
    >
      <Wrapper {...wrapperProps}>
        <span>{formatTeamName(entity, format)}</span>
      </Wrapper>
    </Link>
  );
}
