import React, { useMemo } from 'react';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { WarningIcon } from '@backstage/core-components';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowForward from '@mui/icons-material/ArrowForward';
import {
  CardFooterLink,
  EventTracker,
  TrackedButton,
} from 'plugin-ui-components';
import { useApplicationScorecards } from '../../../hooks';
import { viewAllScorecards, viewScorecardDetails } from '../../../tracking';
import { calculateScorecardCompletion, getColor } from '../../../utils';
import { ScorecardCompletion } from './ScorecardCompletion';

interface ScorecardChipProps {
  entity: IEntity;
  scorecardsToShow?: IEntityScorecard[];
}

export const ScorecardChip = ({
  entity,
  scorecardsToShow,
}: ScorecardChipProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const popoverId = 'scorecard-popover';
  const {
    kind = 'Component',
    metadata: { namespace = 'default', name },
  } = entity;
  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const {
    value: { assessments } = {},
    error,
    loading,
  } = useApplicationScorecards(entity, scorecardsToShow);

  const completion = React.useMemo(() => {
    if (!assessments || !assessments.length) return 0;
    return entity.metadata.scorecardCompletion
      ? (entity.metadata.scorecardCompletion as number)
      : calculateScorecardCompletion(assessments);
  }, [assessments, entity]);

  const hasAssessments = useMemo(
    () => assessments && assessments.length,
    [assessments],
  );

  return (
    <>
      {!!error && (
        <Tooltip title="An error occurred while fetching the data.">
          <Chip
            size="small"
            variant="outlined"
            className="info-chip error"
            label={
              <>
                <WarningIcon />
                Error
              </>
            }
          />
        </Tooltip>
      )}

      {loading && <Skeleton width="10%" />}
      {Boolean(!loading && hasAssessments) && (
        <TrackedButton
          onClick={handleOpenPopover}
          variant="text"
          aria-describedby={popoverId}
          style={{ textTransform: 'none', color: 'inherit' }}
          {...viewScorecardDetails(entity.metadata.name)}
        >
          <Chip
            size="small"
            variant="outlined"
            label={`${completion}%`}
            className={`info-chip ${getColor(completion)}`}
          />
        </TrackedButton>
      )}
      {!loading && !hasAssessments && (
        <Tooltip title="This application has no linked scorecards">
          <Chip
            size="small"
            variant="outlined"
            className="info-chip"
            label="N/A"
            style={{ cursor: 'help' }}
          />
        </Tooltip>
      )}
      <Popover
        id={popoverId}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        elevation={4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <>
          <Box padding={2} paddingBottom={1}>
            {assessments
              ?.filter(sc => !!sc.spec.checks.length)
              .slice(0, 3)
              .map(scorecard => (
                <Box marginBottom={1} key={scorecard.metadata.name}>
                  <Typography variant="body2">
                    {scorecard.metadata.title}
                  </Typography>
                  <ScorecardCompletion
                    completion={scorecard.spec.completionPercentage}
                    type="linear"
                  />
                </Box>
              ))}
          </Box>
          <Divider />
          <EventTracker {...viewAllScorecards(entity.metadata.name)}>
            <CardFooterLink
              variant="text"
              to={`/catalog/${namespace}/${kind}/${name}/scorecards`}
            >
              See all Scorecards for <strong>{name}</strong> <ArrowForward />
            </CardFooterLink>
          </EventTracker>
        </>
      </Popover>
    </>
  );
};
