import React from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Progress } from '@backstage/core-components';
import Box from '@mui/material/Box';
import { useServices } from '../../../../services';
import { Text } from '../../../../common';
import { TestUploadsList } from './TestUploadsList';
import { useTestUploads } from './useTestUploads';
import { getMessageByStatus } from './utils';

export const TestResults = observer(() => {
  const { runId = '', stepId = '' } = useParams();
  const { runService } = useServices();

  const { id: stepRunId, status } = runService.step?.run;

  const [{ loading, error, value }] = useTestUploads(
    runId,
    stepId,
    stepRunId,
    status,
  );

  if (error) {
    return (
      <Text error padding={2}>
        An error occurred while retrieving test results, refresh the page to try
        again.
      </Text>
    );
  }

  if (loading && !value) {
    return (
      <Box padding={2}>
        <Progress />
      </Box>
    );
  }

  if (!value) {
    return <Box padding={2}>{getMessageByStatus(status)}</Box>;
  }

  return (
    <Box position="relative" flex={1} display="flex" flexDirection="column">
      <TestUploadsList
        defaultIndex={value.default_index.index}
        defaultType={value.default_index.type}
        uploads={value.uploads}
        stepRunId={stepRunId}
      />
    </Box>
  );
});
