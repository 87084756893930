import React from 'react';
import Link, { type LinkProps } from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import { EventTracker, useHoverAndCopy } from 'plugin-ui-components';
import { stringToPath } from '../SupportSections/utils';
import { useStyles } from './style';
import { SUPPORT_PAGE_URL } from '../../constants';

interface TrackedSharableLinkProps extends LinkProps, IEventTracker {
  title: string;
  linkName: string;
}

export const TrackedSharableLink: React.FC<TrackedSharableLinkProps> = ({
  plugin,
  eventCategory,
  eventLabel,
  eventAction,
  eventValue,
  title,
  linkName,
  ...linkProps
}) => {
  const classes = useStyles();

  const { copyText, hover, handleMouseOut, copyToClipboard, handleHover } =
    useHoverAndCopy();

  return (
    <EventTracker
      plugin={plugin}
      eventLabel={eventLabel}
      eventAction={eventAction}
      eventCategory={eventCategory}
      interaction="onClickCapture"
    >
      <div
        onMouseOver={handleHover}
        onFocus={handleHover}
        onMouseLeave={handleMouseOut}
        className={classes.linkContainer}
      >
        <Link {...linkProps} />
        {hover ? (
          <Tooltip placement="top" title={copyText}>
            <FileCopyOutlined
              className={classes.copyIcon}
              onClick={() =>
                copyToClipboard(
                  `${SUPPORT_PAGE_URL}/${stringToPath(title)}/${stringToPath(
                    linkName,
                  )}`,
                )
              }
              fontSize="small"
            />
          </Tooltip>
        ) : (
          <div className={classes.copyIcon} />
        )}
      </div>
    </EventTracker>
  );
};
