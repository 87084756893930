import React from 'react';
import { LinkProps } from '@backstage/core-components';
import Grid from '@mui/material/Grid';
import {
  AboutField,
  EventTracker,
  InfoCard,
  TrackedLink,
} from 'plugin-ui-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import EditIcon from '@mui/icons-material/Edit';
import {
  attemptEditWhenDisabled,
  clickLinkToDiscover,
} from '../../../tracking';

interface Stats {
  subDomains: number;
  applications: number;
  contributingTeams: number;
  contributingUsers: number;
}

const initialStats: Stats = {
  subDomains: 0,
  applications: 0,
  contributingTeams: 0,
  contributingUsers: 0,
};

const widgetName = 'Domain Overview/Consists of';

function LinkEl({
  to,
  disabled,
  ...props
}: LinkProps & { disabled?: boolean } & JSX.IntrinsicElements['span']) {
  let value = 0;
  React.Children.map(props.children, child => {
    value = Number(child ?? 0);
  });

  if (value && !disabled) {
    const discoverText = `${to}`.replace(/[.\/]/, '');
    return (
      <TrackedLink
        to={to}
        {...props}
        style={{ fontSize: '1.25rem' }}
        {...clickLinkToDiscover(discoverText, widgetName)}
      />
    );
  }
  return <span {...props} style={{ fontSize: '1.25rem' }} />;
}

export function ConsistsOfWidget() {
  const { entity } = useEntity<IEntityDomain>();
  const [stats, setStats] = React.useState<Stats>(initialStats);

  React.useEffect(() => {
    const newStats = { ...initialStats };

    if (entity.spec?.spec) {
      const { entities, stakeholders } = entity.spec.spec;

      entities.forEach(ent => {
        if (ent.type === 'APPLICATION') newStats.applications++;
        if (ent.type === 'DOMAIN') newStats.subDomains++;
      });

      stakeholders.forEach(person => {
        if (person.type === 'USER') newStats.contributingUsers++;
        if (person.type === 'GROUP') newStats.contributingTeams++;
      });
    }

    setStats(newStats);
  }, [entity]);

  return (
    <InfoCard
      title="Consists of"
      cardHeaderProps={{
        action: (
          <EventTracker {...attemptEditWhenDisabled(widgetName)}>
            <EditIcon color="disabled" />
          </EventTracker>
        ),
      }}
    >
      <Grid container style={{ padding: '1rem' }}>
        <AboutField label="Sub-domains" xs={6}>
          <LinkEl to="./connected-domains">{stats.subDomains}</LinkEl>
        </AboutField>
        <AboutField label="Applications" xs={6}>
          <LinkEl to="./applications">{stats.applications}</LinkEl>
        </AboutField>
        <AboutField label="Contributing teams" xs={6}>
          <LinkEl disabled to="./people">
            {stats.contributingTeams}
          </LinkEl>
        </AboutField>
        <AboutField label="Individual contributors" xs={6}>
          <LinkEl disabled to="./people">
            {stats.contributingUsers}
          </LinkEl>
        </AboutField>
      </Grid>
    </InfoCard>
  );
}
