import React from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../../services';
import { Progress } from '@backstage/core-components';
import Box from '@mui/material/Box';
import { Resources } from './Resources';
import { useDeploymentStatus } from './useDeploymentStatus';
import { DEPLOYMENT_STATUS_TIMER, STEP_STATUS } from '../../../../constants';
import { useInterval } from 'react-use';
import { getResources } from './utils';
import { Text } from '../../../../common';

export const DeploymentStatusProcess = observer(() => {
  const { runId = '', stepId = '' } = useParams();
  const { runService } = useServices();

  const { id: stepRunId, status } = runService.step?.run;

  const [{ loading, error, value }, { retry }] = useDeploymentStatus(
    runId,
    stepId,
    stepRunId,
    {
      stepId: runService.step?.id,
    },
    status,
  );

  useInterval(
    retry,
    status === STEP_STATUS.IN_PROGRESS ? DEPLOYMENT_STATUS_TIMER : null,
  );

  if (error) {
    return (
      <Text error padding={2}>
        An error occurred while retrieving deployment status, refresh the page
        to try again.
      </Text>
    );
  }

  if (loading && !value.length) {
    return (
      <Box padding={2}>
        <Progress />
      </Box>
    );
  }

  const resources = getResources(value);

  return (
    <Box padding={2}>
      <Resources status={status} resources={resources} />
    </Box>
  );
});
