import React, { PropsWithChildren, useLayoutEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import {
  EventTracker,
  FeatureFlagRibbon,
  HomeWidget,
  useUserTeams,
} from 'plugin-ui-components';
import InfoIcon from '@mui/icons-material/Info';
import { Table } from '@backstage/core-components';
import {
  useApplicationsWithLowestGrades,
  useOrgReposAnalysisReports,
} from '../../hooks';
import { columns } from './lowestScoresColumn';
import { useNavigate } from 'react-router';
import { Analysis } from '../../types';
import { NoAnalysisCardContent } from './components';

const Title = (
  <Tooltip
    title="Your 3 applications with the lowest score on the Codacy analysis report."
    content="Code Quality"
  >
    <span>
      Code Quality
      <InfoIcon style={{ cursor: 'pointer', marginLeft: 6 }} />
    </span>
  </Tooltip>
);

function CustomTabPanel({
  value,
  index,
  loadingGrades,
  children,
}: PropsWithChildren<{
  value: number;
  index: number;
  loadingGrades: boolean;
}>) {
  useLayoutEffect(() => {
    document
      .querySelector(`#simple-tabpanel-${index} > div > div > div:nth-child(1)`)
      ?.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingGrades]);
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {loadingGrades ? <CircularProgress /> : children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const CodacyLowestScores = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<number>(0);
  const {
    value: { userTeams },
  } = useUserTeams();
  const { reports, loading } = useOrgReposAnalysisReports({ userTeams });
  const { lowestGrades, loadingGrades } = useApplicationsWithLowestGrades({
    reports,
    userTeams,
  });
  const isLoading = loading || loadingGrades;
  const renderBottomLink =
    lowestGrades[tab]?.team_id && lowestGrades[tab]?.fullName;
  const emptyContent = !lowestGrades?.length;

  if (emptyContent) {
    return (
      <FeatureFlagRibbon name="code-quality">
        <HomeWidget title={Title}>
          {!isLoading && <NoAnalysisCardContent />}
        </HomeWidget>
      </FeatureFlagRibbon>
    );
  }

  return (
    <FeatureFlagRibbon name="code-quality">
      {isLoading ? (
        <HomeWidget title={Title}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CardContent>
              <CircularProgress />
            </CardContent>
          </Box>
        </HomeWidget>
      ) : (
        <HomeWidget
          title={Title}
          footerLink={
            renderBottomLink
              ? {
                  href: `/catalog/default/group/${lowestGrades[tab]?.team_id}/applications`,
                  text: `See all ${lowestGrades[tab]?.fullName} applications`,
                }
              : { href: '', text: '' }
          }
        >
          <EventTracker
            interaction="onClick"
            plugin="code-quality"
            eventAction="click"
            eventLabel="Team tab on widget"
            eventCategory="Start Page Widget Code Quality Widget"
          >
            <Box pl={lowestGrades.length > 1 ? 0 : 3}>
              <Tabs
                value={tab}
                onChange={(_, newValue: number) => setTab(newValue)}
                variant="scrollable"
                TabScrollButtonProps={{
                  style: { width: 24 },
                }}
              >
                {lowestGrades.map((team, i) => (
                  <Tab
                    key={i}
                    label={team.fullName}
                    value={i}
                    style={{ textTransform: 'capitalize', height: 64 }}
                    {...a11yProps(i)}
                  />
                ))}
              </Tabs>
            </Box>
          </EventTracker>
          {lowestGrades.map((team, i) => {
            const combinedAnalysis = team.analysis
              .reduce(
                (result: Analysis[], org) => [...result, ...org.analysis],
                [],
              )
              .slice(0, 3);

            return (
              <EventTracker
                interaction="onClick"
                plugin="code-quality"
                eventAction="click"
                eventLabel="Application row with code analysis"
                eventCategory="Start Page Widget Code Quality Widget"
              >
                <CustomTabPanel
                  key={i}
                  value={tab}
                  index={i}
                  loadingGrades={loadingGrades}
                >
                  <Table
                    style={{
                      boxShadow: 'none',
                      width: '100%',
                      border: 'none',
                    }}
                    data={combinedAnalysis}
                    columns={columns}
                    isLoading={loadingGrades}
                    options={{ paging: false, search: false, padding: 'dense' }}
                    onRowClick={(_, data) =>
                      navigate(
                        `/catalog/default/Component/${data?.repository.name}#code-quality-widget`,
                      )
                    }
                  />
                </CustomTabPanel>
              </EventTracker>
            );
          })}
        </HomeWidget>
      )}
    </FeatureFlagRibbon>
  );
};
