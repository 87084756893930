import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider, { SliderProps } from '@mui/material/Slider';
import { useStyles } from './styles';
import { useSearchParams } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import type { Mark } from '@mui/material/Slider/useSlider.types';

export { Mark };

interface Props {
  marks: Mark[];
  maxDepth: number;
}

export const DepthSlider = React.memo(({ marks, maxDepth }: Props) => {
  const styles = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const depth = Number(searchParams.get('depth')) || marks[0]?.value || 1;
  const [sliderValue, setSliderValue] = React.useState(-Number(depth || 1));

  React.useEffect(() => {
    setSliderValue(-Number(depth || 1));
  }, [depth]);

  const handleChange: SliderProps['onChangeCommitted'] = (_, val) => {
    if (typeof val === 'number') {
      const params = new URLSearchParams(searchParams);
      params.set('depth', String(Math.abs(val)));
      setSearchParams(params);
    }
  };

  return (
    <div>
      <Box className={styles.sliderWrapper}>
        <Typography variant="h6" className={styles.sliderHeader}>
          Depth to show
        </Typography>
        <div className={styles.sliderSize}>
          <Slider
            orientation="vertical"
            value={sliderValue}
            onChange={(_, val) => setSliderValue(val as number)}
            onChangeCommitted={handleChange}
            marks={marks}
            valueLabelDisplay="auto"
            step={1}
            min={-maxDepth}
            max={-1}
            scale={x => -x}
            track="inverted"
          />
        </div>
      </Box>
    </div>
  );
});
