import React, { RefObject, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { observer } from 'mobx-react-lite';
import withStyles from '@mui/styles/withStyles';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  PRIMARY_LOGS_POLLING_TIMER,
  SECONDARY_LOGS_POLLING_TIMER,
  LOGS_POLLING_RETRIES,
  STEP_STATUS,
} from '../../../../constants';
import { LogsFoldModel } from '../../../../models';
import { useServices } from '../../../../services';
import { Text } from '../../../../common';
import { LogsFold } from './components/Fold';
import { LogsHeader } from './components/Header';
import {
  ErrorMessage,
  LoadingMessage,
  NoLogsMessage,
} from './components/Messages';
import { cssVariables } from './cssVariables';

import 'style-loader!css-loader!sass-loader!./styles.scss';

interface Props {
  scrollableRef: RefObject<HTMLElement>;
}

export const ScalyrLogsComponent = observer(({ scrollableRef }: Props) => {
  const { runService, logsService } = useServices();
  const [counter, setCounter] = useState(1);
  const [wasRunning, setWasRunning] = useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    if (runService.step.run.hasStarted) {
      setCounter(1);
      logsService.clean();
      logsService.getFolds(runService.step.run);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runService.step.run.id, runService.step.run.hasStarted]);

  useEffect(() => {
    if (runService.step.run.isRunning) {
      setWasRunning(true);
    }
  }, [runService.step.run.isRunning]);

  useEffect(() => {
    if (
      runService.step.run.isFinished &&
      !wasRunning &&
      (logsService.logsState.isLoaded || logsService.logsState.isRecoverable)
    ) {
      logsService.updateFinalState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runService.step.run.isFinished, logsService.logsState.state]);

  useInterval(
    async () => {
      if (runService.step.run.isFinished) {
        setCounter(counter + 1);
        await logsService.getFolds(runService.step.run);
        if (counter === LOGS_POLLING_RETRIES) {
          logsService.updateFinalState();
        }
      }
    },
    runService.step.run.isFinished &&
      wasRunning &&
      !logsService.logsState.isUnrecoverable &&
      counter <= LOGS_POLLING_RETRIES
      ? PRIMARY_LOGS_POLLING_TIMER
      : null,
  );

  useInterval(
    () => logsService.getFolds(runService.step.run),
    runService.step.run.isRunning ? PRIMARY_LOGS_POLLING_TIMER : null,
  );

  useInterval(
    () => logsService.loadPendingLines(runService.step.run),
    !logsService.logs.wereAllLinesRetrieved
      ? SECONDARY_LOGS_POLLING_TIMER
      : null,
  );

  function handleClick(fold: LogsFoldModel) {
    logsService.logs.toggleOpen(fold);

    if (fold.shouldFetch()) {
      logsService.getLines(fold, runService.step.run);
    }
  }

  if (!runService.step.run.id || logsService.logsState.isNotAvailable) {
    return <NoLogsMessage />;
  }

  switch (runService.step.run.status) {
    case STEP_STATUS.PENDING_APPROVAL:
      return <Text p={2}>Waiting for manual approval…</Text>;
    case STEP_STATUS.NOT_STARTED:
      return <Text p={2}>Script step was not reached yet.</Text>;
    case STEP_STATUS.INIT:
      return <Text p={2}>Script step is initializing.</Text>;
    default:
      break;
  }

  if (logsService.logsState.isUnrecoverable) {
    return <ErrorMessage message={logsService.logsState.message} />;
  }

  if (logsService.logs.folds.length === 0) {
    return (
      <LoadingMessage
        isRunning={runService.step.run.isRunning}
        wasRunning={wasRunning}
      />
    );
  }

  return (
    <div id="scalyr-logs">
      <LogsHeader handle={handle} scrollableRef={scrollableRef} />
      <FullScreen handle={handle}>
        {logsService.logs.folds.map((fold, index) => (
          <LogsFold
            key={`fold-${index}`}
            fold={fold}
            index={index}
            onClick={handleClick}
            scrollableRef={scrollableRef}
          />
        ))}
      </FullScreen>
    </div>
  );
});

export const ScalyrLogs = withStyles(cssVariables)(ScalyrLogsComponent);
