import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Criticality';
const id = 'criticality-filter';
export const CriticalityFilter = React.memo(() => {
  const { criticalityFilter, setCriticalityFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" margin="dense" size="small">
      <InputLabel id={id} children={label} />
      <Select
        variant="outlined"
        size="small"
        value={criticalityFilter}
        onChange={ev => setCriticalityFilter(String(ev.target.value))}
        labelId={id}
        label={label}
        classes={{ select }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        <MenuItem value="Tier 1">Tier 1</MenuItem>
        <MenuItem value="Tier 2">Tier 2</MenuItem>
        <MenuItem value="Tier 3">Tier 3</MenuItem>
        <MenuItem value="Not Relevant">Not Relevant</MenuItem>
      </Select>
    </FormControl>
  );
});
