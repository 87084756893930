import React, {
  type MouseEvent,
  type MouseEventHandler,
  type PropsWithChildren,
} from 'react';
import { PluginTracking } from './PluginTracking';

/**
 * Tracks events triggered in children components
 **/
export function EventTracker(
  props: PropsWithChildren<IEventTracker | GATrackingEvent>,
) {
  const {
    plugin,
    eventCategory,
    eventLabel,
    eventAction,
    eventValue,
    childSelector,
    interaction,
  } = props as IEventTracker;

  const { name, parameters } = props as GATrackingEvent;
  /**
   * Passes details to Google analytics when the desired interaction is registered
   * @param event The event object
   * @param handler The element's own event handler
   */
  const handleInteraction = (event: MouseEvent, handler: MouseEventHandler) => {
    let shouldTrack = true;
    if (childSelector) {
      // do not track when a childSelector is given but does not match the interacted element or its children
      const matchingElements =
        event.currentTarget.querySelectorAll(childSelector);
      if (!matchingElements.length) {
        shouldTrack = false;
      } else {
        shouldTrack = Array.from(matchingElements.values()).some(
          element =>
            element === event.target || element.contains(event.target as Node),
        );
      }
    }

    if (shouldTrack) {
      if (name && parameters) {
        PluginTracking.sendEvent({
          name,
          parameters,
        });
      } else {
        PluginTracking.sendEvent({
          plugin,
          eventCategory,
          eventLabel,
          eventAction,
          eventValue,
        });
      }
    }

    if (handler) handler(event);
  };

  return (
    <>
      {React.Children.map(props.children, Child => {
        if (!React.isValidElement(Child)) {
          return Child;
        }
        const handler = (e: any) =>
          handleInteraction(e, Child.props[interaction]);
        return React.cloneElement(Child, { [interaction]: handler });
      })}
    </>
  );
}
