/* eslint-disable no-extend-native */
import '@backstage/cli/asset-types';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

declare global {
  interface Date {
    toISODateString(type?: 'long' | 'short'): string;
    getWeekNumber(): number;
  }
}
/**
 * hint key was removed in MUI v5 due to the team thinking it was not used.
 * @see https://mui.com/material-ui/migration/v5-style-changes/#restore-theme-palette-text-hint-key-if-needed
 * This is a workaround to add it back, until we don't need it anymore.
 * This color is defined in plugins/ui-components/src/theme.tsx
 */
declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    hint: string;
  }
}

/**
 * Converts a Date object to a string in ISO format. Example: 2021-12-31 23:59
 */
Date.prototype.toISODateString = function toISODateString(type = 'long') {
  if (this.toString() === 'Invalid Date') return '';

  const dateString = this.toLocaleDateString('sv'); // uses the format YYYY-MM-DD
  const timestamp = this.toLocaleTimeString(); // uses CET time zone

  switch (type) {
    case 'short':
      return dateString;
    case 'long': {
      const time = timestamp.split(':').slice(0, 2).join(':');
      return `${dateString} ${time}`;
    }
    default:
      throw new Error(
        'Date.toISODateString param "type" value should be either "long" or "short"',
      );
  }
};

Date.prototype.getWeekNumber = function getWeekNumber() {
  if (this.toString() === 'Invalid Date') return 0;
  // Get the date of the first day of the year
  const yearStart = new Date(Date.UTC(this.getFullYear(), 0, 1));
  // Calculate the difference in milliseconds between the target date and the first day of the year
  const timeDifference = this.getTime() - yearStart.getTime();
  // Calculate the number of weeks by dividing the time difference by the milliseconds in a week
  return Math.ceil(timeDifference / (7 * 24 * 60 * 60 * 1000));
};

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
