import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Link } from '@backstage/core-components';
import { useAnalysisReport } from '../../hooks';
import { AnalysisGrade } from './components';

export const CodacyAnalysisGrade = ({
  appEntity,
}: {
  appEntity: IEntityApp;
}) => {
  const { report, loading } = useAnalysisReport({ entity: appEntity });

  if (loading) {
    return <CircularProgress />;
  }

  if (!report) {
    return (
      <Tooltip
        title={
          <>
            Code Quality analysis not available.{' '}
            <Link to="https://cloud.docs.zalando.net/howtos/codacy/">
              Add Codacy{' '}
            </Link>
            to your repository in order to get code quality analysis.
          </>
        }
      >
        <Typography>N/A</Typography>
      </Tooltip>
    );
  }

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={`/catalog/default/Component/${report?.repository.name}#code-quality-widget`}
    >
      <AnalysisGrade report={report} row />
    </Link>
  );
};
