import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(3, -2, 2),
  },
  red: {
    paddingRight: theme.spacing(1),
    color: 'red',
  },
  green: {
    paddingRight: theme.spacing(1),
    color: 'green',
  },
}));
