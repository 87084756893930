import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'plugin-ui-components';

export const useTagColorStyles = makeStyles(theme => ({
  head: {
    background: colors.semantic.tealLight,
  },
  schedule: {
    background: colors.semantic.infoDark,
  },
  retrigger: {
    background: colors.semantic.pendingLight,
  },
  dependency: {
    background: colors.semantic.runningLight,
  },
  ghe: {
    background: theme.palette.text.hint,
  },
}));
