import React from 'react';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Header, Page, Content, HeaderLabel } from '@backstage/core-components';
import SupportCategory from '../SupportCategory';
import { useQuery } from 'react-query';

export const SupportPage = (): JSX.Element => {
  // Async function to dynamically import static support data (used to avoid leaking support data in the bundle)
  const { data: supportData, isLoading } = useQuery(
    'load-support-data',
    async () => {
      return {
        softwareDevelopmentSupportList: (
          await import('../supportLists/softwareDevelopment')
        ).default,
        dataEngineeringScienceAnalysisSupportList: (
          await import('../supportLists/dataEngineeringScienceAnalysis')
        ).default,
      };
    },
  );

  return (
    <Page themeId="support">
      <Header
        title="Support for Builder tooling & Services"
        subtitle="These links are provided by the owners of the tooling and therefore your most reliable channel for support."
      >
        <HeaderLabel label="Owner" value="Team Pitchfork" />
      </Header>
      <Content>
        {isLoading && <LinearProgress />}
        {!isLoading && supportData && (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <SupportCategory
                title="Software Development"
                list={supportData.softwareDevelopmentSupportList}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SupportCategory
                title="Data Engineering, Science and Analysis"
                list={supportData.dataEngineeringScienceAnalysisSupportList}
              />
            </Grid>
          </Grid>
        )}
      </Content>
    </Page>
  );
};
