import React from 'react';
import { useAsync } from 'react-use';
import { useApi } from '@backstage/core-plugin-api';
import { Content, Progress } from '@backstage/core-components';
import Alert from '@mui/lab/Alert';
import { OnCallParticipantRef, Schedule } from '../../types';
import { opsgenieApiRef } from '../../api';
import { SchedulesGrid } from './SchedulesGrid';

export type ResponderTitleFormatter = (
  responder: OnCallParticipantRef,
  schedule: Schedule,
) => string;

export type OnCallListProps = {
  cardsPerPage?: number;
  responderFormatter?: ResponderTitleFormatter;
};

export const OnCallList = ({
  cardsPerPage,
  responderFormatter,
}: OnCallListProps) => {
  const opsgenieApi = useApi(opsgenieApiRef);
  const { value, loading, error } = useAsync(
    async () => await opsgenieApi.getSchedules(),
    [],
  );

  const allSchedules = React.useMemo(
    () => value?.filter(s => s.enabled),
    [value],
  );

  if (loading) {
    return <Progress />;
  } else if (error) {
    return (
      <Alert data-testid="error-message" severity="error">
        {error.message}
      </Alert>
    );
  }

  return (
    <Content>
      <SchedulesGrid
        schedules={allSchedules || []}
        cardsPerPage={cardsPerPage || 12}
        responderFormatter={responderFormatter}
      />
    </Content>
  );
};
