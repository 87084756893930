import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AboutField, UserReference } from 'plugin-ui-components';
import { UserTag, getFormattedShortDateTime } from 'plugin-cdp';
import { Analysis } from '../../../types';

type Props = {
  report?: Analysis;
  user?: string;
};

export const LastAnalysis: React.FC<Props> = ({ user, report }) => {
  return (
    <Grid item xs={12}>
      <AboutField label="Last analysis:">
        {report && (
          <Box>
            <Typography variant="caption">
              {getFormattedShortDateTime(
                report.lastAnalysedCommit.endedAnalysis,
              )}
            </Typography>
            <br />
            <Typography variant="caption">triggered by </Typography>
            {user ? (
              <UserReference user={user} displayType="full_name" />
            ) : (
              <UserTag user={report.lastAnalysedCommit.authorEmail} />
            )}
            <br />
            <Typography variant="caption">
              {report.lastAnalysedCommit.message}
            </Typography>
          </Box>
        )}
      </AboutField>
    </Grid>
  );
};
