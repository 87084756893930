import makeStyles from '@mui/styles/makeStyles';

type ClassKey = 'inputRoot' | 'filterWrapper';
export const useStyles = makeStyles<Theme, {}, ClassKey>((theme: Theme) => ({
  filterWrapper: {
    borderRadius: 4,
  },
  inputRoot: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
