import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectProps } from '@mui/material/Select';
import { Duration } from '../../types';
import { formatLastTwoLookaheadQuarters } from '../../utils/formatters';
import { findAlways } from '../../utils/assert';
import { useSelectStyles as useStyles } from '../../utils/styles';
import { useLastCompleteBillingDate } from '../../hooks';
import { ModeOption } from '../CostOverviewModeSelect/CostOverviewModeSelect';
import { EventTracker } from 'plugin-ui-components';

export type PeriodOption = {
  value: Duration;
  label: string;
};

export function getDefaultOptions(
  lastCompleteBillingDate: string,
): PeriodOption[] {
  return [
    {
      value: Duration.P6W,
      label: 'Past 6 weeks',
    },
    {
      value: Duration.P90D,
      label: 'Past 6 Months',
    },
    {
      value: Duration.P30D,
      label: 'Past 60 Days',
    },
    {
      value: Duration.P3M,
      label: formatLastTwoLookaheadQuarters(lastCompleteBillingDate),
    },
    {
      value: Duration.P1W,
      label: 'Past two weeks',
    },
  ];
}

type PeriodSelectProps = {
  duration: Duration;
  mode?: string;
  onSelect: (duration: Duration) => void;
  options?: PeriodOption[];
};

export const PeriodSelect = ({
  duration,
  mode,
  onSelect,
  options,
}: PeriodSelectProps) => {
  const classes = useStyles();
  const lastCompleteBillingDate = useLastCompleteBillingDate();
  const optionsOrDefault =
    options ?? getDefaultOptions(lastCompleteBillingDate);

  const handleOnClick = (o: ModeOption) => {
    onSelect(o.value as Duration);
  };

  const renderValue: SelectProps['renderValue'] = value => {
    const option = findAlways(optionsOrDefault, o => o.value === value);
    return <b>{option.label}</b>;
  };

  const isDisabled = (option: string): boolean => {
    if (mode === 'total' && option !== Duration.P6W) {
      return true;
    }

    if (mode === 'nakadi' || mode === 'scalyr') {
      if (option !== Duration.P6W && option !== Duration.P1W) {
        return true;
      }
    }
    return false;
  };

  return (
    <EventTracker
      interaction="onClick"
      plugin="cost-insights"
      eventCategory="Cost Period"
      eventAction="click"
      eventLabel="clicked on period filter"
    >
      <Select
        className={classes.select}
        variant="outlined"
        value={duration}
        renderValue={renderValue}
        data-testid="period-select"
        disabled={optionsOrDefault.length === 1}
      >
        {optionsOrDefault.map((option, i) => (
          <div key={i}>
            <EventTracker
              interaction="onClick"
              plugin="cost-insights"
              eventCategory="Cost Period"
              eventAction="click"
              eventLabel={`clicked on ${option.value}`}
            >
              <MenuItem
                className={classes.menuItem}
                key={option.value}
                value={option.value}
                data-testid={`period-select-option-${option.value}`}
                onClick={() => handleOnClick(option)}
                disabled={isDisabled(option.value)}
              >
                {option.label}
              </MenuItem>
            </EventTracker>
          </div>
        ))}
      </Select>
    </EventTracker>
  );
};
