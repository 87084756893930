import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectProps } from '@mui/material/Select';
import { findAlways } from '../../utils/assert';
import { useSelectStyles as useStyles } from '../../utils/styles';
import { EventTracker } from 'plugin-ui-components';

export type ModeOption = {
  value: string;
  label: string;
};

export function getDefaultOptions(): ModeOption[] {
  return [
    {
      value: 'total',
      label: 'All',
    },
    {
      value: 'kubernetes',
      label: 'Kubernetes cost',
    },
    {
      value: 'aws',
      label: 'AWS Cost',
    },
    {
      value: 'nakadi',
      label: 'Nakadi Cost',
    },
    {
      value: 'scalyr',
      label: 'Scalyr Cost',
    },
  ];
}

type ModeSelectProps = {
  mode: string;
  onSelect: (mode: string) => void;
  options?: ModeOption[];
};

export const CostOverviewModeSelect = ({
  mode,
  onSelect,
  options,
}: ModeSelectProps) => {
  const classes = useStyles();
  const optionsOrDefault = options ?? getDefaultOptions();

  const handleOnClick = (o: ModeOption) => {
    onSelect(o.value);
  };

  const renderValue: SelectProps['renderValue'] = value => {
    const option = findAlways(optionsOrDefault, o => o.value === value);
    return <b>{option.label}</b>;
  };

  return (
    <Select
      className={classes.select}
      variant="outlined"
      value={mode}
      renderValue={renderValue}
      data-testid="cost-overview-mode-select"
    >
      {optionsOrDefault.map((option, i) => (
        <div key={i}>
          <EventTracker
            interaction="onClick"
            plugin="cost-insights"
            eventCategory="Cost Mode"
            eventAction="click"
            eventLabel={`clicked on ${option.value}`}
          >
            <MenuItem
              className={classes.menuItem}
              key={option.value}
              value={option.value}
              data-testid={`cost-overview-mode-select-option-${option.value}`}
              onClick={() => handleOnClick(option)}
            >
              {option.label}
            </MenuItem>
          </EventTracker>
        </div>
      ))}
    </Select>
  );
};
