import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import { useApi } from '@backstage/core-plugin-api';
import { AccessControlCard } from '../AccessControlCard';
import { AccessControlContext } from '../../context';
import { OAuthClientItem } from './OAuthClientItem';
import { ConfidentialityItem, OAuthExtraContent } from './OAuthExtraContent';
import ConfirmationDialog from '../ConfirmationDialog';
import { essentialsApiRef, mintStorageApiRef } from '../../../../api';
import { IDetailsResourceTypeScope } from '../../../../api/types/payload';
import { useSearchParams } from 'react-router-dom';
import { useNavigationBack } from '../../useNavigationBack';

export function OAuthClient() {
  const mintApi = useApi(mintStorageApiRef);
  const essentialsApi = useApi(essentialsApiRef);
  const [updateState, setUpdateState] = React.useState<{
    pending: boolean;
    success: boolean;
  }>({ pending: false, success: true });
  const [visibleData, setVisibleData] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [confirmationData, setConfirmationData] = React.useState<
    Record<string, any>
  >({
    scopesToAdd: [],
    scopesToRemove: [],
    redirectUrl: null,
    isClientConfidential: null,
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const [_, setSearchParams] = useSearchParams();

  const handleBack = useNavigationBack({
    page: 'oAuthClient',
    searchParamsKeys: ['editOAuthClient'],
  });

  const {
    appName,
    appData,
    editState,
    setEditState,
    loadAppData,
    hasEditPermission,
  } = React.useContext(AccessControlContext);

  const onEdit = () => {
    setEditState({ ...editState, oAuthClient: true });
    setSearchParams({ editOAuthClient: 'true' });
  };
  const onCancel = () => handleBack();

  const loadAppOAuthScopes = async () => {
    const { scopes } = appData[0];
    const appOAuthScopes: {
      id: string;
      scopes: IDetailsResourceTypeScope[];
    }[] = [];
    for (const scope of scopes) {
      // Prevent to add same resources multiple times
      if (!appOAuthScopes.some(item => item.id === scope.resource_type_id)) {
        const scopesFromResource = await essentialsApi.getScopesFromResource(
          scope.resource_type_id,
        );

        const oAuthScopes = scopesFromResource.filter(
          item => item.is_resource_owner_scope,
        );

        if (!!oAuthScopes.length) {
          appOAuthScopes.push({
            id: scope.resource_type_id,
            scopes: oAuthScopes,
          });
        }
      }
    }
    setVisibleData([{ appOAuthScopes }]);
  };

  const loadAllOAuthScopes = async () => {
    setIsLoading(true);
    try {
      const resources = await essentialsApi.getResourceTypes();
      for (const resource of resources) {
        if (resource.id) {
          const scopes = await essentialsApi.getScopesFromResource(resource.id);
          const oAuthScopes = scopes.filter(
            item => item.is_resource_owner_scope,
          );
          if (!!oAuthScopes.length) {
            setVisibleData(prev => [
              ...prev,
              {
                id: resource.id,
                name: resource.name,
                scopes: oAuthScopes,
              },
            ]);
          }
        }
      }
    } catch {
      setHasError(true);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (editState.oAuthClient) {
      loadAllOAuthScopes();
    } else {
      if (appData.length) loadAppOAuthScopes();
    }
    return () => setVisibleData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.length, editState]);

  const showConfirmationModal = (updatedData: any, extraUpdatedData: any) => {
    if (!!updatedData.length || extraUpdatedData) {
      setOpen(true);
    } else {
      setEditState({ ...editState, oAuthClient: false });
      return;
    }

    if (!!updatedData.length) {
      const [scopesToRemove, scopesToAdd] = updatedData.reduce(
        (acc: any, curr: any) => {
          const { resource_type_id, scope_id, selected } = curr;
          acc[+selected].push({ resource_type_id, scope_id });
          return acc;
        },
        [[], []],
      );

      setConfirmationData(prev => {
        return {
          ...prev,
          scopesToAdd,
          scopesToRemove,
        };
      });
    }

    if (extraUpdatedData) {
      let {
        redirect_url: redirectURL,
        is_client_confidential: isClientConfidential,
      } = extraUpdatedData;

      if ([undefined, appData[0].redirect_url].includes(redirectURL)) {
        redirectURL = appData[0].redirect_url;
      }

      if (
        [undefined, appData[0].is_client_confidential].includes(
          isClientConfidential,
        )
      ) {
        isClientConfidential = appData[0].is_client_confidential;
      }

      setConfirmationData(prev => {
        return {
          ...prev,
          redirectURL,
          isClientConfidential,
        };
      });
    }
  };

  const handleSave = () => {
    setUpdateState({ ...updateState, pending: true });

    const currentScopes = appData[0].scopes;
    const { scopesToAdd, scopesToRemove, redirectURL, isClientConfidential } =
      confirmationData;
    scopesToRemove.forEach((scopeToRemove: IAccessControl.ScopeSummary) => {
      const index = currentScopes.findIndex(
        currentScope =>
          currentScope.resource_type_id === scopeToRemove.resource_type_id &&
          currentScope.scope_id === scopeToRemove.scope_id,
      );
      if (index !== -1) currentScopes.splice(index, 1);
    });

    const newScopes = [...currentScopes, ...scopesToAdd];

    mintApi
      .updateOAuthData(appName, appData[0], {
        scopes: newScopes,
        redirect_url: redirectURL,
        is_client_confidential: isClientConfidential,
      })
      .then(ok => {
        if (ok) {
          setUpdateState(prev => ({ ...prev, success: true }));
          setEditState({ ...editState, oAuthClient: false });
          loadAppData();
          setOpen(false);
        } else {
          setUpdateState(prev => ({ ...prev, success: false }));
        }
      })
      .catch(() => setUpdateState(prev => ({ ...prev, success: false })))
      .finally(() => setUpdateState(prev => ({ ...prev, pending: false })));
  };

  return (
    <>
      <AccessControlCard
        trackingPlugin="application-registry"
        title="OAuth Client"
        data={visibleData}
        isLoading={isLoading}
        onSave={showConfirmationModal}
        onEdit={onEdit}
        onCancel={onCancel}
        canEdit={editState.oAuthClient}
        render={(
          app,
          index,
          editable,
          updatedData,
          setUpdatedData,
          selectAll,
        ) => {
          return (
            <OAuthClientItem
              key={index}
              index={index}
              editable={editable}
              updatedData={updatedData}
              setUpdatedData={setUpdatedData}
              selectedScopes={appData[0].scopes}
              selectAll={selectAll}
              onEdit={onEdit}
              hasEditPermission={hasEditPermission}
              {...app}
            />
          );
        }}
        extraContent={(editable, extraUpdatedData, setExtraUpdatedData) => (
          <OAuthExtraContent
            isLoading={isLoading}
            editable={editable}
            extraUpdatedData={extraUpdatedData}
            setExtraUpdatedData={setExtraUpdatedData}
            onEdit={onEdit}
            hasEditPermission={hasEditPermission}
            hasError={hasError}
            {...appData[0]}
          />
        )}
      />
      <ConfirmationDialog
        open={open}
        onClose={() => {
          setUpdateState({ pending: false, success: true });
          setOpen(false);
        }}
        onSave={handleSave}
      >
        <Box height="5px">{updateState.pending && <LinearProgress />}</Box>
        {!updateState.success && (
          <Alert severity="error">
            Failed updating configurations. Try again.
          </Alert>
        )}
        <Box maxHeight="300px" overflow="scroll" marginTop="1rem">
          {confirmationData.redirectURL !== '' &&
            confirmationData.redirectURL !== appData[0]?.redirect_url && (
              <Box marginBottom="1rem">
                <Typography>
                  Users will be <strong>redirected to</strong>:
                </Typography>
                <Typography>{confirmationData.redirectURL}</Typography>
              </Box>
            )}
          {!confirmationData.redirectURL && appData[0]?.redirect_url && (
            <Box marginBottom="1rem">
              <Typography>
                The redirect URL will be <strong>removed</strong>
              </Typography>
            </Box>
          )}
          {confirmationData.isClientConfidential !==
            appData[0]?.is_client_confidential && (
            <Box marginBottom="1rem">
              <Typography>
                The <strong>client</strong> will be:
              </Typography>
              <ConfidentialityItem
                confidential={confirmationData.isClientConfidential}
              />
            </Box>
          )}
          {!!confirmationData.scopesToAdd.length && (
            <Box marginBottom="1rem">
              <Typography>
                These will be <strong>added</strong>:
              </Typography>
              <ul>
                {confirmationData.scopesToAdd.map(
                  ({
                    resource_type_id,
                    scope_id,
                  }: IAccessControl.ScopeSummary) => {
                    return (
                      <li key={`${resource_type_id}-${scope_id}`}>
                        <Typography>
                          {scope_id} ({resource_type_id})
                        </Typography>
                      </li>
                    );
                  },
                )}
              </ul>
            </Box>
          )}
          {!!confirmationData.scopesToRemove.length && (
            <Box>
              <Typography>
                These will be <strong>removed</strong>:
              </Typography>
              <ul>
                {confirmationData.scopesToRemove.map(
                  ({
                    resource_type_id,
                    scope_id,
                  }: IAccessControl.ScopeSummary) => {
                    return (
                      <li key={`${resource_type_id}-${scope_id}`}>
                        <Typography>
                          {scope_id} ({resource_type_id})
                        </Typography>
                      </li>
                    );
                  },
                )}
              </ul>
            </Box>
          )}
        </Box>
      </ConfirmationDialog>
    </>
  );
}
