import React, { useState } from 'react';
import UndoIcon from '@mui/icons-material/Undo';

import { RollbackDialog } from './RollbackDialog';
import { Tooltip } from '../../../common';
import Button from '@mui/material/Button';

function RollbackAction() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function open() {
    setIsDialogOpen(true);
  }

  function close() {
    setIsDialogOpen(false);
  }

  return (
    <>
      <Tooltip title="See how to quickly rollback latest production pipeline">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={open}
          data-testid="main-button"
          style={{ boxShadow: 'none' }}
        >
          <UndoIcon />
          &nbsp;Rollback plans
        </Button>
      </Tooltip>

      <RollbackDialog isOpen={isDialogOpen} handleClose={close} />
    </>
  );
}

export const RollbackActionComponent = React.memo(RollbackAction, _ => true);
