import React from 'react';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { IWithRepoInfo } from '../../../../models';
import { useServices } from '../../../../services';
import { RunGroupsItemHeader, RunGroupsItemBody } from '../../../common';

export const RunGroupsList = observer(() => {
  const { runGroupsMainPageService: runGroupsService, preferencesService } =
    useServices();

  const getRepoUrl = (run: IWithRepoInfo) =>
    [run.repoInfo.alias, run.repoInfo.organization, run.repoInfo.name]
      .filter(Boolean)
      .join('/') || '/';

  const getOrgUrl = (run: IWithRepoInfo) =>
    [run.repoInfo.alias, run.repoInfo.organization].filter(Boolean).join('/') ||
    '/';

  return (
    <Box flex={1} margin={0} padding={0}>
      {runGroupsService.runGroups.map(item => (
        <div key={item.id} data-testid="run-groups-item">
          <RunGroupsItemHeader
            run={item}
            repoUrl={getRepoUrl}
            orgUrl={getOrgUrl}
          />
          <RunGroupsItemBody
            run={item}
            repoUrl={getRepoUrl}
            reload={runGroupsService.reload}
            preferencesService={preferencesService}
          />
        </div>
      ))}
    </Box>
  );
});
