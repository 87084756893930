import React, { useContext } from 'react';
import { Link } from '@backstage/core-components';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { FilterGroupsContext } from '../../context';
import { EventTracker } from '../Tracking';
import { clickToerChip } from './tracking';

interface Props {
  toer: string;
}

export const ToerChip = React.memo(({ toer }: Props) => {
  let searchParams;
  // Keep existing filters if already inside application page, otherwise set default filters
  // if chip is displayed ina different page (i.e application details)
  if (window.location.pathname === '/applications')
    searchParams = new URLSearchParams(window.location.search);
  else searchParams = new URLSearchParams('group=all&status=yes');

  searchParams.set('cyberWeekToer', toer);
  const context = useContext(FilterGroupsContext);
  const { setCyberWeekToerFilter } = context;
  const title =
    toer === 'NONE'
      ? 'No TOER available'
      : 'CyberWeek TOER Organization. Click to filter by this org';
  const chipClassName = toer === 'NONE' ? 'error' : 'success';
  return (
    <EventTracker {...clickToerChip()}>
      <Tooltip title={title}>
        <Link
          to={`/applications?${searchParams.toString()}`}
          color="textPrimary"
          onClick={() => setCyberWeekToerFilter([toer])}
        >
          <Chip
            className={`info-chip ${chipClassName}`}
            label={`org:${toer.toUpperCase()}`}
            size="small"
            variant="outlined"
            clickable
          />
        </Link>
      </Tooltip>
    </EventTracker>
  );
});
