import React from 'react';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Tech Teams';
const id = 'teams-filter';

export const TechTeamsFilter = React.memo(() => {
  const { techTeamsFilter, setTechTeamsFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" margin="dense" size="small">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        label={label}
        labelId={id}
        value={techTeamsFilter || ''}
        classes={{ select }}
        onChange={ev => setTechTeamsFilter(ev.target.value as any)}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        <MenuItem value="yes">Yes</MenuItem>
        <MenuItem value="no">No</MenuItem>
      </Select>
      <Typography variant="caption" sx={{ mt: 0.5 }}>
        Displays only teams that contain a tech ID/Alias
      </Typography>
    </FormControl>
  );
});
