import React from 'react';
import MuiPopover, { type PopoverProps } from '@mui/material/Popover';

type Props = Omit<PopoverProps, 'open' | 'children' | 'content'> & {
  content: React.ReactNode;
  children: React.ReactChild;
};

// TODO: Add unit test and storybook
export const Popover: React.FC<Props> = ({
  content,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
  },
  children,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);

  const onClick = (ev: React.MouseEvent<HTMLSpanElement>) => {
    // Open popover
    if (!anchorEl) {
      setAnchorEl(ev.currentTarget);
    }

    /**
     * This is a workaround for an issue in Material-UI Popover component
     * where links are being blocked from opening.
     */
    if (ev.target instanceof HTMLAnchorElement) {
      window.open(ev.target.href, ev.target.target, 'noopener,noreferrer');
    }
  };

  const onClose: PopoverProps['onClose'] = (ev, reason) => {
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose(ev, reason);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <div
      onClick={onClick}
      role="contentinfo"
      style={{ display: 'inline-block' }}
    >
      {children}
      <MuiPopover
        {...props}
        anchorEl={anchorEl}
        children={content}
        open={!!anchorEl}
        onClose={onClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      />
    </div>
  );
};
