import React, { useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/lab/Alert';
import { loadTestingApiRef } from '../../api/LoadTestOrchestratorApi';
import { useStyles } from '../../StylingComponent/styles';
import { InfoTooltip } from 'plugin-ui-components';

interface CreateRampFormProps {
  currentVus: number;
  open: boolean;
  onClose: () => void;
  testRunId: string;
  showSnackbar: (message: string, severity: 'success' | 'error') => void;
}

export const CreateRampForm: React.FC<CreateRampFormProps> = ({
  currentVus,
  open,
  onClose,
  testRunId,
  showSnackbar,
}) => {
  const classes = useStyles();
  const loadTestingApi = useApi(loadTestingApiRef);
  const [targetVus, setTargetVus] = useState<string>('');
  const [stepVus, setStepVus] = useState<string>('');
  const [intervalSeconds, setIntervalSeconds] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);

    const parsedTargetVus = parseInt(targetVus, 10);
    const parsedStepVus = parseInt(stepVus, 10);
    const parsedIntervalSeconds = parseInt(intervalSeconds, 10);

    if (
      isNaN(parsedTargetVus) ||
      isNaN(parsedStepVus) ||
      isNaN(parsedIntervalSeconds)
    ) {
      setError('All values must be valid integers.');
      return;
    }

    if (parsedTargetVus < 0) {
      setError('Target VUs must not be less than 0.');
      return;
    }

    if (parsedIntervalSeconds < 30) {
      setError('Interval seconds should not be less than 30.');
      return;
    }

    if (parsedTargetVus > currentVus && parsedStepVus < 0) {
      setError(
        'Step VUs must be positive when target VUs is greater than current VUs.',
      );
      return;
    }

    if (parsedTargetVus < currentVus && parsedStepVus > 0) {
      setError(
        'Step VUs must be negative when target VUs is less than current VUs.',
      );
      return;
    }

    if (parsedStepVus === 0) {
      setError('Step VUs must not be 0 as it will not ramp up or down.');
      return;
    }

    try {
      await loadTestingApi.createRamp(
        testRunId,
        parsedTargetVus,
        parsedStepVus,
        parsedIntervalSeconds,
      );
      showSnackbar(
        `Ramp created successfully with step VUs: ${stepVus}, interval seconds: ${intervalSeconds}, target VUs: ${targetVus}`,
        'success',
      );
      onClose();
    } catch (err) {
      setError((err as Error).message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <>
        <DialogTitle id="form-dialog-title">
          <>
            Create Ramping of VUs{' '}
            <InfoTooltip>
              <p>
                This enables you to create a ramping of VUs for the specific
                test run. Only one ramping process can be created at a time.
                This can be stopped via a <strong>Stop</strong> button that is
                available as soon as the ramping process starts.
              </p>
              <p>
                <strong>Target VUs:</strong> The number of simulated users you
                would like to reach.
              </p>
              <p>
                <strong>Step VUs:</strong> The number of VUs to increase or
                decrease by (positive integer if you would like to ramp up or
                negative integer to ramp down).
              </p>
              <p>
                <strong>Interval seconds:</strong> The time between each step.
                This should not be less than 30 seconds.
              </p>
            </InfoTooltip>
          </>
        </DialogTitle>
      </>
      <DialogContent>
        {error && (
          <Alert severity="error" className={classes.alert}>
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Target VUs"
                variant="outlined"
                type="number"
                value={targetVus}
                onChange={e => setTargetVus(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Step VUs (+ or -)"
                type="number"
                variant="outlined"
                value={stepVus}
                onChange={e => setStepVus(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Interval Seconds"
                type="number"
                variant="outlined"
                value={intervalSeconds}
                onChange={e => setIntervalSeconds(e.target.value)}
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" type="submit">
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
};
