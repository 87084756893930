import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { getNextWeek, getPreviousWeek } from '../utils/time';
import { useStyles } from './styles';

export const WeekButton = ({
  handleDate,
}: {
  handleDate: (date: string) => void;
}) => {
  const [localDate, setLocalDate] = useState(new Date().toISOString());
  const style = useStyles();

  const [weekNumber, setWeekNumber] = useState<number>();

  const isCurrentWeek = React.useCallback(() => {
    const currentWeek = new Date().getWeekNumber();
    return currentWeek === weekNumber;
  }, [weekNumber]);

  useEffect(() => {
    handleDate(localDate);
    setWeekNumber(new Date(localDate).getWeekNumber());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDate]);

  return (
    <Paper>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        className={style.weekButton}
      >
        <ArrowBack
          onClick={() => setLocalDate(getPreviousWeek(localDate))}
          className={style.arrow}
        />
        {isCurrentWeek() && (
          <Chip
            label="TODAY"
            variant="outlined"
            color="primary"
            size="small"
            className={style.chip}
          />
        )}
        <Typography
          variant="body2"
          className={style.week}
        >{`Week ${weekNumber}  -  ${localDate.split('T')[0]}`}</Typography>
        <ArrowForward
          onClick={() => setLocalDate(getNextWeek(localDate))}
          className={style.arrow}
        />
      </Box>
    </Paper>
  );
};
