import makeStyles from '@mui/styles/makeStyles';

export const useReferenceStyles = makeStyles(theme => ({
  link: {
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  icon: {
    width: '13px',
    height: '13px',
    verticalAlign: 'middle',
    marginBottom: '2px',
  },
}));

export const useProblemStyles = makeStyles(theme => ({
  chip: {
    margin: '0 4px',
    height: '18px',
    backgroundColor: '#2196f314',
    color: theme.palette.info.main,
    '&:hover': {
      cursor: 'help',
    },
  },
}));
