import React, { useEffect, useRef } from 'react';
import { useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import { colors } from 'plugin-ui-components';
import { StatusIcons } from '../StatusIcons';
import { ScrollableBox, Tooltip } from '../../../common';

import { useServices } from '../../../services';
import { SIDEBAR_HEADER_HEIGHT, VALIDATION_SEVERITY } from '../../../constants';
import { ValidationProblemType } from '../../../models/helpers';
import { getColorByStatus } from '../../../utils/pipeline';

import * as S from './styles';

export const Sidebar = observer(() => {
  const routes = useRoutes([{ path: 'steps/:stepId/*' }]);
  const { runService } = useServices();
  const currentItem = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (currentItem.current) {
      currentItem.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentItem]);

  const isSelectedStep = (index: number) =>
    routes?.props?.match?.params?.stepId === index.toString();

  return (
    <S.Sidebar>
      <S.Header>
        <S.HeaderItem
          to="./run"
          data-active={routes?.props?.value?.params?.stepId === undefined}
        >
          <S.Status
            style={{ backgroundColor: getColorByStatus(runService.run.status) }}
          />
          <S.HeaderContent title={runService.run.build_version}>
            <strong>{runService.run.build_version}</strong>
          </S.HeaderContent>
        </S.HeaderItem>
      </S.Header>

      <ScrollableBox height={`calc(100% - ${SIDEBAR_HEADER_HEIGHT})`}>
        <S.Heading>Steps</S.Heading>

        {runService.run.steps.map((step, i) => (
          <S.Item
            ref={isSelectedStep(i) ? currentItem : null}
            to={`./steps/${i}`}
            key={step.id}
            data-active={isSelectedStep(i)}
          >
            <S.Icon style={{ color: getColorByStatus(step.status) }}>
              <S.StatusLine
                style={{ backgroundColor: getColorByStatus(step.status) }}
              />
              <StatusIcons status={step.status} size="small" />
            </S.Icon>

            <S.Content>
              {getStepName(
                step.id,
                step.run.hasValidationErrors,
                step.run.hasValidationwarnings,
              )}
              <small>{step.type}</small>
            </S.Content>
          </S.Item>
        ))}
      </ScrollableBox>
    </S.Sidebar>
  );
});

function getStepName(stepID: string, hasErrors: boolean, hasWarnings: boolean) {
  if (hasErrors) {
    return (
      <StepProblemBadge stepID={stepID} type={VALIDATION_SEVERITY.ERROR} />
    );
  } else if (hasWarnings) {
    return (
      <StepProblemBadge stepID={stepID} type={VALIDATION_SEVERITY.WARNING} />
    );
  }
  return <strong>{stepID}</strong>;
}

function StepProblemBadge({
  stepID,
  type,
}: {
  stepID: string;
  type: ValidationProblemType;
}) {
  const iconStyles = {
    display: 'inline',
    height: 15,
    width: 'auto',
    verticalAlign: 'middle',
    marginLeft: 2,
    marginBottom: 2,
  };

  return (
    <span>
      <strong title={`Validation ${type}s detected`}>{stepID}</strong>

      <Tooltip title="Step problems found">
        {type === 'error' ? (
          <ReportIcon color="error" style={{ ...iconStyles }} />
        ) : (
          <WarningIcon
            style={{ ...iconStyles, color: colors.semantic.warningBase }}
          />
        )}
      </Tooltip>
    </span>
  );
}
