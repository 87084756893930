import { darken } from '@mui/material/styles';
import styled from '@mui/styles/styled';

export const NotFound = styled('div')({
  position: 'relative',
  zIndex: 1,
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  textAlign: 'center',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 15px',
});

export const Title = styled('h1')({
  marginBottom: '30px',
});

export const Text = styled('p')(props => ({
  margin: 0,
  color: props.theme.palette.text.secondary,

  '& a': {
    display: 'block',
    color: props.theme.palette.link,
    textDecoration: 'underline',
  },
}));

export const ImageContainer = styled('figure')({
  width: '100%',
});

export const Image = styled('svg')(props => ({
  width: '100%',
  zIndex: -1,
  position: 'relative',
  color: props.theme.palette.primary.main,

  ['& polygon']: {
    fill: props.theme.palette.background.default,
  },
}));

export const ImageBg = styled('path')(props => ({
  fill: darken(props.theme.palette.background.default, 0.02),
}));
