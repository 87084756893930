import React from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { Progress } from '@backstage/core-components';
import { useServices } from '../../../../services';
import { Text } from '../../../../common';
import { useArtifacts } from './useArtifacts';
import { DockerArtifacts } from './DockerArtifacts';
import { PipelineScopedArtifacts } from './PipelineScopedArtifacts';

import {
  GithubReleases,
  MavenArtifacts,
  NPMArtifacts,
  PythonArtifacts,
  S3Artifacts,
} from './OtherArtifacts';

export const Artifacts = observer(() => {
  const { runId = '', stepId = '' } = useParams();
  const { runService } = useServices();

  const { id: stepRunId, status } = runService.step?.run;

  const [{ loading, error, value }] = useArtifacts(
    runId,
    stepId,
    stepRunId,
    {
      stepId: runService.step?.id,
    },
    status,
  );

  if (error) {
    return (
      <Text error padding={2}>
        An error occurred while retrieving artifacts, refresh the page to try
        again.
      </Text>
    );
  }

  if (loading && !value) {
    return (
      <Box padding={2}>
        <Progress />
      </Box>
    );
  }

  if (!value) {
    return <Box padding={2}>No artifacts produced by this step.</Box>;
  }

  return (
    <Box padding={2}>
      {Boolean(value.docker_images_pushed.length) && (
        <DockerArtifacts images={value.docker_images_pushed} />
      )}

      {Boolean(value.s3_artifacts.length) && (
        <S3Artifacts artifacts={value.s3_artifacts} />
      )}

      {Boolean(value.npm_artifacts.length) && (
        <NPMArtifacts artifacts={value.npm_artifacts} />
      )}

      {Boolean(value.pipeline_scoped_artifacts.length) && (
        <PipelineScopedArtifacts
          artifacts={value.pipeline_scoped_artifacts}
          runId={runId}
          stepId={stepId}
          stepRunId={stepRunId}
        />
      )}

      {Boolean(value.git_releases.length) && (
        <GithubReleases artifacts={value.git_releases} />
      )}

      {Boolean(value.maven_artifacts.length) && (
        <MavenArtifacts artifacts={value.maven_artifacts} />
      )}

      {Boolean(value.python_artifacts.length) && (
        <PythonArtifacts artifacts={value.python_artifacts} />
      )}
    </Box>
  );
});
