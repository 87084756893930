import React from 'react';
import { useLocalStorage } from 'react-use';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/lab/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { TrackedIconButton } from '../Tracking';

interface Props {
  message: React.ReactNode;
  variant: 'standard' | 'filled' | 'outlined';
  severity: 'success' | 'info' | 'warning' | 'error';
  id: string;
  shouldUseLocalStorage?: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },

    '& [class*=MuiAlert-root]': {
      marginBottom: theme.spacing(2),
      borderColor: 'var(--mui-info-border)',
      color: 'var(--mui-info-text)',
    },
  },
}));

export function DismissibleBanner({
  message,
  variant,
  severity,
  id,
  onClose,
  shouldUseLocalStorage = true,
  ...trackingProps
}: Props & IEventTracker) {
  const classes = useStyles();
  const [open, setOpen] = useLocalStorage<boolean>(`${id}Dismissible`, true);

  return (
    <div className={classes.root}>
      <Collapse in={!shouldUseLocalStorage || open}>
        <Alert
          action={
            <TrackedIconButton
              {...trackingProps}
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                onClose?.();
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </TrackedIconButton>
          }
          variant={variant}
          severity={severity}
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
}
