import React from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const TooltipDescription = ({
  subject,
  owner,
  name,
}: {
  subject: string;
  owner?: string;
  name?: string;
}) => {
  return (
    <span>
      {subject}
      This thresholds is defined on codacy's{' '}
      <Link
        href={`https://codacy.bus.zalan.do/ghe/${owner}/${name}/settings/quality`}
        rel="noopener noreferrer"
        target="_blank"
      >
        quality settings.
      </Link>
    </span>
  );
};

type AnalysisItemProps = {
  report?: string | number;
  title: string;
  description: string;
  owner?: string;
  name?: string;
};

export const AnalysisItem: React.FC<AnalysisItemProps> = ({
  report,
  title,
  description,
  owner,
  name,
}) => {
  if (report === undefined) return <></>;
  return (
    <Tooltip
      title={
        <TooltipDescription subject={description} owner={owner} name={name} />
      }
      style={{ cursor: 'pointer' }}
    >
      <Grid item>
        <Typography variant="h6">{title}</Typography>
        <Typography style={{ textAlign: 'center' }} variant="h5">
          {report}%
        </Typography>
      </Grid>
    </Tooltip>
  );
};
