import React from 'react';
import styled from '@mui/styles/styled';
import { fontSize } from '../Typography';

export const List = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: 0,
});

const StyledListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  padding: 0,
  margin: 0,

  ['&:not(:first-of-type)']: {
    marginTop: theme.spacing(1.5),
  },

  ['& a']: {
    color: theme.palette.link,
  },
}));

export const StyledListIcon = styled('figure')(({ theme }) => ({
  margin: 0,
  marginRight: theme.spacing(1.5),
  flexShrink: 0,

  ['&, & > svg']: {
    fontSize: fontSize.small,
    verticalAlign: 'middle',
  },
}));

type Props = {
  children: React.ReactNode;
  icon?: React.ReactNode;
};

export function ListItem({ icon, children }: Props) {
  return (
    <StyledListItem>
      {icon && <StyledListIcon>{icon}</StyledListIcon>}

      {children}
    </StyledListItem>
  );
}
