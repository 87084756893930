import React, {
  useEffect,
  useState,
  forwardRef,
  FormEventHandler,
  ChangeEvent,
} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { AlertFormProps, Entity } from '../../types';
import { KubernetesMigrationAlert } from '../alerts';
import { findAlways } from '../../utils/assert';

export type KubernetesMigrationDismissFormData = {
  services: Entity[];
};

export type KubernetesMigrationDismissFormProps = AlertFormProps<
  KubernetesMigrationAlert,
  KubernetesMigrationDismissFormData
>;

export const KubernetesMigrationDismissForm = forwardRef<
  HTMLFormElement,
  KubernetesMigrationDismissFormProps
>(({ onSubmit, disableSubmit, alert }, ref) => {
  const [services, setServices] = useState<Entity[]>(alert.data.services);

  const onFormSubmit: FormEventHandler = e => {
    /* Remember to prevent default form behavior */
    e.preventDefault();
    onSubmit({ services: services });
  };

  const onCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked) {
      const service = findAlways(
        alert.data.services,
        s => s.id === e.target.value,
      );
      setServices(prevServices => prevServices.concat(service));
    } else {
      setServices(prevServices =>
        prevServices.filter(p => p.id !== e.target.value),
      );
    }
  };

  /* Submit button is disabled by default. Use props.disableSubmit to toggle disabled state. */
  useEffect(() => {
    if (services.length) {
      disableSubmit(false);
    } else {
      disableSubmit(true);
    }
  }, [services, disableSubmit]);

  return (
    /* All custom forms must accept a ref and implement an onSubmit handler. */
    <form ref={ref} onSubmit={onFormSubmit}>
      <FormControl component="fieldset" fullWidth>
        <Typography color="textPrimary">
          <b>Or choose which services to dismiss this alert for.</b>
        </Typography>
        <FormGroup>
          {alert.data.services.map((service, index) => (
            <FormControlLabel
              key={`example-option-${index}`}
              label={service.id}
              value={service.id}
              control={
                <Checkbox
                  color="primary"
                  checked={services.some(p => p.id === service.id)}
                  onChange={onCheckboxChange}
                />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </form>
  );
});
