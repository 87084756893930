import React from 'react';
import Typography from '@mui/material/Typography';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PeopleIcon from '@mui/icons-material/People';
import UpdateIcon from '@mui/icons-material/Update';
import InfoIcon from '@mui/icons-material/Info';
import AppsIcon from '@mui/icons-material/Apps';
import TableChartIcon from '@mui/icons-material/TableChart';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useStyles } from './styles';
import cn from 'classnames';
import { TrackedLink } from 'plugin-ui-components';
import { clickOnResourceLink } from '../../../../tracking';

interface ResourceLinkProps {
  type: string;
  disabled?: boolean;
  link: string;
  domain: string;
}

export const resourceLinkTypes: Array<{
  type: string;
  icon: React.ReactNode;
  linkText: string;
}> = [
  {
    type: 'EXTERNALDOC',
    icon: <MenuBookIcon />,
    linkText: 'External (informative) documentation',
  },
  {
    type: 'INTERNALDOC',
    icon: <LibraryBooksIcon />,
    linkText: 'Internal (collaborative) documentation',
  },
  {
    type: 'KPIS',
    icon: <TrendingUpIcon />,
    linkText: 'KPIs',
  },
  {
    type: 'CUSTOMERS',
    icon: <PeopleIcon />,
    linkText: 'Customers',
  },
  {
    type: 'UPDATES',
    icon: <UpdateIcon />,
    linkText: 'Updates feed (zLife)',
  },
  {
    type: 'SLOS',
    icon: <InfoIcon />,
    linkText: 'SLOs (Tracing)',
  },
  {
    type: 'ARCHITECTURE',
    icon: <AppsIcon />,
    linkText: 'Architecture design overview',
  },
  {
    type: 'ROADMAP',
    icon: <TableChartIcon />,
    linkText: 'Roadmap',
  },
];

export const ResourceLink = ({
  type,
  link,
  disabled = false,
  domain,
}: ResourceLinkProps) => {
  const resourceLink = resourceLinkTypes.find(item => item.type === type);
  const styles = useStyles();
  return (
    <TrackedLink
      to={link}
      className={cn(styles.link, { disabled })}
      {...clickOnResourceLink(type, domain)}
    >
      {resourceLink?.icon}
      <Typography className={styles.linkText}>
        {resourceLink?.linkText}
      </Typography>
    </TrackedLink>
  );
};
