import React, { useCallback, useEffect, useState } from 'react';
import { EmptyState, InfoCard } from '@backstage/core-components';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Tab, { type TabProps } from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useApi } from '@backstage/core-plugin-api';
import { useParams } from 'react-router';
import { notebooksApiRef, RunResponse } from '../../api';
import {
  BaseLayout,
  DetailsCard,
  NavigateToLatest,
  NotebookActionBar,
  NotebookSnapshot,
} from '../../components';
import { InteractiveProps } from '../../components/NavigateToLatest/NavigateToLatest';

const entries = [
  { key: 'status', displayName: 'Status', type: 'status' },
  { key: 'created_at', displayName: 'Created At', type: 'time' },
  { key: 'last_modified_at', displayName: 'Updated At', type: 'time' },
  { key: 'created_by', displayName: 'Created By', type: 'user' },
  {
    key: 'notebook_path',
    displayName: 'Source notebook',
    type: 'notebook_path',
  },
  { key: 'kernel_name', displayName: 'Kernel' },
  { key: 'parameters', displayName: 'Params', type: 'kv_dict_table' },
  { key: 'error_message', displayName: 'Exception', type: 'error_message' },
];

type CustomTabProps = Omit<TabProps, 'content'> & { content: React.ReactNode };

export const RunDetails = (props: InteractiveProps) => {
  const { interactive } = props;
  const { scheduledNotebookId = '', runId = '' } = useParams();
  const [notebookName, setNotebookName] = useState('');
  const [details, setDetails] = useState<RunResponse>();
  const [tab, setTab] = useState(0);

  const notebookClient = useApi(notebooksApiRef);

  const getDetails = useCallback(async () => {
    const res = await notebookClient.getRunDetails(runId);
    setDetails(res);
  }, [runId, notebookClient]);

  useEffect(() => {
    if (runId !== 'latest') {
      getDetails();
    }
  }, [runId, getDetails]);

  useEffect(() => {
    const getScheduledNotebook = async () => {
      const res = await notebookClient.getScheduledNotebookDetails(
        scheduledNotebookId,
      );
      setNotebookName(res.name);
    };
    getScheduledNotebook();
  }, [scheduledNotebookId, notebookClient]);

  const onDelete = async (id: string) => {
    return await notebookClient.deleteRun(id, scheduledNotebookId);
  };

  const actionBarComponent = (showFullScreenButton: boolean) => {
    return (
      details && (
        <Grid item xs={12}>
          <NotebookActionBar
            s3Path={details.s3_path}
            notebook_path={details.notebook_path}
            id={details.id}
            name={details.name}
            parentId={details.scheduled_notebook_id}
            status={details.status}
            createdBy={details.created_by}
            isInteractive={details.is_interactive}
            shouldPublish={details.should_publish}
            archived={details.archived}
            showFullScreenButton={showFullScreenButton}
            onDelete={onDelete}
            onPollCallback={getDetails}
            showRunNow
          />
        </Grid>
      )
    );
  };

  const NotebookNotPublished = (
    <EmptyState missing="info" title="Notebook not published" description="" />
  );

  const tabSortCompareFn = (_t1: CustomTabProps, _t2: CustomTabProps) => {
    return details?.should_publish ? 0 : -1;
  };

  const tabs = [
    {
      label: 'Notebook',
      content: details?.should_publish ? (
        <Grid container>
          {actionBarComponent(true)}
          <NotebookSnapshot
            s3Path={details.s3_path}
            shouldHideCode={details?.should_hide_code}
          />
        </Grid>
      ) : (
        NotebookNotPublished
      ),
    },
    {
      label: 'Run Details',
      content: (
        <Grid container>
          {actionBarComponent(false)}
          <Grid item xs={12}>
            <InfoCard title="Run Details">
              <CardContent>
                <DetailsCard columns={entries} data={details} />
              </CardContent>
            </InfoCard>
          </Grid>
        </Grid>
      ),
    },
  ].sort(tabSortCompareFn);

  return runId === 'latest' || interactive ? (
    <NavigateToLatest
      parentEntityKey="scheduledNotebook"
      interactive={interactive}
      id={runId}
    />
  ) : (
    (details && (
      <BaseLayout notebookName={notebookName} executionName={details.name}>
        <div>
          <Tabs
            value={tab}
            onChange={(_, val: number) => setTab(val)}
            style={{ backgroundColor: 'var(--paper-bg)' }}
          >
            {tabs.map((t, i) => (
              <Tab key={i} label={t.label} value={i} style={{ height: 64 }} />
            ))}
          </Tabs>
          <Box role="tabpanel" p={3}>
            {tabs[tab]?.content}
          </Box>
        </div>
      </BaseLayout>
    )) ||
      null
  );
};
