import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const dataClassification: IAppReview.DataClassification[] = [
  'RED',
  'ORANGE',
  'YELLOW',
  'GREEN',
];
const options = dataClassification.map(option => option.toLowerCase());
const label = 'Data Classification';
const id = 'data-classification-filter';

export const DataClassificationFilter = React.memo(() => {
  const { dataClassificationFilter, setDataClassificationFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  return (
    <FormControl size="small" margin="none">
      <Autocomplete
        id={id}
        multiple
        options={options}
        value={dataClassificationFilter}
        classes={{ inputRoot }}
        onChange={(_: any, value) =>
          setDataClassificationFilter(value as IAppReview.DataClassification[])
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            margin="dense"
            size="small"
          />
        )}
      />
    </FormControl>
  );
});
