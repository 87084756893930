export const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'application-registry',
  eventCategory: 'Access Control Page',
  interaction: 'onClick',
  eventAction: 'click',
};

export const savingScope: IEventTracker = {
  ...common,
  eventLabel: 'Creating new scope in access control',
};

export const editingScope = (name: string): IEventTracker => ({
  ...common,
  eventLabel: `Scope '${name}' is being updated`,
});

export const cancelScope: IEventTracker = {
  ...common,
  eventLabel: `Cancel button for scope (edit/save) clicked`,
};
