import React from 'react';
import Chip from '@mui/material/Chip';

const modKey =
  typeof navigator === 'object' &&
  /Mac|iPod|iPhone|iPad/.test(navigator.platform)
    ? '⌘'
    : 'ctrl';

export const SearchModalChip = () => (
  <Chip
    label={<span>{modKey}+K</span>}
    size="small"
    style={{
      opacity: '.8',
      margin: 0,
      height: 20,
      cursor: 'inherit',
      color: '#000',
      backgroundColor: '#e0e0e0',
    }}
  />
);
