import React, { type PropsWithChildren, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmationDialogProps {
  title?: string;
  open: boolean;
  onSave: () => void;
  onClose: () => void;
  saveButtonText?: string;
}

export interface UseConfirmationReturnType {
  openConfirmation: () => void;
  closeConfirmation: () => void;
  isConfirmationOpen: boolean;
  ConfirmationDialog: React.FC<
    PropsWithChildren<Omit<ConfirmationDialogProps, 'open' | 'onClose'>>
  >;
}

function ConfirmationDialog({
  title = 'Confirmation',
  open,
  onClose,
  onSave,
  children,
  saveButtonText = 'Save',
}: PropsWithChildren<ConfirmationDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle
        component="h5"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>{children}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onSave}>
          {saveButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const useConfirmationModal = (): UseConfirmationReturnType => {
  const [isConfirmationOpen, setConfirmationOpen] = React.useState(false);

  const openConfirmation = useCallback(() => {
    setConfirmationOpen(true);
  }, []);

  const closeConfirmation = useCallback(() => {
    setConfirmationOpen(false);
  }, []);

  const ConfirmationDialogComponent = useCallback(
    ({
      children,
      title,
      onSave,
      saveButtonText,
    }: PropsWithChildren<
      Omit<ConfirmationDialogProps, 'open' | 'onClose'>
    >) => {
      return (
        <ConfirmationDialog
          title={title}
          open={isConfirmationOpen}
          onClose={closeConfirmation}
          saveButtonText={saveButtonText}
          onSave={() => {
            onSave();
          }}
        >
          {children}
        </ConfirmationDialog>
      );
    },
    [isConfirmationOpen, closeConfirmation],
  );

  return {
    openConfirmation,
    closeConfirmation,
    isConfirmationOpen,
    ConfirmationDialog: ConfirmationDialogComponent,
  };
};
