export default `
query Pipelines($account_id: String, $application_id: String, $archived: Boolean, $limit: Int, $offset: Int, $name: String, $pipeline_type: String, $tag_name: String, $with_runs: Boolean, $include: [String!]
) {
meta {
    application_ids
    deployment_environments {
      deployment_environment_id
      account_id
      type
      production_status
      name
    }
    totals
  }
  pipelines(
    account_id: $account_id
    application_id: $application_id
    archived: $archived
    limit: $limit
    name: $name
    offset: $offset
    pipeline_type: $pipeline_type
    tag_name: $tag_name
    with_runs: $with_runs
    include: $include
  ) {
    application_id
    archived
    created_at
    created_by {
      realm
      uid
    }
    deployment_environment {
      account_id
      deployment_environment_id
      name
      production_status
      type
    }
    description
    full_name
    last_run {
      status
      slug
      name
      updated_at
      created_at
      steps {
        full_name
        run_id
        details
        updated_at
        status
        step_id
        name
      }
    }
    meta
    name
    pipeline_id
    pipeline_type
    resource_id
    updated_at
    slug
    updated_by {
      realm
      uid
    }
  }
}`;
