import React from 'react';
import styled from '@mui/styles/styled';
import Typography from '@mui/material/Typography';
import { getThemeId } from 'plugin-core';
import { ChangedLineProps } from './ChangeTracker';
import { useImagePreview } from '../../hooks';

const Wrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(1),
}));

const PlusMinus = styled(Typography)(({ theme }) => ({
  marginRight: `${theme.spacing()}px`,
  width: `${theme.spacing(2)}px`,
  display: 'inline-block',
  flexShrink: 0,
}));

const LineWrapper = styled('div')(
  ({
    theme,
    deleted,
    themeID,
  }: {
    theme: Theme;
    deleted?: boolean;
    themeID?: string;
  }) => {
    let backgroucndColor = !!deleted ? '#ffeef0' : '#e6ffed';

    if (themeID === 'dark') {
      backgroucndColor = !!deleted ? '#a33942' : '#2a6531';
    }

    return {
      display: 'flex',
      backgroundColor: backgroucndColor,
      padding: theme.spacing(0, 1),
    };
  },
);

function Value({ value }: { value: any }) {
  const { value: previewImage } = useImagePreview(
    value instanceof File ? value : null,
  );

  if (previewImage) {
    return <img src={previewImage} alt="preview" />;
  }

  switch (true) {
    case typeof value === 'string':
      if (value.startsWith('data:image')) {
        return <img src={value} alt="preview" />;
      }
      return value;
    case Array.isArray(value):
      return value.join(', ');
    case value instanceof File:
      // This is a placeholder until the preview image is loaded
      return value.name;
    default:
      return String(value);
  }
}

export const ChangedLine = (props: ChangedLineProps) => {
  const themeID = getThemeId();
  const { label, changeType, oldValue, newValue } = props;
  let oldValueElement = null;
  let newValueElement = null;

  if (changeType === 'added' || changeType === 'changed') {
    newValueElement = (
      <LineWrapper themeID={themeID}>
        <PlusMinus variant="body1">+</PlusMinus>
        <Typography variant="body1">
          <Value value={newValue} />
        </Typography>
      </LineWrapper>
    );
  }
  if (changeType === 'changed' || changeType === 'deleted') {
    oldValueElement = (
      <LineWrapper themeID={themeID} deleted>
        <PlusMinus>-</PlusMinus>
        <Typography variant="body1">
          <Value value={oldValue} />
        </Typography>
      </LineWrapper>
    );
  }

  return (
    <Wrapper>
      <Typography variant="body1">{label}</Typography>
      {oldValueElement}
      {newValueElement}
    </Wrapper>
  );
};
