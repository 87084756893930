import React from 'react';
import { Progress } from '@backstage/core-components';

export function TestableProgress() {
  return (
    <div data-testid="testable-progress">
      <Progress />
    </div>
  );
}
