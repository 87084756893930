const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'catalog',
  eventCategory: 'Application Overview',
  eventAction: 'click',
  interaction: 'onClick',
};

export const clickLink = (label: string): IEventTracker => ({
  ...common,
  eventLabel: `${label}`,
});

export const clickToerChip = (): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Toer chip',
});
