import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Status';
const id = 'status-filter';
export const StatusFilter = React.memo(() => {
  const { statusFilter, setStatusFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" margin="dense" size="small">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        variant="outlined"
        size="small"
        className="select-filter"
        value={statusFilter || ''}
        onChange={ev => setStatusFilter(ev.target.value as any)}
        labelId={id}
        label={label}
        classes={{ select }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        <MenuItem value="yes">Active</MenuItem>
        <MenuItem value="no">Inactive</MenuItem>
      </Select>
    </FormControl>
  );
});
