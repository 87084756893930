import { cdpApiRef } from '../api';
import useAsync from 'react-use/esm/useAsync';
import { RunModel } from '../models';
import { useApi } from '@backstage/core-plugin-api';

export const usePipelineRun = (pipelineID: string) => {
  const cdpApi = useApi(cdpApiRef);

  const {
    loading,
    error,
    value: pipeline,
  } = useAsync(async () => {
    if (!pipelineID) return undefined;
    const response = await cdpApi.getRun(pipelineID);
    return new RunModel(response?.run);
  }, [pipelineID]);

  return {
    loading,
    pipeline,
    error,
  };
};
