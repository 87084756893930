export const common: Omit<IEventTracker, 'eventLabel' | 'eventAction'> = {
  plugin: 'domains',
  eventCategory: 'Domains Catalog Page',
  interaction: 'onClick',
};

export const createDomain = (): IEventTracker => ({
  ...common,
  eventLabel: 'Creating new domain in index page',
  eventAction: 'click',
});

export const discoverDomainWithDepth = (
  domainName: string,
  depth: number,
): IEventTracker => ({
  ...common,
  eventLabel: `Clicked on domain "${domainName}" of depth ${depth} in index page`,
  eventAction: 'click',
});

export const discoverDomain = (
  domainName: string,
  widgetName: string,
): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventLabel: `CLicked on domain "${domainName}" in Connected Domains/${widgetName}`,
  eventAction: 'click',
});

export const expandDomain = (
  domainName: string,
  depth: number,
): IEventTracker => ({
  ...common,
  eventLabel: `Expanding domain "${domainName}" of depth ${depth} in index page`,
  eventAction: 'click',
});

export const discoverDomainUser = (
  domainName: string,
  widgetName: string,
  relationToDomain: string,
): IEventTracker => ({
  ...common,
  eventLabel: `User Clicked on ${relationToDomain} of domain "${domainName}" in ${widgetName}`,
  eventAction: 'click',
});

export const discoverApplicationViaDomain = (
  appName: string,
  domainName: string,
): IEventTracker => ({
  ...common,
  eventLabel: `Clicked on application "${appName}" via domain "${domainName}"`,
  eventAction: 'click',
});

export const discoverOwnerViaDomain = (
  ownerName: string,
  domainName: string,
): IEventTracker => ({
  ...common,
  eventLabel: `Clicked on owner "${ownerName}" via domain "${domainName}" applications`,
  eventAction: 'click',
});

export const attemptEditWhenDisabled = (widgetName: string): IEventTracker => ({
  ...common,
  eventLabel: `User attempted to edit the content in "${widgetName}" (Disabled, Coming Soon)`,
  eventAction: 'click',
});

export const clickLinkToDiscover = (
  text: string,
  widgetName: string,
): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventLabel: `Clicked on ${text} link in ${widgetName}`,
  eventAction: 'click',
});

export const clickOnResourceLink = (
  resourceType: string,
  domain: string,
): IEventTracker => ({
  ...common,
  eventLabel: `Clicked on resource link "${resourceType}" on domain "${domain}"`,
  eventAction: 'click',
});
