import React from 'react';
import { useApi } from '@backstage/core-plugin-api';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { PluginTracking, useUpdateQueryString } from 'plugin-ui-components';
import { docHostApiRef } from 'plugin-catalog';
import { useAsync } from 'react-use';
import { useQueryParamState } from '@backstage/core-components';

interface Props {
  onChange: (newValue: string[]) => void;
  loading?: boolean;
}

export function CategoryFilter({ onChange, loading }: Props) {
  const docHostApi = useApi(docHostApiRef);
  const [value] = useQueryParamState<string>('category');
  const updateQueryString = useUpdateQueryString();

  React.useEffect(() => {
    if (!value) onChange([]);
    else onChange(value?.split(',') || []);
  }, [value, onChange]);

  const { value: categoriesList = [] } = useAsync(
    () => docHostApi.getDocCategories(),
    [],
  );

  function setValue(val: string | string[]) {
    PluginTracking.sendEvent({
      plugin: 'zalando-docs',
      eventAction: 'change',
      eventLabel: `User changed the value of the category filter to: "${val}"`,
      eventCategory: 'Documentation category filter',
    });
    updateQueryString({ category: String(val) });
  }

  return (
    <FormControl fullWidth>
      <Autocomplete
        multiple
        size="small"
        options={categoriesList}
        value={value?.split(',') || []}
        onChange={(_: any, val) => setValue(val)}
        disabled={loading}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              margin="dense"
              label="Doc Categories"
              disabled={loading}
            />
          );
        }}
      />
    </FormControl>
  );
}
