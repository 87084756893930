import type { CSSProperties } from 'react';
import { Styles } from 'react-joyride';

export const getStyles = (theme: Theme): Partial<Styles> => {
  return {
    options: {
      beaconSize: 20,
      arrowColor: '#fff',
      backgroundColor: '#fff',
      spotlightShadow:
        'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',
      overlayColor: 'rgba(79, 26, 0, 0.4)',
      primaryColor: '#000',
      textColor: '#004a14',
      zIndex: 1000,
    },
    buttonNext: {
      backgroundColor: theme.palette.primary.light,
      fontSize: theme.typography.button.fontSize,
    },
    buttonBack: {
      border: '1px solid',
      borderColor: theme.palette.primary.light,
      borderRadius: '4px',
      color: theme.palette.primary.light,
      fontSize: theme.typography.button.fontSize,
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    tooltipFooter: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      paddingTop: '1rem',
      marginTop: 0,
    },
    tooltipTitle: {
      textAlign: 'left',
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.grey[900],
    },
    tooltipContent: {
      textAlign: 'left',
      color: theme.palette.grey[900],
      padding: '0.5rem 0',
    },
    beaconInner: {
      backgroundColor: theme.palette.secondary.light,
    },
    beaconOuter: {
      backgroundColor: theme.palette.primary.light,
      borderColor: 'transparent',
    },
  };
};

export const FabStyles: CSSProperties = {
  textTransform: 'initial',
  padding: '0 .3rem',
  color: '#FFF',
  fontSize: '.9rem',
};
