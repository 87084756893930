import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({
  customWidth: { maxWidth: 500 },
});

export const ResourceOwnerToolTip = ({
  resourceName = '',
  scopeId = '',
  resourceOwners = [],
}: {
  resourceName: string;
  scopeId: string;
  resourceOwners: Array<string>;
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{ tooltip: classes.customWidth }}
      title={
        resourceOwners.length ? (
          <>
            <p>
              {resourceOwners.map((owner, i, all) => (
                <span key={i}>
                  <strong>{owner}</strong>
                  {i === all.length - 1 ? '' : ', '}
                </span>
              ))}{' '}
              can grant <strong>{scopeId}</strong> access on their{' '}
              <strong>{resourceName}</strong> data to applications.
            </p>
            <p>
              A Resource Owner Scope can be granted by the resource owners to
              others. The resource owner automatically has the permission to
              grant this scope without further actions. Resource Owner Scopes
              always authorize only in the context of the resource owner’s
              resources.
            </p>
            <p>
              For instance: A customer can grant an application{' '}
              <strong>read</strong> access to <strong>their</strong> sales order
              data.
            </p>
          </>
        ) : (
          <p>Nobody owns {resourceName}</p>
        )
      }
    >
      <InfoIcon />
    </Tooltip>
  );
};

export const ApplicationScopeToolTip = ({
  resourceName = '',
  scopeId = '',
}: {
  resourceName: string;
  scopeId: string;
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{ tooltip: classes.customWidth }}
      title={
        <>
          <p>
            An application can get <strong>{scopeId}</strong> access to{' '}
            <strong>{resourceName}</strong> data.
          </p>
          <p>
            Normally Application Scopes are not bound to the context of a
            resource owner. By default neither applications nor resource owners
            have this scope. It has to be assigned manually in an application’s
            OAuth configuration.
          </p>
          <p>
            For instance: An analytics application can get{' '}
            <strong>read_all</strong> access to all sales order data without
            consent of the resource owners.
          </p>
        </>
      }
    >
      <InfoIcon />
    </Tooltip>
  );
};
