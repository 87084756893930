import React, {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  buildMatchingEntities,
  buildStates,
  collectCostCenters,
  collectDocsCategory,
  collectIdsAndAliases,
  collectJourney,
  collectLifecycles,
  collectScorecards,
  collectStep,
  collectTags,
  collectTeams,
  collectToers,
  collectToolingOwners,
  collectToolingStatus,
  collectTypes,
  getKindFromPathname,
} from 'plugin-core';
import { FilterGroupsContext } from './FilterGroupsContext';
import { useFilterRefs, useFilterSetters, useUserTeams } from '../hooks';
import { CatalogApi, GetEntitiesRequest } from '@backstage/catalog-client';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { addCompletionPercentage, mapFilterKeys } from './utils';
import {
  SECURITY_TIERS,
  SCORECARD_STATUSES,
} from '../components/Catalog/constants';
import { useApplicationScorecardsContext } from 'plugin-scorecards';

type FilterState<T> = { [filterGroupId: string]: T };

/** Implementation of the shared filter groups state. */
export function EntityFilterGroupsProvider({
  children,
}: PropsWithChildren<{}>) {
  const state = useProvideEntityFilters(getKindFromPathname());
  return (
    <FilterGroupsContext.Provider value={state}>
      {children}
    </FilterGroupsContext.Provider>
  );
}

// Initially populate the table with the matching entities
const fetchTableData = async (
  type: ICatalog.EntityType,
  catalogApi: CatalogApi,
  initialData?: IEntityComponent[],
) => {
  const catalogFilters: Record<string, GetEntitiesRequest> = {
    api: {
      filter: { kind: 'API' },
      fields: [
        'kind',
        'metadata',
        'relations',
        /**
         * NOTE: Excluding `spec.definition` because it drastically increases the response size
         *       And since it's unused in the table, removing it improves the response time
         */
        'spec.apiId',
        'spec.consumers',
        'spec.deployments',
        'spec.lifecycle',
        'spec.owner',
        'spec.providingApplications',
        'spec.spec',
        'spec.type',
        'spec.orphanedEntity',
      ],
    },
    documentation: { filter: { kind: 'Documentation' } },
    service: { filter: { kind: 'Component', 'spec.type': 'service' } },
    domain: { filter: { kind: 'domain' } },
    reportingLine: { filter: { kind: 'ReportingLine' } },
    group: { filter: { kind: 'group', 'spec.type': 'team' } },
    tooling: { filter: { kind: 'Tooling' } },
    toer: { filter: { kind: 'Toer' } },
    other: { filter: {} },
  };

  if (initialData) return initialData;

  const response = await catalogApi.getEntities(catalogFilters[type]);

  if (type === 'group') {
    return (response.items as IEntityComponent[]).filter(
      item => !!item?.spec?.fullName,
    );
  }

  return response.items as IEntityComponent[];
};

const constructUserFilters = (refs: ReturnType<typeof useFilterRefs>) => {
  const userFilters: Partial<ICatalog.Filters> = {};

  Object.keys(refs).forEach(key => {
    // Assign the current value of each ref to the corresponding key in userFilters
    // @ts-ignore
    userFilters[key] = refs[key as keyof typeof refs].current;
  });

  return userFilters as ICatalog.Filters; // Ensure the type matches exactly
};

// The hook that implements the actual context building
export function useProvideEntityFilters(
  tab: ICatalog.EntityType,
  initialData?: IEntityComponent[],
  initialDataLoading?: boolean,
): ICatalog.FilterGroupsContext {
  const catalogApi = useApi(catalogApiRef);
  const [searchParams, setSearchParams] = useSearchParams();
  const scorecardsContext = useApplicationScorecardsContext();
  const {
    data: entities,
    error,
    refetch: reload,
    isLoading: loadingEntities,
  } = useQuery(
    [`catalog-table-data-${tab}`, tab, initialDataLoading],
    async (): Promise<IEntityComponent[]> => {
      const data = await fetchTableData(tab, catalogApi, initialData);
      // When showing applications and the scorecards context is empty, update it with the fetched data
      if (tab === 'service' && !scorecardsContext.data.applications.size) {
        scorecardsContext.updateApplications(data);
      }
      return data;
    },
    {
      enabled: !!tab && initialDataLoading !== true,
    },
  );

  const {
    value: { userTeams },
  } = useUserTeams();

  const filterGroups = useRef<FilterState<ICatalog.FilterGroup>>({});
  const selectedFilterKeys = useRef<FilterState<Set<string>>>({});

  const allFilters = useFilterRefs(tab);
  const filterSetters = useFilterSetters(allFilters);

  const [filterGroupStates, setFilterGroupStates] = useState<
    FilterState<ICatalog.FilterGroupStates>
  >({});
  const [matchingEntities, setMatchingEntities] = useState<
    IEntityComponent[] | IEntity[]
  >([]);
  const [availableTags, setAvailableTags] = useState<string[]>([]);
  const [availableTeams, setAvailableTeams] = useState<string[]>([]);
  const [availableDocsCategory, setAvailableDocsCategory] = useState<string[]>(
    [],
  );
  const [availableToers, setAvailableToers] = useState<string[]>([]);
  const [availableLifecycles, setAvailableLifecycles] = useState<string[]>([]);
  const [availableTypes, setAvailableTypes] = useState<string[]>([]);
  const [availablestep, setAvailablestep] = useState<string[]>([]);
  const [availableJourney, setAvailableJourney] = useState<string[]>([]);
  const [availableToolingOwners, setAvailableToolingOwners] = useState<
    string[]
  >([]);
  const [availableToolingStatus, setAvailableToolingStatus] = useState<
    string[]
  >([]);
  const [availableCostCenters, setAvailableCostCenters] = useState<string[]>(
    [],
  );
  const [availableIdsAndAliases, setAvailableIdsAndAliases] = useState<
    (string | number)[]
  >([]);
  const [availableScorecards, setAvailableScorecards] = useState<string[]>([]);
  const [availableSecurityTiers, setAvailableSecurityTiers] = useState<
    number[]
  >([]);
  const [availableScorecardStatus, setAvailableScorecardStatus] = useState<
    string[]
  >([]);
  const [isCatalogEmpty, setCatalogEmpty] = useState<boolean>(false);
  const getUserFilters = useCallback(() => {
    return {
      ...constructUserFilters(allFilters),
      productionReadinessReviewFilter: allFilters.prrFilter.current,
      filterGroups: filterGroups.current,
      selectedFilterKeys: selectedFilterKeys.current,
    };
  }, [allFilters]);

  const rebuild = useCallback(async () => {
    const filters = getUserFilters();
    setFilterGroupStates(buildStates(filters, entities, error as Error));
    setMatchingEntities(
      buildMatchingEntities({
        filters,
        entities,
        scorecardsContext,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities, error, userTeams, scorecardsContext]);

  useEffect(() => {
    const { data: contextData, isLoading } = scorecardsContext;
    if (entities) {
      /* If the entities are applications, add the completion percentage
         to the metadata after context is filled. This allows for apps to be sorted by completion.
      */
      if (!isLoading && contextData.relations.length && tab === 'service') {
        addCompletionPercentage(entities, catalogApi, scorecardsContext);
      }
    }
  }, [entities, scorecardsContext, catalogApi, tab]);

  // INFO: Rebuild the filter groups and initial filter values when the entities change
  useEffect(() => {
    if (entities) {
      // Collect the available filter values from the entities
      setAvailableTags(collectTags(entities));
      setAvailableTeams(collectTeams(entities));
      setAvailableDocsCategory(collectDocsCategory(entities));
      setAvailableToers(collectToers(entities));
      setAvailableLifecycles(collectLifecycles(entities));
      setAvailableTypes(collectTypes(entities));
      setAvailableCostCenters(collectCostCenters(entities));
      setAvailableIdsAndAliases(collectIdsAndAliases(entities));
      setAvailableToolingStatus(collectToolingStatus(entities));
      setAvailableToolingOwners(collectToolingOwners(entities));
      setAvailablestep(collectStep(entities));
      setAvailableJourney(collectJourney(entities));
      setAvailableScorecards(collectScorecards(entities));
      setAvailableSecurityTiers(SECURITY_TIERS);
      setAvailableScorecardStatus(SCORECARD_STATUSES);
      setCatalogEmpty(entities !== undefined && entities.length === 0);
    }
  }, [entities]);

  const register = useCallback(
    (
      filterGroupId: string,
      filterGroup: ICatalog.FilterGroup,
      initialSelectedFilterIds?: string[],
    ) => {
      filterGroups.current[filterGroupId] = filterGroup;
      selectedFilterKeys.current[filterGroupId] = new Set(
        initialSelectedFilterIds ?? [],
      );
    },
    [],
  );

  const unregister = useCallback((filterGroupId: string) => {
    delete filterGroups.current[filterGroupId];
    delete selectedFilterKeys.current[filterGroupId];
  }, []);

  const setGroupSelectedFilters = useCallback(
    (filterGroupId: string, filters: string[]) => {
      selectedFilterKeys.current[filterGroupId] = new Set(filters);
      rebuild();
    },
    [rebuild],
  );

  useEffect(() => {
    Object.keys(allFilters).forEach(key => {
      const typedKey = key as keyof typeof allFilters;
      const filterKey = typedKey.replace(/Filter$/i, '') as any;
      const filterRefValue = allFilters[typedKey].current;
      const isFilterArray = Array.isArray(filterRefValue);

      // Update the search params with the initial filter value
      if (isFilterArray && filterRefValue.length) {
        setSearchParams(currentVal => {
          currentVal.set(filterKey, filterRefValue.join(','));
          return currentVal;
        });
      } else if (!isFilterArray && filterRefValue) {
        setSearchParams(currentVal => {
          currentVal.set(filterKey, filterRefValue);
          return currentVal;
        });
      }

      // Update the initial filter value with the query params
      const filterQueryValue = searchParams.get(mapFilterKeys(filterKey));
      allFilters[typedKey].current = isFilterArray
        ? filterQueryValue?.split(',') || []
        : filterQueryValue || '';
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Trigger rebuild(filtering) when the search params change
  useEffect(() => {
    rebuild();
  }, [searchParams, rebuild]);

  return {
    register,
    unregister,
    setGroupSelectedFilters,
    ...constructUserFilters(allFilters),
    ...filterSetters,
    reload,
    loading: loadingEntities,
    error: error as Error,
    filterGroupStates,
    allEntities: entities ?? [],
    matchingEntities,
    availableTags,
    availableTeams,
    availableDocsCategory,
    availableLifecycles,
    availableTypes,
    availableCostCenters,
    availableIdsAndAliases,
    availableJourney,
    availablestep,
    availableToolingOwners,
    availableToolingStatus,
    availableToers,
    availableScorecards,
    availableSecurityTiers,
    availableScorecardStatus,
    isCatalogEmpty,
  };
}
