import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { RunStatus } from '../../api';

interface StatusFilterProps {
  value?: RunStatus;
  onChange: (value: any) => void;
}

export const StatusFilter = (props: StatusFilterProps) => {
  const defaultValue = 'ALL';
  const { value, onChange } = props;

  return (
    <Select
      value={value || defaultValue}
      variant="outlined"
      margin="dense"
      fullWidth
      onChange={ev =>
        onChange(ev.target.value === defaultValue ? '' : ev.target.value)
      }
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }}
    >
      <MenuItem value={defaultValue}>{defaultValue}</MenuItem>
      <MenuItem value={RunStatus.Succeeded}>{RunStatus.Succeeded}</MenuItem>
      <MenuItem value={RunStatus.Running}>{RunStatus.Running}</MenuItem>
      <MenuItem value={RunStatus.Initialized}>{RunStatus.Initialized}</MenuItem>
      <MenuItem value={RunStatus.Failed}>{RunStatus.Failed}</MenuItem>
    </Select>
  );
};
