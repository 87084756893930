import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import withStyles from '@mui/styles/withStyles';
import { useNestedRouterServices } from '../../services';
import { TestableProgress } from '../../common';
import {
  Pagination,
  GridLayout,
  NothingFound,
  ErrorOccurred,
  NoRepositories,
} from '../common';
import { ActionPanel } from './components/ActionPanel';
import { RunGroupsList } from './components/RunGroupsList';
import { runGroupsCssVariables } from '../../styles/css-variables';
import * as S from './styles';

const ApplicationPageComponent = observer(() => {
  const { applicationService, runGroupsService } = useNestedRouterServices();

  useEffect(() => {
    applicationService.extract();
  }, [applicationService]);

  useEffect(() => {
    if (applicationService.repositoriesState.isLoaded) {
      runGroupsService.getByRepositories(
        applicationService.repositories.map(item => item.fullPath),
        [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationService.repositoriesState.isLoaded]);

  const shouldDisplayNoRepositories =
    applicationService.repositoriesState.isLoaded &&
    applicationService.repositories.length === 0;
  const shouldDisplayList = runGroupsService.runGroups.length > 0;
  const shouldDisplayProgress = runGroupsService.runGroupsState.isLoading;
  const shouldDisplayNothingFound =
    applicationService.repositoriesState.isLoaded &&
    runGroupsService.runGroupsState.isLoaded &&
    runGroupsService.runGroups.length === 0;
  const shouldDisplayError =
    applicationService.repositoriesState.hasError ||
    (runGroupsService.runGroupsState.hasError &&
      runGroupsService.runGroups.length === 0);

  return (
    <>
      <S.Content>
        <S.Card>
          {shouldDisplayNoRepositories ? (
            <NoRepositories />
          ) : (
            <>
              <ActionPanel />

              <GridLayout
                columns={['Latest pipeline run', 'Time', 'Steps', 'Action']}
              >
                {shouldDisplayProgress && <TestableProgress />}
                {shouldDisplayNothingFound && <NothingFound />}
                {shouldDisplayError && <ErrorOccurred />}
                {shouldDisplayList && (
                  <>
                    <RunGroupsList />
                    <Pagination service={runGroupsService} />
                  </>
                )}
              </GridLayout>
            </>
          )}
        </S.Card>
      </S.Content>
    </>
  );
});

export const ApplicationPage = withStyles(runGroupsCssVariables)(
  ApplicationPageComponent,
);
