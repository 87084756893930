import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useServices } from '../../../services';
import { Tooltip } from '../../../common';
import { UpsertFormDialog } from './UpsertFormDialog';
import { UpsertSuccessDialog } from './UpsertSuccessDialog';
import { ValidateFormDialog } from './ValidateFormDialog';
import { ValidateSuccessDialog } from './ValidateSuccessDialog';

export const Secrets = observer(() => {
  const { secretsService } = useServices();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [upsertFormDialogOpen, setUpsertFormDialogOpen] = useState(false);
  const [upsertSuccessDialogOpen, setUpsertSuccessDialogOpen] = useState(false);
  const [validateFormDialogOpen, setValidateFormDialogOpen] = useState(false);
  const [validateSuccessDialogOpen, setValidateSuccessDialogOpen] =
    useState(false);

  useEffect(() => {
    if (secretsService.upsertState.isLoaded && upsertFormDialogOpen) {
      setUpsertFormDialogOpen(false);
      setUpsertSuccessDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secretsService.upsertState.isLoaded]);

  useEffect(() => {
    if (secretsService.validateState.isLoaded && validateFormDialogOpen) {
      setValidateFormDialogOpen(false);
      setValidateSuccessDialogOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secretsService.validateState.isLoaded]);

  useEffect(() => {
    if (upsertFormDialogOpen || validateFormDialogOpen) {
      secretsService.clean();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsertFormDialogOpen, validateFormDialogOpen]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'secrets-popover' : undefined;

  return (
    <>
      <Tooltip title="Create or update secrets for this repository">
        <Button
          aria-describedby={id}
          onClick={handleClick}
          data-testid="main-button"
          color="inherit"
          variant="outlined"
          size="small"
        >
          <VpnKeyIcon />
          &nbsp;Secrets
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardContent>
            <Typography color="secondary" gutterBottom>
              Build secrets
            </Typography>
            <Typography variant="body2">
              Create values only available in CDP's build steps (i.e. steps of
              type "script").
              <br />
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href="https://sunrise.zalando.net/docs/default/Documentation/cloud/howtos/use-secrets-in-builds/"
              >
                Learn More
              </Link>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="primary"
              onClick={() => setUpsertFormDialogOpen(true)}
              data-testid="upsert-button"
            >
              Create / Update secret
            </Button>
            <Button
              size="small"
              color="primary"
              onClick={() => setValidateFormDialogOpen(true)}
              data-testid="validate-button"
            >
              Validate secret
            </Button>
          </CardActions>
        </Card>
      </Popover>
      <UpsertFormDialog
        isOpen={upsertFormDialogOpen}
        handleClose={() => setUpsertFormDialogOpen(false)}
      />
      <UpsertSuccessDialog
        isOpen={upsertSuccessDialogOpen}
        handleClose={() => setUpsertSuccessDialogOpen(false)}
        handleCreateAnother={() => {
          secretsService.clean();
          setUpsertSuccessDialogOpen(false);
          setUpsertFormDialogOpen(true);
        }}
      />
      <ValidateFormDialog
        isOpen={validateFormDialogOpen}
        handleClose={() => setValidateFormDialogOpen(false)}
      />
      <ValidateSuccessDialog
        isOpen={validateSuccessDialogOpen}
        handleClose={() => setValidateSuccessDialogOpen(false)}
        handleValidateAnother={() => {
          secretsService.clean();
          setValidateSuccessDialogOpen(false);
          setValidateFormDialogOpen(true);
        }}
      />
    </>
  );
});
