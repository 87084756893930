import {
  StatusAborted,
  StatusError,
  StatusOK,
  StatusPending,
  StatusWarning,
} from '@backstage/core-components';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { EventTracker } from 'plugin-ui-components';
import React from 'react';
import rings from './rings';
import { Tracking } from '../../../tracking';
import { ProductOrServiceStatus } from '../types';
import Box from '@mui/material/Box';

const Statuses: Record<ProductOrServiceStatus, React.FC<any>> = {
  Adopt: StatusOK,
  Trial: StatusPending,
  Assess: StatusWarning,
  Hold: StatusAborted,
  Retired: StatusError,
} as Record<ProductOrServiceStatus, React.FC<any>>;

interface Props {
  status: ProductOrServiceStatus;
}

export function ProductStatus({ status }: Props) {
  if (Statuses.hasOwnProperty(status)) {
    const StatusComp = Statuses[status];
    return (
      <Tooltip title={rings[status]?.description}>
        <Typography>
          <EventTracker {...Tracking.hoverStatus(`${rings[status]?.name}`)}>
            <Box component={StatusComp} whiteSpace="nowrap">
              <span>{rings[status]?.name}</span>
            </Box>
          </EventTracker>
        </Typography>
      </Tooltip>
    );
  }
  return <Typography>{rings[status].name}</Typography>;
}
