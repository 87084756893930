import React from 'react';
import Link from '@mui/material/Link';
import { ITestUpload, IGropuedUploads } from '../../../../api/types/payload';
import { STEP_STATUS, TEST_TYPES } from '../../../../constants';

export function groupUploads(uploads: ITestUpload[]) {
  const groupedUploads = uploads.reduce(
    (acc, curr) => {
      if (curr.error) {
        acc.errors.push(curr);
        return acc;
      }

      if (curr.type === TEST_TYPES.html) {
        acc.html.push(curr);
        return acc;
      }

      if (curr.type === TEST_TYPES.junit) {
        acc.junit.push(curr);
        return acc;
      }

      return acc;
    },
    {
      errors: [],
      html: [],
      junit: [],
    } as IGropuedUploads,
  );

  return groupedUploads;
}

export function getMessageByStatus(status: string): string | React.ReactNode {
  switch (status) {
    case STEP_STATUS.PENDING_APPROVAL:
      return 'Waiting for manual approval…';
    case STEP_STATUS.NOT_STARTED:
      return 'Script step was not reached yet.';
    case STEP_STATUS.SKIPPED:
    case STEP_STATUS.ABORTED:
    case STEP_STATUS.FAILED:
    case STEP_STATUS.ERROR:
    case STEP_STATUS.SUCCEEDED:
      return (
        <React.Fragment>
          <span>No test results to display.</span>
          <Link
            href="https://cloud.docs.zalando.net/concepts/builds/#test-results"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;Learn more&nbsp;
          </Link>
          <span>on how to upload test results from script step.</span>
        </React.Fragment>
      );
    case STEP_STATUS.IN_PROGRESS:
      return 'No test uploads, script step in progress...';
    default:
      return 'Please wait, script step is starting…';
  }
}
