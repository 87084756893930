import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import styled from '@mui/styles/styled';

export const Wrapper = styled(Card)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.11)',
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  textTransform: 'uppercase',
  fontSize: 12,
  fontWeight: 'bold',
}));

export const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  minHeight: theme.spacing(6),
  boxShadow: theme.shadows[1],
  transition: theme.transitions.create(['background-color'], {
    duration: theme.transitions.duration.shortest,
  }),
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  '&:first-child': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  '&:last-child': {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  backgroundColor: theme.palette.background.paper,
  '&.Mui-selected': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[800],
    },
  },
}));

export const MenuTitle = styled(Typography)(() => ({
  fontWeight: 500,
}));

export const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  minWidth: 30,
  color: theme.palette.text.primary,
}));
