export const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'tech-insights',
  eventCategory: 'Scorecards Plugin',
  eventAction: 'click',
  interaction: 'onClick',
};

export const viewAllScorecards = (applicationName: string): IEventTracker => ({
  ...common,
  eventLabel: `View all scorecards for "${applicationName}"`,
});

export const expandCheck = (
  check: string,
  scorecard?: string,
  applicationName?: string,
): IEventTracker => ({
  ...common,
  eventLabel: `Expanding check "${check}" ${
    scorecard && `in scorecard "${scorecard}"`
  } ${applicationName && `for application "${applicationName}"`}`,
});

export const viewScorecardDetails = (
  applicationName: string,
): IEventTracker => {
  return {
    ...common,
    eventLabel: `Viewing scorecard overview for application "${applicationName}" in application catalog`,
  };
};

export const techInsightCardLinkClicked: IEventTracker = {
  ...common,
  eventLabel: 'Tech Insights card link clicked',
};

export const scorecardsTable: Record<
  'overview' | 'appliedTo' | 'edit' | 'deleteBtn' | 'group',
  IEventTracker
> = {
  overview: {
    ...common,
    eventLabel: 'Scorecards table overview linked clicked',
  },
  appliedTo: {
    ...common,
    eventLabel: 'Scorecards table applied to link clicked',
  },
  edit: { ...common, eventLabel: 'Scorecards table edit link clicked' },
  deleteBtn: {
    ...common,
    eventLabel: 'Scorecards table delete button clicked',
  },
  group: { ...common, eventLabel: 'Scorecards table group filter clicked' },
};

export const registerScorecard: IEventTracker = {
  ...common,
  eventLabel: 'Register scorecard button clicked',
};
