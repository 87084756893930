import React from 'react';
import Box from '@mui/material/Box';
import useTheme from '@mui/styles/useTheme';
import { CostGrowth, LegendItem } from '../../../components';
import { ChangeStatistic, CostInsightsTheme, Duration } from '../../../types';
import { monthOf } from '../../../utils/formatters';

export type MigrationBarChartLegendProps = {
  change: ChangeStatistic;
  startDate: string;
  currentProduct: string;
  comparedProduct: string;
};

export const KubernetesMigrationBarChartLegend = ({
  currentProduct,
  comparedProduct,
  change,
  startDate,
}: MigrationBarChartLegendProps) => {
  const theme = useTheme<CostInsightsTheme>();
  return (
    <Box display="flex" flexDirection="row">
      <Box marginRight={2}>
        <LegendItem
          title={monthOf(startDate)}
          markerColor={theme.palette.magenta}
        >
          {currentProduct}
        </LegendItem>
      </Box>
      <Box marginRight={2}>
        <LegendItem title="Estimated Cost" markerColor={theme.palette.yellow}>
          {comparedProduct}
        </LegendItem>
      </Box>
      <LegendItem title="Total Savings">
        <CostGrowth change={change} duration={Duration.P30D} />
      </LegendItem>
    </Box>
  );
};
