import makeStyles from '@mui/styles/makeStyles';

export const useTrafficStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gridGap: 5,
    border: `1px solid ${theme.palette.divider}`,
    padding: '5px',
    borderRadius: '4px',
  },
  header: {
    fontWeight: 600,
    margin: 0,
    padding: '5px',
  },
  item: {
    margin: 0,
    padding: '5px',
  },
  itemHighlighted: {
    margin: 0,
    padding: '5px',
    background: theme.palette.divider,
    borderRadius: '4px',
  },
}));
