import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  value: {
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  valueContainer: {
    display: 'flex',
    gap: '0.25em',
  },
}));
