import React from 'react';
import { useSearchParams } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { useFilter } from './useFilter.hook';
import { OnChangeCallback } from '.';
import Box from '@mui/material/Box';
import * as S from './styles';

interface CatalogFilterProps {
  buttonGroups: ICatalog.ButtonGroup[];
  initiallySelected: string;
  onChange?: OnChangeCallback;
}

/** The main filter group in the sidebar, toggling owned/starred/all. */
export const CatalogFilter = ({
  buttonGroups,
  onChange,
  initiallySelected,
}: CatalogFilterProps) => {
  const [_, setSearchParams] = useSearchParams();
  const { currentFilter, setCurrentFilter, getFilterCount } = useFilter(
    buttonGroups,
    initiallySelected,
  );

  const onChangeRef = React.useRef<OnChangeCallback>();
  React.useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const setCurrent = React.useCallback(
    (item: { id: string; label: string }) => {
      setCurrentFilter(item.id);
      setSearchParams(currentVal => {
        currentVal.set('group', item.id);
        return currentVal;
      });
      onChangeRef.current?.({ id: item.id, label: item.label });
    },
    [setCurrentFilter, setSearchParams],
  );

  // Make one initial onChange to inform the surroundings about the selected item
  React.useEffect(() => {
    const items = buttonGroups.flatMap(g => g.items);
    const item = items.find(i => i.id === initiallySelected) || items[0];
    if (item) {
      onChangeRef.current?.({ id: item.id, label: item.label });
    }
    // intentionally only happens on startup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper id="category-filter" elevation={0}>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {buttonGroups.map((group, i) => (
          <Box key={i}>
            <S.Title variant="subtitle2">{group.name}</S.Title>
            <Box>
              <List disablePadding dense>
                {group.items.map(item => (
                  <S.MenuItem
                    key={item.id}
                    divider
                    onClick={() => setCurrent(item)}
                    selected={item.id === currentFilter}
                    disableRipple
                  >
                    {item.icon && (
                      <S.ListItemIcon>
                        <item.icon fontSize="small" />
                      </S.ListItemIcon>
                    )}
                    <ListItemText>
                      <S.MenuTitle>{item.label}</S.MenuTitle>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {getFilterCount(item.id) ?? '-'}
                    </ListItemSecondaryAction>
                  </S.MenuItem>
                ))}
              </List>
            </Box>
          </Box>
        ))}
      </Box>
    </S.Wrapper>
  );
};
