import React from 'react';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { RunGroupsModel } from '../../../../models';
import { Tooltip } from '../../../../common';

import * as S from './styles';

interface Props {
  run: RunGroupsModel;
  repoUrl: (run: RunGroupsModel) => string;
  orgUrl?: (run: RunGroupsModel) => string;
}

export const RunGroupsItemHeader = observer(
  ({ run, repoUrl, orgUrl }: Props) => {
    return (
      <Box pt={2} pr={2} pl={1} key={`run-group-item-${run.id}`}>
        {orgUrl && (
          <>
            <Tooltip title="Organization name">
              <S.Title to={orgUrl(run)}>{run.repoInfo.organization}</S.Title>
            </Tooltip>
            {' / '}
          </>
        )}
        <Tooltip title="Repository name">
          <S.Title to={repoUrl(run)}>{run.repoInfo.name}</S.Title>
        </Tooltip>
      </Box>
    );
  },
);
