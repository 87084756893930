export const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'teams-catalog-plugin',
  eventCategory: 'Teams Catalog Plugin',
  interaction: 'onClick',
  eventAction: 'click',
};

export const clickTableItem = (item: string, value: string): IEventTracker => ({
  ...common,
  eventLabel: `Click on ${item}: ${value}`,
});
