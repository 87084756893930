import React, { Fragment } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { default as AcceptIcon } from '@mui/icons-material/Check';
import { default as DismissIcon } from '@mui/icons-material/Delete';
import { default as SnoozeIcon } from '@mui/icons-material/AccessTime';
import { ActionItemCard } from '../ActionItems';
import { Alert, AlertStatus } from '../../types';
import { useActionItemCardStyles as useStyles } from '../../utils/styles';

type AlertGroupProps = {
  alerts: Alert[];
  status: AlertStatus;
  title: string;
  icon: JSX.Element;
};

const AlertGroup = ({ alerts, status, title, icon }: AlertGroupProps) => {
  const classes = useStyles();
  return (
    <Box p={1}>
      {alerts.map((alert, index) => (
        <Fragment key={`alert-${status}-${index}`}>
          <ActionItemCard
            disableScroll
            alert={alert}
            avatar={
              <Tooltip title={title}>
                <Avatar className={classes.avatar}>{icon}</Avatar>
              </Tooltip>
            }
          />
          {index < alerts.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Box>
  );
};

type AlertStatusSummaryProps = {
  open: boolean;
  snoozed: Alert[];
  accepted: Alert[];
  dismissed: Alert[];
};

export const AlertStatusSummary = ({
  open,
  snoozed,
  accepted,
  dismissed,
}: AlertStatusSummaryProps) => {
  const isSnoozedListDisplayed = !!snoozed.length;
  const isAcceptedListDisplayed = !!accepted.length;
  const isDismissedListDisplayed = !!dismissed.length;

  return (
    <Collapse in={open}>
      {isAcceptedListDisplayed && (
        <AlertGroup
          title="Accepted"
          alerts={accepted}
          status={AlertStatus.Accepted}
          icon={
            <AcceptIcon
              role="img"
              aria-hidden={false}
              aria-label={AlertStatus.Accepted}
            />
          }
        />
      )}
      {isSnoozedListDisplayed && (
        <AlertGroup
          title="Snoozed"
          alerts={snoozed}
          status={AlertStatus.Snoozed}
          icon={
            <SnoozeIcon
              role="img"
              aria-hidden={false}
              aria-label={AlertStatus.Snoozed}
            />
          }
        />
      )}
      {isDismissedListDisplayed && (
        <AlertGroup
          title="Dismissed"
          alerts={dismissed}
          status={AlertStatus.Dismissed}
          icon={
            <DismissIcon
              role="img"
              aria-hidden={false}
              aria-label={AlertStatus.Dismissed}
            />
          }
        />
      )}
    </Collapse>
  );
};
