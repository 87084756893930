import React from 'react';
import {
  Link,
  LinkButton,
  type LinkButtonProps,
  LinkProps,
} from '@backstage/core-components';
import { EventTracker } from './EventTracker';
import { extractProps } from './utils';
import { InferredTrackingEvent } from './types';

export function TrackedLink(props: LinkProps & InferredTrackingEvent) {
  const { componentProps, eventProps } = extractProps({
    ...props,
    interaction: props.interaction ?? 'onClick',
    eventAction: props.eventAction ?? 'click',
  });

  return (
    <EventTracker {...eventProps}>
      <Link {...componentProps} />
    </EventTracker>
  );
}

export function TrackedLinkButton(
  props: LinkButtonProps & InferredTrackingEvent,
) {
  const { componentProps, eventProps } = extractProps({
    ...props,
    interaction: props.interaction ?? 'onClick',
    eventAction: props.eventAction ?? 'click',
  });

  return (
    <EventTracker {...eventProps}>
      <LinkButton {...componentProps} />
    </EventTracker>
  );
}
