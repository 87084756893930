import styled from '@mui/styles/styled';
import { List as StyledList, TagSmall } from '../../../../../common';

const ITEM_HEIGHT = '35px';
const LINE_SIZE = '1px';

export const Artifact = styled(StyledList)({
  ['& + &']: {
    marginTop: '16px',
  },
});

export const Title = styled('p')(props => ({
  marginTop: 0,
  fontWeight: 600,
  color: props.theme.palette.textVerySubtle,
}));

export const List = styled('ul')(props => ({
  position: 'relative',
  listStyle: 'none',
  paddingLeft: props.theme.spacing(2),
  marginBottom: 0,

  ['&:before']: {
    position: 'absolute',
    top: 0,
    left: LINE_SIZE,
    width: LINE_SIZE,
    height: `calc(100% - calc(${ITEM_HEIGHT} / 2))`,
    background: props.theme.palette.divider,
    content: `""`,
  },
}));

export const Item = styled('li')(props => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  height: `${ITEM_HEIGHT}`,
  padding: props.theme.spacing(0, 1),
  borderRadius: '4px',
  transition: 'background .1s ease-in',

  ['&:hover']: {
    background: props.theme.palette.divider,
  },

  ['&:before']: {
    position: 'absolute',
    top: `calc(${ITEM_HEIGHT} / 2))`,
    left: `calc(-${props.theme.spacing(2)}px + ${LINE_SIZE})`,
    width: '10px',
    height: LINE_SIZE,
    background: props.theme.palette.divider,
    content: `""`,
  },
}));

export const Text = styled('span')({
  flexShrink: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const Tag = styled(TagSmall)(props => ({
  flexShrink: 0,
  margin: props.theme.spacing(0, 1),
  textTransform: 'uppercase',
}));

export const Action = styled('div')({
  cursor: 'pointer',
  marginLeft: 'auto',
});
