export function extractProps<T>(props: T & IEventTracker): {
  componentProps: T;
  eventProps: IEventTracker;
} {
  const {
    interaction,
    plugin,
    eventCategory,
    eventLabel,
    eventAction,
    eventValue,
    ...rest
  } = props;

  return {
    componentProps: rest as T,
    eventProps: {
      interaction,
      plugin,
      eventCategory,
      eventLabel,
      eventAction,
      eventValue,
    },
  };
}
