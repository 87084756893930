import React from 'react';
import Button, { type ButtonProps } from '@mui/material/Button';
import { EventTracker } from './EventTracker';
import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import { extractProps } from './utils';
import { InferredTrackingEvent } from './types';

export function TrackedButton(props: ButtonProps & InferredTrackingEvent) {
  const { componentProps, eventProps } = extractProps({
    ...props,
    interaction: props.interaction ?? 'onClick',
    eventAction: props.eventAction ?? 'click',
  });

  return (
    <EventTracker {...eventProps}>
      <Button {...componentProps} />
    </EventTracker>
  );
}

export function TrackedIconButton(
  props: IconButtonProps & InferredTrackingEvent,
) {
  const { componentProps, eventProps } = extractProps({
    ...props,
    interaction: props.interaction ?? 'onClick',
    eventAction: props.eventAction ?? 'click',
  });

  return (
    <EventTracker {...eventProps}>
      <IconButton {...componentProps} />
    </EventTracker>
  );
}
