import React from 'react';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { Ribbon, RibbonContainer } from 'react-ribbons';
import { colors } from '../../theme';
import { makeTooltipText } from './utils';
import { useStyles } from './styles';
import { FeatureFlagRibbonProps } from './types';
import { trackingHover } from './tracking';
// These imports are specifically made this way because importing from the index file won't work in Storybook
import { PluginTracking } from '../Tracking/PluginTracking';

interface Accent {
  background: string;
  color: string;
}

export const FeatureFlagRibbon = ({
  name,
  children,
  container = true,
  size = 'large',
  theme = 'primary',
  page,
  details,
  forceShow = false,
}: FeatureFlagRibbonProps) => {
  const classes = useStyles();
  const featureFlags = useApi(featureFlagsApiRef);
  const [accent, setAccent] = React.useState<Accent>({
    background: colors.brand.primaryOrange,
    color: colors.semantic.white,
  });

  React.useEffect(() => {
    switch (theme) {
      case 'primary':
        setAccent({
          background: colors.brand.primaryOrange,
          color: colors.semantic.white,
        });
        break;
      case 'purple':
        setAccent({
          background: colors.brand.purple,
          color: colors.semantic.white,
        });
        break;
      default:
        setAccent({
          background: colors.brand.primaryOrange,
          color: colors.semantic.white,
        });
    }
  }, [theme]);

  const hasContainer = container && !page;
  const Container = hasContainer ? RibbonContainer : React.Fragment;
  const containerProps = hasContainer ? { className: classes.wrapper } : {};

  const sendHoverTracking = () => PluginTracking.sendEvent(trackingHover(name));

  if (featureFlags.isActive(name) || forceShow) {
    return (
      <Container {...containerProps}>
        <div className={cn(classes.ribbonWrapper, { page })}>
          <Ribbon
            side="right"
            size={size}
            type="corner"
            backgroundColor={accent.background}
            color={accent.color}
            fontFamily="Roboto"
          >
            <Tooltip title={makeTooltipText(name, details, forceShow)}>
              <span onMouseEnter={sendHoverTracking}>Beta</span>
            </Tooltip>
          </Ribbon>
        </div>
        {children}
      </Container>
    );
  }
  return <>{children}</>;
};
