import React from 'react';
import { DateTime } from 'luxon';
import useTheme from '@mui/styles/useTheme';
import Box from '@mui/material/Box';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Bar,
} from 'recharts';
import {
  ChartData,
  Cost,
  DEFAULT_DATE_FORMAT,
  Maybe,
  Metric,
  MetricData,
  CostInsightsTheme,
  Duration,
  TooltipRenderer,
} from '../../types';
import {
  BarChartTooltip as Tooltip,
  BarChartTooltipItem as TooltipItem,
} from '../BarChart';
import { formatGraphValue, isInvalid } from '../../utils/graphs';
import { useCostOverviewStyles as useStyles } from '../../utils/styles';
import { groupByDate, toDataMax, trendFrom } from '../../utils/charts';
import { aggregationSort } from '../../utils/sort';
import { CostOverviewLegend } from './CostOverviewLegend';
import { useFilters } from '../../hooks';
import { mapFiltersToProps } from './selector';
import { colors } from 'plugin-ui-components';

type CostOverviewChartProps = {
  metric: Maybe<Metric>;
  metricData: Maybe<MetricData>;
  dailyCostData: Cost;
  responsive?: boolean;
};

export const CostOverviewChart = ({
  dailyCostData,
  metric,
  metricData,
  responsive = true,
}: CostOverviewChartProps) => {
  const theme = useTheme<CostInsightsTheme>();
  const styles = useStyles(theme);
  const { duration, overviewMode } = useFilters(mapFiltersToProps);

  const colorMap: {
    [mode: string]: string;
  } = {
    aws: colors.semantic.errorDark,
    kubernetes: colors.semantic.errorBase,
    scalyr: colors.brand.teal,
    nakadi: colors.semantic.abortedBase,
  };

  const costLines: Record<string, { name: string; color: string }> = {
    kubernetes: {
      name: 'Kubernetes',
      color: colors.semantic.errorBase,
    },
    aws: {
      name: 'AWS',
      color: colors.semantic.errorDark,
    },
    nakadi: {
      name: 'Nakadi',
      color: colors.semantic.abortedBase,
    },
    scalyr: {
      name: 'Scalyr',
      color: colors.brand.teal,
    },
  };

  const data = {
    dailyCost: {
      dataKey: 'dailyCost',
      name: `Cost`,
      format: 'currency',
      data: dailyCostData,
    },
    metric: {
      dataKey: metric?.kind ?? 'Unknown',
      name: metric?.name ?? 'Unknown',
      format: metricData?.format ?? 'number',
      data: metricData,
    },
  };

  const metricsByDate = data.metric.data
    ? data.metric.data.aggregation.reduce(groupByDate, {})
    : {};
  const chartData: ChartData[] = data.dailyCost.data.aggregation
    .slice()
    .sort(aggregationSort)
    .map((entry, index) => ({
      date: Date.parse(entry.date),
      trend: trendFrom(data.dailyCost.data.trendline!, Date.parse(entry.date)),
      dailyCost: entry.amount,
      ...(metric && data.metric.data
        ? { [data.metric.dataKey]: metricsByDate[`${entry.date}`] }
        : {}),
      kubernetes:
        data.dailyCost.data.costBreakDown?.kubernetes?.aggregation[index]
          ?.amount ?? 0,
      aws:
        data.dailyCost.data.costBreakDown?.aws?.aggregation[index]?.amount ?? 0,
      nakadi:
        data.dailyCost.data.costBreakDown?.nakadi?.aggregation[index]?.amount ??
        0,
      scalyr:
        data.dailyCost.data.costBreakDown?.scalyr?.aggregation[index]?.amount ??
        0,
    }));

  const tooltipRenderer: TooltipRenderer = ({ label, payload = [] }) => {
    if (isInvalid({ label, payload })) return null;

    const dataKeys = [
      data.dailyCost.dataKey,
      data.metric.dataKey,
      ...Object.keys(costLines),
    ];
    const date =
      typeof label === 'number'
        ? DateTime.fromMillis(label)
        : DateTime.fromISO(label!);
    const title = date.toUTC().toFormat(DEFAULT_DATE_FORMAT);
    const items = payload
      .filter(p => dataKeys.includes(p.dataKey as string))
      .map((p, i) => {
        const key = p.dataKey as string;
        if (key in costLines) {
          return {
            fill: costLines[key].color,
            label: costLines[key].name,
            value: formatGraphValue(Number(p.value), i, data.dailyCost.format),
          };
        }
        return {
          label:
            p.dataKey === data.dailyCost.dataKey
              ? data.dailyCost.name
              : data.metric.name,
          value:
            p.dataKey === data.dailyCost.dataKey
              ? formatGraphValue(Number(p.value), i, data.dailyCost.format)
              : formatGraphValue(Number(p.value), i, data.metric.format),
          fill:
            p.dataKey === data.dailyCost.dataKey
              ? theme.palette.blue
              : theme.palette.magenta,
        };
      });

    return (
      <Tooltip title={title}>
        {items.map((item, index) => (
          <TooltipItem key={`${item.label}-${index}`} item={item} />
        ))}
      </Tooltip>
    );
  };

  const tickFormatter = (date: number) => {
    if (duration === Duration.P1W || duration === Duration.P6W) {
      return `CW${DateTime.fromMillis(date).minus({ days: 6 }).weekNumber}`;
    }
    return DateTime.fromMillis(date).toFormat(
      duration === Duration.P3M || duration === Duration.P90D
        ? 'yyyy-LL'
        : DEFAULT_DATE_FORMAT,
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <CostOverviewLegend
        dailyCostData={dailyCostData}
        metric={metric}
        metricData={metricData}
      />
      <ResponsiveContainer
        width={responsive ? '100%' : styles.container.width}
        height={styles.container.height}
        className="cost-overview-chart"
      >
        <ComposedChart margin={styles.chart.margin} data={chartData}>
          <CartesianGrid stroke={styles.cartesianGrid.stroke} />
          <XAxis
            dataKey="date"
            domain={['dataMin', 'dataMax']}
            tickFormatter={tickFormatter}
            type={
              duration === Duration.P3M ||
              duration === Duration.P90D ||
              duration === Duration.P1W ||
              duration === Duration.P6W
                ? 'category'
                : 'number'
            }
            stroke={styles.axis.fill}
            tickCount={10}
          />
          <YAxis
            tick={{ fill: styles.axis.fill }}
            tickFormatter={formatGraphValue}
            width={styles.yAxis.width}
            yAxisId={data.dailyCost.dataKey}
          />
          {metric && (
            <YAxis
              hide
              domain={[() => 0, toDataMax(data.metric.dataKey, chartData)]}
              width={styles.yAxis.width}
              yAxisId={data.metric.dataKey}
            />
          )}

          {overviewMode === 'total' && (
            <Bar
              dataKey="kubernetes"
              isAnimationActive={false}
              fill={colorMap.kubernetes}
              fillOpacity={0.8}
              stroke="none"
              yAxisId={data.dailyCost.dataKey}
            />
          )}

          {overviewMode === 'total' && (
            <Bar
              dataKey="aws"
              isAnimationActive={false}
              fill={colorMap.aws}
              fillOpacity={0.8}
              stroke="none"
              yAxisId={data.dailyCost.dataKey}
            />
          )}

          {overviewMode === 'total' && (
            <Bar
              dataKey="nakadi"
              isAnimationActive={false}
              fill={colorMap.nakadi}
              fillOpacity={0.8}
              stroke="none"
              yAxisId={data.dailyCost.dataKey}
            />
          )}

          {overviewMode === 'total' && (
            <Bar
              dataKey="scalyr"
              isAnimationActive={false}
              fill={colorMap.scalyr}
              fillOpacity={0.8}
              stroke="none"
              yAxisId={data.dailyCost.dataKey}
            />
          )}

          {overviewMode !== 'total' && (
            <Bar
              dataKey={data.dailyCost.dataKey}
              isAnimationActive={false}
              fill={colorMap[overviewMode]}
              fillOpacity={0.8}
              stroke="none"
              yAxisId={data.dailyCost.dataKey}
            />
          )}
          <Line
            activeDot={false}
            dataKey="trend"
            dot={false}
            isAnimationActive={false}
            label={false}
            strokeWidth={2}
            stroke={theme.palette.blue}
            yAxisId={data.dailyCost.dataKey}
          />
          {metric && (
            <Line
              dataKey={data.metric.dataKey}
              dot={false}
              isAnimationActive={false}
              label={false}
              strokeWidth={2}
              stroke={theme.palette.magenta}
              yAxisId={data.metric.dataKey}
            />
          )}
          <RechartsTooltip content={tooltipRenderer} animationDuration={100} />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};
