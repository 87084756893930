import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IPaginate } from '../../../services';

import * as S from './styles';

interface Props {
  service: IPaginate;
}

export function Pagination({ service }: Props) {
  return (
    <S.Pagination>
      <S.PrevButton
        variant="outlined"
        size="small"
        disabled={!service.paginationHelper.hasPrevPage}
        onClick={service.getPrevPage}
      >
        <ArrowBackIcon /> Prev
      </S.PrevButton>
      <S.NextButton
        variant="outlined"
        size="small"
        disabled={!service.paginationHelper.hasNextPage}
        onClick={service.getNextPage}
      >
        Next <ArrowForwardIcon />
      </S.NextButton>
    </S.Pagination>
  );
}
