import React from 'react';
import { ValidationResult } from './ValidationResult';
import { InfoCard, List, ListItem } from '../../../common';
import Link from '@mui/material/Link';
import { IDeploymentValidation } from '../../../api/types/payload/run.interface';

type Props = {
  deploymentValidationProblems: IDeploymentValidation;
};

export function DeploymentProblems({ deploymentValidationProblems }: Props) {
  return (
    <InfoCard
      title={<span>Deployment problems</span>}
      subtitle={
        <span>
          Deployment validation problems detected! Visit&nbsp;
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://sunrise.zalando.net/docs/default/Documentation/cloud/concepts/deployment-validation/"
          >
            the documentation
          </Link>
          &nbsp;for more details.
        </span>
      }
    >
      <List>
        {Object.keys(deploymentValidationProblems).map(name => (
          <ListItem key={name}>
            <ValidationResult
              manifestName={name}
              result={deploymentValidationProblems[name]}
            />
          </ListItem>
        ))}
      </List>
    </InfoCard>
  );
}
