export const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'application-registry',
  eventCategory: 'Access Control Page',
  interaction: 'onClick',
  eventAction: 'click',
};

export const savingResourceType: IEventTracker = {
  ...common,
  eventLabel: `New resource type is being saved`,
};

export const editingResourceType = (name: string): IEventTracker => ({
  ...common,
  eventLabel: `Resource Type '${name}' is being updated`,
});

export const cancelResourceType: IEventTracker = {
  ...common,
  eventLabel: `Cancel button for resource types (edit/save) clicked`,
};
