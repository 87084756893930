import Button from '@mui/material/Button';
import styled from '@mui/styles/styled';

export const Pagination = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '15px 10px',
});

const PaginationButton = styled(Button)({
  ['& svg']: {
    fontSize: '15px',
    marginBottom: '1px',
  },
});

export const PrevButton = styled(PaginationButton)({
  marginRight: '8px',

  ['& svg']: {
    marginRight: '10px',
  },
});

export const NextButton = styled(PaginationButton)({
  ['& svg']: {
    marginLeft: '10px',
  },
});
