import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';
import { useParams } from 'react-router';
import { notebooksApiRef, VersionResponse } from '../../api';
import {
  BaseLayout,
  DetailsCard,
  NavigateToLatest,
  NotebookActionBar,
  NotebookSnapshot,
} from '../../components';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { InteractiveProps } from '../../components/NavigateToLatest/NavigateToLatest';

const entries = [
  { key: 'status', displayName: 'Status', type: 'status' },
  { key: 'created_at', displayName: 'Created At', type: 'time' },
  { key: 'last_modified_at', displayName: 'Updated At', type: 'time' },
  { key: 'created_by', displayName: 'Created By', type: 'user' },
  {
    key: 'notebook_path',
    displayName: 'Source notebook',
    type: 'notebook_path',
  },
  { key: 'kernel_name', displayName: 'Kernel' },
  { key: 'error_message', displayName: 'Exception', type: 'error_message' },
];

export const VersionDetails = (props: InteractiveProps) => {
  const { interactive } = props;
  const [tab, setTab] = useState(0);
  const { sharedNotebookId = '', versionId = '' } = useParams();
  const [notebookName, setNotebookName] = useState('');
  const [details, setDetails] = useState<VersionResponse>();
  const should_publish = true;
  const notebookClient = useApi(notebooksApiRef);

  const getDetails = useCallback(async () => {
    const res = await notebookClient.getVersionDetails(versionId);
    setDetails(res);
  }, [versionId, notebookClient]);

  useEffect(() => {
    if (versionId !== 'latest') {
      getDetails();
    }
  }, [versionId, getDetails]);

  useEffect(() => {
    const getSharedNotebook = async () => {
      const res = await notebookClient.getSharedNotebookDetails(
        sharedNotebookId,
      );
      setNotebookName(res.name);
    };
    getSharedNotebook();
  }, [sharedNotebookId, notebookClient]);

  const onDelete = async (id: string) => {
    return await notebookClient.deleteVersion(id, sharedNotebookId);
  };

  const actionBarComponent = (showFullScreenButton: boolean) => {
    return (
      details && (
        <Grid item xs={12}>
          <NotebookActionBar
            s3Path={details.s3_path}
            notebook_path={details.notebook_path}
            id={details.id}
            name={details.name}
            parentId={sharedNotebookId}
            status={details.status}
            createdBy={details.created_by}
            isInteractive={details.is_interactive}
            shouldPublish={should_publish}
            archived={details.archived}
            showFullScreenButton={showFullScreenButton}
            onDelete={onDelete}
            onPollCallback={getDetails}
          />
        </Grid>
      )
    );
  };

  const tabs = [
    {
      label: 'Notebook',
      content: (
        <Grid container>
          {actionBarComponent(true)}
          <NotebookSnapshot
            s3Path={details?.s3_path || ''}
            shouldHideCode={details?.should_hide_code}
          />
        </Grid>
      ),
    },
    {
      label: 'Version Details',
      content: (
        <Grid container>
          {actionBarComponent(false)}
          <Grid item xs={12}>
            <InfoCard title="Version Details">
              <CardContent>
                <DetailsCard columns={entries} data={details} />
              </CardContent>
            </InfoCard>
          </Grid>
        </Grid>
      ),
    },
  ];

  return versionId === 'latest' || interactive ? (
    <NavigateToLatest
      parentEntityKey="sharedNotebook"
      interactive={interactive}
      id={versionId}
    />
  ) : (
    (details && (
      <BaseLayout notebookName={notebookName} executionName={details.name}>
        <div>
          <Tabs
            value={tab}
            onChange={(_, val: number) => setTab(val)}
            style={{ backgroundColor: 'var(--paper-bg)' }}
          >
            {tabs.map((t, i) => (
              <Tab key={i} label={t.label} value={i} style={{ height: 64 }} />
            ))}
          </Tabs>
          <Box role="tabpanel" p={3}>
            {tabs[tab]?.content}
          </Box>
        </div>
      </BaseLayout>
    )) ||
      null
  );
};
