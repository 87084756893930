import React from 'react';
import { ApplicationsAction } from '../ApplicationsAction';
import { RollbackAction } from '../Rollback';
import { Secrets } from '../Secrets';
import { Tab, Tabs } from '../../../common';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
  fullRepoName: string;
  org: string;
  name: string;
  domainAlias: string;
};

const useStyles = makeStyles(theme => ({
  nav: {
    margin: '-24px -24px 24px -24px',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 1),
  },
  actions: {
    alignSelf: 'center',
    marginLeft: 'auto',
  },
}));

export function RepositoryNavigation({
  fullRepoName,
  org,
  name,
  domainAlias,
}: Props) {
  const path = `/cdp/${domainAlias}/${org}/${name}`;
  const styles = useStyles();

  return (
    <div className={styles.nav}>
      <Tabs>
        <Tab size="md" to={path}>
          Pipeline runs
        </Tab>
        <Tab size="md" to={`${path}/deployments`}>
          Deployments
        </Tab>
        <Tab size="md" to={`${path}/insights`}>
          Insights
        </Tab>

        <div className={styles.actions}>
          <RollbackAction />
          &nbsp;&nbsp;
          <Secrets />
          &nbsp;&nbsp;
          <ApplicationsAction repoURL={`https://${fullRepoName}`} />
        </div>
      </Tabs>
    </div>
  );
}
