import React, { useState } from 'react';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import ThumbDown from '@mui/icons-material/ThumbDown';
import ThumbUp from '@mui/icons-material/ThumbUp';
import Replay from '@mui/icons-material/Replay';
import { colors } from 'plugin-ui-components';

import {
  PIPELINE_PENDING_STATUSES,
  PIPELINE_STOPPED_STATUSES,
  STEP_STATUS,
} from '../../../constants';
import { RunsStepModel, RunStepModel } from '../../../models';
import { cdpApiRef } from '../../..';
import { QuickActionButton } from './Action';

type Props = {
  pipelineStatus: string;
  runId: string;
  steps: (RunStepModel | RunsStepModel)[];
  callback: () => Promise<any>;
  hasDependencies: boolean;
};

export function QuickActions({
  pipelineStatus,
  steps,
  runId,
  callback,
  hasDependencies,
}: Props) {
  const cdpApi = useApi(cdpApiRef);
  const errorApi = useApi(errorApiRef);
  const [disabled, setDisabled] = useState<boolean>(false);

  function handleAction(action: () => Promise<Response>) {
    setDisabled(true);

    action()
      .catch(error => {
        const e = error as Error;
        errorApi.post(e);
      })
      .finally(() => {
        callback().then(() => setDisabled(false));
      });
  }

  function approve() {
    const ordinal = steps.findIndex(
      step => step.status === STEP_STATUS.PENDING_APPROVAL,
    );
    return cdpApi.approveStep(runId, ordinal);
  }

  function abort() {
    return cdpApi.abortRun(runId);
  }

  function retrigger() {
    if (hasDependencies) {
      return cdpApi.retriggerRun(runId, { use_fresh_dependencies: true });
    }

    return cdpApi.retriggerRun(runId);
  }

  const hasOneStepToApprove =
    steps.filter(step => step.status === STEP_STATUS.PENDING_APPROVAL)
      .length === 1;

  if (hasOneStepToApprove) {
    return (
      <QuickActionButton
        disabled={disabled}
        color={colors.semantic.warningBase}
        text="Approve step"
        action={() => handleAction(approve)}
      >
        <ThumbUp />
      </QuickActionButton>
    );
  } else if (PIPELINE_PENDING_STATUSES.includes(pipelineStatus)) {
    return (
      <QuickActionButton
        disabled={disabled}
        color={colors.semantic.errorBase}
        text="Abort pipeline"
        action={() => handleAction(abort)}
      >
        <ThumbDown />
      </QuickActionButton>
    );
  } else if (PIPELINE_STOPPED_STATUSES.includes(pipelineStatus)) {
    return (
      <QuickActionButton
        disabled={disabled}
        text={
          hasDependencies
            ? 'Retrigger pipeline with latest dependencies'
            : 'Retrigger pipeline'
        }
        action={() => handleAction(retrigger)}
      >
        <Replay />
      </QuickActionButton>
    );
  }
  return <></>;
}
