import React from 'react';
import { AlertColor } from '@mui/material/Alert';
import Chip, { type ChipProps } from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type Props = Omit<ChipProps, 'color'> & {
  filled?: boolean;
  status: ApecStatusType;
  reasons?: ApecStatus['reasons'];
};

export const CriticalityChip = React.memo(
  ({ status, reasons, ...props }: Props) => {
    const [color, setColor] = React.useState<AlertColor | ''>('');

    React.useEffect(() => {
      switch (status) {
        case 'red':
          setColor('error');
          break;
        case 'amber':
          setColor('warning');
          break;
        case 'green':
          setColor('success');
          break;
        case 'notrelevant':
          setColor('info');
          break;
        default:
          setColor('');
      }
    }, [status]);
    const showIcon = reasons?.length && ['red', 'amber'].includes(status);

    if (showIcon && reasons?.length) {
      return (
        <Tooltip
          title={
            <ul className="checklist error">
              {reasons.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          }
        >
          <div>
            <Chip
              {...props}
              label={
                <>
                  <InfoOutlinedIcon />
                  {status}
                </>
              }
              className={`info-chip ${color}`}
              style={{ cursor: 'help' }}
            />
          </div>
        </Tooltip>
      );
    }

    return (
      <Chip
        {...props}
        label={status}
        className={`info-chip outlined ${color}`}
      />
    );
  },
);
