import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/styles/useTheme';
import InfoIcon from '@mui/icons-material/Info';
import { HeaderLabel } from '@backstage/core-components';
import { HStack } from '../Stacks';
// These imports are specifically made this way because importing from the index file won't work in Storybook
import { EventTracker } from '../Tracking';
import { DefaultTheme } from '@mui/styles/defaultTheme';

interface LastSyncProps {
  /** Source of the data for the entity */
  sources?: Array<string>;
  /** The last date entity was synced */
  lastSyncDate: string;
}

export const getStatusColor = (theme: DefaultTheme, lastSyncDate: string) => {
  const lastSyncDays = Math.floor(
    (new Date().getTime() - new Date(lastSyncDate).getTime()) /
      (1000 * 3600 * 24),
  );
  if (lastSyncDays < 1) return theme.palette.success.light;
  return lastSyncDays === 1
    ? theme.palette.warning.light
    : theme.palette.error.main;
};

export function LastSync({ sources, lastSyncDate }: LastSyncProps) {
  const theme = useTheme();

  const trackingInfo: IEventTracker = {
    plugin: 'ui-components',
    eventCategory: 'Last Sync component',
    interaction: 'onMouseEnter',
    eventAction: 'hover',
    eventLabel: "Entity's last sync component info viewed",
  };

  return (
    <HeaderLabel
      label="Last Sync"
      value={
        <HStack spacing="0.5rem" alignItems="center">
          <Box
            borderRadius="100%"
            width="10px"
            height="10px"
            style={{ backgroundColor: getStatusColor(theme, lastSyncDate) }}
            margin="0"
          >
            &nbsp;
          </Box>
          <Typography style={{ fontSize: '14px' }}>{lastSyncDate}</Typography>
          {sources && (
            <EventTracker {...trackingInfo}>
              <Tooltip
                title={`This entity fetches data from the following sources: ${sources.join(
                  ', ',
                )}`}
                placement="top"
              >
                <InfoIcon fontSize="small" />
              </Tooltip>
            </EventTracker>
          )}
        </HStack>
      }
    />
  );
}
