import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  strippedList: {
    '& li:nth-child(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  subtitle: {
    marginTop: theme.spacing(4),
  },
  alert: {
    marginBottom: theme.spacing(),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: 'none',
    border: `1px ${theme.palette.divider} solid`,
    marginTop: theme.spacing(),
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(),
    height: 35,
    fontSize: theme.typography.body2.fontSize,
  },
  iconButton: {
    color: theme.palette.success.main,
    backgroundColor: theme.palette.background.default,
    borderRadius: '0 4px 4px 0',
    height: '100%',
    width: '47px',
    borderLeft: `1px ${theme.palette.divider} solid`,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
