import { DiscoveryApi } from '@backstage/core-plugin-api';

import {
  RunResponse,
  RunsResponse,
  RunGroupsResponse,
  DeploymentStatusResponse,
  TestResultsResponse,
  JUnitTestResultsResponse,
  ArtifactsResponse,
  RepositoriesGetResponse,
  RepositoriesFilterResponse,
  SecretResponse,
  DeploymentsResponse,
  RollbackPlan,
  RollbackConfirmation,
  StacksetResponse,
} from './types/responses';
import { AbortException } from './exceptions';
import { CDPApi, RetriggerRunBody } from './cdpApi';
import { parseResponse, fetchRetry } from './utils';
import { refreshAccessTokenIfExpiring } from 'plugin-core';

export class CDPClient implements CDPApi {
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = options.discoveryApi;
  }

  async getLatestPipeline(
    domain: string,
    org: string,
    repo: string,
    branch: string,
    event: string,
    status: string,
  ): Promise<{ pipelines: { id: string; build_version: string }[] }> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${proxyUrl}/cdp/pipelines/${domain}/${org}/${repo}?branch=${branch}&event=${event}&status=${status}&limit=1`;
    await refreshAccessTokenIfExpiring(this.discoveryApi);

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    return await parseResponse(response);
  }

  async getRollbackPlan(
    domain: string,
    org: string,
    repo: string,
  ): Promise<RollbackPlan> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${proxyUrl}/cdp/rollback/plan/${domain}/${org}/${repo}`;
    await refreshAccessTokenIfExpiring(this.discoveryApi);

    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
    });
    return await parseResponse(response);
  }

  async rollbackDeployment(actionURL: string): Promise<RollbackConfirmation> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${proxyUrl}/cdp${actionURL}`;
    await refreshAccessTokenIfExpiring(this.discoveryApi);

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    });
    return await parseResponse(response);
  }

  async getDeployments({
    from,
    to,
    target,
    cursor,
    signal,
    repository,
  }: {
    from?: string;
    to?: string;
    target?: string;
    cursor?: string;
    signal?: AbortSignal;
    repository?: string;
  }): Promise<DeploymentsResponse> {
    const queryFrom = from ? `from_date=${from}` : '';
    const queryTo = to ? `to_date=${to}` : '';
    const queryTarget = target ? `target=${target}` : '';
    const queryCursor = cursor ? `cursor=${cursor}` : '';
    const queryRepo = repository ? `repository=${repository}` : '';
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url = `${proxyUrl}/cdp/deployments?${queryFrom}&${queryTo}&${queryTarget}&${queryCursor}&${queryRepo}`;

    await refreshAccessTokenIfExpiring(this.discoveryApi);
    let response: Response = {} as Response;
    const options: { [key: string]: any } = { credentials: 'include' };
    if (signal) options.signal = signal;

    try {
      response = await fetchRetry(url, options, 2);
      return await parseResponse(response);
    } catch (err) {
      const error = err as Error;
      if (error?.name === 'AbortError') {
        throw new AbortException('Pending request aborted');
      }

      throw err;
    }
  }

  async getRunGroupsByRepositories(
    repos: string,
    after: string,
    limit: number,
    events: string[],
    abortSignal?: AbortSignal,
  ): Promise<RunGroupsResponse> {
    return await this.post<RunGroupsResponse>(
      `/pipelines`,
      { repos, after, limit, events },
      abortSignal,
    );
  }

  async getRunGroupsByOrganization(
    organizations: string,
    domain: string,
    after: string,
    limit: number,
    events: string[],
    abortSignal?: AbortSignal,
  ): Promise<RunGroupsResponse> {
    return await this.post<RunGroupsResponse>(
      `/pipelines`,
      { organizations, domain, after, limit, events },
      abortSignal,
    );
  }

  async getRunGroupsByOrganizations(
    organizations: string,
    after: string,
    limit: number,
    events: string[],
    abortSignal?: AbortSignal,
  ): Promise<RunGroupsResponse> {
    return await this.post<RunGroupsResponse>(
      `/pipelines`,
      { organizations, after, limit, events },
      abortSignal,
    );
  }

  async getRuns(
    repoURL: string,
    after: string,
    limit: number,
    events: string[],
    abortSignal?: AbortSignal,
  ): Promise<RunsResponse> {
    return await this.get<RunsResponse>(
      `/pipelines/${repoURL}/runs`,
      { repoURL, after, limit, events },
      abortSignal,
      3,
    );
  }

  async getRun(runId: string, abortSignal?: AbortSignal): Promise<RunResponse> {
    return await this.get<RunResponse>(`/runs/${runId}`, {}, abortSignal);
  }

  async abortRun(runId: string): Promise<Response> {
    return await this.post(`/runs/${runId}/abort`);
  }

  async retriggerRun(runId: string, body: RetriggerRunBody): Promise<Response> {
    return await this.post(`/runs/${runId}/retrigger`, body);
  }

  async retriggerStep(runId: string, ordinal: number): Promise<Response> {
    return await this.post(`/runs/${runId}/steps/${ordinal}/retrigger`);
  }

  async retriggerStepAndUnblockSecurityError(
    runId: string,
    ordinal: number,
  ): Promise<Response> {
    return await this.post(
      `/cdp/unblock/${runId}/${ordinal}`,
      {},
      undefined,
      'proxy',
    );
  }

  async approveStep(runId: string, ordinal: number): Promise<Response> {
    return await this.post(`/runs/${runId}/steps/${ordinal}/approve`);
  }

  async rejectStep(runId: string, ordinal: number): Promise<Response> {
    return await this.post(`/runs/${runId}/steps/${ordinal}/reject`);
  }

  async abortStep(runId: string, ordinal: number): Promise<Response> {
    return await this.post(`/runs/${runId}/steps/${ordinal}/abort`);
  }

  async pauseTrafficSwitch(stepRunId: string): Promise<Response> {
    return await this.post(
      `/cdp-graphql/api/traffic/${stepRunId}/pauses`,
      {},
      undefined,
      'proxy',
    );
  }

  async resumeTrafficSwitch(
    stepRunId: string,
    disableAutomatedRollback: boolean = false,
  ): Promise<Response> {
    return await this.post(
      `/cdp-graphql/api/traffic/${stepRunId}/resumptions?automated-rollback-disabled=${disableAutomatedRollback}`,
      {},
      undefined,
      'proxy',
    );
  }

  async rollbackTrafficSwitch(stepRunId: string): Promise<Response> {
    return await this.post(
      `/cdp-graphql/api/traffic/${stepRunId}/rollback`,
      {},
      undefined,
      'proxy',
    );
  }

  async cancelTrafficSwitch(stepRunId: string): Promise<Response> {
    return await this.post(
      `/cdp-graphql/api/traffic/${stepRunId}/cancellation`,
      {},
      undefined,
      'proxy',
    );
  }

  async promoteTrafficSwitch(stepRunId: string): Promise<Response> {
    return await this.post(
      `/cdp-graphql/api/traffic/${stepRunId}/manual-promotion`,
      {},
      undefined,
      'proxy',
    );
  }

  async skipStep(runId: string, ordinal: number): Promise<Response> {
    return await this.post(`/runs/${runId}/steps/${ordinal}/skip`);
  }

  async getDeploymentStatus(
    runId: string,
    ordinal: string,
    stepRunId: string,
    params: { stepId: string },
  ): Promise<DeploymentStatusResponse> {
    return await this.get(
      `/runs/${runId}/steps/${ordinal}/runs/${stepRunId}/deploymentStatus`,
      params,
    );
  }

  async getArtifacts(
    runId: string,
    ordinal: string,
    stepRunId: string,
    params: { stepId: string },
  ): Promise<ArtifactsResponse> {
    return await this.get(
      `/runs/${runId}/steps/${ordinal}/runs/${stepRunId}/artifacts`,
      params,
    );
  }

  async downloadArtifact(
    runId: string,
    ordinal: string,
    stepRunId: string,
    path: string,
  ): Promise<Response> {
    const url: string = await this.discoveryApi.getBaseUrl('cdp');
    // Check and in case refresh expiring token
    await refreshAccessTokenIfExpiring(this.discoveryApi);
    const requestPath = `/runs/${runId}/steps/${ordinal}/runs/${stepRunId}/artifacts/${path}`;

    // Authentication is made via SessionId Cookie (credentials: 'include' required)
    // in request so Authentication header with token is not needed
    return await fetch(`${url}${requestPath}`, {
      credentials: 'include',
    });
  }

  async getTestUploads(
    runId: string,
    ordinal: string,
    stepRunId: string,
  ): Promise<TestResultsResponse> {
    return await this.get(
      `/runs/${runId}/steps/${ordinal}/runs/${stepRunId}/testUploads`,
    );
  }

  async getTestUpload(
    runId: string,
    ordinal: string,
    stepRunId: string,
    index: string,
  ): Promise<JUnitTestResultsResponse> {
    return await this.get(
      `/runs/${runId}/steps/${ordinal}/runs/${stepRunId}/testUploads/${index}`,
    );
  }

  async filterRepositories(
    domain: string,
    organization: string,
  ): Promise<RepositoriesFilterResponse> {
    return await this.get(`/repositories/${domain}/${organization}`);
  }

  async getRepositories(): Promise<RepositoriesGetResponse> {
    return await this.get('/repositories');
  }

  async upsertSecret(
    repositoryFullPath: string,
    secretID: string,
    body: { secret_value: string; available_in_pull_requests: boolean },
  ): Promise<SecretResponse> {
    const path = `/cdp-graphql/api/secrets/${repositoryFullPath}/${secretID}`;
    return await this.put(path, body, undefined, 'proxy');
  }

  async validateSecret(
    repositoryFullPath: string,
    secretID: string,
    secretVersion: string,
    body: { reference_value: string },
  ): Promise<SecretResponse> {
    const path = `/cdp-graphql/api/secrets/${repositoryFullPath}/${secretID}/${secretVersion}`;
    return await this.post(path, body, undefined, 'proxy');
  }

  async getTrafficStackset(id: string): Promise<StacksetResponse> {
    const path = `/cdp/traffic/${id}/stackset`;
    return await this.get(path, undefined, undefined, 0, 'proxy');
  }

  private async get<T>(
    path: string,
    params: any = {},
    abortSignal?: AbortSignal,
    retries = 0,
    api: string = 'cdp',
  ): Promise<T> {
    const url: string = `${await this.discoveryApi.getBaseUrl(api)}${path}`;
    // Check and in case refresh expiring token
    await refreshAccessTokenIfExpiring(this.discoveryApi);
    const urlParams: URLSearchParams = new URLSearchParams(params);
    let response: Response = {} as Response;

    // Authentication is made via SessionId Cookie (credentials: 'include' required)
    // in request so Authentication header with token is not needed
    try {
      response = await fetchRetry(
        `${url}?${urlParams}`,
        {
          signal: abortSignal,
          credentials: 'include',
        },
        retries,
      );

      return await parseResponse(response);
    } catch (err) {
      const error = err as Error;
      if (error?.name === 'AbortError') {
        throw new AbortException('Pending request aborted');
      }

      throw err;
    }
  }

  private async post<T>(
    path: string,
    body: any = {},
    abortSignal?: AbortSignal,
    api: string = 'cdp',
  ): Promise<T> {
    const url: string = `${await this.discoveryApi.getBaseUrl(api)}${path}`;
    // Check and in case refresh expiring token
    await refreshAccessTokenIfExpiring(this.discoveryApi);
    let response: Response = {} as Response;

    // Authentication is made via SessionId Cookie (credentials: 'include' required)
    // in request so Authentication header with token is not needed
    try {
      response = await fetch(url, {
        method: 'POST',
        signal: abortSignal,
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        credentials: 'include',
        body: JSON.stringify(body),
      });

      return await parseResponse(response);
    } catch (err) {
      const error = err as Error;
      if (error?.name === 'AbortError') {
        throw new AbortException('Pending request aborted');
      }

      throw err;
    }
  }

  private async put<T>(
    path: string,
    body: any = {},
    abortSignal?: AbortSignal,
    api: string = 'cdp',
  ): Promise<T> {
    const url: string = `${await this.discoveryApi.getBaseUrl(api)}${path}`;
    // Check and in case refresh expiring token
    await refreshAccessTokenIfExpiring(this.discoveryApi);
    let response: Response = {} as Response;

    // Authentication is made via SessionId Cookie (credentials: 'include' required)
    // in request so Authentication header with token is not needed
    try {
      response = await fetch(url, {
        method: 'PUT',
        signal: abortSignal,
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
        credentials: 'include',
        body: JSON.stringify(body),
      });

      return await parseResponse(response);
    } catch (err) {
      const error = err as Error;
      if (error?.name === 'AbortError') {
        throw new AbortException('Pending request aborted');
      }

      throw err;
    }
  }
}
