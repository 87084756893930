import makeStyles from '@mui/styles/makeStyles';

export const useTagStyles = makeStyles(theme => ({
  loader: {
    height: '30px',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
  },
  row: {
    color: theme.palette.text.primary,
    height: '30px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridGap: theme.spacing(2),
    alignItems: 'center',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  tag: {
    marginRight: '2px',
  },
}));
