import React, {
  ChangeEvent,
  useEffect,
  useState,
  forwardRef,
  FormEventHandler,
} from 'react';
import { DateTime } from 'luxon';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import {
  Alert,
  AlertFormProps,
  Duration,
  DEFAULT_DATE_FORMAT,
  Maybe,
  AlertSnoozeFormData,
  AlertSnoozeOptions,
} from '../types';
import { useAlertDialogStyles as useStyles } from '../utils/styles';
import { intervalsOf } from '../utils/duration';

export type AlertSnoozeFormProps = AlertFormProps<Alert, AlertSnoozeFormData>;

export const AlertSnoozeForm = forwardRef<
  HTMLFormElement,
  AlertSnoozeFormProps
>(({ onSubmit, disableSubmit }, ref) => {
  const classes = useStyles();
  const [duration, setDuration] = useState<Maybe<Duration>>(Duration.P7D);

  useEffect(() => disableSubmit(false), [disableSubmit]);

  const onFormSubmit: FormEventHandler = e => {
    e.preventDefault();
    if (duration) {
      const repeatInterval = 1;
      const today = DateTime.now().toFormat(DEFAULT_DATE_FORMAT);
      onSubmit({
        intervals: intervalsOf(duration, today, repeatInterval),
      });
    }
  };

  const onSnoozeDurationChange = (
    _: ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setDuration(value as Duration);
  };

  return (
    <form ref={ref} onSubmit={onFormSubmit}>
      <FormControl component="fieldset" fullWidth>
        <Typography color="textPrimary">
          <b>For how long?</b>
        </Typography>
        <Box mb={1}>
          <RadioGroup
            name="snooze-alert-options"
            value={duration}
            onChange={onSnoozeDurationChange}
          >
            {AlertSnoozeOptions.map(option => (
              <FormControlLabel
                key={`snooze-alert-option-${option.duration}`}
                label={option.label}
                value={option.duration}
                control={<Radio className={classes.radio} />}
              />
            ))}
          </RadioGroup>
        </Box>
      </FormControl>
    </form>
  );
});
