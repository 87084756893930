import React from 'react';
import { Text } from '../../../common';
import ErrorIcon from '@mui/icons-material/Error';

import * as S from './styles';

export const ErrorOccurred = () => (
  <S.ErrorWrapper>
    <ErrorIcon />

    <Text py={2} data-testid="run-groups-error">
      Oops, something went wrong. Refresh the page to try again.
    </Text>
  </S.ErrorWrapper>
);
