import styled from '@mui/styles/styled';
import MaterialCard from '@mui/material/Card';

export const Content = styled('main')({
  flex: 1,
});

export const Card = styled(MaterialCard)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});
