import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  chip: {
    margin: 0,
    color: theme.palette.info.dark,
    borderColor: 'currentColor',

    '& svg[class*=MuiChip-icon]': {
      width: '1em',
      marginLeft: '0.5em',
    },
  },
  labelWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.5rem',
    verticalAlign: 'bottom',
  },
}));
