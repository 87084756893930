import React from 'react';
import { Link } from '@backstage/core-components';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import EditIcon from '@mui/icons-material/Edit';
import {
  AddShortcutPopup,
  SHORTCUTS_STORE_KEY,
  SavedShortcut,
} from './AddShortcutPopup';
import { URLIcon } from './URLIcon';

interface Props {
  onNavClick: VoidFunction;
}

export function Shortcuts({ onNavClick }: Props) {
  const [shortcuts, setShortcuts] = React.useState<SavedShortcut[]>([]);
  const [open, setOpen] = React.useState(false);
  const [editId, setEditId] = React.useState('');
  const btnRef = React.useRef<HTMLButtonElement>(null);

  function updateShortcuts() {
    setShortcuts(JSON.parse(localStorage.getItem(SHORTCUTS_STORE_KEY) || '[]'));
  }

  React.useEffect(updateShortcuts, []);

  function onClose() {
    setOpen(false);
    setEditId('');
    updateShortcuts();
  }

  function onEdit(e: React.MouseEvent<HTMLButtonElement>, id: string) {
    e.stopPropagation();
    setEditId(id);
    setOpen(true);
  }

  return (
    <div className="shortcuts">
      <AddShortcutPopup
        open={open}
        anchorEl={btnRef.current}
        onClose={onClose}
        editId={editId}
      />
      <button onClick={() => setOpen(!open)} ref={btnRef}>
        <PlaylistAddIcon />
        <span>Add Shortcuts</span>
      </button>
      {shortcuts.map(({ id, url, title }) => (
        <div className="shortcut" key={id}>
          <Link to={url} onClick={onNavClick}>
            <span className="icon">
              <URLIcon url={url} />
            </span>
            <span>{title}</span>
          </Link>
          <button className="edit" onClick={e => onEdit(e, id)}>
            <EditIcon />
          </button>
        </div>
      ))}
    </div>
  );
}
