import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useStyles } from '../styles';

interface Props {
  data: ICDPMonitoring.PipelineStats | ICDPMonitoring.StepInfo | undefined;
  loading: boolean;
  title: string;
}

export function FailureRate({ data, loading, title }: Props) {
  const styles = useStyles();

  const failed = data?.execution_count?.FAILED || 0;
  const total = (Object.values(data?.execution_count ?? {}) as number[]).reduce(
    (acc, curr) => acc + curr,
    0,
  );

  return (
    <Card elevation={3} className={styles.card}>
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
      <CardHeader title={title} />
      <CardContent>
        <Typography className={styles.statTypography}>
          {Intl.NumberFormat('en', {
            maximumFractionDigits: 2,
            style: 'percent',
          }).format(failed / total || 0)}
        </Typography>
        <Typography>
          ({failed} out of {total} jobs)
        </Typography>
      </CardContent>
    </Card>
  );
}
