import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { DefaultTheme } from '@mui/styles/defaultTheme';
import type { CSSProperties } from 'react';

export const useBreadCrumbStyles = makeStyles(theme => ({
  link: {
    color: 'white',
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(1, 0, 0, 0),
  },
}));

export const useTablePlaceholderStyles = makeStyles(theme => ({
  code: {
    margin: `${theme.spacing(2)}px`,
    textAlign: 'center',
  },
}));

export const useTeamFilterBoxBorderStyles = makeStyles({
  inputRoot: {
    backgroundColor: alpha('#f2f2f2', 0.05),
  },
});

export const tableHeaderStyles = (theme: DefaultTheme): CSSProperties => {
  return {
    padding: theme.spacing(1, 2, 1, 2.5),
    borderTop: `1px solid ${theme.palette.grey.A100}`,
    borderBottom: `1px solid ${theme.palette.grey.A100}`,
    color: theme.palette.textSubtle,
    fontWeight: theme.typography.fontWeightBold,
    position: 'static',
    wordBreak: 'normal',
  };
};

export const useNotFoundPageStyles = makeStyles({
  grid: {
    paddingTop: '2rem',
    gridColumn: '1/3',
    alignItems: 'center',
    justifyContent: 'space-around',

    '& > *': {
      textAlign: 'center',
    },
  },
});

export const useAutocompleteStyles = makeStyles(theme => ({
  groupLabel: {
    textTransform: 'uppercase',
  },
  groupUl: {
    paddingLeft: theme.spacing(2),
  },
}));

export const approvalButtonStyles = makeStyles({
  float: {
    position: 'fixed',
    right: '0px',
    bottom: '0px',
    cursor: 'pointer',
    margin: '20px',
  },
  m10: {
    margin: '10px',
  },
  m5: {
    margin: '5px',
  },
});
