import React from 'react';
import Chip from '@mui/material/Chip';

export const CyberWeekChip = ({ zone }: { zone: string }) => {
  let color = '';
  switch (zone.toLowerCase()) {
    case 'green':
      color = 'success';
      break;
    case 'red':
      color = 'error';
      break;
    case 'amber':
      color = 'warning';
      break;
    default:
  }
  return <Chip label={zone} className={`${color} filled`} />;
};
