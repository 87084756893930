import { createApiRef } from '@backstage/core-plugin-api';
import {
  RunResponse,
  RunsResponse,
  RunGroupsResponse,
  DeploymentStatusResponse,
  TestResultsResponse,
  JUnitTestResultsResponse,
  ArtifactsResponse,
  RepositoriesGetResponse,
  RepositoriesFilterResponse,
  SecretResponse,
  RollbackPlan,
  RollbackConfirmation,
  StacksetResponse,
  DeploymentsResponse,
} from './types/responses';

export const cdpApiRef = createApiRef<CDPApi>({
  id: 'plugin.cdp.service',
});

export type RetriggerRunBody = {
  parameters?: { name: string; value: string | boolean }[];
  use_fresh_dependencies: boolean;
};

export interface CDPApi {
  getRunGroupsByRepositories(
    repos: string,
    after: string,
    first: number,
    events: string[],
    abortSignal?: AbortSignal,
  ): Promise<RunGroupsResponse>;
  getRunGroupsByOrganization(
    organizations: string,
    domain: string,
    after: string,
    first: number,
    events: string[],
    abortSignal?: AbortSignal,
  ): Promise<RunGroupsResponse>;
  getRunGroupsByOrganizations(
    organizations: string,
    after: string,
    first: number,
    events: string[],
    abortSignal?: AbortSignal,
  ): Promise<RunGroupsResponse>;
  getRuns(
    repositoryURL: string,
    after: string,
    first: number,
    events: string[],
    abortSignal?: AbortSignal,
  ): Promise<RunsResponse>;
  getRun(runId: string, abortSignal?: AbortSignal): Promise<RunResponse>;
  abortRun(runId: string): Promise<Response>;
  retriggerRun(runId: string, body?: RetriggerRunBody): Promise<Response>;
  retriggerStep(runId: string, ordinal: number): Promise<Response>;
  retriggerStepAndUnblockSecurityError(
    runId: string,
    ordinal: number,
  ): Promise<Response>;
  abortStep(runId: string, ordinal: number): Promise<Response>;
  approveStep(runId: string, ordinal: number): Promise<Response>;
  rejectStep(runId: string, ordinal: number): Promise<Response>;
  pauseTrafficSwitch(stepRunId: string): Promise<Response>;
  resumeTrafficSwitch(
    stepRunId: string,
    disableAutomatedRollback: boolean,
  ): Promise<Response>;
  rollbackTrafficSwitch(stepRunId: string): Promise<Response>;
  skipStep(runId: string, ordinal: number): Promise<Response>;
  getDeploymentStatus(
    runId: string,
    ordinal: string,
    stepRunId: string,
    params: { stepId: string },
  ): Promise<DeploymentStatusResponse>;
  getArtifacts(
    runId: string,
    ordinal: string,
    stepRunId: string,
    params: { stepId: string },
  ): Promise<ArtifactsResponse>;
  downloadArtifact(
    runId: string,
    ordinal: string,
    stepRunId: string,
    path: string,
  ): Promise<any>;
  getTestUploads(
    runId: string,
    ordinal: string,
    stepRunId: string,
  ): Promise<TestResultsResponse>;
  getTestUpload(
    runId: string,
    ordinal: string,
    stepRunId: string,
    index: string,
  ): Promise<JUnitTestResultsResponse>;
  getRepositories(): Promise<RepositoriesGetResponse>;
  filterRepositories(
    domain: string,
    organization: string,
  ): Promise<RepositoriesFilterResponse>;
  upsertSecret(
    repositoryFullPath: string,
    secretName: string,
    params: {
      secret_value: string;
      available_in_pull_requests: boolean;
    },
  ): Promise<SecretResponse>;
  validateSecret(
    repositoryFullPath: string,
    secretName: string,
    secretVersion: string,
    params: {
      reference_value: string;
    },
  ): Promise<SecretResponse>;
  getRollbackPlan(
    domain: string,
    org: string,
    repo: string,
  ): Promise<RollbackPlan>;
  rollbackDeployment(actionURL: string): Promise<RollbackConfirmation>;
  getLatestPipeline(
    domain: string,
    org: string,
    repo: string,
    branch: string,
    event: string,
    status: string,
  ): Promise<{ pipelines: { id: string; build_version: string }[] }>;
  getTrafficStackset(id: string): Promise<StacksetResponse>;
  cancelTrafficSwitch(id: string): Promise<Response>;
  promoteTrafficSwitch(id: string): Promise<Response>;
  getDeployments({
    from,
    to,
    target,
    cursor,
    signal,
    repository,
  }: {
    from?: string;
    to?: string;
    target?: string;
    cursor?: string;
    signal?: AbortSignal;
    repository?: string;
  }): Promise<DeploymentsResponse>;
}
