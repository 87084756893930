import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import { EventTracker, TrackedButton } from 'plugin-ui-components';
import { AccessControlContext } from '../../context';
import type { AccessControlCardProps } from './types';
import {
  cancelButton,
  editButton,
  refreshButton,
  saveButton,
  searchField,
} from './tracking';
import { useStyles } from './styles';
import { ActionButton } from './ActionButton';

export const AccessControlCard = ({
  data,
  title,
  render,
  extraContent,
  canEdit,
  onEdit,
  onSave,
  isLoading,
  onCancel,
  onRefresh,
  desc,
  filterBy = 'name',
  enableSearch = true,
  actions,
  showPending,
  trackingPlugin,
}: AccessControlCardProps) => {
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [updatedData, setUpdatedData] = useState<any>([]);
  const [extraUpdatedData, setExtraUpdatedData] = useState<any>();
  const [selectAll, setSelectAll] = useState<boolean | undefined>(undefined);
  const [hasSearchText, setHasSearchText] = useState<boolean>(false);
  const [refreshStatus, setRefreshStatus] = useState<string>();

  const styles = useStyles();

  const { hasEditPermission } = React.useContext(AccessControlContext);

  const trackingParams = {
    widget: title.toLowerCase(),
    plugin: trackingPlugin,
  };

  const handleEdit = async () => {
    if (onEdit) await onEdit();
  };

  const handleRefresh = async () => {
    if (onRefresh) {
      setRefreshStatus('loading');
      try {
        const refreshResponseOk = await onRefresh();
        if (refreshResponseOk) {
          setRefreshStatus('completed');
        } else {
          setRefreshStatus('error');
        }
      } catch {
        setRefreshStatus('error');
      }
    }
  };

  const debouncedFilter = debounce(function handleFilter(value: string) {
    if (!value) {
      setFilteredData([]);
      setSelectAll(undefined);
      setHasSearchText(false);
      return;
    }
    const filtered = data.filter(item => {
      return (
        item[filterBy].toLowerCase().includes(value.toLowerCase()) ||
        item.id.toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredData(filtered);
    setHasSearchText(true);
  }, 500);

  const handleSave = async () => {
    await onSave(updatedData, extraUpdatedData);
    if (!updatedData.length) setFilteredData([]);
  };

  const handleCancel = async () => {
    await onCancel();
    setUpdatedData([]);
    setFilteredData([]);
    setSelectAll(undefined);
  };

  return (
    <Card className={styles.card}>
      <CardHeader
        title={
          <Box marginBottom={canEdit ? 0 : 2}>
            {canEdit && (
              <Button
                className={styles.backButton}
                onClick={handleCancel}
                startIcon={<ArrowBackIosIcon />}
                size="small"
              >
                Back
              </Button>
            )}
            <Typography variant="h3">{title}</Typography>
          </Box>
        }
        action={
          !canEdit && (
            <>
              <ActionButton
                hasEditPermission={hasEditPermission}
                onClick={handleEdit}
                trackingProps={editButton(trackingParams)}
              >
                <EditIcon fontSize="small" />
              </ActionButton>
              {onRefresh && (
                <ActionButton
                  hasEditPermission={hasEditPermission}
                  onClick={handleRefresh}
                  trackingProps={refreshButton(trackingParams)}
                  tooltipText="Sync Mint Bucket configuration. Changes can take a few minutes to be reflected in Sunrise"
                >
                  <RefreshIcon fontSize="small" />
                </ActionButton>
              )}
            </>
          )
        }
      />
      {!canEdit && <Divider />}
      <CardContent className={canEdit ? styles.cardContent : ''}>
        {refreshStatus === 'completed' && (
          <Box mb={2}>
            <Alert severity="success">
              The renewal of credentials was successfully triggered. Changes can
              take a few minutes to be reflected in Sunrise.
            </Alert>
          </Box>
        )}
        {refreshStatus === 'error' && (
          <Box mb={2}>
            <Alert severity="error">
              An error occurred when renewing the credentials. Please try again.
            </Alert>
          </Box>
        )}
        {!canEdit && desc && (
          <Typography className={styles.description}>{desc}</Typography>
        )}
        {extraContent &&
          extraContent(canEdit, extraUpdatedData, setExtraUpdatedData)}
        {canEdit && (
          <>
            {showPending ? (
              <Alert className={styles.alert} severity="info">
                You have pending changes, make sure to save before leaving.
              </Alert>
            ) : (
              <Box height={58} />
            )}
            {enableSearch && (
              <Box marginBottom={2}>
                <EventTracker {...searchField(trackingParams)}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={styles.searchBar}
                    margin="dense"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Search data
                    </InputLabel>
                    <OutlinedInput
                      type="text"
                      onKeyUp={e => {
                        const target = e.currentTarget as HTMLInputElement;
                        debouncedFilter(target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      label="Search data"
                    />
                  </FormControl>
                </EventTracker>

                <Box display="flex" alignItems="center">
                  {!isEmpty(filteredData) && (
                    <Box marginRight="auto">
                      <FormControlLabel
                        className={styles.checkbox}
                        control={
                          <Checkbox
                            size="small"
                            name="checkedA"
                            onChange={e => setSelectAll(e.target.checked)}
                          />
                        }
                        label={
                          <Typography variant="body2">Select all</Typography>
                        }
                      />
                    </Box>
                  )}
                  <Box marginLeft="auto">{actions}</Box>
                </Box>
              </Box>
            )}
          </>
        )}
        <Box
          display="flex"
          flexDirection="column"
          className={styles.dataWrapper}
        >
          {isLoading && <CircularProgress className={styles.loader} />}
          {!isEmpty(filteredData) &&
            filteredData.map((item, index) =>
              render(
                item,
                index,
                canEdit,
                updatedData,
                setUpdatedData,
                selectAll,
              ),
            )}
          {isEmpty(filteredData) &&
            !hasSearchText &&
            data.map((item, index) =>
              render(item, index, canEdit, updatedData, setUpdatedData),
            )}
          {isEmpty(filteredData) && hasSearchText && (
            <Typography variant="body2">No results to show</Typography>
          )}
        </Box>
        {canEdit && (
          <Box display="flex" justifyContent="space-between" marginTop="2rem">
            <TrackedButton
              variant="outlined"
              color="primary"
              onClick={handleCancel}
              {...cancelButton(trackingParams)}
            >
              Cancel
            </TrackedButton>
            <TrackedButton
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={extraUpdatedData?.error}
              {...saveButton(trackingParams)}
            >
              Save
            </TrackedButton>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
