import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CostInsightsThemeProvider } from '../CostInsightsPage/CostInsightsThemeProvider';
import { ConfigProvider, CurrencyProvider } from '../../hooks';
import { Header, Page } from '@backstage/core-components';

const useStyles = makeStyles(theme => ({
  root: {
    gridArea: 'pageContent',
    padding: theme.spacing(4),
  },
}));

type AlertInstructionsLayoutProps = {
  title: string;
};

export const AlertInstructionsLayout = ({
  title,
  children,
}: PropsWithChildren<AlertInstructionsLayoutProps>) => {
  const classes = useStyles();
  return (
    <CostInsightsThemeProvider>
      <ConfigProvider>
        <CurrencyProvider>
          <Page themeId="tool">
            <Header
              title="Cost Insights"
              pageTitleOverride={title}
              type="Tool"
            />
            <Container maxWidth="md" disableGutters className={classes.root}>
              <Box mb={3}>
                <Button
                  variant="outlined"
                  startIcon={<ChevronLeftIcon />}
                  href="/cost-insights"
                >
                  Back to Cost Insights
                </Button>
              </Box>
              {children}
            </Container>
          </Page>
        </CurrencyProvider>
      </ConfigProvider>
    </CostInsightsThemeProvider>
  );
};
