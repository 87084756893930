import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MaterialTooltip, { type TooltipProps } from '@mui/material/Tooltip';

const useStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
  popper: {
    zIndex: 10000,
  },
}));

export function Tooltip(props: TooltipProps) {
  const classes = useStyles();
  return <MaterialTooltip arrow classes={classes} {...props} />;
}
