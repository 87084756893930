import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MaterialAlert from '@mui/lab/Alert';
import { costInsightsApiRef } from '../../api';
import { ActionItems } from '../ActionItems';
import { AlertInsights } from '../AlertInsights';
import { CostInsightsLayout } from '../CostInsightsLayout';
import { WhyCostsMatter } from '../WhyCostsMatter';
import {
  CostInsightsHeader,
  CostInsightsHeaderNoGroups,
} from '../CostInsightsHeader';
import { CostOverviewCard } from '../CostOverviewCard';
import {
  useFilters,
  useLastCompleteBillingDate,
  useLoading,
} from '../../hooks';
import {
  Alert,
  Cost,
  CostChange,
  Maybe,
  MetricData,
  TrustedAdvisorData,
} from '../../types';
import { mapLoadingToProps } from './selector';
import { intervalsOf } from '../../utils/duration';
import {
  isAlertAccepted,
  isAlertActive,
  isAlertDismissed,
  isAlertSnoozed,
} from '../../utils/alerts';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { InsightsCard } from '../InsightsCard';

export const CostInsightsPage = () => {
  const client = useApi(costInsightsApiRef);
  const lastCompleteBillingDate = useLastCompleteBillingDate();
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [dailyCost, setDailyCost] = useState<Maybe<Cost>>(null);
  const [metricData, setMetricData] = useState<Maybe<MetricData>>(null);
  const [error, setError] = useState<Maybe<Error>>(null);
  const [trustedAdvisorData, setTrustedAdvisorData] =
    useState<Maybe<TrustedAdvisorData>>(null);
  const [costChanges, setCostChanges] = useState<Maybe<CostChange>>(null);

  const { pageFilters, groups } = useFilters(p => p);

  const active = useMemo(() => alerts.filter(isAlertActive), [alerts]);
  const snoozed = useMemo(() => alerts.filter(isAlertSnoozed), [alerts]);
  const accepted = useMemo(() => alerts.filter(isAlertAccepted), [alerts]);
  const dismissed = useMemo(() => alerts.filter(isAlertDismissed), [alerts]);

  const isActionItemsDisplayed = !!active.length;
  const isAlertInsightsDisplayed = !!alerts.length;

  const {
    loadingActions,
    loadingGroups,
    loadingBillingDate,
    loadingInitial,
    loadingInsights,
    dispatchInitial,
    dispatchInsights,
    dispatchNone,
    dispatchReset,
  } = useLoading(mapLoadingToProps);

  /* eslint-disable react-hooks/exhaustive-deps */
  // The dispatchLoading functions are derived from loading state using mapLoadingToProps, to
  // provide nicer props for the component. These are re-derived whenever loading state changes,
  // which causes an infinite loop as product panels load and re-trigger the useEffect below.
  // Since the functions don't change, we can memoize - but we trigger the same loop if we satisfy
  // exhaustive-deps by including the function itself in dependencies.

  const dispatchLoadingInitial = useCallback(dispatchInitial, []);
  const dispatchLoadingInsights = useCallback(dispatchInsights, []);
  const dispatchLoadingNone = useCallback(dispatchNone, []);
  const dispatchLoadingReset = useCallback(dispatchReset, []);

  useEffect(() => {
    async function getInsights() {
      setError(null);
      try {
        if (pageFilters.group) {
          dispatchLoadingInsights(true);
          const intervals = intervalsOf(
            pageFilters.duration,
            lastCompleteBillingDate,
          );
          const [
            fetchedAlerts,
            fetchedMetricData,
            fetchedDailyCost,
            fetchedTrustedAdvisorData,
            fetchedCostChanges,
          ] = await Promise.all([
            client.getAlerts(pageFilters.group),
            pageFilters.metric
              ? client.getDailyMetricData(pageFilters.metric, intervals)
              : null,
            pageFilters.project
              ? client.getProjectDailyCost(pageFilters.project, intervals)
              : client.getGroupDailyCost(
                  pageFilters.group,
                  intervals,
                  pageFilters.overviewMode,
                ),
            client.getTrustedAdvisorData(pageFilters.group),
            client.getWeeklyChange(pageFilters.group, lastCompleteBillingDate),
          ]);
          setAlerts(fetchedAlerts);
          setMetricData(fetchedMetricData);
          setDailyCost(fetchedDailyCost);
          setTrustedAdvisorData(fetchedTrustedAdvisorData);
          setCostChanges(fetchedCostChanges);
        } else {
          dispatchLoadingNone(loadingActions);
        }
      } catch (e: any) {
        setError(new Error(e));
        dispatchLoadingNone(loadingActions);
      } finally {
        dispatchLoadingInitial(false);
        dispatchLoadingInsights(false);
        dispatchLoadingReset(loadingActions);
      }
    }

    // Wait for metadata to finish loading
    if (!loadingGroups && !loadingBillingDate) {
      getInsights();
    }
  }, [
    client,
    pageFilters,
    loadingActions,
    loadingGroups,
    loadingBillingDate,
    dispatchLoadingInsights,
    dispatchLoadingInitial,
    dispatchLoadingNone,
    lastCompleteBillingDate,
  ]);

  if (loadingInitial || loadingGroups) {
    return <Progress />;
  }

  if (error) {
    return <MaterialAlert severity="error">{error.message}</MaterialAlert>;
  }

  // Loaded but no groups found for the user
  if (!pageFilters.group) {
    return (
      <CostInsightsLayout groups={groups}>
        <CostInsightsHeaderNoGroups />
        <Divider />
        <WhyCostsMatter />
      </CostInsightsLayout>
    );
  }

  if (loadingInsights) {
    return <CostInsightsLayout groups={groups} />;
  }

  if (!dailyCost) {
    return (
      <CostInsightsLayout groups={groups}>
        <CostInsightsHeaderNoGroups />
        <Divider />
        <WhyCostsMatter />
      </CostInsightsLayout>
    );
  }

  const group = groups.find(g => g.id === pageFilters.group);

  const shouldShowHeader =
    active.length > 0 ||
    !dailyCost.aggregation ||
    dailyCost.aggregation.length === 0;
  return (
    <CostInsightsLayout groups={groups}>
      <Grid wrap="nowrap" container>
        <Grid item>
          <InsightsCard
            group={group}
            trustedAdvisorData={trustedAdvisorData}
            costChanges={costChanges}
          />
        </Grid>
        <Grid item>
          <Container maxWidth="lg" disableGutters>
            <Grid container direction="column">
              {shouldShowHeader && (
                <Grid item xs>
                  <CostInsightsHeader
                    owner={group ? group.name : pageFilters.group}
                    groups={groups}
                    hasCostData={!!dailyCost.aggregation?.length}
                    alerts={active.length}
                  />
                </Grid>
              )}
              <Collapse in={isActionItemsDisplayed} enter={false}>
                <Grid item xs>
                  <ActionItems
                    active={active}
                    snoozed={snoozed}
                    accepted={accepted}
                    dismissed={dismissed}
                  />
                </Grid>
                <Divider />
              </Collapse>
              <Grid item xs>
                <CostOverviewCard
                  dailyCostData={dailyCost}
                  metricData={metricData}
                  group={group}
                />
              </Grid>
              <Collapse in={isAlertInsightsDisplayed} enter={false}>
                <Grid item xs>
                  <Box px={6} py={6} mx={-3} bgcolor="alertBackground">
                    <AlertInsights
                      group={pageFilters.group}
                      active={active}
                      snoozed={snoozed}
                      accepted={accepted}
                      dismissed={dismissed}
                      onChange={setAlerts}
                    />
                  </Box>
                </Grid>
              </Collapse>
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <WhyCostsMatter />
    </CostInsightsLayout>
  );
};
