import { Entity } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import React from 'react';
import { useQuery } from 'react-query';

export interface IApplicationsScorecardsContext {
  data: {
    applications: Map<string, Entity>;
    relations: (Entity | undefined)[];
  };
  isLoading: boolean;
  isError: boolean;
  updateApplications: (applications: Array<Entity>) => void;
}
export interface ApplicationsScorecardsContextRelations {
  scorecards: IEntityScorecard[];
  assessments: IEntityScorecardAssessment[];
}

const defaultValues = {
  data: {
    applications: new Map<string, Entity>(),
    relations: [],
  },
  isLoading: false,
  isError: false,
  updateApplications: () => {},
};

export const ApplicationsScorecardsContext =
  React.createContext<IApplicationsScorecardsContext>(defaultValues);

export function useApplicationScorecardsContext() {
  return React.useContext(ApplicationsScorecardsContext);
}

export const ApplicationsScorecardsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [applications, setApplications] = React.useState<Map<string, Entity>>(
    new Map(),
  );
  const catalogApi = useApi(catalogApiRef);

  const {
    data: contextData,
    isFetching,
    isError,
  } = useQuery(
    ['update-scorecards-context', applications.size],
    async (): Promise<IApplicationsScorecardsContext['data']> => {
      // Fetch scorecards and assessments refs for each application
      const relations = Array.from(applications.values())
        .filter(app =>
          app.relations?.find(
            rel => rel.type === 'consumesScorecard' || rel.type === 'produces',
          ),
        )
        .map(app => app.relations || [])
        .flat();

      const { scorecardRefs, assessmentRefs } = relations.reduce(
        (acc, rel) => {
          if (rel.type === 'consumesScorecard') {
            acc.scorecardRefs.add(rel.targetRef);
          } else if (rel.type === 'produces') {
            acc.assessmentRefs.add(rel.targetRef);
          }
          return acc;
        },
        { scorecardRefs: new Set<string>(), assessmentRefs: new Set<string>() },
      );

      if (scorecardRefs.size && assessmentRefs.size) {
        const response = await catalogApi.getEntitiesByRefs({
          entityRefs: [...scorecardRefs, ...assessmentRefs],
        });
        return {
          applications,
          relations: response.items,
        };
      }
      return contextData || defaultValues.data;
    },
    {
      keepPreviousData: true,
      // Only fetch data if a list of applications is provided
      enabled: !!applications.size,
    },
  );

  // Update the list of applications available in the context
  const updateApplications = React.useCallback(
    (list: Array<Entity>) => {
      const newApplications = list.filter(
        app => !applications.has(app.metadata.name),
      );
      // Update the state only if there are new applications
      if (newApplications.length) {
        const newApplicationsEntries: Array<[string, Entity]> =
          newApplications.map(app => [app.metadata.name, app]);
        setApplications(new Map([...applications, ...newApplicationsEntries]));
      }
    },
    [applications],
  );

  // Memoize the context value to avoid unnecessary re-renders
  const contextValue = React.useMemo(
    () => ({
      data: {
        ...(contextData || defaultValues.data),
      },
      isLoading: isFetching,
      isError,
      updateApplications,
    }),
    [contextData, isError, updateApplications, isFetching],
  );

  return (
    <ApplicationsScorecardsContext.Provider value={contextValue}>
      {children}
    </ApplicationsScorecardsContext.Provider>
  );
};
