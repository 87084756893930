import MaterialCard from '@mui/material/Card';
import styled from '@mui/styles/styled';

export const Content = styled('div')({
  flex: 1,
});

export const Card = styled(MaterialCard)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});
