import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  sliderHeader: {
    margin: 0,
    fontSize: '0.75rem',
    paddingBottom: '1rem',
    whiteSpace: 'break-spaces',
    width: 'min-content',
    textAlign: 'center',
  },
  sliderSize: { height: '360px' },
  sliderWrapper: {
    top: 0,
    display: 'flex',
    rowGap: '0.5rem',
    position: 'sticky',
    paddingRight: '1rem',
    flexDirection: 'column',
  },
}));
