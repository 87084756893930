import styled from '@mui/styles/styled';

export const ActionPanel = styled('section')(props => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  padding: props.theme.spacing(1),

  [props.theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const Filters = styled('div')(props => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [props.theme.breakpoints.up('sm')]: {
    flexFlow: 'row wrap',

    ['& > *']: {
      marginTop: 0,
      marginRight: props.theme.spacing(1),
    },
  },
}));
