import React from 'react';
import { useInterval } from 'react-use';
import sortBy from 'lodash/sortBy';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import { useApi } from '@backstage/core-plugin-api';
import { AccessControlCard, EditMessage } from '../AccessControlCard';
import { ClusterItem } from './ClusterItem';
import { AccessControlContext } from '../../context';
import { clusterRegistryApiRef, mintStorageApiRef } from '../../../../api';
import { hasPendingUpdates, removePendingUpdates } from '../../utils';
import ConfirmationDialog from '../ConfirmationDialog';
import { useSearchParams } from 'react-router-dom';
import { useNavigationBack } from '../../useNavigationBack';

export function KubernetesClusters() {
  const [appClustersData, setAppClustersData] = React.useState<
    IAccessControl.KubernetesCluster[]
  >([]);
  const [allClusters, setAllClusters] = React.useState<
    IAccessControl.KubernetesCluster[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasPendingNotification, setHasPendingNotification] =
    React.useState(false);
  const [visibleData, setVisibleData] = React.useState<any[]>([]);
  const [confirmationData, setConfirmationData] = React.useState<
    Record<string, IAccessControl.KubernetesCluster[]>
  >({
    clustersToAdd: [],
    clustersToRemove: [],
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const [updateState, setUpdateState] = React.useState<{
    pending: boolean;
    success: boolean;
  }>({ pending: false, success: true });
  const [_, setSearchParams] = useSearchParams();

  const mintApi = useApi(mintStorageApiRef);
  const clusterRegistryApi = useApi(clusterRegistryApiRef);

  const handleBack = useNavigationBack({
    page: 'kubernetesClusters',
    searchParamsKeys: ['editKubeClusters'],
  });

  const {
    appName,
    appData,
    loadAppData,
    editState,
    setEditState,
    hasEditPermission,
  } = React.useContext(AccessControlContext);

  const { kubernetes_clusters = [] } = (appData.length as any) && appData[0];

  useInterval(() => {
    const pending = hasPendingUpdates('kubeClusters');
    if (pending && !hasPendingNotification) setHasPendingNotification(true);
    if (!pending) setHasPendingNotification(false);
  }, 500);

  React.useEffect(() => {
    setIsLoading(true);
    clusterRegistryApi
      .getClusters()
      .then(response =>
        setAllClusters(
          response.items.sort((a, b) => a.alias?.localeCompare(b.alias)),
        ),
      )
      .finally(() => setIsLoading(false));
    return () => {
      removePendingUpdates('kubeClusters');
    };
  }, [clusterRegistryApi]);

  React.useEffect(() => {
    if (kubernetes_clusters.length && allClusters.length) {
      setAppClustersData(
        allClusters.filter(cluster => kubernetes_clusters.includes(cluster.id)),
      );
    }
  }, [kubernetes_clusters, allClusters]);

  React.useEffect(() => {
    if (editState.kubernetesClusters) {
      const clustersWithSelected = allClusters.map(cluster => {
        if (kubernetes_clusters.includes(cluster.id)) {
          return { ...cluster, initiallySelected: true };
        }
        return cluster;
      });
      const sortedBySelected = sortBy(clustersWithSelected, [
        function sort(cluster) {
          return !cluster.initiallySelected;
        },
      ]);
      setVisibleData(sortedBySelected);
    } else {
      setVisibleData(appClustersData);
    }
    return () => setVisibleData([]);
  }, [kubernetes_clusters, editState, appClustersData, allClusters]);

  const onEdit = () => {
    setEditState({ ...editState, kubernetesClusters: true });
    setSearchParams({
      editKubeClusters: 'true',
    });
  };

  const onCancel = () => {
    handleBack();
  };

  const showConfirmationModal = async (updatedData: any[]) => {
    if (updatedData.length) {
      const [clustersToRemove, clustersToAdd] = updatedData.reduce(
        (acc, curr) => {
          acc[+curr.selected].push(curr);
          return acc;
        },
        [[], []],
      );
      setOpen(true);
      setConfirmationData({ clustersToRemove, clustersToAdd });
    } else {
      setEditState({ ...editState, kubernetesClusters: false });
    }
  };

  const handleSave = () => {
    setUpdateState(prev => ({ ...prev, pending: true }));

    const clustersIdsToAdd = confirmationData.clustersToAdd.map(c => c.id);
    const clustersIdsToRemove = confirmationData.clustersToRemove.map(
      c => c.id,
    );
    const currentClustersIds = appData[0].kubernetes_clusters.filter(
      clusterId => !clustersIdsToRemove.includes(clusterId),
    );

    const newKubernetesClusters = [...currentClustersIds, ...clustersIdsToAdd];

    mintApi
      .updateKubernetesClusters(appName, appData[0], newKubernetesClusters)
      .then(ok => {
        if (ok) {
          setUpdateState(prev => ({ ...prev, success: true }));
          setEditState({ ...editState, kubernetesClusters: false });
          loadAppData();
          setOpen(false);
        } else {
          setUpdateState(prev => ({ ...prev, success: false }));
        }
      })
      .catch(() => setUpdateState(prev => ({ ...prev, success: false })))
      .finally(() => setUpdateState(prev => ({ ...prev, pending: false })));
  };

  return (
    <>
      <AccessControlCard
        trackingPlugin="application-registry"
        title={
          editState.kubernetesClusters
            ? 'Edit Kubernetes Clusters'
            : 'Kubernetes Clusters'
        }
        desc={
          !visibleData.length ? (
            <Typography>
              This application does not have{' '}
              <strong>Kubernetes Clusters</strong> registered.{' '}
              <EditMessage
                hasEditPermission={hasEditPermission}
                onEdit={onEdit}
              />
            </Typography>
          ) : (
            <>
              This application has <strong>{visibleData.length}</strong>{' '}
              kubernetes clusters registered.
            </>
          )
        }
        data={visibleData}
        onEdit={onEdit}
        canEdit={editState.kubernetesClusters}
        onSave={showConfirmationModal}
        onCancel={onCancel}
        isLoading={isLoading}
        filterBy="alias"
        showPending={hasPendingNotification}
        render={(
          cluster,
          index,
          editable,
          updatedData,
          setUpdatedData,
          selectAll,
        ) => (
          <ClusterItem
            key={index}
            index={index}
            editable={editable}
            updatedData={updatedData}
            setUpdatedData={setUpdatedData}
            selectAll={selectAll}
            {...cluster}
          />
        )}
      />
      <ConfirmationDialog
        open={open}
        onClose={() => {
          setUpdateState({ pending: false, success: true });
          setOpen(false);
        }}
        onSave={handleSave}
      >
        <Box height="5px">{updateState.pending && <LinearProgress />}</Box>
        {!updateState.success && (
          <Alert severity="error">
            Failed updating configurations. Try again.
          </Alert>
        )}
        <Box maxHeight="300px" overflow="scroll" marginTop="1rem">
          {!!confirmationData.clustersToAdd.length && (
            <Box marginBottom="1rem">
              <Typography>
                These will be <strong>added</strong>:
              </Typography>
              <ul>
                {confirmationData.clustersToAdd.map(
                  ({ alias, environment }) => {
                    return (
                      <li key={`${alias}-${environment}`}>
                        <Typography>
                          {alias} ({environment})
                        </Typography>
                      </li>
                    );
                  },
                )}
              </ul>
            </Box>
          )}
          {!!confirmationData.clustersToRemove.length && (
            <Box>
              <Typography>
                These will be <strong>removed</strong>:
              </Typography>
              <ul>
                {confirmationData.clustersToRemove.map(
                  ({ alias, environment }) => {
                    return (
                      <li key={`${alias}-${environment}`}>
                        <Typography>
                          {alias} ({environment})
                        </Typography>
                      </li>
                    );
                  },
                )}
              </ul>
            </Box>
          )}
        </Box>
      </ConfirmationDialog>
    </>
  );
}
