import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { CustomContentProps, useSnackbar } from 'notistack';
import Alert from '@mui/lab/Alert';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { lighten } from '@mui/material/styles';
import { ALERT_DURATION } from './constants';

export const AlerterMessage = forwardRef(
  (
    { variant, message, id, autoHideDuration }: CustomContentProps,
    ref: any,
  ) => {
    const { closeSnackbar } = useSnackbar();
    const timer = useRef<NodeJS.Timer>();
    const [progress, setProgress] = useState(100);
    const color = variant === 'default' ? 'info' : variant;

    useEffect(() => {
      let unmounted = false;
      if (autoHideDuration && autoHideDuration > 0) {
        timer.current = setInterval(() => {
          if (unmounted) return;
          setProgress(prev => {
            if (prev <= 0) {
              return 0;
            }
            return prev - 1;
          });
          // Shortening the time by 500 ms to account for the time it takes to animate the progress bar
        }, (ALERT_DURATION - 500) / 100);
      }
      return () => {
        unmounted = true;
        clearTimeout(timer.current as any);
      };
    }, [autoHideDuration]);

    return (
      <Box ref={ref} maxWidth={350} position="relative">
        <Alert
          severity={color}
          action={
            <IconButton size="small" onClick={() => closeSnackbar(id)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          sx={{
            border: theme =>
              `1px solid ${lighten(theme.palette[color].main, 0.7)}`,
          }}
        >
          {message}
        </Alert>
        {autoHideDuration && autoHideDuration > 0 && (
          <LinearProgress
            variant="determinate"
            value={progress}
            color={color}
            sx={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
            }}
          />
        )}
      </Box>
    );
  },
);
