import React from 'react';
import { useLocation, useResolvedPath } from 'react-router';

import * as S from './styles';

type Props = {
  to: string;
  children: React.ReactNode;
  end?: boolean;
  size?: 'sm' | 'md';
};

// @TODO
// useActivePath is temporary solution, react-router v6 doesn't yet export useRouteMatch
// https://github.com/ReactTraining/react-router/issues/7552

export function useActivePath(
  to: string,
  options: { caseSensitive?: boolean; end?: boolean } = {},
) {
  const { caseSensitive = false, end = false } = options;

  let location = useLocation(); // eslint-disable-line prefer-const
  let path = useResolvedPath(to); // eslint-disable-line prefer-const

  let locationPathname = location.pathname;
  let toPathname = path.pathname;

  if (!caseSensitive) {
    locationPathname = locationPathname.toLowerCase();
    toPathname = toPathname.toLowerCase();
  }

  const isActive = end
    ? locationPathname === toPathname
    : locationPathname.startsWith(toPathname);

  return isActive;
}

export function Tab({ to, children, end = true, size = 'sm' }: Props) {
  const isActive = useActivePath(to, { end });
  return (
    <S.Tab
      data-size={size}
      to={to}
      data-active={Boolean(isActive)}
      tabIndex={0}
    >
      {children}
    </S.Tab>
  );
}
