import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { useStyles } from '../styles';
import { getChartData } from './utils';

ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  data: ICDPMonitoring.PipelineStats | ICDPMonitoring.StepInfo | undefined;
  loading: boolean;
  title: string;
}

export function PipelineStatus({ data, loading, title }: Props) {
  const styles = useStyles();

  return (
    <Card elevation={3} className={styles.card}>
      <Backdrop open={loading} className={styles.backdrop}>
        <CircularProgress />
      </Backdrop>
      <CardHeader title={title} />
      <CardContent>
        <Doughnut
          data={getChartData(data?.execution_count)}
          options={{
            plugins: {
              tooltip: {
                callbacks: {
                  label: item => {
                    const total = item.dataset.data.reduce((acc, curr) => {
                      if (typeof curr === 'number') {
                        return acc + curr;
                      }
                      return acc;
                    }, 0);
                    const percentage = Intl.NumberFormat('en', {
                      style: 'percent',
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }).format(item.parsed / total);

                    return ` ${percentage} (${item.parsed})`;
                  },
                },
              },
              legend: {
                labels: {
                  /**
                   * The color in ChartJS is drawn in a canvas so the color
                   * has to be defined explicitly
                   */
                  color: window
                    .getComputedStyle(document.body)
                    .getPropertyValue('color'),
                },
                display: true,
                position: 'right',
                align: 'center',
              },
            },
          }}
        />
      </CardContent>
    </Card>
  );
}
