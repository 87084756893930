interface TrackingParams {
  widget: string;
  plugin: string;
}

export const common = (plugin: string): Omit<IEventTracker, 'eventLabel'> => ({
  plugin: plugin,
  eventCategory: 'Access Control Card',
  eventAction: 'click',
  interaction: 'onClick',
});

export const editButton = ({
  widget,
  plugin,
}: TrackingParams): IEventTracker => ({
  ...common(plugin),
  eventLabel: `Edit button for ${widget} clicked`,
});

export const refreshButton = ({
  widget,
  plugin,
}: TrackingParams): IEventTracker => ({
  ...common(plugin),
  eventLabel: `Refresh button for ${widget} clicked`,
});

export const searchField = ({
  widget,
  plugin,
}: TrackingParams): IEventTracker => ({
  ...common(plugin),
  eventLabel: `Search field for ${widget} clicked`,
});

export const saveButton = ({
  widget,
  plugin,
}: TrackingParams): IEventTracker => ({
  ...common(plugin),
  eventLabel: `Save button for ${widget} clicked`,
});

export const cancelButton = ({
  widget,
  plugin,
}: TrackingParams): IEventTracker => ({
  ...common(plugin),
  eventLabel: `Cancel button for ${widget} clicked`,
});

export const addButton = ({
  widget,
  plugin,
}: TrackingParams): IEventTracker => ({
  ...common(plugin),
  eventLabel: `Add button for ${widget} clicked`,
});
