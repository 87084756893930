import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/lab/Alert';
import { EventTracker } from 'plugin-ui-components';
import type { AccessControlTableCardProps } from './types';
import { useStyles } from './styles';
import { addButton } from './tracking';

export const AccessControlTableCard = ({
  data,
  title,
  render,
  renderEmpty,
  onCreate,
  isLoading,
  trackingPlugin,
  hasEditPermission,
  isError,
}: AccessControlTableCardProps) => {
  const styles = useStyles();
  const trackingParams = {
    widget: title.toLowerCase(),
    plugin: trackingPlugin,
  };

  const handleCreate = async () => {
    if (onCreate) await onCreate();
  };

  return (
    <Card className={styles.card}>
      <CardHeader
        title={
          <Box marginBottom={2}>
            <Typography variant="h3">{title}</Typography>
          </Box>
        }
        action={
          <EventTracker {...addButton(trackingParams)}>
            {hasEditPermission ? (
              <IconButton
                aria-label="settings"
                onClick={handleCreate}
                className={styles.cardIcon}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            ) : (
              <Tooltip
                title="Only the owner can edit the application"
                placement="top"
              >
                <span>
                  <IconButton
                    aria-label="settings"
                    className={styles.cardIcon}
                    disabled
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </EventTracker>
        }
      />
      <Divider />
      <CardContent>
        {isError && (
          <Alert severity="error">
            An error occured while loading the Resource Types for this app. Try
            again.
          </Alert>
        )}
        {isLoading && <LinearProgress className={styles.loader} />}
        {!isLoading && !isError && (
          <Box
            display="flex"
            flexDirection="column"
            className={styles.dataWrapper}
          >
            {data.length === 0 ? (
              renderEmpty
            ) : (
              <table className={styles.stripedTable}>
                <thead>
                  <tr>
                    <td className={styles.firstColumn}>Title & Description</td>
                    <td className={styles.alignCenter}>Scopes</td>
                    <td className={styles.alignCenter}>Actions</td>
                  </tr>
                </thead>
                <tbody>{data.map((item, index) => render(item, index))}</tbody>
              </table>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
