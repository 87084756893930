import React from 'react';

import Link from '@mui/material/Link';

import { InfoCard, List } from '../../../../../common';
import { Element } from './Element';

import * as S from './styles';

type GithubRelease = {
  url: string;
  body: string;
  name: string;
  draft: boolean;
  tag_name: string;
  prerelease: boolean;
  target_commitish: string;
};

export function GithubReleases({ artifacts }: { artifacts: string[] }) {
  return (
    <InfoCard
      title={
        <span>
          <S.GitHubIcon />
          <span>&nbsp;Github releases</span>
        </span>
      }
    >
      <List>
        {artifacts.map((record, i) => {
          const {
            url,
            body,
            name,
            draft,
            tag_name,
            prerelease,
            target_commitish,
          } = JSON.parse(record) as GithubRelease;

          return (
            <S.Artifact key={i}>
              <Element text={name} label="Name" />
              <Element text={tag_name} label="Tag name" />
              <Element text={body} label="Body" />
              <Element text={target_commitish} label="Target commitish" />

              {draft && (
                <Element text="Draft" label="The release is a draft." />
              )}

              {prerelease && (
                <Element text="Pre-release" label="This is a pre-release." />
              )}

              <Element
                label="Link"
                text={
                  <Link href={url} target="_blank" rel="noopener noreferrer">
                    Github
                  </Link>
                }
              />
            </S.Artifact>
          );
        })}
      </List>
    </InfoCard>
  );
}
