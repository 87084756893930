import React, { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Group } from '../../types';
import { CostInsightsTabs } from '../CostInsightsTabs';
import { Content, Header, Page } from '@backstage/core-components';
import DocsIcon from '@mui/icons-material/MenuBook';
import SupportIcon from '@mui/icons-material/ContactSupport';
import { EventTracker } from 'plugin-ui-components';

type CostInsightsLayoutProps = {
  title?: string;
  groups: Group[];
};

export const CostInsightsLayout = ({
  groups,
  children,
}: PropsWithChildren<CostInsightsLayoutProps>) => (
  <Page themeId="costs">
    <Header
      title="Cost Insights"
      subtitle="Explore the infrastructure costs of your team"
      pageTitleOverride="Cost Insights"
    >
      <Box color="white" display="flex" flexDirection="column">
        <Box display="grid" margin="4px">
          <EventTracker
            plugin="cost-insights"
            eventCategory="Cost Insights Documentation"
            eventAction="click"
            eventLabel="Click on cost documentation link"
            interaction="onClick"
          >
            <Button
              target="_blank"
              size="small"
              href="https://cloud-cost-efficiency.docs.zalando.net/"
              variant="outlined"
              color="inherit"
              endIcon={<DocsIcon style={{ marginTop: -4 }} />}
            >
              Docs
            </Button>
          </EventTracker>
        </Box>
        <Box display="grid" margin="4px">
          <EventTracker
            plugin="cost-insights"
            eventCategory="Cost Insights Feedback"
            eventAction="click"
            eventLabel="Click on cost feedback link"
            interaction="onClick"
          >
            <Button
              target="_blank"
              size="small"
              href="https://github.bus.zalan.do/zooport/issues/issues/new?assignees=&labels=Support+Request%2C+Zooport&template=support-request.md&title="
              variant="outlined"
              color="inherit"
              endIcon={<SupportIcon style={{ marginTop: -4 }} />}
            >
              Support
            </Button>
          </EventTracker>
        </Box>
      </Box>
    </Header>
    <Box gridArea="pageSubheader">
      <CostInsightsTabs groups={groups} />
    </Box>
    <Content>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Content>
  </Page>
);
