import React from 'react';
import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';

export type GradeChips = {
  A: string;
  B: string;
  C: string;
  D: string;
  E: string;
  F: string;
};

export const gradeColors = {
  A: 'rgb(37, 177, 77)',
  B: 'rgb(124, 177, 37)',
  C: 'rgb(199, 203, 0)',
  D: '#FFA10100',
  E: '#E0444000',
  F: 'rgb(201, 44, 34)',
};

export const StyledChip = withStyles({
  root: {
    color: '#ffffff',
    cursor: 'pointer',
    backgroundColor: ({ grade }: { grade: string }) =>
      gradeColors[grade as keyof GradeChips],
  },
})(Chip);

export const AnalysisChip = ({ grade }: { grade: string }) => {
  return <StyledChip label={grade} grade={grade} />;
};
