import { useApi } from '@backstage/core-plugin-api';
import { useAsyncRetry } from 'react-use';
import { cdpApiRef } from '../api';

export function useLatestDeployments(fullrepoName: string) {
  const api = useApi(cdpApiRef);

  const {
    loading,
    error,
    value: deployments = [],
    retry,
  } = useAsyncRetry(async () => {
    const data = await api.getDeployments({ repository: fullrepoName });
    return data.items || [];
  }, []);

  return {
    loading,
    deployments,
    error,
    retry,
  };
}
