import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { mapLoadingToProps, mapFiltersToProps } from './selector';
import { Group } from '../../types';
import { useFilters, useLoading } from '../../hooks';
import { useCostInsightsTabsStyles as useStyles } from '../../utils/styles';
import { EventTracker } from 'plugin-ui-components';

export type CostInsightsTabsProps = {
  groups: Group[];
};

export const CostInsightsTabs = ({ groups }: CostInsightsTabsProps) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const { setGroup } = useFilters(mapFiltersToProps);
  const { loadingActions, dispatchReset } = useLoading(mapLoadingToProps);

  const updateGroupFilterAndCloseMenu = (g: Group, index: number) => () => {
    dispatchReset(loadingActions);
    setTabIndex(index);
    setGroup(g);
  };

  const hasAtLeastTwoGroups = groups.length >= 2;

  if (!hasAtLeastTwoGroups) return null;

  return (
    <Paper elevation={0} square>
      <Tabs
        indicatorColor="primary"
        value={tabIndex}
        variant="scrollable"
        scrollButtons="auto"
      >
        {groups.map((tab, index) => (
          <EventTracker
            interaction="onClick"
            plugin="cost-insights"
            eventCategory="Cost Types"
            eventAction="click"
            eventLabel={`clicked on ${tab.name}`}
            key={tab.id}
          >
            <Tab
              className={classes.menuItem}
              onClick={updateGroupFilterAndCloseMenu(tab, index)}
              label={tab.name}
              key={tab.id}
              value={index}
            />
          </EventTracker>
        ))}
      </Tabs>
    </Paper>
  );
};
