import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useApi } from '@backstage/core-plugin-api';
import { useOnScreen } from 'plugin-ui-components';
import { essentialsApiRef } from '../../../../api';
import { removePendingUpdates, setPendingUpdates } from '../../utils';
import { useStyles } from './styles';
import { EditMessage } from '../AccessControlCard';

interface ScopeProps {
  id: string;
  resourceTypeId: string;
  summary: string;
  onChangeHandler: Function;
  checked: boolean;
  styles: { checkbox: string };
}
const Scope = ({
  id,
  resourceTypeId,
  summary,
  onChangeHandler,
  checked,
  styles,
}: ScopeProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    setIsChecked(checked);
    return () => {
      setIsChecked(false);
    };
  }, [checked]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={styles.checkbox}
          size="small"
          checked={isChecked}
          onChange={e => {
            setIsChecked(e.target.checked);
            onChangeHandler(id, summary, e.target.checked);
          }}
        />
      }
      label={
        <Typography variant="body2">
          <b>{resourceTypeId}</b>.{id} ({summary})
        </Typography>
      }
    />
  );
};

const ResourceTypeScopes = ({
  id,
  name,
  updatedData,
  setUpdatedData,
  selectedScopes,
  selectAll,
}: IAccessControl.ResourceType & {
  updatedData: Array<any>;
  setUpdatedData: Function;
  selectAll: boolean;
}) => {
  const styles = useStyles();
  const essentialsApi = useApi(essentialsApiRef);
  const scrollRef: any = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen<HTMLDivElement>(scrollRef, '300px');
  const [scopes, setScopes] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const addScopeToUpdate = (
    scopeIndex: number,
    selected: boolean,
    scopeSummary: string,
    scopeId: string,
    resourceId: string,
    currentUpdate?: Array<any>,
  ) => {
    const edit = currentUpdate ? currentUpdate : [...updatedData];
    if (scopeIndex !== -1) {
      edit[scopeIndex].selected = selected;
    } else {
      edit.push({ scopeId, scopeSummary, selected, resourceId });
    }
    return edit;
  };

  // Updates edit data state when user select/deselect a scope
  const handleScopeChange = (
    scopeId: string,
    scopeSummary: string,
    selected: boolean,
    currentUpdate?: Array<any>,
  ): Array<any> => {
    const edit = currentUpdate ? currentUpdate : [...updatedData];
    const isInitiallySelected = selectedScopes?.some(
      item => item.scope_id === scopeId && item.resource_type_id === id,
    );
    const scopeIndex = edit.findIndex(
      item => item.scopeId === scopeId && item.resourceId === id,
    );
    if (
      (isInitiallySelected && !selected) ||
      (!isInitiallySelected && selected)
    ) {
      setPendingUpdates('appScopes');
      const data = addScopeToUpdate(
        scopeIndex,
        selected,
        scopeSummary,
        scopeId,
        id,
        currentUpdate,
      );
      setUpdatedData(data);
      return data;
    }
    if (scopeIndex !== -1) edit.splice(scopeIndex, 1);
    if (edit.length === 0) removePendingUpdates('appScopes');
    setUpdatedData(edit);
    return edit;
  };

  // Check whether scope should be checked by default or not
  const shouldScopeBeSelected = (scopeId: string): boolean => {
    if (typeof selectAll === 'boolean') return selectAll;
    const defaultSelect = selectedScopes?.some(
      item => item.scope_id === scopeId && item.resource_type_id === id,
    );
    if (defaultSelect) return defaultSelect;
    return updatedData.some(
      item => item.scopeId === scopeId && item.resourceId === id,
    );
  };

  useEffect(() => {
    if (typeof selectAll === 'boolean') {
      let currentUpdate = updatedData;
      for (const scope of scopes) {
        currentUpdate = handleScopeChange(
          scope.id,
          scope.summary,
          selectAll,
          currentUpdate,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll, scopes, handleScopeChange]);

  useEffect(() => {
    // Load scopes info only if resource type becomes visible in viewport
    if (isVisible) {
      essentialsApi
        .getScopesFromResource(id)
        .then(setScopes)
        .finally(() => setIsLoading(false));
    }
  }, [isVisible, essentialsApi, id]);

  return (
    <div ref={scrollRef}>
      <Typography variant="body2" className={styles.mediumBold}>
        {name}
      </Typography>
      <div className={styles.scopesWrapper}>
        {isLoading && <span className={styles.scopeLoader}>...</span>}
        {!isLoading &&
          scopes.map(({ id: scopeId, summary }, index) => {
            const checked = shouldScopeBeSelected(scopeId);
            return (
              <Scope
                styles={styles}
                id={scopeId}
                resourceTypeId={id}
                summary={summary}
                onChangeHandler={handleScopeChange}
                checked={checked}
                key={index}
              />
            );
          })}
      </div>
    </div>
  );
};

export function ScopeItem({
  id,
  scopes,
  editable,
  name,
  updatedData,
  setUpdatedData,
  selectedScopes,
  selectAll,
  onEdit,
  hasEditPermission,
}: IAccessControl.ApplicationType &
  IAccessControl.ResourceType & {
    editable: boolean;
    updatedData: Array<any>;
    setUpdatedData: Function;
    selectAll: boolean;
    onEdit: Function;
    hasEditPermission: boolean | undefined;
  }) {
  const styles = useStyles();
  const groupByResources = groupBy(scopes, function group(scope) {
    return scope.resource_type_id;
  });
  return (
    <>
      {/* Display registered scopes from mint in view mode */}
      {!editable && (
        <>
          {!isEmpty(groupByResources) && (
            <Typography className={styles.description}>
              This application has <strong>{scopes.length}</strong> scopes that
              has the permission to access data.
            </Typography>
          )}
          {Object.keys(groupByResources).map(key => {
            return (
              <Box mb={1}>
                <Typography variant="body2" className={styles.mediumBold}>
                  {key}
                </Typography>
                {groupByResources[key].map(scope => (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    key={scope.scope_id}
                  >
                    {scope.scope_id} ({scope.resource_type_id})
                  </Typography>
                ))}
              </Box>
            );
          })}
          {isEmpty(groupByResources) && (
            <Typography>
              This application does not have registered <strong>scopes</strong>.{' '}
              <EditMessage
                hasEditPermission={hasEditPermission}
                onEdit={onEdit}
              />
            </Typography>
          )}
        </>
      )}
      {/* Display resource types and scopes when in edit mode */}
      {editable && (
        <ResourceTypeScopes
          id={id}
          selectedScopes={selectedScopes}
          name={name}
          updatedData={updatedData}
          setUpdatedData={setUpdatedData}
          selectAll={selectAll}
        />
      )}
    </>
  );
}
