import React, { PropsWithChildren } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

export interface ComingSoonWrapperProps {
  comingSoonText?: string;
  hardWrap?: boolean;
}

const useStyles = makeStyles({
  root: {
    position: 'relative',
    pointerEvents: 'none',
    '& > *': {
      filter: 'blur(2px)',
    },
    '&::after': {
      content: 'attr(data-coming-soon)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
});

export function ComingSoonWrapper({
  comingSoonText = 'Coming soon!',
  hardWrap,
  children,
}: PropsWithChildren<ComingSoonWrapperProps>) {
  const classes = useStyles();
  return (
    <>
      {React.Children.map(children, Child => {
        if (!React.isValidElement(Child) || hardWrap) {
          return (
            <div className={classes.root} data-coming-soon={comingSoonText}>
              {Child}
            </div>
          );
        }
        return React.cloneElement(
          Child as React.ReactElement<any>,
          {
            className: classnames(Child.props.className, classes.root),
            'data-coming-soon': comingSoonText,
          } as any,
        );
      })}
    </>
  );
}
