import makeStyles from '@mui/styles/makeStyles';
import { darken, Theme } from '@mui/material/styles';

const sideBarWidth = 270;

export const useStyles = makeStyles<Theme>(theme => {
  return {
    globalSearch: {
      display: 'grid',
      width: '100%',
      minHeight: '100%',
      overflow: 'hidden',
      gap: theme.spacing(2),
      gridTemplateRows: 'auto auto 1fr',

      '&.horizontal': {
        gridTemplateColumns: 'auto 1fr',
        gridTemplateAreas: `
        "search-field search-field"
        "search-tabs search-tabs"
        "search-filters search-results"
      `,

        '&.compact': {
          gridTemplateRows: 'auto auto auto 1fr',
          gridTemplateColumns: 'minmax(200px, 100%)',
          gridTemplateAreas: `
          "search-field"
          "search-tabs"
          "search-filters"
          "search-results"
        `,
        },
      },
      '&.vertical': {
        gridTemplateColumns: `minmax(auto, ${sideBarWidth}px) minmax(200px, 100%)`,
        gridTemplateAreas: `
        "search-field search-field"
        "search-tabs search-results"
        "search-filters search-results"
      `,
      },
      '&.stripped': {
        gridTemplateAreas: `
        "search-tabs"
        "search-results"
      `,
        gridTemplateColumns: '100%',
      },
    },

    searchField: {
      gridArea: 'search-field',
      maxWidth: '100%',

      '& > div > div': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        borderRadius: 4,
      },
      '& > div > svg': {
        zIndex: 2,
      },
    },
    searchFiltersWrapper: {
      gridArea: 'search-filters',
      minWidth: 200,
      maxWidth: sideBarWidth,
      margin: '0 !important',
      background: 'none',
      border: 'none',

      '&.compact': {
        maxWidth: '100%',
      },

      '&::before': {
        display: 'none',
      },
    },
    searchFilters: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    searchFilterAutocomplete: {
      '& label+div': {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.background.paper,
      },
    },
    searchFiltersAccordionSummary: {
      paddingInline: 0,
      flexDirection: 'column',
      gap: '1rem',
      '& > div': {
        rowGap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
      },
    },
    searchFiltersAccordionSummaryHeader: {
      minHeight: 'max-content !important',
      paddingInline: 0,
      '& > div': {
        marginBlock: '0 !important',
      },
    },
    searchTabsContainer: {
      gridArea: 'search-tabs',
      width: '100%',

      '&.vertical': {
        boxShadow: theme.shadows[2],
        border: '1px solid',
        borderColor:
          theme.palette.mode === 'light'
            ? darken(theme.palette.grey['300'], 0.05)
            : theme.palette.grey['700'],
        borderRadius: 4,
      },

      '&.horizontal': {
        '& button': {
          paddingTop: 9,
          paddingBottom: 12,
        },
      },

      '& button': {
        maxWidth: 'none',
      },
    },
    searchTabs: {
      borderRadius: 4,
      padding: 8,
      backgroundColor: theme.palette.background.paper,

      '&.horizontal': {
        padding: 0,
      },
    },
    searchTabSelected: {
      '&.vertical': {
        background: 'var(--mui-selected-list-item)',

        '&:hover': {
          color: theme.palette.text.primary,
        },
      },
    },
    searchTab: {
      transition: 'background-color 0.2s ease-in-out',
      '&.vertical': {
        // backgroundColor: darken(theme.palette.background.default, 0.03),
        '&:not(:last-of-type)': {
          borderBottom: '1px solid',
          borderBottomColor:
            theme.palette.mode === 'light'
              ? theme.palette.grey['400']
              : theme.palette.grey['700'],
        },
      },

      '&.horizontal > *': {
        flexDirection: 'row',

        '& > div': {
          gap: 10,
        },
      },
    },
    searchTabsScrollButtons: {
      transition: 'width 0.2s ease-in-out',
      '&.Mui-disabled': {
        width: 0,
      },
    },
    searchTabsIndicator: {
      display: 'none',
    },
    searchTabBadge: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      minWidth: 28,
      borderRadius: 20,
      paddingInline: 10,
      paddingBlock: 3,
      textAlign: 'center',
      fontSize: theme.typography.caption.fontSize,
    },
    searchTabLabel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      gap: theme.spacing(4),
      textTransform: 'none',
    },
    searchTabLabelIcon: {
      minHeight: 'max(100%, 50px)',
    },
    searchResults: {
      gridArea: 'search-results',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    searchResultsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    tabFilterTitle: {
      fontSize: theme.typography.caption.fontSize,
      fontWeight: 'bold',
    },
    resultCard: {
      padding: theme.spacing(2),

      '& > .title': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        fontWeight: 'bold',

        '& .no-link': {
          color: 'var(--disabled-color)',
        },

        '& .icon-holder': {
          display: 'inline-flex',
          alignItems: 'start',
          justifyContent: 'center',
          height: '100%',

          '& > svg': {
            width: 20,
            height: 'fit-content',
            aspectRatio: '1/1',
            marginTop: 6,
          },
        },
        '& svg.external-link-icon': {
          width: 15,
          minWidth: 15,
          height: 15,
          marginInlineStart: theme.spacing(0.5),
          transform: 'translateY(2px)',
        },
      },
    },
    resultDatalist: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      rowGap: theme.spacing(1),
      columnGap: theme.spacing(4),
      margin: 0,
      '& > div': {
        display: 'flex',
        gap: theme.spacing(1),
      },
      '& dt': {
        margin: 0,
        color: theme.palette.text.secondary,
        '&::after': {
          content: '":"',
        },
      },
      '& dd': {
        margin: 0,
      },

      '&.multi-column': {
        gridTemplateColumns: 'repeat(auto-fit, minmax(min-content, 46%))',
        maxWidth: 500,
      },
      '&.dense': {
        rowGap: theme.spacing(0.25),
        columnGap: theme.spacing(2),
      },
    },
    noResults: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',

      '& > img': {
        width: '100%',
        height: '100%',
        maxWidth: 'min(400px, 60vw)',
        maxHeight: 'min(400px, 50vh)',
      },
    },
  };
});
