import React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import type {
  IResourceType,
  IResourceTypeScopes,
} from '../../../../api/types/payload';
import { useStyles } from './styles';
import { EditMessage } from '../AccessControlCard';

const Scope = ({
  resourceID,
  scope,
  onChangeHandler,
  checked,
  styles,
}: {
  resourceID: string;
  scope: IResourceTypeScopes;
  onChangeHandler: Function;
  checked: boolean;
  styles: { checkbox: string };
}) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(checked);

  React.useEffect(() => {
    setIsChecked(checked);
    return () => setIsChecked(false);
  }, [checked]);

  return (
    <Box>
      <FormControlLabel
        key={`${resourceID}-${scope.id}`}
        control={
          <Checkbox
            className={styles.checkbox}
            size="small"
            checked={isChecked}
            onChange={e => {
              setIsChecked(e.target.checked);
              onChangeHandler(scope.id, e.target.checked);
            }}
          />
        }
        label={
          <Typography variant="body2">
            {scope.id} {scope.description ? `(${scope.description})` : ``}
          </Typography>
        }
      />
    </Box>
  );
};

export const OAuthClientItem = ({
  id,
  name,
  scopes,
  selectedScopes,
  appOAuthScopes,
  editable,
  updatedData,
  setUpdatedData,
  selectAll,
  onEdit,
  hasEditPermission,
}: IResourceType & {
  scopes: IResourceTypeScopes[];
  appOAuthScopes: Partial<IResourceType & { scopes: IResourceTypeScopes[] }>[];
  editable: boolean;
  updatedData: {
    resource_type_id: string;
    scope_id: string;
    selected: boolean;
  }[];
  setUpdatedData: Function;
  selectAll: boolean;
  onEdit: Function;
  hasEditPermission: boolean | undefined;
}) => {
  const styles = useStyles();

  const isInitiallySelected = (scopeID: string) => {
    return !!selectedScopes?.some(
      item => item.resource_type_id === id && item.scope_id === scopeID,
    );
  };

  const handleScopeChange = (
    scopeID: string,
    checked: boolean,
    currentUpdate?: {
      resource_type_id: string;
      scope_id: string;
      selected: boolean;
    }[],
  ) => {
    const editList = currentUpdate ?? [...updatedData];
    const initiallySelected = isInitiallySelected(scopeID);
    if ((initiallySelected && !checked) || (!initiallySelected && checked)) {
      editList.push({
        resource_type_id: id!,
        scope_id: scopeID,
        selected: checked,
      });
    } else if (
      (initiallySelected && checked) ||
      (!initiallySelected && !checked)
    ) {
      const itemIndex = editList.findIndex(
        item => item.resource_type_id === id && item.scope_id === scopeID,
      );
      if (itemIndex !== -1) editList.splice(itemIndex, 1);
    }
    setUpdatedData(editList);
    return editList;
  };

  const shouldBeChecked = (scopeID: string) => {
    if (selectAll) return true;
    if (isInitiallySelected(scopeID)) return isInitiallySelected(scopeID);
    return updatedData.some(
      item => item.resource_type_id === id && item.scope_id === scopeID,
    );
  };

  React.useEffect(() => {
    if (typeof selectAll === 'boolean') {
      let currentUpdate = updatedData;
      for (const scope of scopes) {
        currentUpdate = handleScopeChange(scope.id!, selectAll, currentUpdate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll, scopes, handleScopeChange]);

  return !editable ? (
    <>
      <Typography variant="h6">Resource owner scopes</Typography>
      {!!appOAuthScopes?.length ? (
        appOAuthScopes?.map(resource => (
          <Box key={resource.id} marginBottom={1}>
            <Typography variant="body2" className={styles.mediumBold}>
              {resource.id}
            </Typography>
            {resource?.scopes?.map(scope => (
              <Typography
                key={`${resource.id}-${scope.id}`}
                variant="body2"
                color="textSecondary"
              >
                {scope.id} {scope.description && `(${scope.description})`}
              </Typography>
            ))}
          </Box>
        ))
      ) : (
        <Typography variant="body2">
          This application does not have <strong>owner scopes</strong>{' '}
          registered.{' '}
          <EditMessage hasEditPermission={hasEditPermission} onEdit={onEdit} />
        </Typography>
      )}
    </>
  ) : (
    <Box marginBottom={1}>
      <Typography variant="body2" className={styles.mediumBold}>
        {name}
      </Typography>
      {scopes?.map(scope => (
        <Scope
          styles={styles}
          resourceID={id!}
          scope={scope}
          onChangeHandler={handleScopeChange}
          checked={shouldBeChecked(scope.id!)}
        />
      ))}
    </Box>
  );
};
