import React from 'react';
import SvgIcon, { type SvgIconProps } from '@mui/material/SvgIcon';

export const BranchIcon = (props: SvgIconProps) => (
  <SvgIcon width="70px" height="70px" viewBox="0 0 70 70" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M22,0 C27.5228475,0 32,4.4771525 32,10 C32,14.4773171 29.0575337,18.2673844 25.0008825,19.5419205 L25.0007044,42.8185501 C27.2924227,40.7945335 29.3157784,39.5657219 31.1833656,39.1176001 C31.4129987,39.0625004 31.6464647,39.0094767 31.8852132,38.9580419 L32.840573,38.7639196 C33.0643691,38.7210123 33.2857135,38.6808927 33.5538479,38.6348776 L35.512406,38.3154731 C38.2748507,37.8732585 39.4305648,37.5602224 40.0615903,37.0974704 C42.1948167,35.5331043 43.547034,33.6192169 44.1846865,31.2461662 C40.553977,29.7460709 38,26.1712921 38,22 C38,16.4771525 42.4771525,12 48,12 C53.5228475,12 58,16.4771525 58,22 C58,26.3654833 55.2026909,30.0776201 51.302748,31.4417352 C50.4887435,36.070283 48.0881505,39.891828 44.2011359,42.7423053 C42.4581847,44.0204696 40.7868683,44.5287525 37.4003937,45.0993151 L34.6134325,45.5559018 C34.2620216,45.6174922 33.9991868,45.6686833 33.6496648,45.7401453 C33.3578152,45.7998157 33.0813986,45.8608639 32.8166344,45.9243933 C31.7824453,46.1725437 29.8170679,47.6936834 27.1950468,50.4524197 C30.0759943,52.2084301 32,55.3795273 32,59 C32,64.5228475 27.5228475,69 22,69 C16.4771525,69 12,64.5228475 12,59 C12,54.5845966 14.8616503,50.8375674 18.8314717,49.5123918 C18.8813085,49.4051695 18.9374996,49.2998856 18.99953,49.1969418 L19.0001192,19.5422352 C14.9429507,18.2680083 12,14.4776856 12,10 C12,4.4771525 16.4771525,0 22,0 Z M22,56 C20.3431458,56 19,57.3431458 19,59 C19,60.6568542 20.3431458,62 22,62 C23.6568542,62 25,60.6568542 25,59 C25,57.3431458 23.6568542,56 22,56 Z M48,19 C46.3431458,19 45,20.3431458 45,22 C45,23.6568542 46.3431458,25 48,25 C49.6568542,25 51,23.6568542 51,22 C51,20.3431458 49.6568542,19 48,19 Z M22,7 C20.3431458,7 19,8.34314575 19,10 C19,11.6568542 20.3431458,13 22,13 C23.6568542,13 25,11.6568542 25,10 C25,8.34314575 23.6568542,7 22,7 Z"
      />
    </g>
  </SvgIcon>
);

export const CommitIcon = (props: SvgIconProps) => (
  <SvgIcon width="70px" height="70px" viewBox="0 0 70 70" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M35,20 C42.0792278,20 48.0130496,24.9040659 49.5895462,31.5002785 L65.5,31.5 C67.4329966,31.5 69,33.0670034 69,35 C69,36.8685634 67.5357224,38.3951264 65.6920352,38.4948211 L65.5,38.5 L49.5893071,38.5007219 C48.0124496,45.0964299 42.0788699,50 35,50 C27.9211301,50 21.9875504,45.0964299 20.4106929,38.5007219 L5,38.5 C3.06700338,38.5 1.5,36.9329966 1.5,35 C1.5,33.1314366 2.9642776,31.6048736 4.8079648,31.5051789 L5,31.5 L20.4104538,31.5002785 C21.9869504,24.9040659 27.9207722,20 35,20 Z M35,27 C30.581722,27 27,30.581722 27,35 C27,39.418278 30.581722,43 35,43 C39.418278,43 43,39.418278 43,35 C43,30.581722 39.418278,27 35,27 Z"
      />
    </g>
  </SvgIcon>
);

export const PRIcon = (props: SvgIconProps) => (
  <SvgIcon width="70px" height="70px" viewBox="0 0 70 70" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M15,1 C20.5228475,1 25,5.4771525 25,11 C25,15.2912283 22.2970436,18.9511474 18.5007916,20.3700965 L18.5007916,50.6299035 C22.2970436,52.0488526 25,55.7087717 25,60 C25,65.5228475 20.5228475,70 15,70 C9.4771525,70 5,65.5228475 5,60 C5,55.7083942 7.70343189,52.0482088 11.5002102,50.6295292 L11.5002102,20.3704708 C7.70343189,18.9517912 5,15.2916058 5,11 C5,5.4771525 9.4771525,1 15,1 Z M15,57 C13.3431458,57 12,58.3431458 12,60 C12,61.6568542 13.3431458,63 15,63 C16.6568542,63 18,61.6568542 18,60 C18,58.3431458 16.6568542,57 15,57 Z M15,8 C13.3431458,8 12,9.34314575 12,11 C12,12.6568542 13.3431458,14 15,14 C16.6568542,14 18,12.6568542 18,11 C18,9.34314575 16.6568542,8 15,8 Z"
      />
      <path
        fill="currentColor"
        d="M41,20.780265 C41,21.3325498 40.5522847,21.780265 40,21.780265 C39.7559101,21.780265 39.5202486,21.6909892 39.3374261,21.5292616 L28.8466918,12.2489966 C28.4330324,11.8830671 28.3943396,11.2510855 28.7602691,10.8374261 C28.7872876,10.8068834 28.8161491,10.778022 28.8466918,10.7510034 L39.3374261,1.47073843 C39.7510855,1.10480898 40.3830671,1.14350174 40.7489966,1.55716111 C40.9107242,1.73998361 41,1.9756451 41,2.219735 L40.9992541,8.17872341 L43,8.17955139 C52.0054902,8.17955139 59.3259423,15.3940566 59.4969418,24.3586524 L59.5,24.6795514 L59.499944,50.6295868 C63.2966413,52.0483078 66,55.7084523 66,60 C66,65.5228475 61.5228475,70 56,70 C50.4771525,70 46,65.5228475 46,60 C46,55.7087717 48.7029564,52.0488526 52.4992084,50.6299035 L52.5,24.6795514 C52.5,19.5233067 48.3921061,15.326512 43.2704787,15.1833278 L43,15.1795514 L40.9992541,15.1787234 L41,20.780265 Z M56,57 C54.3431458,57 53,58.3431458 53,60 C53,61.6568542 54.3431458,63 56,63 C57.6568542,63 59,61.6568542 59,60 C59,58.3431458 57.6568542,57 56,57 Z"
      />
    </g>
  </SvgIcon>
);

export const UserIcon = (props: SvgIconProps) => (
  <SvgIcon width="70px" height="70px" viewBox="0 0 70 70" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M61,64.375 C61,66.376 59.3716429,68 57.3571429,68 C55.3426429,68 53.7142857,66.376 53.7142857,64.375 C53.7142857,54.380875 45.5433571,46.25 35.5,46.25 C25.4566429,46.25 17.2857143,54.380875 17.2857143,64.375 C17.2857143,66.376 15.6573571,68 13.6428571,68 C11.6283571,68 10,66.376 10,64.375 C10,50.3825 21.4422143,39 35.5,39 C49.5577857,39 61,50.3825 61,64.375 M35.5,10 C39.63625,10 43,13.1395 43,17 C43,20.8605 39.63625,24 35.5,24 C31.36375,24 28,20.8605 28,17 C28,13.1395 31.36375,10 35.5,10 M35.5,32 C43.49675,32 50,25.49675 50,17.5 C50,9.50325 43.49675,3 35.5,3 C27.50325,3 21,9.50325 21,17.5 C21,25.49675 27.50325,32 35.5,32"
      />
    </g>
  </SvgIcon>
);

export const PipelineIcon = (props: SvgIconProps) => (
  <SvgIcon width="70px" height="70px" viewBox="0 0 16 16" {...props}>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        fill="currentColor"
        d="M2.75 2.5A1.75 1.75 0 001 4.25v1C1 6.216 1.784 7 2.75 7h1a1.75 1.75 0 001.732-1.5H6.5a.75.75 0 01.75.75v3.5A2.25 2.25 0 009.5 12h1.018c.121.848.85 1.5 1.732 1.5h1A1.75 1.75 0 0015 11.75v-1A1.75 1.75 0 0013.25 9h-1a1.75 1.75 0 00-1.732 1.5H9.5a.75.75 0 01-.75-.75v-3.5A2.25 2.25 0 006.5 4H5.482A1.75 1.75 0 003.75 2.5h-1zM2.5 4.25A.25.25 0 012.75 4h1a.25.25 0 01.25.25v1a.25.25 0 01-.25.25h-1a.25.25 0 01-.25-.25v-1zm9.75 6.25a.25.25 0 00-.25.25v1c0 .138.112.25.25.25h1a.25.25 0 00.25-.25v-1a.25.25 0 00-.25-.25h-1z"
        clipRule="evenodd"
      />
    </g>
  </SvgIcon>
);
