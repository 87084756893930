import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Text } from '../../../common';
import * as S from './styles';
import Alert from '@mui/lab/Alert';

export function RuntimeBanner() {
  return (
    <Alert severity="warning">
      <Box display="flex" flexDirection="column">
        <Text bold>Overlay → Runtime</Text>
        <p>
          <span>
            CDP overlays have been deprecated since January 2022, and frozen
            since June 2022. Their operating system (Ubuntu 16.04) and included
            package versions are now extremely out-of-date, and impossible to
            update. To prevent the execution of vulnerable software in CI/CD
            script steps and reduce the risk of compromising Zalando's
            production environments,&nbsp;
          </span>
          <Link
            href="https://sunrise.zalando.net/docs/default/Documentation/cloud/howtos/overlays/"
            target="blank"
            rel="noopener noreferrer"
          >
            CDP no longer loads overlays
          </Link>
          <span>.</span>
        </p>

        <S.StyledTrackedLink
          plugin="cdp"
          eventCategory="Runtime Migration Banners"
          interaction="onClick"
          eventAction="click"
          eventLabel="Click on Runtime documentation link"
          to="https://cloud.docs.zalando.net/howtos/builds-in-runtimes/"
          target="blank"
          rel="noopener noreferrer"
        >
          Please migrate to CDP runtimes.
          <S.StyledOpenInNewIcon />
        </S.StyledTrackedLink>
      </Box>
    </Alert>
  );
}
