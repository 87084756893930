import React from 'react';
import { useInterval } from 'react-use';
import { observer } from 'mobx-react-lite';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Tooltip } from '../../../common';
import { PollingIcon } from '../../common';
import { IPoll } from '../../../services';
import { LIVE_TIMER } from '../../../constants';

interface Props {
  service: IPoll;
  isReady?: boolean;
}

export const LiveButton = observer(({ service, isReady = true }: Props) => {
  useInterval(
    service.reload,
    service.pollingState.isCurrentlyLive && isReady ? LIVE_TIMER : null,
  );

  return service.pollingState.isLive ? (
    <Box display="flex" alignItems="center">
      <Tooltip title="Stop polling latest pipelines">
        <span>
          <IconButton
            disabled={!isReady}
            color="secondary"
            onClick={service.togglePolling}
          >
            <PauseIcon />
          </IconButton>
        </span>
      </Tooltip>
      <PollingIcon service={service} />
    </Box>
  ) : (
    <Tooltip title="Fetch latest data and continue polling">
      <span>
        <IconButton disabled={!isReady} onClick={service.togglePolling}>
          <PlayArrowIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
});
