import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  ApecStatusFilter,
  AppReviewFilter,
  CriticalityFilter,
  CyberWeekFilter,
  CyberWeekPhaseFilter,
  DataClassificationFilter,
  ProductionReadinessReviewFilter,
  StatusFilter,
  Support247,
  TeamsFilter,
  CyberWeekToerFilter,
  SecurityTierFilter,
} from './filters';
import { useStyles } from './styles';
import { FilterGroupsContext } from 'plugin-ui-components';
import { ScorecardsFilter } from './filters/ScorecardsFilter';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { ScorecardStatusFilter } from './filters/ScorecardStatusFilter';

const SCORECARD_TARGETS_PAGE_PATH =
  /^\/catalog\/default\/scorecard\/([^/]+)\/targets$/;

/** The additional results filter in the sidebar. */
export const ApplicationResultsFilter = () => {
  const { filterWrapper } = useStyles();
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const {
    setTagsFilter,
    setStatusFilter,
    setCriticalityFilter,
    setCyberweekRelevantFilter,
    setSupport247Filter,
    setTeamsFilter,
    setReviewFilter,
    setDataClassificationFilter,
    setApecFilter,
    setCyberWeekPhaseFilter,
    setCyberWeekToerFilter,
    setPrrFilter,
    setSecurityTierFilter,
  } = React.useContext(FilterGroupsContext);

  const onClearFilters = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    setTagsFilter([]);
    setCriticalityFilter('');
    setStatusFilter('');
    setCyberweekRelevantFilter('');
    setSupport247Filter('');
    setTeamsFilter([]);
    setReviewFilter([]);
    setDataClassificationFilter([]);
    setApecFilter([]);
    setCyberWeekPhaseFilter([]);
    setCyberWeekToerFilter([]);
    setPrrFilter('');
    setSecurityTierFilter([]);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop="1.5rem"
      rowGap="0.5rem"
      padding="0.5rem"
      className={filterWrapper}
    >
      {window.location.pathname.match(SCORECARD_TARGETS_PAGE_PATH) && (
        <ScorecardStatusFilter />
      )}
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6" style={{ margin: 0, fontSize: '0.75rem' }}>
          Refine Results
        </Typography>
        <Button
          onClick={onClearFilters as any}
          style={{ fontSize: '0.75rem', padding: 0, minWidth: 'auto' }}
          variant="text"
        >
          Clear
        </Button>
      </Box>
      <CriticalityFilter />
      <StatusFilter />
      <Support247 />
      <TeamsFilter />
      <AppReviewFilter />
      <DataClassificationFilter />
      {featureFlagsApi.isActive('app-catalog-security-tier') && (
        <SecurityTierFilter />
      )}
      <ApecStatusFilter />
      <CyberWeekToerFilter />
      <CyberWeekFilter />
      <CyberWeekPhaseFilter />
      <ProductionReadinessReviewFilter />
      <ScorecardsFilter />
    </Box>
  );
};
