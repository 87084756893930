import React from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { LinkButton } from '@backstage/core-components';
import { IDockerImage } from '../../../../../api/types/payload';
import { InfoCard, List, TagSmall } from '../../../../../common';
import { STATUS_COLORS } from '../../../../../utils/colors';
import * as S from './styles';

type Props = {
  images: IDockerImage[];
};

function getImageStatusColor(status: string): string {
  switch (status) {
    case 'production_ready':
      return STATUS_COLORS.success;
    case 'stups_production_ready':
      return STATUS_COLORS.warning;
    case 'not_production_ready':
      return STATUS_COLORS.failed;
    default:
      return STATUS_COLORS.default;
  }
}

export function DockerArtifacts({ images }: Props) {
  return (
    <InfoCard title="🐳 Docker">
      <List>
        {images.map(image => (
          <S.DockerArtifact key={image.image_name}>
            <S.Content>
              <span>{image.image_name}</span>

              <TagSmall
                color={getImageStatusColor(image.image_meta?.status ?? '')}
                title={image.image_meta?.reason ?? ''}
              >
                {image.image_meta?.status ?? 'status unknown'}
              </TagSmall>

              {image.base_image_meta?.allowed ? (
                <TagSmall color={STATUS_COLORS.success}>
                  base image allowed
                </TagSmall>
              ) : (
                <TagSmall color={STATUS_COLORS.failed}>
                  base image not allowed
                </TagSmall>
              )}
            </S.Content>

            <ContainerImagesLink image={image.image_name} />
          </S.DockerArtifact>
        ))}
      </List>
    </InfoCard>
  );
}

function ContainerImagesLink({ image }: { image: string }) {
  const [name, tag] = image.split(':');
  return (
    <LinkButton
      size="small"
      color="primary"
      variant="outlined"
      to={`/container-images/${name}?tag=${tag}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>details&nbsp;</span>
      <LaunchIcon fontSize="inherit" />
    </LinkButton>
  );
}
