export class Tracking {
  private static common: Omit<IEventTracker, 'eventLabel'> = {
    plugin: 'tooling-catalog',
    eventCategory: 'Tooling Catalog',
    interaction: 'onClick',
    eventAction: 'click',
  };

  static viewLink = (eventLabel: string): IEventTracker => ({
    ...this.common,
    eventLabel: eventLabel,
  });

  static hoverStatus = (status: string): IEventTracker => ({
    ...this.common,
    interaction: 'onMouseEnter',
    eventAction: 'hover',
    eventLabel: `User hovered on '${status}' status in Tooling Catalog`,
  });

  static createTooling = (): IEventTracker => ({
    ...this.common,
    eventLabel: `Create new tooling`,
  });

  static editTooling = (): IEventTracker => ({
    ...this.common,
    eventLabel: `Edit tooling`,
  });
}
