import styled from '@mui/styles/styled';
import { NavLink } from 'react-router-dom';

export const Tabs = styled('ul')(props => ({
  flexShrink: 0,
  display: 'flex',
  margin: 0,
  padding: 0,
  listStyle: 'none',
  borderBottom: `1px solid ${props.theme.palette.divider}`,
}));

export const Tab = styled(NavLink)<
  Theme,
  {
    tabIndex: number;
    'data-active'?: string | boolean;
    'data-size'?: 'sm' | 'md';
  }
>(props => ({
  position: 'relative',
  padding:
    props['data-size'] === 'sm'
      ? props.theme.spacing(1.5, 2)
      : props.theme.spacing(3, 2),
  fontSize: '0.8rem',
  fontWeight: 600,
  color: props['data-active']
    ? props.theme.palette.text.primary
    : props.theme.palette.textSubtle,
  textAlign: 'center',
  verticalAlign: 'middle',
  lineHeight: '1.1',

  ['&:before']: {
    position: 'absolute',
    bottom: '-1px',
    left: 0,
    right: 0,
    width: '100%',
    height: '2px',
    content: `""`,
    borderRadius: '5px',
    background: props.theme.palette.primary.main,
    transition: 'transform .2s ease',
    transform: props['data-active'] ? 'scale(1)' : 'scale(0)',
  },

  ['&:hover']: {
    background: props.theme.palette.action.hover,
  },

  ['&:focus, &:active']: {
    background: props.theme.palette.action.hover,
    outline: 'none',
  },
}));
