import React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Grid, Item } from '../Grid';
import { ImagePlatformInfoModel } from '../../models';
import { TagSmall, bytesToSize } from 'plugin-cdp';

type Props = {
  selected: number;
  options: string[];
  platform: ImagePlatformInfoModel;
  handleSelection: (idx: number) => void;
};

export const useStyles = makeStyles(theme => ({
  select: {
    width: '80px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: '14px',
  },
  labels: {
    paddingTop: theme.spacing(2),
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(0.5),
  },
  text: {
    margin: '2px 2px 2px 0',
    maxWidth: '100%',
  },
  textLight: {
    fontWeight: 600,
    color: theme.palette.text.hint,
    marginRight: '3px',
  },
  info: {
    marginLeft: '4px',
    marginBottom: '2px',
    fontSize: 'inherit',
    verticalAlign: 'middle',
    fill: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

export function ImagePlatforms({
  selected,
  handleSelection,
  platform,
  options,
}: Props) {
  const classes = useStyles();

  function handleChange(e: SelectChangeEvent) {
    handleSelection(Number(e.target?.value) || 0);
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={3}
      >
        <Typography variant="h4">Platforms</Typography>

        <Select
          variant="outlined"
          value={String(selected)}
          onChange={handleChange}
          data-testid="arch-select"
          classes={{ select: classes.select }}
        >
          {options.map((option, i) => (
            <MenuItem key={option} value={i} data-testid={`option-${option}`}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Grid>
        <Item title="Created">{platform.createdAt}</Item>

        <Item title="Compressed size">
          <span>{bytesToSize(platform.size)}</span>
          <Tooltip title="The size is calculated by summing the image's layers, which are compressed.">
            <span>
              <InfoOutlined className={classes.info} />
            </span>
          </Tooltip>
        </Item>
      </Grid>

      <div className={classes.labels}>
        <Item title="Labels">
          {!!Object.keys(platform.labels).length ? (
            Object.keys(platform.labels).map(key => (
              <Box className={classes.label} key={key}>
                <Box>
                  <small className={classes.textLight}>{key}</small>
                </Box>
                <TagSmall title={platform.labels[key]} className={classes.text}>
                  {platform.labels[key]}
                </TagSmall>
              </Box>
            ))
          ) : (
            <small>No labels found for the image</small>
          )}
        </Item>
      </div>
    </>
  );
}
