export const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'application-registry',
  eventCategory: 'Access Control Page',
  interaction: 'onClick',
  eventAction: 'click',
};

export const createScope = (): IEventTracker => ({
  ...common,
  eventLabel: `Create new scope button is clicked`,
});

export const editResourceType = (): IEventTracker => ({
  ...common,
  eventLabel: `Edit resource type button is clicked`,
});

export const deleteResourceTypeClicked: IEventTracker = {
  ...common,
  eventLabel: 'Delete resource type button is clicked',
};

export const deleteResourceTypeHovered: IEventTracker = {
  ...common,
  eventLabel: 'Delete resource type button is hovered',
  eventAction: 'hover',
  interaction: 'onMouseEnter',
};

export const deleteScope = (scopeId: string | undefined): IEventTracker => ({
  ...common,
  eventLabel: `Delete scope button is clicked ${
    scopeId ? `for scope ${scopeId}` : ''
  }`,
});

export const openResourceTypeMenu = (): IEventTracker => ({
  ...common,
  eventLabel: `User opened resource type menu (add/edit)`,
});

export const editScope = (scopeId: string | undefined): IEventTracker => ({
  ...common,
  eventLabel: `Edit '${scopeId}' scope menu item is clicked`,
});
