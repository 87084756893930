import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Type';
const id = 'type-filter';
export const TypesFilter = React.memo(() => {
  const { availableTypes, typeFilter, setTypeFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" size="small" margin="dense">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        variant="outlined"
        size="small"
        value={typeFilter}
        onChange={ev => setTypeFilter(String(ev.target.value))}
        label={label}
        labelId={id}
        classes={{ select }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {availableTypes.map(type => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
