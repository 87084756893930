import React from 'react';
import { Analysis } from '../../types';
import { AnalysisGrade } from './components';
import Typography from '@mui/material/Typography';
import { Link, TableColumn } from '@backstage/core-components';
import { getFormattedShortDateTime } from 'plugin-cdp';

export const columns: TableColumn<Analysis>[] = [
  {
    title: 'Grade',
    field: 'grade',
    render: data => <AnalysisGrade report={data} row />,
  },
  {
    title: 'Application',
    field: 'repository',
    render: data => (
      <Link to="">
        <Typography component="span" variant="body2">
          {data.repository.name}
        </Typography>
      </Link>
    ),
  },
  {
    title: 'Last Analysis',
    field: 'lastAnalysedCommit',
    render: data => (
      <Typography component="span" variant="body2">
        {getFormattedShortDateTime(data.lastAnalysedCommit?.endedAnalysis)}
      </Typography>
    ),
  },
];
