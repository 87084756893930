import React from 'react';
import classnames from 'classnames';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { default as ArrowDropUp } from '@mui/icons-material/ArrowDropUp';
import { default as ArrowDropDown } from '@mui/icons-material/ArrowDropDown';
import { growthOf } from '../../utils/change';
import { ChangeStatistic, GrowthType, Maybe } from '../../types';
import { useCostGrowthStyles as useStyles } from '../../utils/styles';

export type CostGrowthIndicatorProps = TypographyProps & {
  change: ChangeStatistic;
  formatter?: (change: ChangeStatistic) => Maybe<string>;
};

export const CostGrowthIndicator = ({
  change,
  formatter,
  className,
  ...props
}: CostGrowthIndicatorProps) => {
  const classes = useStyles();
  const growth = growthOf(change);

  const classNames = classnames(classes.indicator, className, {
    [classes.excess]: growth === GrowthType.Excess,
    [classes.savings]: growth === GrowthType.Savings,
  });

  return (
    <Typography className={classNames} component="span" {...props}>
      {formatter ? formatter(change) : change.ratio} WoW
      {growth === GrowthType.Excess && <ArrowDropUp aria-label="excess" />}
      {growth === GrowthType.Savings && <ArrowDropDown aria-label="savings" />}
    </Typography>
  );
};
