export const common: Omit<IEventTracker, 'eventLabel'> = {
  plugin: 'credential-distribution',
  eventCategory: 'Access Control Page',
  eventAction: 'click',
  interaction: 'onClick',
};

export const addBucket = (): IEventTracker => ({
  ...common,
  eventLabel:
    "'Add new Mint Bucket' button is clicked in Credential Distribution widget",
});

export const removeBucket = (): IEventTracker => ({
  ...common,
  eventLabel:
    "'Remove Mint Bucket' button is clicked in Credential Distribution widget",
});

export const addSuggestedBucket = (): IEventTracker => ({
  ...common,
  eventLabel:
    "'Suggested Mint Bucket' button is clicked in Credential Distribution widget",
});
