import styled from '@mui/styles/styled';

export const DependencyGraph = styled('div')(props => ({
  padding: props.theme.spacing(2),
  height: '100%',
}));

export const StyledGraph = styled('div')({
  overflow: 'scroll',
  width: '100%',
  height: '100%',
  border: '1px solid #e7e8ef',
  marginTop: '15px',

  ['& a']: {
    pointerEvents: 'painted',
  },

  ['& svg > path']: {
    stroke: '#e7e8ef',
  },

  ['& marker > path']: {
    fill: '#e7e8ef',
  },
});
