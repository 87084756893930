import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/lab/Alert';
import { TrackedLink } from 'plugin-ui-components';
import {
  subscriptionApiRef,
  SubscriptionsManager,
} from '../../api/SubscriptionsApi';
import { useApiCall } from '../../hooks/useApiCall';
import { UnPromisify } from '../../utils/typeUtils';
import { StyledTableCell } from '../Generic';
import LaunchIcon from '@mui/icons-material/Launch';
import CheckIcon from '@mui/icons-material/Check';
import { Subscriptions } from '../../domain/Subscriptions';

export type SubscriptionStatProps = {
  subscription: Subscriptions;
  nakadiUiURL: string;
  application: string;
};

export const SubscriptionStatsHeader = ({
  nakadiUiURL,
  subscription,
  rowSpan = 1,
}: SubscriptionStatProps & { rowSpan?: number }) => (
  <StyledTableCell component="th" scope="row" rowSpan={rowSpan}>
    <TrackedLink
      key={subscription.id}
      plugin="nakadi"
      eventCategory="Subscription List"
      eventLabel={`Subscription redirect to Nakadi UI - ${subscription.id}`}
      to={`${nakadiUiURL}/#subscriptions/${subscription.id}`}
      target="_blank"
    >
      <span>{subscription.id}</span>
      <LaunchIcon style={{ width: '15px', height: '10px' }} />
    </TrackedLink>
  </StyledTableCell>
);

export const SubscriptionStats = (props: SubscriptionStatProps) => {
  const { subscription, nakadiUiURL, application } = props;
  const {
    payLoad: subscriptionStats = [],
    isLoading: isLoadingSubStats,
    status,
  } = useApiCall<
    SubscriptionsManager,
    UnPromisify<ReturnType<SubscriptionsManager['getSubscriptionStats']>>
  >(subscriptionApiRef, api => api.getSubscriptionStats(subscription.id));

  if (status.isError) {
    return (
      <TableRow>
        <SubscriptionStatsHeader {...props} />
        <StyledTableCell colSpan={12}>
          <Alert severity="error">{status.error}</Alert>
        </StyledTableCell>
      </TableRow>
    );
  }

  if (isLoadingSubStats) {
    return (
      <TableRow>
        <SubscriptionStatsHeader {...props} />
        <StyledTableCell colSpan={12}>
          <LinearProgress data-testid="loading_indicator" />
        </StyledTableCell>
      </TableRow>
    );
  }

  return (
    <React.Fragment>
      {subscriptionStats.map((sub, index) => (
        <TableRow
          key={sub.event_type}
          data-testid={`subscription-container-${subscription.id}`}
        >
          {index === 0 && (
            <SubscriptionStatsHeader
              {...props}
              rowSpan={subscriptionStats.length}
            />
          )}
          <StyledTableCell>
            <TrackedLink
              key={sub.event_type}
              plugin="nakadi"
              eventCategory="Event Type for Subscription"
              eventLabel={`Event type redirect to Nakadi UI - ${sub.event_type}`}
              to={`${nakadiUiURL}/#types/${sub.event_type}`}
              target="_blank"
            >
              <span>{sub.event_type}</span>
              <LaunchIcon style={{ width: '15px', height: '10px' }} />
            </TrackedLink>
          </StyledTableCell>
          <StyledTableCell align="right">
            {sub.unconsumed_events !== undefined
              ? sub.unconsumed_events
              : 'no data'}
          </StyledTableCell>
          <StyledTableCell align="right">
            {sub.consumer_lag_seconds !== undefined
              ? `${sub.consumer_lag_seconds}s`
              : 'no data'}
          </StyledTableCell>
          <StyledTableCell align="center" size="small" padding="none">
            {(subscription.owning_application === application ||
              subscription.owning_application === `stups_${application}`) && (
              <Tooltip title="Subscription is owned by your application">
                <CheckIcon style={{ fontSize: '2em' }} />
              </Tooltip>
            )}
          </StyledTableCell>
          <StyledTableCell align="center" size="small" padding="none">
            {subscription.authorization?.readers?.find(
              reader =>
                reader.value === `stups_${application}` &&
                reader.data_type === 'service',
            ) && (
              <Tooltip title="Your application is consuming from subscription">
                <CheckIcon style={{ fontSize: '2em' }} />
              </Tooltip>
            )}
          </StyledTableCell>
        </TableRow>
      ))}
    </React.Fragment>
  );
};
