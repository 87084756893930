import React, { useState } from 'react';
import { useAsync } from 'react-use';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import AppsIcon from '@mui/icons-material/Apps';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { makeEntityUrl } from 'plugin-core';
import { List, ListItem, Text, Tooltip } from '../../../common';
import Button from '@mui/material/Button';

export function ApplicationsAction({ repoURL }: { repoURL: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const catalogApi = useApi(catalogApiRef);

  const { value: apps, loading: entitiesLoading } = useAsync(
    () =>
      catalogApi.getEntities({
        filter: {
          kind: 'Component',
          'spec.spec.scm_url': repoURL,
        },
      }),
    [catalogApi],
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = popoverOpen ? 'applications-popover' : undefined;

  return (
    <>
      <Tooltip title="Applications deployed by this repository">
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          aria-label="applications links"
          onClick={handleClick}
        >
          <AppsIcon />
          &nbsp;Applications
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Card>
          <CardContent>
            <Typography color="secondary" gutterBottom>
              Applications associated with the repository
            </Typography>
            {entitiesLoading && <Progress />}
            {!entitiesLoading && !!apps?.items.length ? (
              <AppLinks apps={apps.items as IEntityApp[]} />
            ) : (
              <Text>
                It looks like this pipeline doesn't have any associated
                applications.
              </Text>
            )}
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}

function AppLinks({ apps }: { apps: IEntityApp[] }) {
  return (
    <>
      <hr />
      <List>
        {apps.map(app => (
          <ListItem icon={<AppsIcon />} key={app.metadata.uid}>
            <Link href={makeEntityUrl(app)}>{app.metadata.name}</Link>
          </ListItem>
        ))}
      </List>
    </>
  );
}
