import React from 'react';
import { observer } from 'mobx-react-lite';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'plugin-ui-components';
import { WarningIcon } from '@backstage/core-components';

import { Tooltip } from '../../../common';
import { IPoll } from '../../../services';

interface Props {
  service: IPoll;
}

const useStyles = makeStyles({
  root: {
    width: '20px',
    height: '20px',
    background: colors.brand.primaryOrange,
    borderRadius: '100%',
    marginLeft: '10px',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    cursor: 'help',

    '& svg': {
      width: '65%',
      marginBottom: '2px',
    },
  },
});

export const PollingIcon = observer(({ service }: Props) => {
  const classes = useStyles();

  return service.pollingState.consecutiveFailedPolls >= 3 ? (
    <Tooltip title="Network connection issue detected.">
      <div className={classes.root}>
        <WarningIcon />
      </div>
    </Tooltip>
  ) : (
    <></>
  );
});
