import React from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import LaunchIcon from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Progress } from '@backstage/core-components';
import { Tooltip, Text } from '../../../../../common';
import { useServices } from '../../../../../services';
import { useIframeURL } from './useIframeURL';
import * as S from './styles';
import { TestResultsHeader } from '../TestResultsHeader';

function Iframe({ src }: { src: string }) {
  return (
    <S.IframeContainer>
      <S.Iframe
        sandbox="allow-same-origin allow-scripts"
        title="HTML test results"
        src={src}
        width="100%"
        height="100%"
      />
    </S.IframeContainer>
  );
}

export const HTMLTestResults = observer(
  ({ src, navigateBack }: { src: string; navigateBack: () => void }) => {
    const { runId = '', stepId = '' } = useParams();
    const { runService } = useServices();
    const { id: stepRunId } = runService.step?.run;
    const [{ loading, error, value }] = useIframeURL(
      runId,
      stepId,
      stepRunId,
      src,
    );

    if (error) {
      return (
        <Text error padding={2}>
          An error occurred while retrieving test results, refresh the page to
          try again.
        </Text>
      );
    }

    if (loading) {
      return (
        <Box padding={2}>
          <Progress />
        </Box>
      );
    }

    return (
      <>
        <TestResultsHeader back={navigateBack}>
          {value && (
            <Tooltip title="Open in a new tab">
              <IconButton
                href={value}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LaunchIcon style={{ padding: '2px' }} />
              </IconButton>
            </Tooltip>
          )}
        </TestResultsHeader>

        {value ? <Iframe src={value} /> : <Box>Faild to create Irame URL</Box>}
      </>
    );
  },
);
