import { createApiRef } from '@backstage/core-plugin-api';
import { RunStatus } from './types/payloads';
import {
  SharedNotebooksResponse,
  ScheduledNotebooksResponse,
  VersionsResponse,
  RunsResponse,
  RunResponse,
  VersionResponse,
  ScheduledNotebookResponse,
  SharedNotebookResponse,
  NotebookSearchResponse,
  TriggerRunResponse,
} from './types/responses';

export interface NotebooksApi {
  getSharedNotebooks(
    projectId: string,
    pageNumber: number,
    status?: RunStatus,
    limit?: number,
  ): Promise<SharedNotebooksResponse>;
  getScheduledNotebooks(
    projectId: string,
    pageNumber: number,
    status?: RunStatus,
  ): Promise<ScheduledNotebooksResponse>;
  getVersions(
    sharedNotebookId: string,
    page: number,
    status?: RunStatus,
  ): Promise<VersionsResponse>;
  getRuns(
    scheduledNotebookId: string,
    page: number,
    status?: RunStatus,
  ): Promise<RunsResponse>;
  getScheduledNotebookDetails(
    scheduledNotebookId: string,
  ): Promise<ScheduledNotebookResponse>;
  getSharedNotebookDetails(
    sharedNotebookId: string,
  ): Promise<SharedNotebookResponse>;
  getRunDetails(runId: string): Promise<RunResponse>;
  getVersionDetails(versionId: string): Promise<VersionResponse>;
  updateScheduledNotebook(
    id: string,
    disabled: boolean,
  ): Promise<ScheduledNotebookResponse>;
  deleteRun(id: string, scheduledNotebookId: string): Promise<RunResponse>;
  deleteVersion(id: string, sharedNotebookId: string): Promise<VersionResponse>;
  deleteSharedNotebook(
    sharedNotebookId: string,
  ): Promise<SharedNotebookResponse>;
  deleteScheduledNotebook(
    scheduledNotebookId: string,
  ): Promise<ScheduledNotebookResponse>;
  runScheduledNotebook(
    scheduledNotebookId: string,
  ): Promise<TriggerRunResponse>;
  search(query: string, PAGE_SIZE: number): Promise<NotebookSearchResponse>;
}

export const notebooksApiRef = createApiRef<NotebooksApi>({
  id: 'notebook-api',
});
