import { NO_OF_ROWS_PER_PAGE } from '../config';
import { BaseClient } from './BaseClient';
import { RunStatus } from './types/payloads';
import {
  ExecutionsQueryParams,
  NotebooksQueryParams,
  SearchQueryParams,
} from './types/queryParams';
import {
  SharedNotebooksResponse,
  ScheduledNotebooksResponse,
  VersionsResponse,
  RunsResponse,
  RunResponse,
  VersionResponse,
  ScheduledNotebookResponse,
  SharedNotebookResponse,
  NotebookSearchResponse,
  TriggerRunResponse,
} from './types';
import { NotebooksApi } from './NotebooksApi';

export class NotebooksApiClient extends BaseClient implements NotebooksApi {
  apiBaseUrl = '/notebooks-api/api';

  async getNotebooks<T>(
    entityType: string,
    projectId: string,
    pageNumber: number,
    status?: RunStatus,
    limit?: number,
  ): Promise<T> {
    const offset = pageNumber * NO_OF_ROWS_PER_PAGE;
    const params: NotebooksQueryParams = {
      offset: offset,
      limit: limit || NO_OF_ROWS_PER_PAGE,
    };
    if (projectId) {
      params.project_id = projectId;
    }
    if (status) {
      params.status = status;
    }
    return await this.get<T>(`${this.apiBaseUrl}/${entityType}`, params);
  }

  async getChildEntities<T>(
    queryParamKey: string,
    childEntityId: string,
    entityKey: string,
    page: number,
    status?: RunStatus,
  ): Promise<T> {
    const offset = NO_OF_ROWS_PER_PAGE * page;
    const params: ExecutionsQueryParams = {
      offset: offset,
      limit: NO_OF_ROWS_PER_PAGE,
      [queryParamKey]: childEntityId,
    };
    if (status) {
      params.status = status;
    }
    return await this.get(`${this.apiBaseUrl}/${entityKey}`, params);
  }

  async getSharedNotebooks(
    projectId: string,
    pageNumber: number,
    status?: RunStatus,
    limit?: number,
  ): Promise<SharedNotebooksResponse> {
    return this.getNotebooks(
      'shared-notebooks',
      projectId,
      pageNumber,
      status,
      limit,
    );
  }

  async getScheduledNotebooks(
    projectId: string,
    pageNumber: number,
    status?: RunStatus,
  ): Promise<ScheduledNotebooksResponse> {
    return this.getNotebooks(
      'scheduled-notebooks',
      projectId,
      pageNumber,
      status,
    );
  }

  async getVersions(
    sharedNotebookId: string,
    page: number,
    status?: RunStatus,
  ): Promise<VersionsResponse> {
    return this.getChildEntities(
      'shared_notebook_id',
      sharedNotebookId,
      'versions',
      page,
      status,
    );
  }

  async getRuns(
    scheduledNotebookId: string,
    page: number,
    status?: RunStatus,
  ): Promise<RunsResponse> {
    return this.getChildEntities(
      'scheduled_notebook_id',
      scheduledNotebookId,
      'runs',
      page,
      status,
    );
  }

  async getScheduledNotebookDetails(
    scheduledNotebookId: string,
  ): Promise<ScheduledNotebookResponse> {
    return await this.get(
      `${this.apiBaseUrl}/scheduled-notebooks/${scheduledNotebookId}`,
    );
  }
  async getSharedNotebookDetails(
    sharedNotebookId: string,
  ): Promise<SharedNotebookResponse> {
    return await this.get(
      `${this.apiBaseUrl}/shared-notebooks/${sharedNotebookId}`,
    );
  }
  async getRunDetails(runId: string): Promise<RunResponse> {
    return await this.get(`${this.apiBaseUrl}/runs/${runId}`);
  }

  async getVersionDetails(versionId: string): Promise<VersionResponse> {
    return await this.get(`${this.apiBaseUrl}/versions/${versionId}`);
  }

  async updateScheduledNotebook(
    id: string,
    disabled: boolean,
  ): Promise<ScheduledNotebookResponse> {
    const payload = { disabled: disabled };
    return await this.patch(
      `${this.apiBaseUrl}/scheduled-notebooks/${id}`,
      payload,
    );
  }

  async deleteRun(
    id: string,
    scheduledNotebookId: string,
  ): Promise<RunResponse> {
    const payload = {
      scheduled_notebook_id: scheduledNotebookId,
      archived: true,
    };
    return await this.patch(`${this.apiBaseUrl}/runs/${id}`, payload);
  }

  async deleteVersion(
    id: string,
    sharedNotebookId: string,
  ): Promise<VersionResponse> {
    const payload = {
      shared_notebook_id: sharedNotebookId,
      archived: true,
    };
    return await this.patch(`${this.apiBaseUrl}/versions/${id}`, payload);
  }

  async deleteSharedNotebook(
    sharedNotebookId: string,
  ): Promise<SharedNotebookResponse> {
    const payload = { archived: true };
    return await this.patch(
      `${this.apiBaseUrl}/shared-notebooks/${sharedNotebookId}`,
      payload,
    );
  }

  async deleteScheduledNotebook(
    scheduledNotebookId: string,
  ): Promise<ScheduledNotebookResponse> {
    const payload = { archived: true };
    return await this.patch(
      `${this.apiBaseUrl}/scheduled-notebooks/${scheduledNotebookId}`,
      payload,
    );
  }

  async runScheduledNotebook(
    scheduledNotebookId: string,
  ): Promise<TriggerRunResponse> {
    const payload = { scheduled_notebook_id: scheduledNotebookId };
    return await this.post(
      `${this.apiBaseUrl}/scheduled-notebooks/runs`,
      payload,
    );
  }

  async search(
    query: string,
    PAGE_SIZE: number,
  ): Promise<NotebookSearchResponse> {
    const params: SearchQueryParams = {
      text: query,
      limit: PAGE_SIZE,
    };
    return await this.get<NotebookSearchResponse>(
      `${this.apiBaseUrl}/entities`,
      params,
    );
  }
}
