import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Box from '@mui/system/Box';
import {
  AboutField,
  getFormattedDateTime,
  timeAgo,
  UserReference,
} from 'plugin-ui-components';
import React from 'react';
import { usePipelineRun } from '../../../hooks';
import { RunStepModel } from '../../../models';
import { TagMedium } from '../../../common';
import { getColorByStatus } from '../../../utils/pipeline';
import { STEP_PRE_IN_PROGRESS_STATUSES } from '../../../constants';

type ProductionDeployments = {
  steps: { name: string; ordinal: number; status: string }[];
  targets: string[];
};

const PROD_CLUSTERS = ['cuckoo-test', 'aruha-test', 'bazaar-test'];
const TEST_CLUSTERS = ['sandbox', 'playground'];

function productionDeployments(steps: RunStepModel[]): ProductionDeployments {
  return steps.reduce(
    (acc, curr) => {
      const isProd =
        !!curr.target_cluster &&
        (!curr.target_cluster.includes('-test') ||
          PROD_CLUSTERS.includes(curr.target_cluster)) &&
        !TEST_CLUSTERS.includes(curr.target_cluster) &&
        !STEP_PRE_IN_PROGRESS_STATUSES.includes(curr.status);

      if (isProd) {
        const newSteps = [
          ...acc.steps,
          { name: curr.id, ordinal: curr.ordinal, status: curr.status },
        ];

        const newTargets = acc.targets.includes(curr.target_cluster)
          ? acc.targets
          : [...acc.targets, curr.target_cluster];

        return {
          steps: newSteps,
          targets: newTargets,
        };
      }

      return acc;
    },
    { steps: [], targets: [] } as ProductionDeployments,
  );
}

export function LatestDeploymentPipeline({
  pipelineID,
}: {
  pipelineID: string;
}) {
  const { pipeline, loading } = usePipelineRun(pipelineID);

  if (loading) {
    return <LinearProgress />;
  }

  const prodDeployments = productionDeployments(pipeline?.steps || []);

  return (
    <Grid container spacing={2}>
      <AboutField xs={12} sm={6} label="Pipeline">
        <Link href={`/cdp/pipeline/${pipelineID}`}>
          {pipeline?.build_version}
        </Link>
      </AboutField>

      <AboutField xs={12} sm={6} label="Status">
        <TagMedium color={getColorByStatus(pipeline?.status || '')}>
          {pipeline?.status}
        </TagMedium>
      </AboutField>

      <AboutField xs={12} sm={6} label="Associated PR">
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${pipeline?.target_repo}/pull/${pipeline?.env_vars.CDP_ASSOCIATED_PR_NUMBER}`}
        >
          #{pipeline?.env_vars.CDP_ASSOCIATED_PR_NUMBER}
        </Link>
        <span>&nbsp;(</span>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${pipeline?.source_repo}/commit/${pipeline?.commit_id}`}
        >
          {pipeline?.commit_id.slice(0, 6)}
        </Link>
        <span>)</span>
      </AboutField>

      <AboutField xs={12} sm={6} label="Finished at">
        <strong>{timeAgo(pipeline?.finished_at)}&nbsp;</strong>
        <small>({getFormattedDateTime(pipeline?.finished_at)})</small>
      </AboutField>

      <AboutField xs={12} sm={6} label="Accountable user">
        <UserReference user={pipeline?.accountable_user} displayType="login" />
      </AboutField>

      <AboutField xs={12} sm={6} label="Application">
        <strong>{pipeline?.tags.application}</strong>
      </AboutField>

      <AboutField xs={12} sm={6} label="Production target account/-s">
        {prodDeployments.targets?.map(t => (
          <strong>{t}&nbsp;</strong>
        ))}
      </AboutField>

      <AboutField xs={12} sm={6} label="Production steps">
        {prodDeployments.steps?.map(s => (
          <Box display="inline-block" margin="1px">
            <Link href={`./${pipelineID}/steps/${s.ordinal}`}>
              <TagMedium color={getColorByStatus(s.status)}>{s.name}</TagMedium>
            </Link>
          </Box>
        ))}
      </AboutField>
    </Grid>
  );
}
