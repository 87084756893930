import React, { ReactElement } from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import {
  timeAgo,
  durationHumanized,
  getFormattedDateTime,
  getFormattedShortDateTime,
} from '../../../../utils/time';

import { useServices } from '../../../../services';
import { IWithRepoInfo, RunsModel } from '../../../../models';
import { Tooltip, Emoji } from '../../../../common';
import {
  StyledGridRow,
  StyledGridColumn,
  QuickActions,
  StepsList,
} from '../../../common';
import { Commit } from '../Commit';
import { useHighlightStyles } from '../../../common/GridLayout/styles';
import { PIPELINE_STATUS } from '../../../../constants';
import { Status } from '../../../common/Status';

import * as S from './styles';

export const RunList = observer(() => {
  const { runsService, preferencesService } = useServices();

  const getRepoUrl = (run: IWithRepoInfo) =>
    `/cdp/${run.repoInfo.alias}/${run.repoInfo.organization}/${run.repoInfo.name}`;

  return (
    <Box flex={1} margin={0} padding={0}>
      {runsService.runs.map(item => {
        if (
          item.id === runsService.rollbackPipelineID &&
          item.status === PIPELINE_STATUS.IN_PROGRESS
        ) {
          return (
            <HighlightedElement text="retriggered by rollback">
              <Execution
                e={item}
                absoluteDate={preferencesService.absoluteDate}
                getRepoUrl={getRepoUrl}
                action={runsService.reload}
              />
            </HighlightedElement>
          );
        }
        return (
          <Execution
            e={item}
            absoluteDate={preferencesService.absoluteDate}
            getRepoUrl={getRepoUrl}
            action={runsService.reload}
          />
        );
      })}
    </Box>
  );
});

function HighlightedElement({
  text,
  children,
}: {
  text: string;
  children: ReactElement;
}) {
  const classes = useHighlightStyles();
  return (
    <div className={classes.container}>
      <p className={classes.container__element}>{text}</p>
      {children}
    </div>
  );
}

function Execution({
  e,
  action,
  absoluteDate,
  getRepoUrl,
}: {
  e: RunsModel;
  absoluteDate: boolean;
  action: () => Promise<void>;
  getRepoUrl: (run: IWithRepoInfo) => string;
}) {
  return (
    <StyledGridRow key={e.id}>
      <StyledGridColumn item spaced>
        <Status status={e.status} dense />
        <S.TextWrapped>
          <Link to={`./${e.id}/run`}>{e.build_version}</Link>
        </S.TextWrapped>
      </StyledGridColumn>

      <StyledGridColumn item>
        <Commit item={e} />
      </StyledGridColumn>

      <StyledGridColumn item>
        <Tooltip
          title={`Last triggered at: ${getFormattedDateTime(e.triggered_at)}`}
        >
          <S.Text>
            {absoluteDate
              ? getFormattedShortDateTime(e.triggered_at)
              : timeAgo(e.triggered_at)}
          </S.Text>
        </Tooltip>

        <Tooltip title="Time from pipeline creation to completion.">
          <S.TextLight>
            {e.finished_at && durationHumanized(e.created_at, e.finished_at)}
          </S.TextLight>
        </Tooltip>
      </StyledGridColumn>

      <StyledGridColumn>
        {e.steps && e.steps.length ? (
          <StepsList key={e.id} run={e} repoUrl={getRepoUrl} />
        ) : (
          <S.ErrorMessage>
            <Tooltip title={e.error}>
              <span>
                Pipeline creation failed&nbsp;
                <Emoji name="dizzy face" emoji="😵" />
              </span>
            </Tooltip>
          </S.ErrorMessage>
        )}
      </StyledGridColumn>
      <StyledGridColumn item>
        <QuickActions
          pipelineStatus={e.status}
          steps={e.steps}
          runId={e.id}
          callback={action}
          hasDependencies={e.hasDependencyVars}
        />
      </StyledGridColumn>
    </StyledGridRow>
  );
}
