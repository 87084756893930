import styled from '@mui/styles/styled';
import MaterialGitHubIcon from '@mui/icons-material/GitHub';

export const Artifact = styled('li')({
  display: 'flex',
  flexFlow: 'row wrap',
  overflow: 'hidden',

  ['& + &']: {
    paddingTop: '16px',
  },
});

export const Element = styled('div')({
  flexShrink: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  position: 'relative',

  ['& + &']: {
    ['&:before']: {
      margin: '0 10px',
      content: `"•"`,
      opacity: '.6',
    },
  },

  ['& > span']: {
    display: 'inline-flex',
    alignItems: 'center',
  },
});

export const GitHubIcon = styled(MaterialGitHubIcon)({
  width: '15px',
  height: '15px',
  verticalAlign: '-1px',
});
