import styled from '@mui/styles/styled';

const SPACING = '8px';

export const Grid = styled('div')(props => ({
  columns: 'auto 2',
  columnGap: 0,
  margin: `-${SPACING}`,

  [props.theme.breakpoints.down('md')]: {
    columns: 'auto 1',
  },
}));

export const GridItem = styled('div')({
  position: 'relative',
  breakInside: 'avoid',
  padding: SPACING,
});
