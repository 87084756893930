import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

export const WhyCostsMatter = () => {
  return (
    <Box mt={2} mb={0}>
      <Container maxWidth="md">
        <Box mt={2} mb={2}>
          <Typography variant="h5" align="center" gutterBottom>
            Why cloud costs matter?
          </Typography>
        </Box>
        <Grid container alignContent="space-around" spacing={3} wrap="nowrap">
          <Grid item>
            <Typography variant="h6">
              Sustainability{' '}
              <span role="img" aria-label="globe">
                🌎
              </span>
            </Typography>
            <Typography>
              Reducing cloud usage improves our carbon footprint.
            </Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Revenue{' '}
              <span role="img" aria-label="money-with-wings">
                💸
              </span>
            </Typography>
            <Typography>
              Keeping cloud costs well-tuned prevents infrastructure from eating
              into revenue.
            </Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Innovation{' '}
              <span role="img" aria-label="medal">
                🥇
              </span>
            </Typography>
            <Typography>
              The more we save, the more we can reinvest in speed and
              innovation.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
