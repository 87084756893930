import styled from '@mui/styles/styled';
import MuiDialogContent from '@mui/material/DialogContent';

export const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '400px',
}));

export const DialogContent = styled(MuiDialogContent)(props => ({
  '& >:not(:last-child)': {
    marginBottom: props.theme.spacing(2),
  },
}));

export const ErrorWrapper = styled('div')(props => ({
  borderBottom: `2px solid ${props.theme.palette.divider}`,
  marginBottom: props.theme.spacing(1),
  '& svg': {
    marginRight: props.theme.spacing(1),
  },
}));

export const ErrorDescription = styled('p')(props => ({
  paddingLeft: props.theme.spacing(4),
}));
