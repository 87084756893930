import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const label = 'Lifecycle';
const id = 'lifecycle-filter';
export const LifecyclesFilter = React.memo(() => {
  const { availableLifecycles, lifecycleFilter, setLifecycleFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot: select } = useStyles();

  return (
    <FormControl variant="outlined" size="small" margin="dense">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        variant="outlined"
        size="small"
        value={lifecycleFilter}
        onChange={ev => setLifecycleFilter(String(ev.target.value))}
        label={label}
        labelId={id}
        classes={{ select }}
      >
        <MenuItem value="">
          <em>All</em>
        </MenuItem>
        {availableLifecycles.map(lifecycle => (
          <MenuItem key={lifecycle} value={lifecycle}>
            {lifecycle}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
