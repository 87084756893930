import { UnifiedThemeOptions } from '@backstage/theme';
import { alertClasses } from '@mui/material/Alert';

/** The value is defined in `Root.tsx` and updated when the theme changes */
const disabledColor: string = 'var(--disabled-color)';

export const stylesOverrides: UnifiedThemeOptions['components'] = {
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        // Fix height changing when expanded
        '&.Mui-expanded': {
          minHeight: 48,
        },
      },
      content: {
        // Fix height changing when expanded
        '&.Mui-expanded': {
          margin: '12px 0',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        margin: 0,
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        [`&.${alertClasses.outlined}.${alertClasses.colorSuccess}`]: {
          color: '#4caf50',
        },
        [`&.${alertClasses.outlined}.${alertClasses.colorInfo}`]: {
          color: '#2196f3',
        },
        [`&.${alertClasses.outlined}.${alertClasses.colorWarning}`]: {
          color: '#ff9800',
        },
        [`&.${alertClasses.outlined}.${alertClasses.colorError}`]: {
          color: '#f44336',
        },
        [`&.${alertClasses.standard}.${alertClasses.colorInfo}`]: {
          border: '1px solid #c0e4ff',
        },
        [`&.${alertClasses.standard}.${alertClasses.colorWarning}`]: {
          border: '1px solid #ffe8c8',
        },
        [`&.${alertClasses.standard}.${alertClasses.colorError}`]: {
          border: '1px solid #ffe0dc',
        },
      },
      message: {
        flexGrow: 1,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        '&$disabled': {
          color: disabledColor,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        '&:before': {
          borderBottom: '1px solid currentColor',
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        backgroundColor: disabledColor,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: 'currentColor',
        '&$disabled': {
          color: disabledColor,
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
      popupIndicator: {
        color: 'currentColor',
      },
      clearIndicator: {
        color: 'currentColor',
      },
      tag: {
        '& ~ input:empty:not(:focus)': {
          height: 0,
          padding: '0 !important',
        },
      },
      groupLabel: {
        fontWeight: 700,
        marginTop: '0.5rem',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        color: 'currentColor',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'var(--mui-icon-color)',
      },
    },
  },
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        backgroundColor: 'transparent',
      },
      grouped: {
        borderColor: '#8e8e8e',
        color: 'currentColor',
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        '&$selected': {
          color: 'white',
          backgroundColor: '#000',
          '&:hover': {
            backgroundColor: '#403f3f',
          },
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        letterSpacing: '0.07em',
        '@media (min-width: 600px)': {
          minWidth: 120,
        },
      },
      textColorInherit: {
        opacity: 1,
      },
      labelIcon: {
        '& > span': {
          '& > *:first-child': {
            marginBottom: '0 !important',
          },
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'var(--tr-bg)',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: 'var(--disabled-color)',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      // TODO: Add these typography styles and re-align with providers to match the design guidelines
      // h1: {
      //   fontSize: '2rem',
      //   fontWeight: 700,
      // },
      // h2: {
      //   fontSize: '1.75rem',
      //   fontWeight: 700,
      // },
      h3: {
        // fontSize: '1.5rem',
        fontSize: '1.3rem',
        fontWeight: 700,
      },
      h4: {
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: 1.6,
        marginBottom: 0,
      },
      // h5: {
      //   fontSize: '1.125rem',
      //   fontWeight: 700,
      // },
      h6: {
        color: 'var(--text-secondary)',
        textTransform: 'uppercase',
        // fontSize: '1rem',
        fontSize: '0.630rem',
        fontWeight: 700,
        letterSpacing: 0.5,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: 'inherit',
        height: 'auto',
      },
    },
  },
};
