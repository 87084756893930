import React from 'react';
import FormControl from '@mui/material/FormControl';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const label = 'Scorecard Status';
const id = 'scorecard-status-filter';

export const ScorecardStatusFilter = React.memo(() => {
  const {
    availableScorecardStatus,
    scorecardStatusFilter,
    setScorecardStatusFilter,
  } = React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();
  return (
    <FormControl variant="outlined" size="medium" sx={{ marginBottom: '1rem' }}>
      <Autocomplete
        id={id}
        multiple
        options={availableScorecardStatus}
        value={scorecardStatusFilter}
        classes={{ inputRoot }}
        onChange={(_: any, value) =>
          setScorecardStatusFilter(value as string[])
        }
        getOptionKey={option => option.toLocaleLowerCase()}
        renderInput={params => (
          <TextField
            label={label}
            {...params}
            size="small"
            variant="outlined"
            margin="dense"
          />
        )}
      />
    </FormControl>
  );
});
