import TableCell from '@mui/material/TableCell';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      color: theme.palette.text.primary,
      wordBreak: 'keep-all',
    },
  }),
)(TableCell);
