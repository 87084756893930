import {
  LocalStoredShortcuts,
  Shortcut,
} from '@backstage-community/plugin-shortcuts';
import { PluginTracking } from 'plugin-ui-components';

export class ShortcutsTrackingWrapper extends LocalStoredShortcuts {
  add(shortcut: Omit<Shortcut, 'id'>): Promise<void> {
    PluginTracking.sendEvent({
      plugin: 'shortcuts',
      eventCategory: 'Shortcuts Widget',
      eventLabel: `Shortcut added as ${shortcut.title}. URL: ${shortcut.url}`,
      eventAction: 'change',
    });
    return super.add(shortcut);
  }

  remove(id: string): Promise<void> {
    PluginTracking.sendEvent({
      plugin: 'shortcuts',
      eventCategory: 'Shortcuts Widget',
      eventLabel: 'Shortcut id: ${id} removed',
      eventAction: 'change',
    });
    return super.remove(id);
  }

  update(shortcut: Shortcut): Promise<void> {
    PluginTracking.sendEvent({
      plugin: 'shortcuts',
      eventCategory: 'Shortcuts Widget',
      eventLabel: `Shortcut updated as ${shortcut.title}. URL: ${shortcut.url}`,
      eventAction: 'change',
    });
    return super.update(shortcut);
  }
}
