import React from 'react';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { TreeExpandIconProps } from './types';
import { useStyles } from './styles';

export function TreeExpandIcon({
  open,
  showTreeGuides,
  isTree,
  onTreeExpandClicked,
  indent = 1,
  row,
  setOpen,
  setLocalDepth,
}: TreeExpandIconProps) {
  const styles = useStyles();

  if (!isTree) return <></>;

  return (
    <div
      key="tree"
      className={cn(styles.tableCell, styles.tableCellExpand, {
        'show-guides': showTreeGuides,
      })}
    >
      <IconButton
        aria-label="expand row"
        size="small"
        className="tree-icon-btn"
        style={{ marginLeft: `${(indent - 1) * 2}rem` }}
        onClick={() => {
          const expanded = !open;
          setLocalDepth(indent);
          setOpen(expanded);
          if (onTreeExpandClicked)
            onTreeExpandClicked(
              { ...row, children: undefined },
              indent,
              expanded,
            );
        }}
        data-indent={indent}
        data-has-children={String(!!row.children?.length)}
        data-expanded={String(open)}
        disabled={!row.children?.length}
        disableRipple
      >
        <AddIcon className="icon-placeholder" />
        {!!row.children?.length && (
          <div className="icon-wrapper">
            <AddIcon fontSize="small" className={cn('icon', { show: !open })} />
            <RemoveIcon
              fontSize="small"
              className={cn('icon', { show: open })}
            />
          </div>
        )}
      </IconButton>
    </div>
  );
}
