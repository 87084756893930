import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import {
  ActionPanelLayout,
  LiveButton,
  UserPreferencesAction,
} from '../../../common';
import { useServices } from '../../../../services';
import * as S from './styles';
import { AutoComplete, IOption } from 'plugin-ui-components';
import { EVENT_OPTIONS } from '../../../../constants';

type ActionPanelProps = {
  e: string[];
};

export const ActionPanel = observer(({ e }: ActionPanelProps) => {
  const { runsService, preferencesService } = useServices();
  const [_, setSearchParams] = useSearchParams({
    event: [],
  });

  const handleEventSelection = (option: IOption[]) => {
    const optionValue = option.map(item => item.value);
    setSearchParams({ event: optionValue }, { replace: true });
  };

  const getEventsSelected = (evt: string[]) => {
    return evt?.map(item => ({
      label: item,
      value: item,
    }));
  };

  return (
    <ActionPanelLayout
      filtersChildren={
        <AutoComplete
          onChange={handleEventSelection}
          options={EVENT_OPTIONS}
          defaultSelection={getEventsSelected(e)}
          uniqueId="events_type_repo"
          placeholder="Event type"
          inputPlaceholder="Filter event types"
          showSelectedFirst={false}
        />
      }
    >
      <S.Actions>
        <LiveButton service={runsService} />
        <UserPreferencesAction service={preferencesService} />
      </S.Actions>
    </ActionPanelLayout>
  );
});
