import React from 'react';
import { useInterval } from 'react-use';
import { useSearchParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { useApi } from '@backstage/core-plugin-api';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Alert from '@mui/lab/Alert';
import RefreshIcon from '@mui/icons-material/Refresh';
import { mintStorageApiRef } from '../../../../api';
import { AccessControlContext } from '../../context';
import { hasPendingUpdates } from '../../utils';
import { AccessControlCard } from '../AccessControlCard';
import ConfirmationDialog from '../ConfirmationDialog';
import { CredentialItem } from './CredentialItem';
import { useNavigationBack } from '../../useNavigationBack';

export function CredentialDistribution() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [confirmationData, setConfirmationData] = React.useState<{
    bucketsToAdd: string[];
    bucketsToRemove: string[];
  }>({
    bucketsToAdd: [],
    bucketsToRemove: [],
  });
  const [updateState, setUpdateState] = React.useState<{
    pending: boolean;
    success: boolean;
    warning: boolean;
  }>({ pending: false, success: true, warning: false });
  const [hasPendingNotification, setHasPendingNotification] =
    React.useState(false);

  const [_, setSearchParams] = useSearchParams();
  const handleBack = useNavigationBack({
    page: 'credentialDistribution',
    searchParamsKeys: ['editCredentialDistribution'],
  });

  const {
    appName,
    appData,
    editState,
    setEditState,
    loadAppData,
    hasEditPermission,
  } = React.useContext(AccessControlContext);

  const { s3_buckets: appBuckets }: { s3_buckets: string[] } =
    (appData.length as any) && appData[0];

  const mintApi = useApi(mintStorageApiRef);

  useInterval(() => {
    const pending = hasPendingUpdates('credentialDistribution');
    if (pending && !hasPendingNotification) setHasPendingNotification(true);
    if (!pending) setHasPendingNotification(false);
  }, 500);

  const onEdit = () => {
    setEditState({ ...editState, credentialDistribution: true });
    setSearchParams({
      editCredentialDistribution: 'true',
    });
  };

  const showConfirmationModal = async (updatedData: any[]) => {
    if (!isEqual(appBuckets, updatedData)) {
      const bucketsToAdd = updatedData.filter(
        item => !appBuckets.includes(item),
      );
      const bucketsToRemove = appBuckets.filter(
        item => !updatedData.includes(item),
      );
      setConfirmationData({ bucketsToAdd, bucketsToRemove });
      setOpen(true);
    } else {
      setEditState({ ...editState, credentialDistribution: false });
    }
  };

  const handleSave = async () => {
    setUpdateState(prev => ({ ...prev, pending: true }));
    const { bucketsToAdd, bucketsToRemove } = confirmationData;
    const filteredAppBuckets = appBuckets.filter(
      bucket => !bucketsToRemove.includes(bucket),
    );
    const newAppBuckets = [...bucketsToAdd, ...filteredAppBuckets];

    try {
      const mintResponseOk = await mintApi.updateS3BucketsData(
        appName,
        appData[0],
        newAppBuckets,
      );
      if (mintResponseOk) {
        const renewResponseOk = await mintApi.renewCredentials(appName);
        if (renewResponseOk) {
          setUpdateState(prev => ({ ...prev, success: true }));
          setEditState({ ...editState, credentialDistribution: false });
          loadAppData();
          setOpen(false);
        } else {
          setUpdateState(prev => ({ ...prev, warning: true }));
        }
      } else {
        setUpdateState(prev => ({ ...prev, success: false }));
      }
    } catch {
      setUpdateState(prev => ({ ...prev, success: false }));
    } finally {
      setUpdateState(prev => ({ ...prev, pending: false }));
    }
  };

  const onRefresh = async () => await mintApi.renewCredentials(appName);

  return (
    <>
      <AccessControlCard
        trackingPlugin="credential-distribution"
        title="Credential Distribution (STUPS only)"
        data={appData}
        showPending={hasPendingNotification}
        onSave={showConfirmationModal}
        onEdit={onEdit}
        onCancel={handleBack}
        onRefresh={onRefresh}
        canEdit={editState.credentialDistribution}
        enableSearch={false}
        render={(app, index, editable, updatedData, setUpdatedData) => (
          <CredentialItem
            onEdit={() => {}}
            key={index}
            hasEditPermission={hasEditPermission}
            updatedData={updatedData}
            setUpdatedData={setUpdatedData}
            editable={editable}
            {...app}
          />
        )}
      />
      <ConfirmationDialog
        open={open}
        onClose={() => {
          setUpdateState({ pending: false, success: true, warning: false });
          setOpen(false);
        }}
        onSave={handleSave}
      >
        <Box height="5px">{updateState.pending && <LinearProgress />}</Box>
        {!updateState.success && (
          <Alert severity="error">
            Failed updating configurations. Try again.
          </Alert>
        )}
        {updateState.warning && (
          <Alert severity="warning">
            The configuration was successfully stored in Mint API, however there
            was a problem syncing the Credentials. Please try requesting the
            renewal of Credentials manually via the "Sync"{' '}
            <RefreshIcon fontSize="small" style={{ verticalAlign: 'middle' }} />{' '}
            button in{' '}
            <Link href={`/catalog/default/component/${appName}/access-control`}>
              Access Control page
            </Link>
            .
          </Alert>
        )}
        {!updateState.warning && updateState.success && (
          <Alert severity="info">
            Please note that changes can take a few minutes to be reflected in
            Sunrise.
          </Alert>
        )}
        <Box maxHeight="300px" overflow="scroll" marginTop="1rem">
          {!!confirmationData.bucketsToAdd.length && (
            <Box marginBottom="1rem">
              <Typography>
                These will be <strong>added</strong>:
              </Typography>
              <ul>
                {confirmationData.bucketsToAdd.map(bucket => (
                  <li>{bucket}</li>
                ))}
              </ul>
            </Box>
          )}
          {!!confirmationData.bucketsToRemove.length && (
            <Box marginBottom="1rem">
              <Typography>
                These will be <strong>removed</strong>:
              </Typography>
              <ul>
                {confirmationData.bucketsToRemove.map(bucket => (
                  <li>{bucket}</li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </ConfirmationDialog>
    </>
  );
}
