export const common: Pick<IEventTracker, 'plugin' | 'eventCategory'> = {
  plugin: 'catalog',
  eventCategory: 'Documentation About Card',
};

export const viewSource = (name: string): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Clicked on View Source link of documentation ${name}`,
});

export const viewDocs: IEventTracker = {
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Clicked on Service View Documentation link',
};

export const deleteDoc = (name: string): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Clicked on Service Delete Document ${name}`,
});

export const confirmDeleteDoc = (name: string): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Clicked on Service Confirm Delete Document ${name}`,
});

export const ownerLink: IEventTracker = {
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: 'Documentation Owner link',
};

export const editCategoryDoc = (name: string): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Clicked on Service Edit Document ${name} Category`,
});

export const confirmEditDocCategory = (name: string): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Clicked on Service Confirm Edit Document ${name} Category`,
});
