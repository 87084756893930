import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TestRun } from '../../api/LoadTestOrchestratorApi';

export interface RuntimeVusComponentProps {
  testRun: TestRun;
  refetch: () => void;
}

export const RuntimeVusComponent: React.FC<RuntimeVusComponentProps> = ({
  testRun,
}) => {
  return (
    <Grid container>
      <Grid item>
        <Typography variant="body2" style={{ marginRight: 8 }}>
          {testRun.state_parameters?.runtimeVus}
        </Typography>
      </Grid>
    </Grid>
  );
};
