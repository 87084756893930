import React from 'react';
import cn from 'classnames';
import Tooltip from '@mui/material/Tooltip';
import FlagOutlined from '@mui/icons-material/FlagOutlined';
import { makeTooltipText } from './utils';
import { useStyles } from './styles';
import type { FeatureFlagIconProps } from './types';
import { trackingHover } from './tracking';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
// These imports are specifically made this way because importing from the index file won't work in Storybook
import { PluginTracking } from '../Tracking/PluginTracking';

export function FeatureFlagIcon({
  name,
  details,
  color = 'primary',
  ...props
}: FeatureFlagIconProps) {
  const featureFlags = useApi(featureFlagsApiRef);
  const classes = useStyles();

  const sendHoverTracking = (ev: React.MouseEvent<SVGSVGElement>) => {
    PluginTracking.sendEvent(trackingHover(name));
    if (props.onMouseEnter) props.onMouseEnter(ev);
  };

  return featureFlags.isActive(name) ? (
    <Tooltip title={makeTooltipText(name, details)} placement="top" arrow>
      <FlagOutlined
        {...(props as any)}
        className={cn(props.className, classes.icon, 'feature-flag-icon')}
        color={color}
        style={{ fill: color }}
        onMouseEnter={sendHoverTracking}
      />
    </Tooltip>
  ) : (
    <></>
  );
}
