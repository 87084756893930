import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import {
  ListModel,
  ListModelsResponse,
  Model,
  UpdateModelRequest,
} from '../interfaces/model';
import { HttpClient } from '../httpClient';
import modelsQuery from '../graphql/models';
import { InputError } from '@backstage/errors';
import {
  ListModelVersionsParams,
  ListModelVersionsResponse,
  ModelVersion,
  UpdateModelVersion,
} from '../interfaces/model_version';
import { ModelCard, UpdateModelCard } from '../interfaces/model_card';

export interface ModelServiceApi {
  getModelById(modelId: string): Promise<Model>;
  getModels(getModelsParameters: ListModel): Promise<ListModelsResponse>;
  updateModel(modelId: number, model: UpdateModelRequest): Promise<number>;
  getModelVersions(
    listModelVersionParams: ListModelVersionsParams,
  ): Promise<ListModelVersionsResponse>;
  getModelVersionById(
    modelVersionId: string,
    modelId?: string,
  ): Promise<ModelVersion>;
  getModelCardForModel(modelId: number): Promise<ModelCard>;
  updateModelVersion(
    modelVersionId: number,
    modelVersion: UpdateModelVersion,
  ): Promise<number>;
  updateModelCard(
    modelCardId: number,
    modelCard: UpdateModelCard,
  ): Promise<number>;
  getLatestModelVersionForModel(modelId: string): Promise<ModelVersion>;
  getModelBySlug(modelSlug: string): Promise<Model>;
  getModelVersionBySlug(modelVersionSlug: string): Promise<ModelVersion>;
}

export class ModelService implements ModelServiceApi {
  readonly httpClient: HttpClient;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.httpClient = new HttpClient({
      pluginProxyEndpoint: 'ml',
      ...options,
    });
  }

  async getModelBySlug(modelSlug: string): Promise<Model> {
    return this.httpClient.get(`/models/slug/${modelSlug}`);
  }

  async getModelById(modelId: string): Promise<Model> {
    return this.httpClient.get(`/models/${modelId}`);
  }

  async getModels(getModelsParameters: ListModel): Promise<ListModelsResponse> {
    const variables = this.httpClient.cleanUpParameters(getModelsParameters);
    return this.httpClient
      .post('/graphql', {
        operationName: 'Models',
        query: modelsQuery,
        variables,
      })
      .then(response => {
        if (response.data.errors && !response.data.data) {
          throw new InputError(
            response.errors[0]?.message ||
              'There was a problem with fetching the models data',
          );
        }
        return response.data;
      });
  }

  async updateModel(
    modelId: number,
    model: UpdateModelRequest,
  ): Promise<number> {
    const parameters = this.httpClient.cleanUpParameters(model);
    return this.httpClient.patch(`/models/${modelId}`, parameters);
  }

  async getModelVersions(
    listModelVersionParams: ListModelVersionsParams,
  ): Promise<ListModelVersionsResponse> {
    const { model_id, ...queryParams } = listModelVersionParams;
    const filteredParams = this.httpClient.cleanUpParameters(queryParams);

    return this.httpClient
      .get(`/model_versions/`, {
        model_id,
        ...filteredParams,
      })
      .then(response => {
        return {
          versions: response.items as ModelVersion[],
          total: response.total,
        } as ListModelVersionsResponse;
      });
  }

  async getLatestModelVersionForModel(modelId: string): Promise<ModelVersion> {
    return this.httpClient.get(`/model_versions/${modelId}/model/latest`);
  }

  async getModelVersionById(modelVersionId: string): Promise<ModelVersion> {
    return this.httpClient.get(`/model_versions/${modelVersionId}`);
  }

  async getModelVersionBySlug(modelVersionSlug: string): Promise<ModelVersion> {
    return this.httpClient.get(`/model_versions/slug/${modelVersionSlug}`);
  }

  async getModelCardForModel(modelId: number): Promise<ModelCard> {
    return this.httpClient.get(`/models/${modelId}/card`).then(response => {
      return response.items[0] as ModelCard;
    });
  }

  async updateModelCard(
    modelCardId: number,
    modelCard: UpdateModelCard,
  ): Promise<number> {
    const parameters = this.httpClient.cleanUpParameters(modelCard);
    return this.httpClient.patch(`/model_cards/${modelCardId}`, parameters);
  }

  async updateModelVersion(
    modelVersionId: number,
    modelVersion: UpdateModelVersion,
  ): Promise<number> {
    const parameters = this.httpClient.cleanUpParameters(modelVersion);
    return this.httpClient.patch(
      `/model_versions/${modelVersionId}`,
      parameters,
    );
  }
}

export const modelsApiRef = createApiRef<ModelServiceApi>({
  id: 'ml-platform-models-api',
});
