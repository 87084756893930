import React from 'react';
import Link from '@mui/material/Link';
import { observer } from 'mobx-react-lite';

import { Text } from '../../../../common';
import { useServices } from '../../../../services';

import * as S from './styles';

export const RunPreview = observer(() => {
  const {
    runService: { run },
  } = useServices();

  return (
    <S.Preview>
      {run.commit_id && (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`https://${run.target_repo}/commit/${run.commit_id}`}
        >
          <strong>{run.commit_id.slice(0, 6)}</strong>
        </Link>
      )}

      <span>&nbsp;•&nbsp;</span>

      <Text bold ellipsis shrink={3}>
        {run.pr_title || run.commit_message}
      </Text>

      <span>&nbsp;•&nbsp;</span>

      <Text ellipsis>{run.accountable_user}</Text>

      <span>&nbsp;•&nbsp;</span>

      <Text ellipsis shrink={1}>
        {run.target_branch} {run.isPullRequest && `← ${run.branch}`}
      </Text>
    </S.Preview>
  );
});
