import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  showAllBtn: {
    display: 'block',
    padding: '0.5rem 1rem',
    textTransform: 'none',
    textAlign: 'left',
    width: '100%',
    fontWeight: 'normal',

    '&:hover': {
      textDecoration: 'none',
    },

    '& svg': {
      fontSize: '1rem',
      verticalAlign: 'middle',
      display: 'inline-block',
    },
  },
});
