import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Analysis } from '../../../types';

type Props = {
  report?: Analysis;
};

export const CardBottom: React.FC<Props> = ({ report }) => {
  return (
    <Link
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
      href={`https://codacy.bus.zalan.do/ghe/${report?.repository.owner}/${report?.repository.name}/dashboard`}
    >
      <Typography color="primary" variant="body2">
        See more{' '}
      </Typography>
      <OpenInNewIcon fontSize="small" />
    </Link>
  );
};
