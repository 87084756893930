import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';

import { DetailsHeader, PollingIcon } from '../../../common';
import { useServices } from '../../../../services';
import { Actions } from '../Actions';

import * as S from './styles';
import { UserReference } from 'plugin-ui-components';
import { GitHubTag } from '../../../common/PipelineExecutionTags/PipelineExecutionTags';

const PRTitle = observer(() => {
  const {
    runService: { run },
  } = useServices();

  return (
    <S.Title>
      <S.TextShrink>{run.pr_title}</S.TextShrink>

      <a
        href={`https://${run.target_repo}/pull/${run.pr_number}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.Icon />

        <S.TextLight>{run.pr_number}</S.TextLight>
      </a>

      {!!run.tags.labels.length && (
        <Box display="flex" alignItems="center" marginLeft={2}>
          {run.tags.labels.map(label => (
            <>
              &nbsp;
              <GitHubTag name={label} />
            </>
          ))}
        </Box>
      )}
    </S.Title>
  );
});

const Details = observer(() => {
  const {
    runService: { run },
  } = useServices();

  return (
    <S.Content>
      <S.Text>{`${run.source_type} by`}&nbsp;</S.Text>

      <UserReference user={run.accountable_user} displayType="login" />

      <S.Text>&nbsp;•&nbsp;</S.Text>

      <a
        href={`https://${run.target_repo}/tree/${run.target_branch}`}
        target="_blank"
        rel="noopener noreferrer"
        title={run.target_branch}
      >
        <S.TextLimited>{`@${run.target_branch}`}</S.TextLimited>
      </a>

      {run.isPullRequest && (
        <>
          <S.Text>&nbsp;←&nbsp;</S.Text>

          <a
            href={`https://${run.source_repo}/tree/${run.branch}`}
            target="_blank"
            rel="noopener noreferrer"
            title={run.branch}
          >
            <S.TextLimited>{`@${run.branch}`}</S.TextLimited>
          </a>
        </>
      )}
    </S.Content>
  );
});

const DependencyDetails = observer(() => {
  const {
    runService: { run },
  } = useServices();

  return (
    <S.Content>
      <S.Text>Reriggered by dependency&nbsp;</S.Text>
      <S.TextBold>{run.triggered_by_dependency}</S.TextBold>
      <S.Text>&nbsp;from&nbsp;</S.Text>
      <Link to={`./../../${run.retriggered_from_pipeline}`}>
        <S.TextBold>{run.retriggered_from_pipeline_build_version}</S.TextBold>
      </Link>
    </S.Content>
  );
});

const ScheduleDetails = observer(() => {
  const {
    runService: { run },
  } = useServices();

  return (
    <S.Content>
      <S.Text>Reriggered by&nbsp;</S.Text>
      <S.TextBold>schedule&nbsp;</S.TextBold>
      <S.Text>from&nbsp;</S.Text>
      <Link to={`./../../${run.retriggered_from_pipeline}`}>
        <S.TextBold>{run.retriggered_from_pipeline_build_version}</S.TextBold>
      </Link>
    </S.Content>
  );
});

function getDetails(
  isSchedule: boolean,
  isTriggeredByDeps: boolean,
): React.ReactElement {
  if (isSchedule) {
    return <ScheduleDetails />;
  }
  if (isTriggeredByDeps) {
    return <DependencyDetails />;
  }

  return <Details />;
}

export const Header = observer(() => {
  const { runService } = useServices();

  return (
    <DetailsHeader
      status={runService.run.status}
      title={
        runService.run.isPullRequest ? (
          <PRTitle />
        ) : (
          runService.run.commit_message
        )
      }
      details={getDetails(
        runService.run.isSchedule,
        runService.run.isTriggeredByDependency,
      )}
      actions={
        <>
          <Actions />
          <PollingIcon service={runService} />
        </>
      }
    />
  );
});
