import React from 'react';
import { opsgenieApiRef } from '../../api';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import Alert from '@mui/lab/Alert';
import { AlertsTable } from './AlertsTable';

export const AlertsList = () => {
  const opsgenieApi = useApi(opsgenieApiRef);

  const { value, loading, error } = useAsync(
    async () => await opsgenieApi.getAlerts(),
  );

  if (loading) {
    return <Progress />;
  } else if (error) {
    return (
      <Alert data-testid="error-message" severity="error">
        {error.message}
      </Alert>
    );
  }

  return <AlertsTable alerts={value!} />;
};
