import React, { type MouseEvent, type PropsWithChildren } from 'react';
import cn from 'classnames';
import MTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import MTablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  ISort,
  PaginationData,
  ParentFinder,
  TableColumn,
  TableData,
  TableProps,
  TreeNode,
  TreeNodeWithChildren,
} from './types';
import { Row } from './Row';
import { buildTree, paginate, sortRecursively } from './utils';
import { useStyles } from './styles';
import { Columns } from './Columns';
import { HStack } from '../Stacks';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';
import { FilterInput } from '../FilterInput';
import Box from '@mui/material/Box';
import { parseEntityRef } from '@backstage/catalog-model';

export function EmptyTableWrapper({
  colSpan,
  children,
}: PropsWithChildren<{ colSpan: number }>) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        {children}
      </TableCell>
    </TableRow>
  );
}

export function Table<T = any>({
  data,
  columns,
  header,
  treeMapper,
  stickyHeader,
  openDepth,
  onTreeExpandClicked,
  showTreeGuides,
  ...props
}: TableProps<T>) {
  const { pagination, showSearch = true } = props.options || {};
  const [paginationInfo, setPaginationInfo] = React.useState<PaginationData>({
    currentPage: 0,
    pageSize: props.options?.pageSize || 20,
    pageData: [],
    total: 0,
  });
  const [sort, setSort] = React.useState<ISort>();
  const [isFiltered, setIsFiltered] = React.useState<boolean>(false);
  const [processedDataNotSorted, setProcessedDataNotSorted] = React.useState<
    TreeNodeWithChildren<T>[]
  >([]);
  const [processedData, setProcessedData] = React.useState<
    TreeNodeWithChildren<T>[]
  >([]);
  const styles = useStyles();
  const isTree = data.length !== processedData.length;

  // calculates tree depth
  const depth = React.useMemo(() => {
    if (isFiltered) {
      if (openDepth !== 1) return openDepth;
      return 2;
    }
    return isTree ? openDepth : undefined;
  }, [isFiltered, isTree, openDepth]);

  // gets the columns that are searchable, passed through table options
  const searchableColumns = React.useMemo(
    () =>
      columns.filter(column => {
        if (column.field) {
          return column.searchable === undefined ? true : column.searchable;
        }
        return false;
      }),
    [columns],
  );

  const buildTreeStructure = React.useCallback(
    (d: TableData<TreeNode<T>>[], mapper?: ParentFinder<any>) => {
      if (mapper) {
        return buildTree<T>(d, mapper);
      }
      return d.map(item => {
        return { ...item, children: [] };
      });
    },
    [],
  );

  const treeData = React.useMemo(
    () => buildTreeStructure(data, treeMapper),
    [buildTreeStructure, data, treeMapper],
  );

  const handlePageChange = (
    _: MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPaginationInfo({
      ...paginationInfo,
      pageData: paginate(treeData, page, paginationInfo.pageSize),
      currentPage: page,
    });
  };

  function onColumnSort(column: TableColumn) {
    if (!column.field) return;
    if (sort?.column.field === column.field) {
      setSort(sort.order === 'asc' ? { column, order: 'desc' } : undefined);
    } else {
      setSort({ column, order: 'asc' });
    }
  }

  const debouncedFilter = debounce(function handleFilter(value: string) {
    if (value) {
      const filteredData = data.filter(row => {
        return searchableColumns.some(col => {
          // If filter function is provided use it, otherwise fallback to default filtering logic
          if (col.filterFunction) return col.filterFunction(row, value);
          // @ts-ignore
          const fieldValue = row[col.field];
          if (fieldValue) {
            if (typeof fieldValue !== 'object')
              return fieldValue
                .toLocaleString()
                .toLowerCase()
                .trim()
                .includes(value.toLowerCase().trim());
          }
          return false;
        });
      });
      // Include relations if data is a tree
      const withRelations: TableData<TreeNode<T>>[] = [];
      filteredData.forEach(row => {
        row?.relations?.forEach((rel: any) => {
          const relEntity = data.find(
            (item: TreeNode<any>) =>
              item.name === parseEntityRef(rel.targetRef).name,
          );
          if (relEntity && !withRelations.find(i => i.id === relEntity.id))
            withRelations.push(relEntity);
        });
      });

      const builtTree = buildTreeStructure(
        unionBy(filteredData, withRelations, 'id'),
        treeMapper,
      );
      setIsFiltered(true);
      return setProcessedData(builtTree);
    }
    setIsFiltered(false);
    return setProcessedData(treeData);
  }, 300);

  // resets pagination when data changes (filtering, sorting...)
  const resetPagination = React.useCallback(
    (rowsPerPage: number = paginationInfo.pageSize) => {
      setPaginationInfo({
        ...paginationInfo,
        currentPage: 0,
        pageData: paginate(
          isFiltered || sort ? processedData : treeData,
          0,
          rowsPerPage,
        ),
        pageSize: rowsPerPage,
        total: processedData.length,
      });
    },
    [processedData, isFiltered, sort, treeData, paginationInfo],
  );

  // used to map data to table rows to avoid duplicate code
  const mapTableDataToRows = (tableData: TreeNodeWithChildren[]) => {
    return tableData.map((row, index) => {
      return (
        <Row
          key={index}
          row={row}
          columns={columns}
          isTree={isTree}
          openDepth={depth}
          onTreeExpandClicked={onTreeExpandClicked}
          showTreeGuides={showTreeGuides}
        />
      );
    });
  };

  // effects
  React.useEffect(() => {
    if (pagination) {
      resetPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFiltered, processedData, pagination]);

  React.useEffect(() => {
    if (pagination) {
      resetPagination();
    }
    setProcessedData(treeData);
    setProcessedDataNotSorted(treeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, treeMapper]);

  React.useEffect(() => {
    const defaultSortColumn = columns.find(c => c.defaultSort);
    const order: ISort['order'] =
      typeof defaultSortColumn?.defaultSort === 'string'
        ? (defaultSortColumn.defaultSort as ISort['order'])
        : 'asc';
    // Only sort by default when `defaultSort` is set to true
    if (defaultSortColumn) {
      setSort({
        column: defaultSortColumn,
        order,
      });
    }
  }, [columns]);

  React.useEffect(() => {
    if (!processedData.length) return;
    if (sort) {
      const sorted = sortRecursively(processedDataNotSorted, sort);
      setProcessedData(sorted);
    } else {
      setProcessedData(processedDataNotSorted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, processedDataNotSorted]);

  React.useEffect(() => {
    setPaginationInfo(s => ({
      ...s,
      pageData: paginate(
        treeData,
        paginationInfo.currentPage,
        paginationInfo.pageSize,
      ),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.pageSize, paginationInfo.currentPage]);

  return (
    <div className={styles.tableWrapper}>
      {/* search component */}
      {(header || showSearch) && (
        <HStack
          justifyContent="space-between"
          alignItems="center"
          padding="16px"
        >
          {header && <div>{header}</div>}
          <Box flex={1} />
          {showSearch && (
            <FilterInput
              onKeyUp={e => {
                const target = e.currentTarget as HTMLInputElement;
                debouncedFilter(target.value);
              }}
            />
          )}
        </HStack>
      )}
      <MTable
        {...props}
        className={cn(props.className, styles.table, {
          sticky: stickyHeader,
        })}
      >
        <Columns
          columns={columns}
          isTree={isTree}
          sortOptions={sort}
          onSort={onColumnSort}
        />
        <TableBody>
          {/* if data is available and pagination is off, using processed data */}
          {columns &&
            processedData &&
            !pagination &&
            mapTableDataToRows(processedData)}

          {/* if data is available and pagination is on, using paginated data */}
          {columns &&
            processedData &&
            pagination &&
            mapTableDataToRows(paginationInfo.pageData)}

          {/* displaying empty node when processed data is empty */}
          {columns && !processedData.length && props?.options?.emptyNode && (
            <EmptyTableWrapper colSpan={columns.length}>
              {props?.options?.emptyNode}
            </EmptyTableWrapper>
          )}
          {columns && !processedData.length && !props?.options?.emptyNode && (
            <EmptyTableWrapper colSpan={columns.length}>
              <Typography> No records to display</Typography>
            </EmptyTableWrapper>
          )}
        </TableBody>

        {/* pagination component */}
        {pagination && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length} align="center" padding="none">
                <HStack
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-end"
                >
                  <MTablePagination
                    count={paginationInfo.total}
                    page={paginationInfo.currentPage}
                    rowsPerPage={paginationInfo.pageSize}
                    rowsPerPageOptions={Array.from(
                      new Set([props.options?.pageSize ?? 0, 10, 20, 50, 100]),
                    )
                      .filter(Boolean)
                      .sort((a, b) => a - b)}
                    onRowsPerPageChange={ev =>
                      setPaginationInfo({
                        ...paginationInfo,
                        pageSize: Number(ev.target.value),
                      })
                    }
                    onPageChange={handlePageChange}
                    slots={{
                      actions: {
                        lastButtonIcon: LastPageIcon,
                        firstButtonIcon: FirstPageIcon,
                        nextButtonIcon: NavigateNextIcon,
                        previousButtonIcon: NavigateBeforeIcon,
                      },
                    }}
                  />
                </HStack>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </MTable>
    </div>
  );
}
