export const common: Pick<IEventTracker, 'plugin' | 'eventCategory'> = {
  plugin: 'plugin-ui-components',
  eventCategory: 'Product Tour',
};

export const startTour = (
  tourId: string,
  totalSteps: number,
): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Starting tour ${tourId} with ${totalSteps} steps`,
});

export const closeTour = (
  tourId: string,
  totalSteps: number,
  currentStep: string,
): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Tour with id ${tourId} closed at ${currentStep}/${totalSteps}`,
});

export const completeTour = (
  tourId: string,
  totalSteps: number,
): IEventTracker => ({
  ...common,
  interaction: 'onClick',
  eventAction: 'click',
  eventLabel: `Tour with id ${tourId} completed ${totalSteps}/${totalSteps}`,
});
