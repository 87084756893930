import React, { useEffect, useState } from 'react';
import {
  Content,
  Header,
  HeaderLabel,
  Link,
  Page,
  Select,
  type TableColumn,
} from '@backstage/core-components';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useParams } from 'react-router';
import { Table as MaterialTable } from '@backstage/core-components';
import type {
  NSQLAuthorizationView,
  NSQLQuery,
} from '../../domain/NSQLQueries';
import { nsqlApiRef } from '../../gateway/NakadiSQLApiGateway/NakadiSQLAPIGateway';
import Alert from '@mui/lab/Alert';
import { useApi } from '@backstage/core-plugin-api';
import { EventTracker } from 'plugin-ui-components';
import { toISODateTime } from '../../utils/unitFormatters';
import {
  onApplicationClick,
  onOwningAppClick,
  onTeamClick,
  onUserClick,
} from '../../utils/tracking';

const AUHTORIZATION_SUBJECTS_LIST = [
  { label: 'team', value: 'team' },
  { label: 'user', value: 'user' },
  { label: 'service', value: 'service' },
];

function validateValue(auth: NSQLAuthorizationView) {
  if (!auth.value) {
    return {
      isValid: false,
      helperText: 'This field is required',
    };
  }
  if (
    (auth.type === 'service' && !auth.value?.startsWith('stups_')) ||
    (auth.type === 'service' && !auth.value?.replace('stups_', ''))
  ) {
    return {
      isValid: false,
      helperText: 'Service name should start with "stups_" perfix',
    };
  }
  return true;
}

function validateRole(auth: NSQLAuthorizationView) {
  if (!auth.isAdmin && !auth.isReader) {
    return {
      isValid: false,
      helperText: 'Please select at least one role',
    };
  }
  return true;
}

function mapToAuthorizationView(
  nsqlQuery?: NSQLQuery,
): NSQLAuthorizationView[] {
  if (!nsqlQuery) {
    return [];
  }

  const { authorization } = nsqlQuery;
  const authorizationViewHolder: any = {};

  [
    ...authorization.readers.map(auth => ({ ...auth, isReader: true })),
    ...authorization.admins.map(auth => ({ ...auth, isAdmin: true })),
  ].forEach((auth: any) => {
    const recordId = `${auth.data_type}+${auth.value}`;
    authorizationViewHolder[recordId] = {
      ...authorizationViewHolder[recordId],
      id: recordId,
      type: auth.data_type,
      value: auth.value,
      [auth.isReader ? 'isReader' : 'isAdmin']: true,
    };
  });

  const authorizationView: NSQLAuthorizationView[] = [];
  Object.keys(authorizationViewHolder).forEach(recordId => {
    authorizationView.push(authorizationViewHolder[recordId]);
  });

  return authorizationView.sort((a, b) => {
    const localCompare = a.type.localeCompare(b.type);
    if (localCompare !== 0) {
      return localCompare;
    }
    return a.value.localeCompare(b.value);
  });
}

function hasTeamsAsAdmin(auth: NSQLAuthorizationView[]): boolean {
  return Boolean(auth.find(i => i.type === 'team'));
}

export function NSQLQueryLandingPage() {
  const { query_id = '' } = useParams();
  const nsqlApi = useApi(nsqlApiRef);
  const [query, setQuery] = useState<NSQLQuery>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [savedQuery, setSavedQuery] = useState<NSQLQuery>();

  useEffect(() => {
    nsqlApi
      .getNSQLQuery(query_id)
      .then(async responseQuery => {
        setQuery(responseQuery);
        setSavedQuery(responseQuery);
      })
      .catch((reason: string) => {
        setError(reason.toString());
      })
      .finally(() => setIsLoading(false));
  }, [nsqlApi, query_id]);

  const onAddRecord = async (newAuth: NSQLAuthorizationView) => {
    if (!query) {
      return;
    }

    const updatedAuth = {
      ...query.authorization,
    };

    if (newAuth.isAdmin) {
      updatedAuth.admins = [
        ...updatedAuth.admins,
        {
          data_type: newAuth.type || 'team',
          value: newAuth.value,
        },
      ];
    }

    if (newAuth.isReader) {
      updatedAuth.readers = [
        ...updatedAuth.readers,
        {
          data_type: newAuth.type || 'team',
          value: newAuth.value,
        },
      ];
    }

    setQuery({
      ...query,
      authorization: updatedAuth,
    });
  };

  const onDeleteRecord = async (authToDlete: NSQLAuthorizationView) => {
    if (!query) {
      return;
    }

    setQuery({
      ...query,
      authorization: {
        admins: query.authorization.admins.filter(
          auth => auth.value !== authToDlete.value,
        ),
        readers: query.authorization.readers.filter(
          auth => auth.value !== authToDlete.value,
        ),
      },
    });
  };

  const onUpdateRecord = async (
    newAuth: NSQLAuthorizationView,
    oldAuth?: NSQLAuthorizationView,
  ) => {
    if (!query) {
      return;
    }

    const updatedAuth = {
      admins: query?.authorization.admins.filter(
        auth => auth.value !== oldAuth?.value,
      ),
      readers: query?.authorization.readers.filter(
        auth => auth.value !== oldAuth?.value,
      ),
    };

    if (newAuth.isAdmin) {
      updatedAuth.admins = [
        ...updatedAuth.admins,
        {
          data_type: newAuth.type,
          value: newAuth.value,
        },
      ];
    }

    if (newAuth.isReader) {
      updatedAuth.readers = [
        ...updatedAuth.readers,
        {
          data_type: newAuth.type,
          value: newAuth.value,
        },
      ];
    }

    setQuery({
      ...query,
      authorization: updatedAuth,
    });
  };

  const onAuthorizationSave = async () => {
    setIsLoading(true);
    try {
      if (query) {
        await nsqlApi.updateNSQLQueryAuthorization(
          query.id,
          query.authorization,
        );
      }
      setSavedQuery(query);
    } catch (err: any) {
      setError(err.toString());
    } finally {
      setIsLoading(false);
    }
  };

  const queryAuthorizationView = mapToAuthorizationView(query);

  const hasAuthorizationChanged =
    JSON.stringify(queryAuthorizationView) !==
    JSON.stringify(mapToAuthorizationView(savedQuery));

  const COLUMNS: TableColumn<NSQLAuthorizationView>[] = [
    {
      title: 'Type(What?)',
      field: 'type',
      editComponent: auth => (
        <Select
          items={AUHTORIZATION_SUBJECTS_LIST}
          onChange={e => auth.onChange(e.toString())}
          label=""
          selected={auth.value || AUHTORIZATION_SUBJECTS_LIST[0].value}
        />
      ),
    },
    {
      title: 'Value(Who?)',
      field: 'value',
      validate: validateValue,
      render: auth => {
        if (auth.type === 'team') {
          return (
            <EventTracker {...onTeamClick()}>
              <Link color="primary" to={`/catalog/default/group/${auth.value}`}>
                {auth.value}
              </Link>
            </EventTracker>
          );
        }
        if (auth.type === 'user') {
          return (
            <EventTracker {...onUserClick()}>
              <Link color="primary" to={`/catalog/default/user/${auth.value}`}>
                {auth.value}
              </Link>
            </EventTracker>
          );
        }
        return (
          <EventTracker {...onApplicationClick()}>
            <Link
              color="primary"
              to={`/catalog/default/Component/${auth.value.replace(
                'stups_',
                '',
              )}`}
            >
              {auth.value}
            </Link>
          </EventTracker>
        );
      },
    },
    {
      title: 'Is administrator?',
      field: 'isAdmin',
      type: 'boolean',
      validate: validateRole,
    },
    {
      title: 'Is reader?',
      field: 'isReader',
      type: 'boolean',
      validate: validateRole,
    },
  ];

  return (
    <Page themeId="nakadi">
      <Header
        title={query?.id}
        subtitle={`Nakadi / SQL Queries / ${query?.id}`}
      >
        {query && (
          <>
            <HeaderLabel
              label="Owning Application"
              value={
                <EventTracker {...onOwningAppClick()}>
                  <Link
                    data-testid="owning-application-header"
                    color="inherit"
                    underline="always"
                    to={`/catalog/default/Component/${query?.output_event_type.owning_application.replace(
                      'stups_',
                      '',
                    )}/nakadi-events`}
                  >
                    {query?.output_event_type.owning_application}
                  </Link>
                </EventTracker>
              }
            />
            <HeaderLabel
              label="Created"
              value={query && toISODateTime(new Date(query.created))}
            />
            <HeaderLabel
              label="Updated"
              value={query && toISODateTime(new Date(query.updated))}
            />
          </>
        )}
      </Header>
      <Content>
        <Grid container>
          <Grid item xs={12}>
            {query && !hasTeamsAsAdmin(queryAuthorizationView) && (
              <Alert severity="warning">
                This query is not managed by a team. Please add your team as an
                admin!
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            {error && <Alert severity="error">{error}</Alert>}
            <MaterialTable
              title="Authorization"
              columns={COLUMNS}
              data={queryAuthorizationView}
              isLoading={isLoading}
              options={{
                search: true,
                sorting: true,
                paging: false,
                actionsColumnIndex: -1,
              }}
              editable={{
                onRowDelete: onDeleteRecord,
                onRowUpdate: onUpdateRecord,
                onRowAdd: onAddRecord,
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <Paper elevation={4}>
              <Button
                disabled={!hasAuthorizationChanged}
                onClick={onAuthorizationSave}
                variant="outlined"
                fullWidth
                size="large"
                color="secondary"
              >
                Save
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
}
