import React, { useState } from 'react';
import { useParams } from 'react-router';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import { colors } from 'plugin-ui-components';
import { MarkdownContent } from '@backstage/core-components';
import { RollbackOption } from '../../../api/types/responses';
import { Dialog, TagSmall, Text, Tooltip } from '../../../common';
import { filterPlans, getLabelByType, getURLByType } from './helpers';
import {
  useOptionStyles,
  useRollbackPlanStyles,
  useRollbackSummaryStyles,
} from './styles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export type RollbackStrategy = {
  plans: RollbackOption[];
};

export function PlanForm({
  action,
  plan,
  hasTitle,
  showConfirmationModal,
}: {
  plan: RollbackStrategy;
  action: (url: string) => void;
  hasTitle: boolean;
  showConfirmationModal: boolean;
}) {
  const [optionIdx, setOptionIdx] = useState<number>(0);
  const classes = useRollbackPlanStyles();

  const { disabled, available } = filterPlans(plan.plans);
  const selected = available[optionIdx];

  return (
    <article className={classes.plan}>
      {hasTitle && (
        <h1 className={classes.plan__title}>
          <span>Available rollback plans</span>
        </h1>
      )}

      <section className={classes.plan__from}>
        <ul className={classes.plan__list}>
          {available.map((option, idx) => {
            return (
              <li key={idx} className={classes.plan__option}>
                <PlanOption
                  option={option}
                  value={idx}
                  selected={optionIdx === idx}
                  action={setOptionIdx}
                />
              </li>
            );
          })}
          {disabled.map((option, idx) => {
            return (
              <li
                key={idx}
                className={`${classes.plan__option} ${classes.plan__disabled}`}
              >
                <PlanOptionDisabled option={option} />
              </li>
            );
          })}
        </ul>

        {!!selected && (
          <PlanSummary
            selected={selected}
            action={action}
            showConfirmationModal={showConfirmationModal}
          />
        )}
      </section>
    </article>
  );
}

function PlanOption({
  selected,
  option,
  value,
  action,
}: {
  selected: boolean;
  option: RollbackOption;
  value: number;
  action: (i: number) => void;
}) {
  const classes = useOptionStyles();

  function handleSelection() {
    action(value);
  }

  return (
    <div>
      <FormControlLabel
        classes={{ label: classes.label }}
        control={<Radio checked={selected} onChange={handleSelection} />}
        value={value}
        label={getLabelByType(option)}
      />
    </div>
  );
}

function PlanOptionDisabled({ option }: { option: RollbackOption }) {
  const classes = useOptionStyles();

  return (
    <div>
      <p className={classes.title}>
        <TagSmall>disabled</TagSmall>&nbsp;
        {getLabelByType(option)}
      </p>

      <span>
        <strong>Reason:</strong>&nbsp;
        {option.metadata.disable_reason}
      </span>
    </div>
  );
}

function PlanSummary({
  selected,
  action,
  showConfirmationModal,
}: {
  selected: RollbackOption;
  action: (a: string) => void;
  showConfirmationModal: boolean;
}) {
  const classes = useRollbackSummaryStyles();
  const { domain_alias = '', org_name = '', repo_name = '' } = useParams();
  const [open, setOpen] = useState(false);

  function handleRollback() {
    return action(selected.action);
  }

  function openConfirmationModal() {
    setOpen(true);
  }

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          title="Are you sure you want to perform the Rollback ?"
          children={
            <>
              <DialogContent>
                <Text>Rollback will proceed with the selected plan.</Text>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleRollback}
                  color="primary"
                  variant="contained"
                >
                  Confirm
                </Button>
              </DialogActions>
            </>
          }
        />
      )}
      <p className={classes.title}>Selected plan summary</p>

      <section className={classes.description}>
        <div className={classes.summary}>
          <p>Type</p>
          <TagSmall color={colors.brand.primaryOrange}>
            {selected.type}
          </TagSmall>
        </div>

        <div className={classes.summary}>
          <p>Deployment version</p>
          <p>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={getURLByType(selected, domain_alias, org_name, repo_name)}
            >
              {selected.metadata.pipeline_build_version}
            </Link>
          </p>
        </div>

        <div className={classes.summary}>
          <p>Details</p>
          <MarkdownContent content={selected.description} dialect="gfm" />
        </div>
      </section>

      {!showConfirmationModal && (
        <section className={classes.action}>
          <Tooltip title="⚠️ Deployment rollback will start after click! ⚠️">
            <Button
              color="primary"
              variant="contained"
              onClick={handleRollback}
            >
              Rollback
            </Button>
          </Tooltip>
        </section>
      )}

      {showConfirmationModal && (
        <section className={classes.action}>
          <Button
            color="primary"
            variant="contained"
            onClick={openConfirmationModal}
          >
            Rollback
          </Button>
        </section>
      )}
    </>
  );
}
