import React from 'react';
import Box from '@mui/material/Box';

import { IProvidedParameter } from '../../../../api/types/payload';
import { InfoCard, List, ListItem, Emoji } from '../../../../common';

export function ProvidedParameters({
  parameters,
}: {
  parameters: IProvidedParameter[];
}) {
  return (
    <InfoCard
      title={
        <span>
          <Emoji name="list" emoji="📝" /> Provided parameters
        </span>
      }
    >
      <details>
        <summary>List of provided parameters</summary>

        <Box marginTop={2}>
          <List>
            {parameters.map(param => (
              <ListItem>
                <span>{param.name}:&nbsp;</span>
                <strong>{param.value.toString()}</strong>
              </ListItem>
            ))}
          </List>
        </Box>
      </details>
    </InfoCard>
  );
}
