import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { type SelectProps } from '@mui/material/Select';
import { Currency, CurrencyType } from '../../types';
import { findAlways } from '../../utils/assert';
import { useSelectStyles as useStyles } from '../../utils/styles';

const NULL_VALUE = 'engineers';

type CurrencySelectProps = {
  currency: Currency;
  currencies: Currency[];
  onSelect: (currency: Currency) => void;
};

export const CurrencySelect = ({
  currency,
  currencies,
  onSelect,
}: CurrencySelectProps) => {
  const classes = useStyles();

  const getOption = (value: unknown) => {
    const kind = (value === NULL_VALUE ? null : value) as CurrencyType;
    return findAlways(currencies, c => c.kind === kind);
  };

  const handleOnChange: SelectProps['onChange'] = e => {
    const option = getOption(e.target.value);
    onSelect(option);
  };

  const renderValue: SelectProps['renderValue'] = value => {
    const option = getOption(value);
    return <b>{option.label}</b>;
  };

  return (
    <FormControl variant="outlined">
      <InputLabel shrink>Convert to:</InputLabel>
      <Select
        className={classes.select}
        variant="outlined"
        label="Convert to:"
        onChange={handleOnChange}
        value={currency.kind || NULL_VALUE}
        renderValue={renderValue}
      >
        {currencies.map((c: Currency) => (
          <MenuItem
            className={classes.menuItem}
            key={c.kind || NULL_VALUE}
            value={c.kind || NULL_VALUE}
          >
            <span role="img" aria-label={c.label}>
              {c.label}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
