import React from 'react';
import Link from '@mui/material/Link';

interface EditMessageProps {
  hasEditPermission: boolean | undefined;
  onEdit: Function;
}

export function EditMessage({ hasEditPermission, onEdit }: EditMessageProps) {
  if (!hasEditPermission) return <></>;

  return (
    <>
      Please{' '}
      <Link
        onClick={event => {
          event.preventDefault();
          onEdit();
        }}
        href="#"
      >
        <strong>edit</strong>
      </Link>{' '}
      to register.
    </>
  );
}

interface RegisterMessageProps {
  hasEditPermission: boolean | undefined;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

export function RegisterMessage({
  hasEditPermission,
  onClick,
}: RegisterMessageProps) {
  if (!hasEditPermission) return <></>;

  return (
    <>
      Please{' '}
      <Link onClick={onClick} href="#">
        <strong>register</strong>
      </Link>
      .
    </>
  );
}
