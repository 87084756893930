import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import React from 'react';
import { FilterGroupsContext } from '../../../../context';
import { useStyles } from '../styles';

export const ScorecardsFilter = () => {
  const { availableScorecards, scorecardsFilter, setScorecardsFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();
  return (
    <>
      <FormControl size="small" margin="none">
        <Autocomplete
          id="scorecards-filter"
          size="small"
          multiple
          options={availableScorecards}
          value={scorecardsFilter}
          classes={{ inputRoot }}
          onChange={(_: any, value) => setScorecardsFilter(value)}
          renderInput={params => (
            <TextField
              label="Scorecards"
              {...params}
              variant="outlined"
              size="small"
              margin="dense"
            />
          )}
        />
      </FormControl>
    </>
  );
};
