import React, { useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import type { SvgIconTypeMap } from '@mui/material/SvgIcon';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { useHoverAndCopy } from 'plugin-ui-components';
import { TrackedSharableLink } from '../TrackedSharableLink';
import { isLinkOnPath, isTitleEqualToPath, stringToPath } from './utils';
import { useStyles } from './styles';
import { SUPPORT_PAGE_URL } from '../../constants';

const SupportSection = ({ title, Icon, links }: SupportItem) => {
  const classes = useStyles();
  const { section, subject } = useParams();
  const { copyText, hover, handleMouseOut, copyToClipboard, handleHover } =
    useHoverAndCopy();

  const handleSupportClick = (linkName: string, channel: string) => {
    return [linkName, channel];
  };

  const isSectionActive = isTitleEqualToPath({
    title,
    path: section,
  });

  const sectionRef = useRef(null);

  const scroll = (el: HTMLElement | null) => {
    el?.scrollIntoView({ behavior: 'smooth' });
  };

  useLayoutEffect(() => {
    if (sectionRef.current !== null && isSectionActive) {
      scroll(sectionRef.current);
    }
  }, [isSectionActive]);

  if (!Icon || !title || !links) {
    return <></>;
  }

  return (
    <div
      className={
        isSectionActive ? classes.sectionHighlight : classes.sectionContainer
      }
    >
      <div
        ref={sectionRef}
        className={classes.sectionHeader}
        onMouseOver={handleHover}
        onFocus={handleHover}
        onMouseLeave={handleMouseOut}
      >
        <Icon className={classes.headerIcon} />
        <Typography className={classes.headerText}>{title}</Typography>

        {hover && (
          <Tooltip placement="top" title={copyText}>
            <FileCopyOutlined
              className={classes.copyIcon}
              onClick={() =>
                copyToClipboard(`${SUPPORT_PAGE_URL}/${stringToPath(title)}`)
              }
              fontSize="small"
            />
          </Tooltip>
        )}
      </div>
      <div className={classes.links}>
        {links.map(({ linkName, channel, link }) => (
          <div key={`${linkName}${channel}`}>
            <TrackedSharableLink
              title={title}
              linkName={linkName}
              className={
                isLinkOnPath({
                  linkName,
                  isGroupActive: isSectionActive,
                  path: subject,
                })
                  ? classes.linkHighlight
                  : classes.linkItem
              }
              key={linkName}
              href={link}
              target="_blank"
              onClick={() => handleSupportClick(linkName, channel)}
              eventAction="click"
              interaction="onClick"
              eventLabel={linkName}
              eventCategory="Support Page"
              plugin="support"
            >
              {linkName}
            </TrackedSharableLink>
          </div>
        ))}
      </div>
    </div>
  );
};

type SupportItem = {
  title: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  links: SupportLink[];
};

type SupportLink = {
  link: string;
  linkName: string;
  channel: string;
};

export const SupportSections = ({ list }: { list: SupportItem[] }) => (
  <div>
    {list.map(supportSection => (
      <SupportSection key={supportSection.title} {...supportSection} />
    ))}
  </div>
);
