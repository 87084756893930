import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/lab/Autocomplete';
import type { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const apecStatuses: ApecStatusType[] = ['green', 'amber', 'red', 'notrelevant'];
const label = 'APEC status';
const id = 'apec-filter';
export const ApecStatusFilter = React.memo(() => {
  const { apecFilter, setApecFilter } = React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  return (
    <FormControl size="small" margin="none">
      <Autocomplete
        id={id}
        multiple
        options={apecStatuses}
        value={apecFilter}
        onChange={(_: any, value) => setApecFilter(value)}
        classes={{ inputRoot }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            margin="dense"
            size="small"
          />
        )}
      />
    </FormControl>
  );
});
