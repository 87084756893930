import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

interface KV {
  key: string;
  value: any;
}
interface KVTableProps {
  data: KV[];
}

export const KVTable = (props: KVTableProps) => {
  return (
    <Table>
      <TableBody>
        {props.data.map(kv => (
          <TableRow key={kv.key}>
            <TableCell component="th" scope="row">
              {kv.key}
            </TableCell>
            <TableCell component="th" scope="row">
              {kv.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
