import React, { type Dispatch, type SetStateAction } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { removePendingUpdates, setPendingUpdates } from '../../utils';
import { useStyles } from './styles';

export const ClusterItem = ({
  id,
  alias,
  environment,
  initiallySelected = false,
  editable,
  updatedData,
  setUpdatedData,
  selectAll,
}: IAccessControl.KubernetesCluster & {
  initiallySelected: boolean;
  editable: boolean;
  selectAll: boolean;
  updatedData: any;
  setUpdatedData: Dispatch<SetStateAction<any[]>>;
}) => {
  const [isChecked, setIsChecked] = React.useState(false);

  const styles = useStyles();

  const handleChange = (checked: boolean, currentData?: any) => {
    setIsChecked(checked);

    const editList = currentData ?? [...updatedData];
    if ((initiallySelected && !checked) || (!initiallySelected && checked)) {
      editList.push({ id, alias, environment, selected: checked });
      setPendingUpdates('kubeClusters');
      setUpdatedData(editList);
    } else if (
      (!initiallySelected && !checked) ||
      (initiallySelected && checked)
    ) {
      const index = editList.findIndex((item: any) => item.id === id);
      if (index !== -1) editList.splice(index, 1);
      if (!editList.length) removePendingUpdates('kubeClusters');
      setUpdatedData(editList);
    }
  };

  React.useEffect(() => {
    if (typeof selectAll === 'boolean') {
      setIsChecked(selectAll);
      handleChange(selectAll, updatedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  React.useEffect(() => {
    if (initiallySelected) setIsChecked(initiallySelected);
    return () => setIsChecked(false);
  }, [initiallySelected]);

  const shouldBeChecked = () => {
    if (selectAll) return true;
    if (initiallySelected) return isChecked;
    return updatedData.some((item: any) => item.id === id);
  };

  return editable ? (
    <FormControlLabel
      key={`${alias}-${environment}`}
      control={
        <Checkbox
          className={styles.checkbox}
          size="small"
          checked={shouldBeChecked()}
          onChange={e => handleChange(e.target.checked)}
          name="clusters"
        />
      }
      label={
        <Typography variant="body2">
          {alias} ({environment})
        </Typography>
      }
    />
  ) : (
    <Typography variant="body2" key={id} className={styles.clusterItem}>
      {alias} ({environment})
    </Typography>
  );
};
