import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  action: {
    width: '100%',
    border: 'none',
    background: 'transparent',
    textAlign: 'left',
    cursor: 'pointer',

    '&:active': {
      transform: 'translateY(1px)',
    },
  },
});
