import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { FeatureFlagIcon } from 'plugin-ui-components';
import { useAnalysisByOrgPercentage } from '../../../hooks';

export const AnalysisGradeTitle = ({ entity }: { entity: IEntityGroup }) => {
  const percentage = useAnalysisByOrgPercentage(entity);

  return (
    <Tooltip
      title={`Around ${percentage}% of your applications are using Codacy.`}
    >
      <span>
        Code Quality <FeatureFlagIcon name="code-quality" />{' '}
      </span>
    </Tooltip>
  );
};
