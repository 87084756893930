import React from 'react';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  button: {
    padding: '1px 9px',
    margin: '0 5px',
    '&:active': {
      transform: 'translateY(1px)',
    },
  },
  icon: {
    width: '13px',
    marginRight: '3px',
  },
});

export function InfoButton({
  children,
  action,
}: {
  children: React.ReactNode;
  action: () => void;
}) {
  const classes = useStyles();

  return (
    <Button
      classes={{ root: classes.button }}
      variant="outlined"
      onClick={action}
      size="small"
    >
      <InfoIcon className={classes.icon} />
      <small>{children}</small>
    </Button>
  );
}
