import React from 'react';
import { InfoCard } from '@backstage/core-components';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/lab/Alert';
import { TrackedLink } from 'plugin-ui-components';
import { Subscriptions } from '../../domain/Subscriptions';
import { Status } from '../../hooks/useApiCall';
import { SubscriptionStats } from '../SubscriptionStats';
import { StyledTableCell } from '../Generic';

export type SubscriptionListProps = {
  application: string;
};

export const SubscriptionWithStats = ({
  nakadiUiURL,
  subscriptions,
  status,
  isLoading,
  application,
}: SubscriptionProps & Status) => {
  if (isLoading) {
    return (
      <TableRow>
        <StyledTableCell colSpan={12}>
          <LinearProgress data-testid="loading_indicator" />
        </StyledTableCell>
      </TableRow>
    );
  }

  if (status.isError) {
    return (
      <TableRow>
        <StyledTableCell colSpan={12}>
          <Alert severity="error">{status.error}</Alert>
        </StyledTableCell>
      </TableRow>
    );
  }

  if (subscriptions.length === 0) {
    return (
      <TableRow>
        <StyledTableCell colSpan={12}>
          <Alert severity="info">
            <span>
              No subscriptions found! Please make sure your application has
              explicit
              <TrackedLink
                plugin="nakadi"
                eventCategory="Subscription List"
                eventLabel="No subscription found - Subscription redirect to Nakadi UI"
                to="https://nakadi.docs.zalando.net/nakadi-event-broker/authorization/"
                target="_blank"
              >
                {' '}
                read{' '}
              </TrackedLink>
              permissions on the subscriptions you expected to see here.
            </span>
          </Alert>
        </StyledTableCell>
      </TableRow>
    );
  }

  return (
    <React.Fragment>
      {subscriptions.map(sub => (
        <SubscriptionStats
          key={sub.id}
          subscription={sub}
          nakadiUiURL={nakadiUiURL}
          application={application}
        />
      ))}
    </React.Fragment>
  );
};

export type SubscriptionProps = {
  subscriptions: Subscriptions[];
  isLoading: boolean;
  nakadiUiURL: string;
  application: string;
};

export const SubscriptionList = (
  props: SubscriptionListProps & SubscriptionProps & Status,
) => (
  <InfoCard
    title="Subscriptions"
    subheader={`All subscriptions consumed by ${props.application}`}
  >
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <strong>Subscription</strong>
            </StyledTableCell>
            <StyledTableCell>
              <strong>Event Type(s)</strong>
            </StyledTableCell>
            <StyledTableCell align="right">
              <strong>Unconsumed Events</strong>
            </StyledTableCell>
            <StyledTableCell align="right">
              <strong>Consumer Lag</strong>
            </StyledTableCell>
            <StyledTableCell align="center" size="small" padding="none">
              <strong>Owned</strong>
            </StyledTableCell>
            <StyledTableCell align="center" size="small" padding="none">
              <strong>Consuming</strong>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <SubscriptionWithStats {...props} />
        </TableBody>
      </Table>
    </TableContainer>
  </InfoCard>
);
