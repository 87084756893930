import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import { ProductInsightsCard } from './ProductInsightsCard';
import { Duration, Entity, Product } from '../../types';
import { ProductState } from '../../utils/loading';

type ProductInsightsCardListProps = {
  initialStates: ProductState[];
  onSelectAsync: (product: Product, duration: Duration) => Promise<Entity>;
};

export const ProductInsightsCardList = ({
  initialStates,
  onSelectAsync,
}: ProductInsightsCardListProps) => {
  if (!initialStates.length) {
    return (
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Collapse in timeout={1000}>
      {initialStates.map(({ product, entity, duration }) => (
        <Box
          key={product.kind}
          mb={6}
          position="relative"
          data-testid={`product-list-item-${product.kind}`}
        >
          <ProductInsightsCard
            product={product}
            onSelectAsync={onSelectAsync}
            initialState={{ entity: entity, duration: duration }}
          />
        </Box>
      ))}
    </Collapse>
  );
};
