import React from 'react';
import Grid from '@mui/material/Grid';
import { EventTracker, InfoCard } from 'plugin-ui-components';
import { ResourceLink, resourceLinkTypes } from './ResourceLink';
import EditIcon from '@mui/icons-material/Edit';
import { useEntity } from '@backstage/plugin-catalog-react';
import { attemptEditWhenDisabled } from '../../../../tracking';

export const ResourceLinksWidget = () => {
  const { entity } = useEntity<IEntityDomain>();
  const resources = entity.spec.spec.resources;
  const disabledResources = resourceLinkTypes.filter(resource => {
    return !resources.map(item => item.type).includes(resource.type);
  });
  return (
    <InfoCard
      title="Resource Links"
      cardHeaderProps={{
        action: (
          <EventTracker
            {...attemptEditWhenDisabled('Domain Overview - Resource Links')}
          >
            <EditIcon color="disabled" />
          </EventTracker>
        ),
      }}
    >
      <Grid container spacing={2} style={{ padding: '1rem' }}>
        {resources.map(resource => {
          return (
            <Grid item xs={12} md={6}>
              <ResourceLink
                link={resource.uri}
                type={resource.type}
                domain={entity.metadata.title}
              />
            </Grid>
          );
        })}
        {disabledResources.map(resource => {
          return (
            <Grid item xs={12} md={6}>
              <ResourceLink
                link="#"
                type={resource.type}
                domain={entity.metadata.title}
                disabled
              />
            </Grid>
          );
        })}
      </Grid>
    </InfoCard>
  );
};
